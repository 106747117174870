import React from "react";
import styles from './styles.module.scss';
import Layout from "Layout";
import { OrganizationsTable } from "./OrganizationsTable";
import { FormattedMessage } from "react-intl";


export const ManageOrganizations = () => {
  return (
    <Layout>
      <div className={styles.manageDepartments}>
        <h3><FormattedMessage id="manageOrganizations" defaultMessage="Manage Organizations"/></h3>
        <OrganizationsTable/>
      </div>
    </Layout>
  );
}
