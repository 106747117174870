import { useCallback, useEffect, useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { UserInfo, useUserState } from "context/user";
import { makeDropdownOptionValue, DropdownOption, getSortProperty, getSortMethod, makeDropdownOptionText } from "components/Forms/Dropdown";
import { basicSorter, BasicSortOptions, BasicSortOptionsIntl, createAlphaNumericSorterByProperty } from "lib/sorter";
import { RoleType } from "actions/roleActions";
import { getUsers } from "actions/userActions";
import { getTranslatedText } from "lib/commonFunctions";
import { matchPath, useHistory } from "react-router-dom";
import { routePaths } from "App/routing";

enum UserSortOptions {
  FullName = "fullName",
  Organization = "organizationName",
  Role = "roleName"
}

const UserSortOptionsIntl: Map<UserSortOptions, MessageDescriptor> = new Map([
  [UserSortOptions.FullName, { id: "fullName", defaultMessage: "FullName" }],
  [UserSortOptions.Organization, { id: "organization", defaultMessage: "Organization" }],
  [UserSortOptions.Role, { id: "role", defaultMessage: "Role" }]
]);

export const useSortedUsers = (
  users: (UserInfo & RoleType)[] | undefined,
  setUsers: (users: (UserInfo & RoleType)[] | undefined) => void
) => {

  const intl = useIntl();
  const history = useHistory();
  const userInfo: UserInfo & RoleType = useUserState();

  // for each sort property (e.g. fullName), create a dropdown option corresponding to available sort methods (e.g. "A-Z")
  // the value for each dropdown option will be [sortProperty, sortMethod] e.g. ["fullName", "A-Z"]
  // the name for each dropdown option will be sortPropertyText and sortMethodText joined e.g. "FullName A-Z"
  // the id for each dropdown option determine the sequence of displayed options
  let userDropdownOptionsIndex = 0;
  const userSortDropdownOptions: DropdownOption[] = Object.values(UserSortOptions).reduce(
    (dropdownOptions: DropdownOption[], userSortOption) => {
      const userSortOptionText = getTranslatedText(intl, UserSortOptionsIntl.get(userSortOption));

      Object.values(BasicSortOptions).forEach((basicSortOption) => {
        const basicSortOptionText = getTranslatedText(intl, BasicSortOptionsIntl.get(basicSortOption));

        dropdownOptions.push({
          id: userDropdownOptionsIndex,
          value: makeDropdownOptionValue({
            sortProperty: userSortOption,
            sortMethod: basicSortOption
          }),
          name: makeDropdownOptionText(userSortOptionText, basicSortOptionText)
        });

        userDropdownOptionsIndex++;
      });
      
      return dropdownOptions;
    }, []
  );

  const initialSortPropertyAndMethod: string = userSortDropdownOptions[0].value;
  const initialSortProperty = getSortProperty(initialSortPropertyAndMethod);
  const initialSortMethod = getSortMethod(initialSortPropertyAndMethod);

  const [selectedSortProperty, setSelectedSortProperty] = useState<string>(initialSortProperty);
  const [selectedSortMethod, setSelectedSortMethod] = useState<string>(initialSortMethod);

  const populateUsers = useCallback(async (query?: string) => {
    try {
      const usersResult = await getUsers(query);
      const usersResultData = usersResult?.data;
      const sortedUsers = basicSorter(
        usersResultData ? [...usersResultData] : undefined, 
        selectedSortMethod, 
        createAlphaNumericSorterByProperty(selectedSortProperty)
      );

      setUsers(
        [
          routePaths.assignUser, 
          routePaths.editDepartmentUsers, 
          routePaths.addDepartmentUsers
        ].some(path => matchPath(history.location?.pathname, { path, exact: true })) 
          ? sortedUsers?.filter(u => (u.organizationId === userInfo.organizationId)) 
          : sortedUsers
      );
    } catch (err) {
      // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
      console.log(err);
    }
  }, [selectedSortProperty, selectedSortMethod, userInfo]);

  useEffect(() => {
    populateUsers();
  }, [userInfo.manageUsers, userInfo.organizationId, !users]);

  const onSortOptionChange = (sortPropertyAndMethod: string) => {
    const newSortProperty = getSortProperty(sortPropertyAndMethod);
    const newSortMethod = getSortMethod(sortPropertyAndMethod);
    setSelectedSortProperty(newSortProperty);
    setSelectedSortMethod(newSortMethod);

    const sortedUsers = basicSorter(
      users ? [...users] : undefined, 
      newSortMethod, 
      createAlphaNumericSorterByProperty(newSortProperty)
    );
    setUsers(sortedUsers);
  };

  return {
    userSortDropdownOptions,
    populateUsers,
    onSortOptionChange,
    sortedUsers: users // users in state are sorted
  }
}
