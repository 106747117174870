import React, { FC, useEffect } from 'react';
import LayoutPresentation from './Layout.Presentation';
import { logoutUser, userIsAuthorized } from 'actions/userActions';

const Layout :FC = (props) => {
  const countdown = 3600; // 1 hour
  const countdownStep = 60;
  const [counter, setCounter] = React.useState(countdown); 

  // Counter for 1 hour, refreshes page once auth token 
  // has expired to redirect 
  // back to login
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - countdownStep), 1000*60);

    if(counter <= 0) {
      logoutUser();
      window.location.reload();
      setCounter(countdown);
    }
  }, [counter]);

  return (
      <LayoutPresentation {...props} ></LayoutPresentation> 
  );

};

export default Layout;

