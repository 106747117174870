import * as base from "./lib/baseActions";
import { UserInfo } from 'context/user';
import { LoginErrors } from "pages/Login";
import { RoleType } from "actions/roleActions";
import { AddOrEditUserErrors } from "pages/AddOrEditUser";
import { CreatePasswordErrors } from "pages/CreatePassword";
import { ForgotPasswordErrors } from "pages/Login/ForgotPassword";
import { TwoFAFormErrors } from "pages/TwoFA";

export interface loginAdminData {
  email?: string;
  password?: string;
};

export interface addOrEditUserData {
  fullName?: string;
  email?: string;
  password?: string;
  roleId?: number;
  organizationId?: number;
};

export interface createPasswordData {
  password?: string;
  confirmPassword?: string;
};

export interface twoFAData {
  email?: string,
  twoFaCode?: string
};

export interface forgotPasswordData {
  email: string;
}

// returned users are filtered by permissions as well on the backend
const getUsers = async (searchQuery?: string | undefined): Promise<base.DataWrapper<UserInfo[]>> => {
  try {
    let getUsersUrl = 'adminPanelUsers';
    if (searchQuery) {
      getUsersUrl += `?searchQuery=${searchQuery}`;
    }
    const usersResult = await base.get(getUsersUrl);
    return usersResult;
  } catch (err) {
    throw err;
  }
};

const getAdminById = async (id: string | number) => {
  try {
    const userResult = await base.get(`adminPanelUser/${id}`);
    return userResult;
  } catch (err) {
    throw err;
  }
};

/// Fetch data for logged in user
const fetchAdminPanelUser = () => {
  return base.get("admin/me");
};

const loginAdminPanelUser = async (data: loginAdminData) : Promise<base.DataWrapper<UserInfo & RoleType>> => {
  const {email, password} = data;

  try {
    const adminPanelUser =  await base.post("adminLogin", { email, password });
    return adminPanelUser;
  } catch (err) {
    if (err?.original?.response?.status === 400) {
      throw base.customizeError(err, LoginErrors.InvalidCredentials);
    } else {
      throw base.customizeError(err, LoginErrors.General);
    }
  }
};

const createAdminPanelUser = async (data: addOrEditUserData) : Promise<base.DataWrapper<UserInfo & RoleType>> => {
  const { fullName, email, roleId, organizationId } = data;

  try {
    const createAdminPanelUserResult = await base.post("adminPanelUser", { fullName, email, roleId, organizationId });
    return createAdminPanelUserResult;
  } catch (err) {
    if (err?.original?.response?.status === 400) {
      throw base.customizeError(err, AddOrEditUserErrors.UserAlreadyExistsError);
    } else {
      throw base.customizeError(err, AddOrEditUserErrors.GeneralAddError);
    }
  }
};

const updateAdminPanelUser = async (data: addOrEditUserData) : Promise<base.DataWrapper<UserInfo & RoleType>> => {
  const { fullName, email, password, roleId, organizationId } = data;

  try {
    const updateAdminPanelUserResult = await base.put("adminPanelUser", { fullName, email, password, roleId, organizationId });
    return updateAdminPanelUserResult;
  } catch (err) {
    throw base.customizeError(err, AddOrEditUserErrors.GeneralEditError);
  }

};

const deleteAdminPanelUser = async (id: string | number | undefined) => {
  try {
    const userResult = await base.del(`adminPanelUser/${id}`);
    return userResult;
  } catch (err) {
    throw err;
  }
}

const logoutUser = () => {
  if (navigator.cookieEnabled) {
    localStorage.removeItem('authToken');
  }
};

const sendForgotPasswordEmail = async(data: forgotPasswordData) => {
  const { email } = data;

  try {
    const forgotPasswordResult = await base.put(`users/reset`, { email });
    return forgotPasswordResult;
  } catch (err) {
    if (err?.original?.response?.status === 400) {
      if (err?.original?.response?.data?.id?.includes("unknownUser")) {
        throw base.customizeError(err, ForgotPasswordErrors.UserDoesNotExist);
      }
      throw base.customizeError(err, ForgotPasswordErrors.General);
    }
  }
};

const resetPassword = async (data: createPasswordData, resetKey?: string) : Promise<base.DataWrapper<UserInfo & RoleType>> => {
  const { password, confirmPassword } = data;

  try {
    //returning (cleaned) user from backend only because their roleId is needed
    const updatedUserResult = await base.put(
      `passwordReset/${resetKey}`, 
      { password, confirmPassword }
    );
    return updatedUserResult;
  } catch (err) {
    throw base.customizeError(err, CreatePasswordErrors.General);
  }
};

// check if user is Authorized
const userIsAuthorized = () => {
  const initialAuthToken = localStorage?.getItem('authToken');
  return initialAuthToken ? true : false;
}

const verify2FACode = async (data: twoFAData) : Promise<base.DataWrapper<UserInfo & RoleType>> => {
  const {email, twoFaCode} = data;

  try {
    const loginResult = await base.post("validate2Fa", { email, twoFaCode });

    localStorage.setItem('authToken', loginResult.data.token);

    return fetchAdminPanelUser();
  } catch (err) {
    if (err?.original?.response?.status === 400) {
      throw base.customizeError(err, TwoFAFormErrors.Invalid2FACode);
    } else {
      throw base.customizeError(err, TwoFAFormErrors.General);
    }
  }
};

export {
  getUsers,
  getAdminById,
  fetchAdminPanelUser,
  loginAdminPanelUser,
  userIsAuthorized,
  createAdminPanelUser,
  updateAdminPanelUser,
  resetPassword,
  deleteAdminPanelUser,
  logoutUser,
  verify2FACode,
  sendForgotPasswordEmail,
};
