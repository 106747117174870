import { creatContextStore } from './base';
import { userIsAuthorized } from 'actions/userActions';

export interface AppInfo {
  Authenticated?: boolean; 
  Authenticating?: boolean;
};

let initialUserAuth: boolean = false;
if (userIsAuthorized) {
  initialUserAuth = !navigator.cookieEnabled ? false : userIsAuthorized();
}
const initialState = {
  Authenticated: initialUserAuth,
  Authenticating: false,
};

const [ Provider, useState, useSetState, useMergeState ] = creatContextStore<AppInfo>(initialState);

export {
  Provider as AppContextProvider,
  useState as useAppState,
  useSetState as useSetAppState,
  useMergeState as useMergeAppState,
};
