import { useReportUserNotesAnswersState, ReportUserNotesAnswers, getAnswerKey, Answer } from '../../context/reportUserNotesAnswers';
import { useTranslator } from "../../hooks/useTranslator";
import { NoteType } from "../../actions/noteActions";
import questions from '../../data/questions.json';
import { SectionType, QuestionType, QuestionTypes } from "../../actions/questionActions";
import { ReactNode } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet} from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';
import { reportStatuses, reportStatusTypes } from 'lib/reportStatuses';
import { convertBoolToYesNoIntl, dateFormatter , DateFormStyle} from 'lib/commonFunctions';
import { ReportFields } from 'actions/reportActions';
import { UserInfo, useUserState } from 'context/user';
import { RoleType } from 'actions/roleActions';
import { isOrganizationTypeCrisisCenter, isOrganizationTypeLawEnforcement, isOrganizationTypeCampus } from 'lib/permissions';

enum pdfSurvivorKeys {
  email = "email",
  ageRange = "ageRange",
  phoneNumber = "phoneNumber",
  methodOfContact = "methodOfContact"
} 

enum pdfReportKeys {
  reportKey = "reportKey",
  acknowledgementOfConsent = "acknowledgementOfConsent",
  allowPoliceContact = "allowPoliceContact",
  allowSupportCenterContact = "allowSupportCenterContact",
  allowVestaContact = "allowVestaContact",
  allowCampusContact = "allowCampusContact",
  policeStatus = "policeStatus",
  supportCenterStatus = "supportCenterStatus",
  dateSubmitted = "dateSubmitted",
  releaseVersion = "releaseVersion",
  methodOfContact = "methodOfContact",
  reportPathType = "reportPathType",
  createdAt = "createdAt",
  updatedAt = "updatedAt"
};

enum pdfNoteKeys {
  author = "author",
  note = "note",
  createdAt = "createdAt"
} 

const defaultText = "N/A";

export const ReportNotesAnswersDocument = () => {
  const userInfo: UserInfo & RoleType = useUserState();
  const reportUserNotesAnswers: ReportUserNotesAnswers = useReportUserNotesAnswersState();
  const translator = useTranslator();

  const stylesPdf = StyleSheet.create({
    page: {
      display: 'flex',
      padding: 20,
      table: true,
      width: 'auto',
      borderSpacing: '5px'
    },
    header: {
      display: 'flex',
      padding: 20,
      alignSelf: 'center',
      alignItems: 'center'
    },
    title: {
      fontSize: 15,
    },
    downloadDate: {
      fontSize: 8,
    },
    labelsAndDataContainer: {
      display: 'flex',
      marginBottom: '20px',
    },
    labelsContainer: {
      display: 'flex',
      border: '2px solid black',
      backgroundColor: '#6A788E',
      fontSize: 12,
    },
    labels: {
      flexWrap: 'wrap',
      border: '0.5px solid black',
      alignItems: 'center',
      paddingHorizontal: '5px',
    },
    dataContainer: {
      display: 'flex',
      width: '100%',
      border: '2px solid black',
    },
    singularData: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      border: '0.5px solid black',
      alignItems: 'stretch',
      backgroundColor: '#CECECE',
    },
    paragraphContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    survivorLabelsAndDataContainer: {
      flexDirection: 'column',
    },
    survivorLabelsContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    survivorLabels: {
      flexDirection: 'column',
      width: '100%',
    },
    survivorDataContainer: {
      flexDirection: 'column',
      fontSize: 12,
    },
    singularSurvivor: {
      fontSize: 12,
    },
    survivorDataKey: {
      width: '50%',
      paddingHorizontal: '5px',
      borderRight: '0.5px solid black',
      alignItems: 'center',
    },
    survivorData: {
      width: '50%',
      alignItems: 'center',
      paddingHorizontal: '5px',
    },
    survivorDidNotConsent: {
      alignItems: 'center',
      padding: '5px',
      fontSize: 12
    },
    reportLabelsAndDataContainer: {
      flexDirection: 'column',
    },
    reportLabelsContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    reportLabels: {
      flexDirection: 'column',
      width: '100%',
    },
    reportDataContainer: {
      flexDirection: 'column',
      fontSize: 12,
    },
    singularReport: {
      fontSize: 12,
    },
    value: {
      paddingHorizontal: '5px',
    },
    reportDataKey: {
      width: '50%',
      paddingHorizontal: '5px',
      borderRight: '0.5px solid black',
      alignItems: 'center',
    },
    reportData: {
      width: '50%',
      alignItems: 'center',
      paddingHorizontal: '5px',
    },
    noteLabelsAndDataContainer: {
      flexDirection: 'column',
    },
    noteLabelsContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    noteLabels: {
      flexDirection: 'column',
      width: '100%',
    },
    notesDataContainer: {
      flexDirection: 'column',
    },
    singularNote: {
      fontSize: 10,
    },
    noteData: {
      width: '100%',
      borderLeft: '0.5px solid black',
    },
    answerLabelsAndDataContainer: {
      flexDirection: 'column',
    },
    answerLabelsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    answerLabels: {
      flexDirection: 'column',
      width: '100%',
    },
    answersDataContainer: {
      flexDirection: 'column',
    },
    singularAnswer: {
      fontSize: 10,
    },
    question: {
      width: '50%',
      paddingHorizontal: '5px',
      borderRight: '0.5px solid black',
      alignItems: 'center',
    },
    answerData: {
      width: '50%',
      flexWrap: 'wrap',
    },
  });

  const pdfSurvivorLabels: ReactNode[] = [
    (
      <Text style={[stylesPdf.labels, stylesPdf.survivorLabels]} key="survivorDataKey">
        <FormattedMessage id="survivorDataKey" defaultMessage="Survivor Data Key"/>
      </Text>
    ),
    (
      <Text style={[stylesPdf.labels, stylesPdf.survivorLabels]} key="survivorDataValue">
        <FormattedMessage id="value" defaultMessage="Value"/>
      </Text>
    ),
  ];

  const pdfSurvivorData = Object.entries(reportUserNotesAnswers.reportUser || {})?.reduce((sanitizedSurvivorData: ReactNode[], [key, value]) => {
    if (Object.values(pdfSurvivorKeys).includes(key as pdfSurvivorKeys)) {  

      let sanitizedValue;
      if ((key === pdfSurvivorKeys.ageRange) && value?.length) {
        sanitizedValue = translator(value);
      } else {
        sanitizedValue = value || defaultText;
      }

      sanitizedSurvivorData.push(
        <View style={[stylesPdf.singularData, stylesPdf.singularSurvivor]} key={key}>
          <Text style={stylesPdf.survivorDataKey}>
            {translator(key)}
          </Text>
          <Text style={[stylesPdf.value, stylesPdf.survivorData]}>
            {sanitizedValue}
          </Text>
        </View>
      );
    }

    return sanitizedSurvivorData;
  }, []);

  const pdfReportLabels: ReactNode[] = [
    (
      <Text style={[stylesPdf.labels, stylesPdf.reportLabels]} key="reportDataKey">
        <FormattedMessage id="reportDataKey" defaultMessage="Report Data Key"/>
      </Text>
    ),
    (
      <Text style={[stylesPdf.labels, stylesPdf.reportLabels]} key="reportDataValue">
        <FormattedMessage id="value" defaultMessage="Value"/>
      </Text>
    ),
  ];

  const pdfReportData = Object.entries(reportUserNotesAnswers.report || {})?.reduce((sanitizedReportData: ReactNode[], reportKeyValue) => {
    if (Object.values(pdfReportKeys).includes(reportKeyValue[0] as pdfReportKeys)) {

      let sanitizedValue;
      if (reportStatusTypes.some(type => (type.key === reportKeyValue[0]))) {
        sanitizedValue = <FormattedMessage {...reportStatuses.find(status => (status.key === reportKeyValue[1]))?.intl}/>;
      } else if (
        [
          ReportFields.AllowVestaContact, 
          ReportFields.AllowPoliceContact, 
          ReportFields.AllowSupportCenterContact, 
          ReportFields.AllowCampusContact, 
          ReportFields.AcknowledgementOfConsent
        ].includes(reportKeyValue[0] as ReportFields)
      ) {
        sanitizedValue = <FormattedMessage {...convertBoolToYesNoIntl(reportKeyValue[1] as boolean)}/>;
      } else if ( 
        [
          pdfReportKeys.dateSubmitted
        ].includes(reportKeyValue[0] as pdfReportKeys)
      ) {
        sanitizedValue = reportKeyValue[1] && dateFormatter(reportKeyValue[1], DateFormStyle.TextDateTime) || defaultText
      } else {
        sanitizedValue = reportKeyValue[1] || defaultText;
      }
      sanitizedReportData.push(
        <View style={[stylesPdf.singularData, stylesPdf.singularReport]} key={reportKeyValue[0]}>
          <Text style={stylesPdf.reportDataKey}>
            {translator(reportKeyValue[0])}
          </Text>
          <Text style={[stylesPdf.value, stylesPdf.reportData]}>
            {sanitizedValue}
          </Text>
        </View>
      );
    }

    return sanitizedReportData;
  }, []);

  const pdfNoteLabels: ReactNode[] = Object.keys(pdfNoteKeys).map((pdfNoteKey) => (
    <Text style={[stylesPdf.labels, stylesPdf.noteLabels]} key={pdfNoteKey}>
      {translator(pdfNoteKey, 'note')}
    </Text>
  ));

  const pdfNoteData: ReactNode[] = reportUserNotesAnswers.notes?.map((note: NoteType) => (
    <View style={[stylesPdf.singularData, stylesPdf.singularNote]}>
      {Object.values(pdfNoteKeys).map((pdfNoteKey) => (
        <Text style={[stylesPdf.value, stylesPdf.noteData]}>
          {note[pdfNoteKey] || defaultText}
        </Text>
      ))}
    </View>
  )) || [];

  const pdfAnswerLabels: ReactNode[] = [
    (
      <Text style={[stylesPdf.labels, stylesPdf.answerLabels]} key="question">
        <FormattedMessage id="question" defaultMessage="Question"/>
      </Text>
    ),
    (
      <Text style={[stylesPdf.labels, stylesPdf.answerLabels]} key="answer">
        <FormattedMessage id="answer" defaultMessage="Answer"/>
      </Text>
    ),
  ];

  const pdfAnswerData: ReactNode[] = [];
  questions.sections.forEach((section: SectionType) => {
    section.questions.forEach((question: QuestionType) => {
      if (
        (question.type !== QuestionTypes.Attachments) && 
        (question.key !== "q-offender-face")
      ) {
        const answerKey: string = getAnswerKey(section.key, question.key);
        const answerObject: Answer | undefined = reportUserNotesAnswers?.answers?.get(answerKey);

        let paragraphs;
        if (typeof answerObject?.valueFormatted === "string") {
          paragraphs = answerObject?.valueFormatted?.split(/(.{2000})/).filter(O=>O);
          paragraphs.forEach((paragraph: string) => {
            pdfAnswerData.push(
            <View style={[stylesPdf.singularData, stylesPdf.singularAnswer]} id={question.key} key={question.key}>
              <Text style={stylesPdf.question}>{translator(question.key)}</Text>
              <View style={[stylesPdf.paragraphContainer]}>
                  <Text style={[stylesPdf.value, stylesPdf.answerData]}>{paragraph}</Text>
              </View>
            </View>
            );
          });         
          
        } else {
        
          pdfAnswerData.push(
          <View style={[stylesPdf.singularData, stylesPdf.singularAnswer]} id={question.key} key={question.key}>
            <Text style={stylesPdf.question}>{translator(question.key)}</Text>
            <View style={[stylesPdf.paragraphContainer]}>
                <Text style={[stylesPdf.value, stylesPdf.answerData]}>{answerObject?.valueFormatted || defaultText}</Text>
            </View>
          </View>
          );
        }
      }
    });
  }, []);

  return (
    <Document>
      <Page size="A4" style={stylesPdf.page}>
        <View style={stylesPdf.header}>
          <Text style={stylesPdf.title}>
            <FormattedMessage id="report" defaultMessage="Report"/> {reportUserNotesAnswers.report?.reportKey}
          </Text>
          <Text style={stylesPdf.downloadDate}>
            <FormattedMessage id="downloadDate" defaultMessage="Download Date"/>: {dateFormatter(new Date())}
          </Text>
        </View>

        {(
          (isOrganizationTypeLawEnforcement(userInfo.organizationTypeId) && reportUserNotesAnswers?.report?.[ReportFields.AllowPoliceContact]) || 
          (isOrganizationTypeCrisisCenter(userInfo.organizationTypeId) && reportUserNotesAnswers?.report?.[ReportFields.AllowSupportCenterContact]) ||
          (isOrganizationTypeCampus(userInfo.organizationTypeId) && reportUserNotesAnswers?.report?.[ReportFields.AllowCampusContact])
        ) ? (
          <View style={[stylesPdf.labelsAndDataContainer, stylesPdf.survivorLabelsAndDataContainer]}>
            <View style={[stylesPdf.labelsContainer, stylesPdf.survivorLabelsContainer]}>
              {pdfSurvivorLabels}
            </View>
            <View style={[stylesPdf.dataContainer, stylesPdf.survivorDataContainer]}>
              {pdfSurvivorData}
            </View>
          </View>
        ) : (
          <View style={stylesPdf.survivorDidNotConsent}>
            <Text>Survivor did not consent "Yes" to being contacted - their contact info is redacted</Text>
          </View>)}
        
        <View style={[stylesPdf.labelsAndDataContainer, stylesPdf.reportLabelsAndDataContainer]}>
          <View style={[stylesPdf.labelsContainer, stylesPdf.reportLabelsContainer]}>
            {pdfReportLabels}
          </View>
          <View style={[stylesPdf.dataContainer, stylesPdf.reportDataContainer]}>
            {pdfReportData}
          </View>
        </View>

        <View style={[stylesPdf.labelsAndDataContainer, stylesPdf.noteLabelsAndDataContainer]}>
          <View style={[stylesPdf.labelsContainer, stylesPdf.noteLabelsContainer]}>
            {pdfNoteLabels}
          </View>
          <View style={[stylesPdf.dataContainer, stylesPdf.notesDataContainer]}>
            {pdfNoteData}
          </View>
        </View>

        <View style={[stylesPdf.labelsAndDataContainer, stylesPdf.answerLabelsAndDataContainer]}>
          <View style={[stylesPdf.labelsContainer, stylesPdf.answerLabelsContainer]}>
            {pdfAnswerLabels}
          </View>
          <View style={[stylesPdf.dataContainer, stylesPdf.answersDataContainer]}>
            {pdfAnswerData}
          </View>
        </View>
      </Page> 
    </Document>
  );
};
