import { useTranslator } from "../../hooks/useTranslator";
import { ReactNode } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet} from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';
import { dateFormatter, DateFormStyle } from 'lib/commonFunctions';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ReportLocationType } from "actions/reportActions";

import classNames from "classnames";

export const ReportLocationsDocument = (data: Array<ReportLocationType>) => {
  const translator = useTranslator();

  const stylesPdf = StyleSheet.create({
    page: {
      display: 'flex',
      padding: 20,
      table: true,
      width: 'auto',
      borderSpacing: '5px'
    },
    header: {
      display: 'flex',
      padding: 20,
      alignSelf: 'center',
      alignItems: 'center'
    },
    title: {
      fontSize: 15,
    },
    downloadDate: {
      fontSize: 8,
    },
    labelsAndDataContainer: {
      display: 'flex',
      marginBottom: '20px',
    },
    labelsContainer: {
      display: 'flex',
      border: '2px solid black',
      backgroundColor: '#6A788E',
      fontSize: 12,
    },
    labels: {
      flexWrap: 'wrap',
      border: '0.5px solid black',
      alignItems: 'center',
      paddingHorizontal: '5px',
    },
    dataContainer: {
      display: 'flex',
      width: '100%',
      border: '2px solid black',
    },
    singularData: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      border: '0.5px solid black',
      alignItems: 'stretch',
      backgroundColor: '#CECECE',
    },
    paragraphContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#8B304B',
    },
    survivorLabelsAndDataContainer: {
      flexDirection: 'column',
    },
    survivorLabelsContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    survivorLabels: {
      flexDirection: 'column',
      width: '100%',
    },
    survivorDataContainer: {
      flexDirection: 'column',
      fontSize: 12,
    },
    singularSurvivor: {
      fontSize: 12,
    },
    survivorDataKey: {
      width: '50%',
      paddingHorizontal: '5px',
      borderRight: '0.5px solid black',
      alignItems: 'center',
    },
    survivorData: {
      width: '50%',
      alignItems: 'center',
      paddingHorizontal: '5px',
    },
    survivorDidNotConsent: {
      alignItems: 'center',
      padding: '5px',
      fontSize: 12
    },
    reportLabelsAndDataContainer: {
      flexDirection: 'column',
    },
    reportLabelsContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    reportLabels: {
      flexDirection: 'column',
      width: '100%',
    },
    reportDataContainer: {
      flexDirection: 'column',
      fontSize: 12,
    },
    singularReport: {
      fontSize: 12,
    },
    value: {
      paddingHorizontal: '5px',
    },
    reportDataKey: {
      width: '50%',
      paddingHorizontal: '5px',
      borderRight: '0.5px solid black',
      alignItems: 'center',
    },
    reportData: {
      width: '50%',
      alignItems: 'center',
      paddingHorizontal: '5px',
    },
    noteLabelsAndDataContainer: {
      flexDirection: 'column',
    },
    noteLabelsContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    noteLabels: {
      flexDirection: 'column',
      width: '100%',
    },
    notesDataContainer: {
      flexDirection: 'column',
    },
    singularNote: {
      fontSize: 10,
    },
    noteData: {
      width: '100%',
      borderLeft: '0.5px solid black',
    },
    answerLabelsAndDataContainer: {
      flexDirection: 'column',
    },
    answerLabelsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    answerLabels: {
      flexDirection: 'column',
      width: '100%',
    },
    answersDataContainer: {
      flexDirection: 'column',
    },
    singularAnswer: {
      fontSize: 10,
    },
    question: {
      width: '50%',
      paddingHorizontal: '5px',
      borderRight: '0.5px solid black',
      alignItems: 'center',
    },
    answerData: {
      width: '50%',
      flexWrap: 'wrap',
    },
  });

  const pdfReportLabels: ReactNode[] = [
    (
      <Text style={[stylesPdf.labels, stylesPdf.reportLabels]} key="reportDataDate">
        {translator('Date','stats')}
      </Text>
    ),
    (
      <Text style={[stylesPdf.labels, stylesPdf.reportLabels]} key="reportDataKey">
        {translator('Address','stats')}
      </Text>
    ),
    (
      <Text style={[stylesPdf.labels, stylesPdf.reportLabels]} key="reportDataType">
        {translator('Type','stats')}
      </Text>
    ),
  ];

  const pdfReportData = data?.reduce((sanitizedReportData: ReactNode[], reportKeyValue) => {
    sanitizedReportData.push(
      <View style={[stylesPdf.singularData, stylesPdf.singularReport]} key={`${reportKeyValue.address}:${reportKeyValue.date}`}>
        <Text style={[stylesPdf.value, stylesPdf.reportData]}>
          {(reportKeyValue.date && dateFormatter(reportKeyValue.date, DateFormStyle.TextDateTime)) || ''}
        </Text>
        <Text style={[stylesPdf.value, stylesPdf.reportData]}>
          {reportKeyValue.address}
        </Text>
        <Text style={[stylesPdf.value, stylesPdf.reportData]}>
          {reportKeyValue.type || ''}
        </Text>
      </View>
    );

    return sanitizedReportData;
  }, []);


  return (
    <Document>
      <Page size="A4" style={stylesPdf.page}>
        <View style={stylesPdf.header}>
          <Text style={stylesPdf.title}>
            <FormattedMessage id="report" defaultMessage="Report"/> 
          </Text>
          <Text style={stylesPdf.downloadDate}>
            <FormattedMessage id="downloadDate" defaultMessage="Download Date"/>: {dateFormatter(new Date())}
          </Text>
        </View>

        <View style={[stylesPdf.labelsAndDataContainer, stylesPdf.reportLabelsAndDataContainer]}>
          <View style={[stylesPdf.labelsContainer, stylesPdf.reportLabelsContainer]}>
            {pdfReportLabels}
          </View>
          <View style={[stylesPdf.dataContainer, stylesPdf.reportDataContainer]}>
            {pdfReportData}
          </View>
        </View>

      </Page> 
    </Document>
  );
};

interface pdfLinkProps {
  reportLocationData: Array<ReportLocationType>;
  iconClassName: string;
  downloadUrl: string;
  className: string;
  children: ReactNode;
}

export const DownloadReportPDFLink = ({
  reportLocationData,
  iconClassName,
  downloadUrl,
  className,
  children
}: pdfLinkProps) => {

  return (
    <div className={className}>
      <div className={classNames("i--icon-download", iconClassName)}/>
      <PDFDownloadLink 
        document={ReportLocationsDocument(reportLocationData)}
        style={{
          display: 'flex',
          textDecoration: 'underline',
          fontWeight: 800,
          flex: 1
        }}
        fileName={downloadUrl}
      >
        {({ blob, url, loading, error }: any) => { 
          if (loading) {
            return (<FormattedMessage id="loading" defaultMessage="Loading"/>)
          } else {
            return (<>
              {children} 
            </>);
          }
        }}
      </PDFDownloadLink>
    </div>
  );
}
