import { Form, useFormikContext } from 'formik';
import { Button, ButtonStyle } from 'components/Buttons';
import { TextField, TextFieldTypes, TextFieldEventType } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ArrowIcon } from 'components/Icons';
import _ from 'lodash';
import { CreatePasswordFormFields } from '..';
import { ErrorType } from 'actions/lib/baseActions';

interface CreatePasswordFormProps {
  createPasswordError: ErrorType | null;
  setCreatePasswordError: (value: ErrorType | null) => void;
}

export const CreatePasswordForm = ({ createPasswordError, setCreatePasswordError }: CreatePasswordFormProps) => {

  const { values, handleChange, isSubmitting, setSubmitting, errors, submitForm } = useFormikContext<CreatePasswordFormFields>();

  const onTextFieldChange = (e: TextFieldEventType) => {
    handleChange(e);
    setCreatePasswordError(null); 
    setSubmitting(false); 
  };
  
  // TODO: add on key down func for entering password too, https://trello.com/c/siwp0hRR/625-create-password-form-keydown

  return (
    <Form className={styles.createPasswordForm}>
      <TextField type={TextFieldTypes.Password} 
        name="password" 
        placeholder="Password"  
        value={values.password}
        onChange={onTextFieldChange}
        className={classNames(styles.inputField, errors.password && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.password}/>

      <TextField type={TextFieldTypes.Password} 
        name="confirmPassword" 
        placeholder="Confirm Password"  
        value={values.confirmPassword}
        onChange={onTextFieldChange}
        className={classNames(styles.inputField, errors.confirmPassword && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.confirmPassword}
        icon={<ArrowIcon className={styles.arrowIcon} onClick={() => { return _.isEmpty(errors) && submitForm(); }} />}/>

      <Button loading={isSubmitting} 
        disabled={isSubmitting} 
        type="submit"
        style={ButtonStyle.Primary}
        className={styles.createPasswordButton}>
        <FormattedMessage id="buttons.createPassword" defaultMessage="Create Password"/>
      </Button>

      {_.isEmpty(errors) && createPasswordError && (
        <div className={styles.errorMessage}>
          <FormattedMessage id={createPasswordError.intlId} defaultMessage={createPasswordError.defaultMessage || "Sorry, there was an error."}/>
        </div>
      )}
    </Form>
  );
};
