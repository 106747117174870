import { useEffect, ChangeEvent, useCallback, ReactNode } from 'react'
import { getOrganizations, Organization } from 'actions/organizationActions';
import { Field, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { AddOrEditUserFormFields } from '..';
import styles from './styles.module.scss';
import { matchPath, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { UserInfo, useUserState } from 'context/user';
import { routePaths } from 'App/routing';
import { RoleType } from 'actions/roleActions';


export const OrganizationsDropdown =  () => {

  const userInfo = useUserState();
  
  const history: any = useHistory();
  const currentLocation = history.location;
  const currentPath = currentLocation.pathname;
  const currentState = currentLocation.state as { user: UserInfo & RoleType };
  const userToEdit = currentState?.user; 

  const isAddUserPage = matchPath(currentPath, {
    path: routePaths.addUser,
    exact: true
  });

  const { values, setFieldValue } = useFormikContext<AddOrEditUserFormFields>();

  const userToEditOrganization = userToEdit ? _.find(values.organizations, (o) => { return (o.id === userToEdit.organizationId); }) : undefined;

  const setOrganizations = useCallback(async () => {
    try {
      const organizationsResult = await getOrganizations();
      if (organizationsResult?.data?.length) {
        setFieldValue('organizations', organizationsResult?.data);

        if (isAddUserPage) {
          const firstOrganizationResult = organizationsResult?.data[0];
          setFieldValue('organizationId', firstOrganizationResult?.id); // default organization in dropdown is first in list
          setFieldValue('organizationTypeId', firstOrganizationResult?.organizationTypeId); // default organization in dropdown is first in list
        }
      }
    } catch (err) {
      // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
      console.log(err);
    }
  }, []);
  
  useEffect(() => {
    setOrganizations();
  }, [userInfo.shareOrganization, userInfo.organizationId]);

  useEffect(() => {
    if (userToEditOrganization?.organizationTypeId) {
      setFieldValue('organizationTypeId', userToEditOrganization.organizationTypeId);
    }
    if (userToEditOrganization?.id) {
      setFieldValue('organizationId', userToEditOrganization.id);
    }
  }, [userToEditOrganization]);

  const onOrganizationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const organizationId = Number(e.target.value);
    setFieldValue('organizationId', organizationId);

    const organizationTypeId = values.organizations?.find((o) => (o.id === organizationId))?.organizationTypeId;
    if (values.organizationTypeId?.toString() !== organizationTypeId?.toString()) {
      setFieldValue('organizationTypeId', organizationTypeId); 
    } 
  };

  return (
    <div className={styles.organizationsDropdown}>
      <label htmlFor="organizationId">
        <FormattedMessage id="organization" defaultMessage="Organization"/>
      </label>

      <Field as="select" 
        name="organizationId" 
        onChange={onOrganizationChange}
        value={values.organizationId || userToEditOrganization?.id}>
          {values.organizations?.reduce((filteredOrganizations: ReactNode[], organization: Organization) => {
            if (organization.organizationTypeId?.toString() === values.organizationTypeId?.toString()) {
              filteredOrganizations.push(
                <option key={organization.id} value={organization.id}>
                  {organization.name}
                </option> 
              );
            }
            return filteredOrganizations;
          }, [])}
      </Field>
    </div>
  );
};
