import { useState } from "react";
import { Organization } from "actions/organizationActions";
import { PencilIcon } from "components/Icons";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import { routePaths } from "App/routing";
import styles from './styles.module.scss';
import classNames from "classnames";
import { ErrorGeneralModal } from "components/Modals/ErrorGeneralModal";
import { SearchInput } from "components/SearchInput";
import { Dropdown } from "components/Forms/Dropdown";
import { LinkButton, ButtonStyle } from "components/Buttons";
import { AttentionMessage } from "components/AttentionMessage";
import { useSortedOrganizations } from "pages/AssignOrganizations/AssignOrganizationsTable/useSortedOrganizations";


export interface OrganizationsTableProps {
  className?: string;
}

export const OrganizationsTable = ( { className } : OrganizationsTableProps ) => {

  const [organizations, setOrganizations] = useState<Organization[]>();

  const [errorGeneralModalIsOpen, setErrorGeneralModalIsOpen] = useState(false);

  const openErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(true);
  }

  const closeErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(false);
  };

  const {
    organizationSortDropdownOptions,
    onSortOptionChange,
    populateOrganizations,
    sortedOrganizations
  } = useSortedOrganizations(organizations, setOrganizations);

  return (
    <>
      <div className={classNames(styles.organizationsTable, className)}>
        <div className={styles.organizationsFilterSortSearch}>
          <div className={styles.addButtonContainer}>
            <LinkButton to={{ pathname: routePaths.addOrganizationsUsers, state: {} }}
              style={ButtonStyle.Secondary}
              className={styles.addButton}
            >
              <span className={styles.addButtonText}>
                <FormattedMessage id="buttons.add" defaultMessage="Add"/>
              </span>
            </LinkButton>
          </div>

          <Dropdown dropdownId="assignOrganizationSortOptions" 
            key="assignOrganizationSortOptions"
            dropdownOptions={organizationSortDropdownOptions}
            className={styles.organizationsTableDropdown}
            onSelect={onSortOptionChange}
          />

          <SearchInput onChange={populateOrganizations}/>
        </div>

        <div className={styles.organizationsTableContainer}>
          <div className={classNames(styles.organizationsTableRow, styles.rowHeader)}>
            <p className={styles.name}><FormattedMessage id="organizations" defaultMessage="Organizations"/></p>
            <p className={styles.type}><FormattedMessage id="type" defaultMessage="Type"/></p>
            <p className={styles.edit}><FormattedMessage id="edit" defaultMessage="Edit"/></p>
          </div>

          <div className={styles.organizationsRows}>
            {sortedOrganizations?.map((organization: Organization) => {
              const editOrganizationUsersPath = generatePath(routePaths.editOrganizationsUsers, { editOrganizationId: organization.id });
              return (
                <div className={classNames(styles.organizationsTableRow, styles.rowBorder)} key={organization.id}>
                  <h5 className={styles.name}>{organization.name}</h5>
                  <span className={classNames(styles.type, styles.smallText)}>
                    <FormattedMessage id={`organizationType.${organization.organizationTypeName}`} defaultMessage={organization.organizationTypeName}/>
                  </span>
                  <PencilIcon to={{ pathname: editOrganizationUsersPath, state: { organization } }}
                    className={classNames(styles.edit, styles.icon)}/>
                </div>
              );
            })}
          </div>

          {!sortedOrganizations || (sortedOrganizations?.length === 0) && (
            <div className={styles.noOrgMessage}>
              <AttentionMessage message="message.noOrganizations" defaultMessage="There are currently no organizations."/>
            </div>
          )}
        </div>
      </div>

      <ErrorGeneralModal isOpen={errorGeneralModalIsOpen} onClose={closeErrorGeneralModal}/>
    </>
  );
}
