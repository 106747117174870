import { useCallback, useEffect, useState } from "react";
import { IntlShape, MessageDescriptor, useIntl } from "react-intl";
import { makeDropdownOptionValue, getSortProperty, getSortMethod, makeDropdownOptionText, sortPropertyAndMethod } from "components/Forms/Dropdown";
import { createAlphaNumericSorterByProperty } from "lib/sorter";
import { getDepartments, Department } from "actions/departmentActions";
import { getTranslatedText } from "lib/commonFunctions";
import { UserInfo, useUserState } from "context/user";
import { RoleType } from "actions/roleActions";
import { useLocation } from "react-router-dom";

enum DepartmentSortProperties {
  Name = "name",
}

const DepartmentSortPropertiesIntl: Map<DepartmentSortProperties, MessageDescriptor> = new Map([
  [DepartmentSortProperties.Name, { id: "name", defaultMessage: "Name" }]
]);

enum DepartmentSortMethods {
  AlphabeticalAscending = "A-Z",
  AlphabeticalDescending = "Z-A"
};

const DepartmentSortMethodsIntl: Map<DepartmentSortMethods, MessageDescriptor> = new Map([
  [DepartmentSortMethods.AlphabeticalAscending, { id: "alphabeticalAscending", defaultMessage: "A-Z" }],
  [DepartmentSortMethods.AlphabeticalDescending, { id: "alphabeticalDescending", defaultMessage: "Z-A" }]
]);

const sortDepartments = (
  unsortedDepartments: Department[] = [],
  sortProperty: string = "",
  sortMethod: string = ""
) => {
  const sortAlphaNumericAscending = createAlphaNumericSorterByProperty(sortProperty);
  switch (sortMethod) {
    case DepartmentSortMethods.AlphabeticalAscending:
      return [...unsortedDepartments].sort(sortAlphaNumericAscending);
    case DepartmentSortMethods.AlphabeticalDescending:
      const sortAlphaNumericDescending = (a: any, b: any) => sortAlphaNumericAscending(b, a); // arguments reversed
      return [...unsortedDepartments].sort(sortAlphaNumericDescending);
    default:
      return [...unsortedDepartments].sort(sortAlphaNumericAscending);
  }
};

const departmentSortDropdownOptions = (intl: IntlShape) => [
  { sortProperty: DepartmentSortProperties.Name, sortMethod: DepartmentSortMethods.AlphabeticalAscending },
  { sortProperty: DepartmentSortProperties.Name, sortMethod: DepartmentSortMethods.AlphabeticalDescending },
].map((sortPropertyAndMethod: sortPropertyAndMethod, index) => {
  const { sortProperty = "", sortMethod = "" } = sortPropertyAndMethod;
  const sortPropertyText = getTranslatedText(intl, DepartmentSortPropertiesIntl.get(sortProperty  as DepartmentSortProperties));
  const sortMethodText = getTranslatedText(intl, DepartmentSortMethodsIntl.get(sortMethod  as DepartmentSortMethods));
  return ({
    id: index,
    value: makeDropdownOptionValue(sortPropertyAndMethod),
    name: makeDropdownOptionText(sortPropertyText, sortMethodText)
  });
});

export const useSortedDepartments = (
  departments: Department[] | undefined,
  setDepartments: (departments: Department[] | undefined) => void
) => {

  const intl = useIntl();
  const userData: UserInfo & RoleType = useUserState();
  const currentUrl = useLocation().pathname;

  const initialSortPropertyAndMethod: string = departmentSortDropdownOptions(intl)[0].value;
  const initialSortProperty = getSortProperty(initialSortPropertyAndMethod);
  const initialSortMethod = getSortMethod(initialSortPropertyAndMethod);

  const [selectedSortProperty, setSelectedSortProperty] = useState<string>(initialSortProperty);
  const [selectedSortMethod, setSelectedSortMethod] = useState<string>(initialSortMethod);

  // only departments that adminPanelUser has permissions to either manage are returned by backend
  const populateDepartments = useCallback(async (query?: string) => {
    try {
      const departmentsData = await getDepartments(query);
      const departmentsResult = departmentsData?.data?.departments;

      const sortedDepartments = sortDepartments(departmentsResult, selectedSortProperty, selectedSortMethod);

      setDepartments(sortedDepartments);
    } catch (err) {
      // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
      console.log(err);
    }
  }, [selectedSortProperty, selectedSortMethod]);

  useEffect(() => {
    populateDepartments();
  }, [userData, currentUrl]);

  const onSortOptionChange = (sortPropertyAndMethod: string) => {
    const newSortProperty = getSortProperty(sortPropertyAndMethod);
    const newSortMethod = getSortMethod(sortPropertyAndMethod);
    setSelectedSortProperty(newSortProperty);
    setSelectedSortMethod(newSortMethod);

    const sortedDepartments = sortDepartments(departments, newSortProperty, newSortMethod);
    setDepartments(sortedDepartments);
  };

  return {
    departmentSortDropdownOptions: departmentSortDropdownOptions(intl),
    populateDepartments,
    onSortOptionChange,
    sortedDepartments: departments // departments in state are sorted
  }
}
