import React from "react";
import classNames from "classnames";
import styles from './styles.module.scss';


export const Checkbox = ({
  name,
  value,
  onChange,
  onBlur,
  errors,
  touched,
  setFieldValue,
  id,
  label,
  className,
  disabled,
  ...props
}: any) => {
  return (
    <div className={styles.checkbox} key={id}>
      <label htmlFor={id}>{label}</label>
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className={classNames("radio-button")}
      />
    </div>
  );
};
