import { creatContextStore } from './base';
import { RoleType } from 'actions/roleActions';
import { OrganizationType } from 'actions/organizationActions';

export enum UserFields {
  AgeRange  = "ageRange",
  PhoneNumber  = "phoneNumber",
  Email  = "email",
  MethodOfContact = "methodOfContact",
  FullName = "fullName",
};

export enum AgeRange {
  OlderThan19 = "olderThanNineteen",
  SixteenToEighteen = "sixteenToEighteen",
  YoungerThanSixteen = "youngerThanSixteen" 
}

export interface UserInfo {
  id?: string | number,
  roleId?: number,
  organizationId?: string | number,
  organizationTypeId?: number,
  organizationTypeName?: OrganizationType;
  organizationName?: string;
  email?: string;
  nickname?: string;
  fullName?: string;
  ageRange?: AgeRange;
  address?: string;
  phoneNumber?: string;
  methodOfContact?: string;
  roleName?: string;
  assignedDepartmentIds?: number[];
};

const initialUserState = {
};

const [ Provider, useState, useSetState, useMergeState ] = creatContextStore<UserInfo & RoleType>(initialUserState);

export {
  Provider as UserContextProvider,
  useState as useUserState,
  useSetState as useSetUserState,
  useMergeState as useMergeUserState,
};
