import React from "react";
import Layout from "Layout";
import { ReportsTable } from "pages/Reports/ReportsTable";
import { StatsGroup } from "pages/Statistics/StatsGroup";
import styles from './styles.module.scss';
import { isPermissionScopeAllOrOrganization } from "lib/permissions";
import { useUserState } from "context/user";
import { AttentionMessage } from "components/AttentionMessage";


export const Dashboard = () => {

  const userInfo = useUserState();

  return (
    <Layout>
      <ReportsTable isRecentReports={true} className={styles.recentReports}/>
      {isPermissionScopeAllOrOrganization(userInfo?.viewReportStatistics) ? (
        <StatsGroup isDashboard={true} className={styles.overallStats}/>
      ) : (
        <div className={styles.attMessageContainer}>
          <AttentionMessage message={"message.someFeaturesDisabled"} />
        </div>
      )}
    </Layout>
  );
}
