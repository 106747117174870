import { useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";

export enum BarChartColour {
  Blue = "#253C5E",
  Pink = "#9B4D68",
  LightBlue = "#6A788E",
  LightPink = "#B57E91",
}

export enum BarChartDataType {
  SupportConsent = "counsellorConsent",
  PoliceConsent = "policeConsent",
  CampusConsent = "campusConsent",
  BothPoliceAndSupportConsent = "totalBothPoliceAndSupportConsent",
  BothPoliceAndCampusConsent = "totalBothPoliceAndCampusConsent",
  BothSupportAndCampusConsent = "totalBothSupportAndCampusConsent",
  PoliceSupportAndCampusConsent = "PoliceSupportAndCampusConsent",
  NeitherPoliceNorSupportNorCampusConsent = "totalNeitherPoliceNorSupportNorCampusConsent",
  Unassigned = "unassigned",
  AssignedUnactioned = "assignedUnactioned",
  ActiveOngoing = "activeOngoing",
  ClearedByCharge = "clearedByCharge",
  ClearedInsufficientEvidence = "clearedInsufficientEvidence",
  ClearedNoChargeableSuspectIdentified = "clearedNoChargeableSuspectIdentified",
  ClearedComplainantDeclinesToProceed = "clearedComplainantDeclinesToProceed ",
  ClearedOtherwise = "clearedOtherwise",
  KnownOffenders = "knownOffenders",
  NamedOffenders = "namedOffenders",
  UnknownOffenders = "totalUnknownOffenders",
  NoResponseOffenders = "totalNoResponseOffender",
  ActiveAppointmentBooked = "activeAppointmentBooked",
  ActiveWaitlisted = "activeWaitlisted",
  ActiveCounselling = "activeCounselling",
  DischargedNoContact = "dischargedNoContact",
  DischargedChangedMind = "dischargedChangedMind",
  DischargedReferredElsewhere = "dischargedReferredElsewhere"
}

export const BarChartDataIntl: Map<BarChartDataType, MessageDescriptor> = new Map([
  [BarChartDataType.CampusConsent, { id: "stats.campusConsent", defaultMessage: "Has campus consent" }],
  [BarChartDataType.BothPoliceAndSupportConsent, { id: "stats.totalBothPoliceAndSupportConsent", defaultMessage: "Has both police and support center consent" }],
  [BarChartDataType.BothPoliceAndCampusConsent, { id: "stats.totalBothPoliceAndCampusConsent", defaultMessage: "Has both police and campus consent" }],
  [BarChartDataType.BothSupportAndCampusConsent, { id: "stats.BothSupportAndCampusConsent", defaultMessage: "Has both support center and campus consent" }],
  [BarChartDataType.NeitherPoliceNorSupportNorCampusConsent, { id: "stats.totalNeitherPoliceNorSupportNorCampusConsent", defaultMessage: "Has neither police nor campus nor support center consent" }],
  [BarChartDataType.PoliceSupportAndCampusConsent, { id: "stats.totalPoliceSupportAndCampusConsent", defaultMessage: "Has police, support center and campus consent" }],
  [BarChartDataType.SupportConsent, { id: "stats.supportCenterConsent", defaultMessage: "Has support center consent" }],
  [BarChartDataType.PoliceConsent, { id: "stats.policeConsent", defaultMessage: "Has police consent" }],
  [BarChartDataType.Unassigned, { id: "stats.unassigned", defaultMessage: "Unassigned" }],
  [BarChartDataType.ActiveOngoing, { id: "stats.activeOngoing", defaultMessage: "Active/Ongoing" }],
  [BarChartDataType.AssignedUnactioned, { id: "stats.assignedUnactioned", defaultMessage: "Assigned Unactioned" }],
  [BarChartDataType.ClearedByCharge, { id: "stats.clearedByCharge", defaultMessage: "Cleared by charge" }],
  [BarChartDataType.ClearedInsufficientEvidence, { id: "stats.clearedInsufficientEvidence", defaultMessage: "Insufficient evidence" }],
  [BarChartDataType.ClearedNoChargeableSuspectIdentified, { id: "stats.clearedNoChargeableSuspectIdentified", defaultMessage: "No chargeable suspect identified" }],
  [BarChartDataType.ClearedComplainantDeclinesToProceed, { id: "stats.clearedComplainantDeclinesToProceed", defaultMessage: "Chargeable suspect identified" }],
  [BarChartDataType.ClearedOtherwise, { id: "stats.clearedOtherwise", defaultMessage: "Cleared otherwise" }],
  [BarChartDataType.KnownOffenders, { id: "stats.totalKnownOffenders", defaultMessage: "Has known offenders" }],
  [BarChartDataType.NamedOffenders, { id: "stats.totalNamedOffenders", defaultMessage: "Has known and named offenders" }],
  [BarChartDataType.UnknownOffenders, { id: "stats.totalUnknownOffenders", defaultMessage: "Has unknown offenders" }],
  [BarChartDataType.NoResponseOffenders, { id: "stats.totalNoResponseOffender", defaultMessage: "No response for offender question" }],
  [BarChartDataType.ActiveAppointmentBooked, { id: "stats.activeAppointmentBooked", defaultMessage: "Appointment booked" }],
  [BarChartDataType.ActiveWaitlisted, { id: "stats.activeWaitlisted", defaultMessage: "Waitlisted" }],
  [BarChartDataType.ActiveCounselling, { id: "stats.activeCounselling", defaultMessage: "Counselling" }],
  [BarChartDataType.DischargedNoContact, { id: "stats.dischargedNoContact", defaultMessage: "No Contact" }],
  [BarChartDataType.DischargedChangedMind, { id: "stats.dischargedChangedMind", defaultMessage: "Changed Mind" }],
  [BarChartDataType.DischargedReferredElsewhere, { id: "stats.dischargedReferredElsewhere", defaultMessage: "Referred Elsewhere" }]
])

export interface BarChartData {
  name: string;
  value: number;
  color: string;
}

export const useBarChartData = () => {
  const intl = useIntl();

  const constructBarChartData = (
    barChartDataType: BarChartDataType, 
    value: number, 
    color: BarChartColour = BarChartColour.Blue
  ): BarChartData => {
    const barChartDataIntl = BarChartDataIntl.get(barChartDataType);
    return { 
      name: barChartDataIntl ? intl.formatMessage(barChartDataIntl) : "",
      value: value || 0, 
      color 
    };
  };

  const consentBarChartData = (
    totalSupportConsent: number, 
    totalPoliceConsent: number,
    totalCampusConsent: number,
    totalBothPoliceAndSupportConsent: number,
    totalBothPoliceAndCampusConsent: number,
    totalBothSupportAndCampusConsent: number,
    totalPoliceSupportAndCampusConsent: number,
    totalNeitherPoliceNorSupportNorCampusConsent: number,
  ): BarChartData[] => {
    return [
      constructBarChartData(BarChartDataType.SupportConsent, totalSupportConsent, BarChartColour.Blue), 
      constructBarChartData(BarChartDataType.PoliceConsent, totalPoliceConsent, BarChartColour.Pink),
      constructBarChartData(BarChartDataType.CampusConsent, totalCampusConsent, BarChartColour.Blue),
      constructBarChartData(BarChartDataType.BothPoliceAndSupportConsent, totalBothPoliceAndSupportConsent, BarChartColour.LightPink),
      constructBarChartData(BarChartDataType.BothPoliceAndCampusConsent, totalBothPoliceAndCampusConsent, BarChartColour.LightBlue),
      constructBarChartData(BarChartDataType.BothSupportAndCampusConsent, totalBothSupportAndCampusConsent, BarChartColour.LightPink),
      constructBarChartData(BarChartDataType.PoliceSupportAndCampusConsent, totalPoliceSupportAndCampusConsent, BarChartColour.LightBlue),
      constructBarChartData(BarChartDataType.NeitherPoliceNorSupportNorCampusConsent, totalNeitherPoliceNorSupportNorCampusConsent, BarChartColour.LightPink)
    ];
  };

  const offendersBarChartData = (
    totalKnownOffenders: number, 
    totalNamedOffenders: number,
    totalUnknownOffenders: number,
    totalNoResponseOffender: number,
  ): BarChartData[] => {
    return [
      constructBarChartData(BarChartDataType.KnownOffenders, totalKnownOffenders, BarChartColour.Blue), 
      constructBarChartData(BarChartDataType.NamedOffenders, totalNamedOffenders, BarChartColour.Pink),
      constructBarChartData(BarChartDataType.UnknownOffenders, totalUnknownOffenders, BarChartColour.LightBlue),
      constructBarChartData(BarChartDataType.NoResponseOffenders, totalNoResponseOffender, BarChartColour.LightPink)
    ];
  };

  const policeStatusesBarChartData = (
    totalUnassigned: number,
    totalAssignedUnactioned: number,
    totalActiveOngoing: number,
    totalClearedByCharge: number, 
    totalClearedInsufficientEvidence: number, 
    totalClearedNoChargeableSuspectIdentified: number, 
    totalClearedComplainantDeclinesToProceed: number, 
    totalClearedOtherwise: number
  ): BarChartData[] => {
    return [
      constructBarChartData(BarChartDataType.Unassigned, totalUnassigned, BarChartColour.Blue), 
      constructBarChartData(BarChartDataType.AssignedUnactioned, totalAssignedUnactioned, BarChartColour.Blue),
      constructBarChartData(BarChartDataType.ActiveOngoing, totalActiveOngoing, BarChartColour.Pink),  
      constructBarChartData(BarChartDataType.ClearedByCharge, totalClearedByCharge, BarChartColour.Pink), 
      constructBarChartData(BarChartDataType.ClearedInsufficientEvidence, totalClearedInsufficientEvidence, BarChartColour.Blue),
      constructBarChartData(BarChartDataType.ClearedNoChargeableSuspectIdentified, totalClearedNoChargeableSuspectIdentified, BarChartColour.Pink),
      constructBarChartData(BarChartDataType.ClearedComplainantDeclinesToProceed, totalClearedComplainantDeclinesToProceed, BarChartColour.Blue),
      constructBarChartData(BarChartDataType.ClearedOtherwise, totalClearedOtherwise, BarChartColour.Pink), 
    ];
  };

  const supportCenterStatusesBarChartData = (
    totalUnassigned: number, 
    totalAssignedUnactioned: number,
    totalActiveAppointmentBooked: number, 
    totalActiveWaitlisted: number, 
    totalActiveCounselling: number, 
    totalDischargedNoContact: number, 
    totalDischargedChangedMind: number,
    totalDischargedReferredElsewhere: number
  ): BarChartData[] => {
    return [
      constructBarChartData(BarChartDataType.Unassigned, totalUnassigned, BarChartColour.Blue), 
      constructBarChartData(BarChartDataType.AssignedUnactioned, totalAssignedUnactioned, BarChartColour.Pink), 
      constructBarChartData(BarChartDataType.ActiveAppointmentBooked, totalActiveAppointmentBooked, BarChartColour.Blue), 
      constructBarChartData(BarChartDataType.ActiveWaitlisted, totalActiveWaitlisted, BarChartColour.Pink),
      constructBarChartData(BarChartDataType.ActiveCounselling, totalActiveCounselling, BarChartColour.Blue),
      constructBarChartData(BarChartDataType.DischargedNoContact, totalDischargedNoContact, BarChartColour.Pink),
      constructBarChartData(BarChartDataType.DischargedChangedMind, totalDischargedChangedMind, BarChartColour.Blue), 
      constructBarChartData(BarChartDataType.DischargedReferredElsewhere, totalDischargedReferredElsewhere, BarChartColour.Pink), 
    ];
  };

  const initialConsentData = consentBarChartData(0, 0, 0, 0, 0, 0, 0, 0);
  const initialOffendersData = offendersBarChartData(0, 0, 0, 0);
  const initialPoliceStatusesData = policeStatusesBarChartData(0, 0, 0, 0, 0, 0, 0, 0);
  const initialSupportCenterStatusesData = supportCenterStatusesBarChartData(0, 0, 0, 0, 0, 0, 0, 0);

  const [consentData, setConsentData] = useState<BarChartData[]>(initialConsentData);
  const [offendersData, setOffendersData] = useState<BarChartData[]>(initialOffendersData);
  const [policeStatusesData, setPoliceStatusesData] = useState<BarChartData[]>(initialPoliceStatusesData);
  const [supportCenterStatusesData, setSupportCenterStatusesData] = useState<BarChartData[]>(initialSupportCenterStatusesData);

  return {
    consentBarChartData,
    offendersBarChartData,
    policeStatusesBarChartData,
    supportCenterStatusesBarChartData,
    consentData,
    setConsentData,
    offendersData,
    setOffendersData,
    policeStatusesData,
    setPoliceStatusesData,
    supportCenterStatusesData,
    setSupportCenterStatusesData
  }
};
