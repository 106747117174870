import React, { FC } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
  
import { Login } from "pages/Login";
import { useAppState } from 'context';
import { Dashboard } from 'pages/Dashboard';
import { Reports } from 'pages/Reports';
import { AssignOrganizations } from 'pages/AssignOrganizations';
import { AssignUsers } from 'pages/AssignUsers';
import { Report } from 'pages/Report';
import { UserRoles } from "pages/UserRoles";
import { ManageDepartments } from "pages/ManageDepartments";
import { ManageOrganizations } from "pages/ManageOrganizations";
import { AddOrEditUser } from "pages/AddOrEditUser";
import { AddOrEditDepartmentUsers } from "pages/AddOrEditDepartmentUsers";
import { Statistics } from 'pages/Statistics';
import { CreatePassword } from 'pages/CreatePassword';
import { TwoFA } from 'pages/TwoFA';
import { UserInformation } from 'pages/UserInformation';
import { Feedback } from 'pages/Feedback';
import { AddOrEditOrganizationUsers } from 'pages/AddOrEditOrganizationUsers';
import { LocationStatistics } from 'pages/LocationStatistics';

//note: these key names matter to LeftNav component
export const routePaths = {
  home: '/',
  dashboard: '/dashboard',
  login: '/login',
  report: '/report/:reportId',
  assignOrganization: '/report/:reportId/assignOrganization',
  assignUser: '/report/:reportId/assignUser',
  reports: '/reports',
  userRoles: '/userRoles',
  manageDepartments: '/manageDepartments',
  manageOrganizations: '/manageOrganizations',
  addOrganizationsUsers: '/addOrganizationsUsers',
  editOrganizationsUsers: '/editOrganizationsUsers/:editOrganizationId',
  addUser: '/addUser',
  editUser: '/editUser/:editUserId',
  addDepartmentUsers: '/addDepartmentUsers',
  editDepartmentUsers: '/editDepartmentUsers/:editDepartmentId',
  statistics: '/statistics',
  passwordReset: '/passwordReset/:resetKey',
  twoFA: '/twoFA',
  userInformation: '/userInformation',
  feedback: '/feedback',
  locationStatistics: '/locationStatistics'
};

const AuthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path={routePaths.assignOrganization}>
        <AssignOrganizations/>
      </Route>
      <Route path={routePaths.addOrganizationsUsers}>
        <AddOrEditOrganizationUsers/>
      </Route>
      <Route path={routePaths.editOrganizationsUsers}>
        <AddOrEditOrganizationUsers/>
      </Route>
      <Route path={routePaths.assignUser}>
        <AssignUsers/>
      </Route>
      <Route path={routePaths.report}>
        <Report/>
      </Route>
      <Route path={routePaths.reports}>
        <Reports/>
      </Route>
      <Route path={routePaths.userRoles}>
        <UserRoles/>
      </Route>
      <Route path={routePaths.manageDepartments}>
        <ManageDepartments/>
      </Route>
      <Route path={routePaths.manageOrganizations}>
        <ManageOrganizations/>
      </Route>
      <Route path={routePaths.addUser}>
        <AddOrEditUser/>
      </Route>
      <Route path={routePaths.editUser}>
        <AddOrEditUser/>
      </Route>
      <Route path={routePaths.addDepartmentUsers}>
        <AddOrEditDepartmentUsers/>
      </Route>
      <Route path={routePaths.editDepartmentUsers}>
        <AddOrEditDepartmentUsers/>
      </Route>
      <Route path={routePaths.statistics}>
        <Statistics/>
      </Route>
      <Route path={routePaths.locationStatistics}>
        <LocationStatistics/>
      </Route>
      <Route path={routePaths.dashboard}>
        <Dashboard />
      </Route>
      <Route path={routePaths.userInformation}>
        <UserInformation />
      </Route>
      <Route path={routePaths.feedback}>
        <Feedback />
      </Route>
      <Route path={routePaths.home}>
        <Dashboard />
      </Route>
    </Switch>
  )
}

export const Routing : FC = () => {
  const appState = useAppState();
  return (
    <Router>
        <Switch>
          <Route path={routePaths.login}>
            <Login />
          </Route>
          <Route path={routePaths.passwordReset}>
            <CreatePassword/>
          </Route>
          <Route path={routePaths.twoFA}>
            <TwoFA />
          </Route>
          <Route
            render={({ location }) => {
              if(appState.Authenticating && !appState.Authenticated) {
                return <>null</>;
              } else if (appState.Authenticated) {
                return <AuthenticatedRoutes />
              } else {
                return <Redirect
                  to={{
                    pathname: routePaths.login,
                    state: { from: location }
                  }}
                />
              }
            }}
          />
        </Switch>
    </Router>
  );
};
