import { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { getRoles, RoleType, survivorRoleId } from 'actions/roleActions';
import { AddOrEditUserFormFields } from '../index';
import classNames from 'classnames';
import { GreenCheckIcon } from 'components/Icons';
import { Button, ButtonStyle } from 'components/Buttons';
import { matchPath, useHistory } from 'react-router-dom';
import { UserInfo, useUserState } from 'context/user';
import { OrganizationTypeIds } from 'actions/organizationActions';
import { isPermissionScopeAll } from 'lib/permissions';
import { routePaths } from 'App/routing';


export const RolesList = () => {

  const userInfo = useUserState();
  const history = useHistory();
  const currentLocation = history.location;
  const currentPath = currentLocation.pathname;
  const currentState = currentLocation.state as { user: UserInfo & RoleType };
  const userToEdit = currentState?.user; 

  const isEditUserPage = matchPath(currentPath, {
    path: routePaths.editUser,
    exact: true
  });

  const { values, setFieldValue, errors } = useFormikContext<AddOrEditUserFormFields>();

  const [roles, setRoles] = useState<RoleType[]>();

  const getAndSetRoles = useCallback(async (organizationTypeId: OrganizationTypeIds | undefined) => {
    try {
      if (organizationTypeId) {
        const rolesResult = await getRoles(organizationTypeId);
        if (rolesResult?.data) {
          const filteredRoles = rolesResult.data.filter(r => (r.id !== survivorRoleId));
          setRoles(filteredRoles);

          if (isEditUserPage && (userToEdit?.organizationTypeId?.toString() === organizationTypeId?.toString())) {
            setFieldValue("role", { ...userToEdit, id: userToEdit.roleId });
          } else {
            setFieldValue("role", filteredRoles[0]);
          }
        }
      }
    } catch (err) {
      // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getAndSetRoles(isPermissionScopeAll(userInfo.shareOrganization) ? values.organizationTypeId : userInfo.organizationTypeId);
  }, [userInfo.shareOrganization, userInfo.organizationTypeId, values.organizationTypeId]);

  const onRoleClick = (role: RoleType) => {
    setFieldValue('role', role);
    if (values.organizationTypeId?.toString() !== role.organizationTypeId?.toString()) {
      setFieldValue('organizationId', undefined);
      setFieldValue('organizationTypeId', undefined);
    } else {
      setFieldValue('organizationTypeId', role.organizationTypeId?.toString());
    }
  }
  
  return (
    <div className={styles.setUserRoleField}>
      <span className={styles.label}><FormattedMessage id="setUserRole" defaultMessage="Set user role"/></span>
      <div className={classNames(styles.rolesContainer, errors.role && styles.errorField)}>
        {roles?.map((role: RoleType) => ( 
          <Button type="button" 
            style={ButtonStyle.None} 
            className={classNames(styles.role, styles.roleBorder)}
            onClick={() => { onRoleClick(role); }}
            key={role.name}>
              
            <h5>{role.name}</h5>
            <GreenCheckIcon className={classNames(styles.greenCheckIcon, (values.role?.id === role.id) && styles.visible)}/> 
          </Button> 
        ))}
      </div>

      {errors.role && ( 
        <p className={styles.alert}>
          {errors.role}
        </p> 
      )}
    </div>
  );
};
