import * as base from "./lib/baseActions";
import { DataWrapper } from "./lib/baseActions";
import { AssignUsersErrors } from "pages/AssignUsers";


const getReportStatusesByOrganizationId = async (organizationId: string | number): Promise<DataWrapper<any> | undefined> => {
  try {
    const result = await base.get(`organizations/${organizationId}/statuses`);
    return result;
  } catch (err) {
    // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
    console.log(err);
  }
};

// the org id is implicitly admin org id for this since an admin is only allowed to update status for their org - that's why it's not a param for this req
const updateReportStatus = async (reportId: string | number, data: { reportStatusId: string | number }) => {
  const updateAssignedReportUsersResult = await base.put(
    `reports/${reportId}/status`,
    data
  );
  return updateAssignedReportUsersResult;
};

export {
  getReportStatusesByOrganizationId,
  updateReportStatus
};
