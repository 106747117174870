// The base API url for taxplan.api
export const baseAPIUrl = process.env.REACT_APP_API_URL;
export const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;

// Jwt specific data 
export const jwtData = { 
  refreshUrl: '/users/refreshJwt',
  authToken: 'authToken',
  refreshToken: 'refresh_token'
};

export const crypto = {
  temporaryPasswordByteSize: 256,
}

export const awsData = 
{
  awsKey: process.env.REACT_APP_AWS_KEY,
  awsSecret: process.env.REACT_APP_AWS_SECRET,
  awsBucket: process.env.REACT_APP_AWS_BUCKET || "",
  awsRegion: process.env.REACT_APP_AWS_REGION || "ca-central-1",
  awsSignatureVersion: process.env.REACT_APP_AWS_VERSION || "v4"
}