import classNames from 'classnames';
import { Checkbox } from 'components/Checkbox';
import React, { ReactElement, useState } from 'react';
import styles from './styles.module.scss'

interface ColourCodedCheckboxProps {
  colour: string;
  title: string;
  value: string;
  callBack: (arg1:string[]) => void;
  checkBoxes: string[];
}



export function ColourCodedCheckbox({colour, value, title, callBack, checkBoxes}: ColourCodedCheckboxProps) {
  const updateBoxes = () =>{
    if(checkBoxes.includes(value)){
      callBack(checkBoxes.filter(e => e !== value));
    }
    else{
      callBack(checkBoxes.concat(value));
    }
  }

  return (
      <div className={styles.checkboxFilter}>
        <div className={styles.label}>
          <div className={classNames(styles.circle, styles[colour])}/>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.checkbox}>
          <Checkbox onChange={updateBoxes}/>
        </div>
      </div>
  )
}