import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReportUserNotesAnswersState, ReportUserNotesAnswers } from 'context/reportUserNotesAnswers';
import { ErrorGeneralModal } from 'components/Modals/ErrorGeneralModal';
import { Dropdown, DropdownOption } from 'components/Forms/Dropdown';
import { Button, ButtonStyle } from 'components/Buttons';
import { ConfirmationModal } from 'components/Modals/ConfirmationModal';
import { UserInfo, useUserState } from 'context/user';
import { getReportStatusesByOrganizationId, updateReportStatus } from 'actions/reportStatusActions';

interface SetReportStatusProps {
  className?: string;
};

export const SetReportStatus = ( { className }: SetReportStatusProps ) => {
  const intl = useIntl();
  const userInfo: UserInfo = useUserState();
  const reportUserNotesAnswers: ReportUserNotesAnswers = useReportUserNotesAnswersState();

  const [errorGeneralModalIsOpen, setErrorGeneralModalIsOpen] = useState(false);
  const [dropdownReportStatusOptions, setDropdownReportStatusOptions] = useState<any>([]);
  const [selectedReportStatusId, setSelectedReportStatus] = useState<string | number>(dropdownReportStatusOptions[0]?.value);
  const [currentReportStatus, setCurrentReportStatus] = useState<DropdownOption>();
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState<boolean>(false);


  const getReportStatuses = useCallback(async (organizationId: string | number) => {
    try {
      const statusesResult = await getReportStatusesByOrganizationId(organizationId);

      const dropdownStatusOptions = statusesResult?.data?.map((reportStatus: any, index: any) => ({
        id: index,
        value: reportStatus.id,
        name: intl.formatMessage({id: `report.status.${reportStatus.name}`, defaultMessage: reportStatus.displayName})
      })) || [];
      setDropdownReportStatusOptions(dropdownStatusOptions);

      const currentReportStatusId = reportUserNotesAnswers?.report?.assignedOrganizations?.find(o => (o.id === userInfo.organizationId))?.reportStatusId;
      const currentReportStatusDropdownOption = dropdownStatusOptions.filter((statusOption: DropdownOption) => (statusOption.value === currentReportStatusId)); 

      if (currentReportStatusDropdownOption?.[0]) {
        setCurrentReportStatus(currentReportStatusDropdownOption[0]);
        setSelectedReportStatus(currentReportStatusDropdownOption[0]);
      } else {
        // TODO: update ReportStatus typescript type
        const defaultReportStatusId = statusesResult?.data?.find((status: any) => !!status.isDefault)?.id;
        const defaultReportStatusDropdownOption = dropdownStatusOptions.filter((statusOption: DropdownOption) => (statusOption.value === defaultReportStatusId)); 
        setCurrentReportStatus(defaultReportStatusDropdownOption[0]); 
        setSelectedReportStatus(defaultReportStatusDropdownOption[0]);
      }
    
    } catch(err) {
      //TODO: handle errors
    }
  }, []);

  useEffect(() => {
    // for now admins can only retrieve statuses for their org
    // but in the future we maybe want to allow vesta admin to be able to see list of statuses for different orgs
    // the backend checks for permissions
    if (userInfo.organizationId) {
      getReportStatuses(userInfo.organizationId)
    }
  }, [userInfo.organizationId]);


  const onSaveStatus = async (reportStatusId: string | number, reportId?: string | number) => {
    if (reportId) {
      try {
        await updateReportStatus(reportId, { reportStatusId });
        setConfirmationModalIsOpen(true);
      } catch (err) {
        setErrorGeneralModalIsOpen(true);
      }
    }
  };

  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  };

  const closeErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(false);
  };


  return (
    <div className={classNames(styles.setReportStatus, className)}>
      {dropdownReportStatusOptions?.length ? (

        <div className={classNames(styles.reportStatuses)}>
          <Dropdown dropdownId="reportStatusOptions" key="reportStatusOptions"
            dropdownOptions={dropdownReportStatusOptions}
            dropdownValue={currentReportStatus}
            className={styles.reportStatusDropdown}
            label={<FormattedMessage id="report.setState" defaultMessage="Set State"/>}
            onSelect={(status) => setSelectedReportStatus(status)}/>
        </div>
      ) : null}
      <div className={styles.saveStateButton}>
        <Button 
          style={ButtonStyle.Primary} 
          onClick={() => onSaveStatus(selectedReportStatusId, reportUserNotesAnswers.report?.id)}
          disabled={(selectedReportStatusId === currentReportStatus?.id)}
        >
          <FormattedMessage id="common.save" defaultMessage="Save"/>
        </Button>
      </div>

      <ConfirmationModal
        isOpen={confirmationModalIsOpen}
        onClose={() => closeConfirmationModal()}>
        <p>
          <FormattedMessage id="modal.confirm.reportStatusUpdated" defaultMessage="Report status has been successfully updated"/>
        </p>
      </ConfirmationModal>

      <ErrorGeneralModal isOpen={errorGeneralModalIsOpen} onClose={() => closeErrorGeneralModal()}/>
    </div>
  );
};
