import { useTranslator } from "hooks/useTranslator";

enum csvStatsKeys {
  totalReports = "totalReports",

  totalNeitherPoliceNorSupportNorCampusConsent = "totalNeitherPoliceNorSupportNorCampusConsent",
  totalBothPoliceAndCampusConsent = "totalBothPoliceAndCampusConsent",
  totalBothPoliceAndSupportConsent = "totalBothPoliceAndSupportConsent", 
  totalBothSupportAndCampusConsent = "totalBothSupportAndCampusConsent",
  
  totalCampusConsent = "totalCampusConsent",
  totalSupportConsent = "totalSupportConsent",
  totalPoliceSupportAndCampusConsent = "totalPoliceSupportAndCampusConsent",
  totalPoliceConsent = "totalPoliceConsent",
  
  activeCasesPercentage = "activeCasesPercentage",
  savedButNotSubmittedPercentage = "savedButNotSubmittedPercentage",
  
  totalKnownOffenders = "totalKnownOffenders",
  totalNamedOffenders = "totalNamedOffenders",
  totalUnknownOffenders = "totalUnknownOffenders",
  totalNoResponseOffender = "totalNoResponseOffender"
}

export const useDownloadStatsURL = () => {
  const translator = useTranslator();
  
  return (statsData: any) => {
    const csvStatsLabels: string[] = Object.values(csvStatsKeys).map((csvStatsKey) => {
      return translator(csvStatsKey, 'stats');
    });
 
    const csvStatsData: string[] = [];
    Object.values(csvStatsKeys).map((csvStatsKey) => {
      return statsData && csvStatsData.push(JSON.stringify(statsData[csvStatsKey]));
    });
  
    const csvStats: string = [ 
      csvStatsLabels, csvStatsData,
    ].map((stringArray: string[]): string => {
      return stringArray.map((s) => {return `"${typeof s !== `undefined` ? s :`` }"`; }).join(',');
    }).join('\r\n');

    return URL.createObjectURL( new Blob([csvStats], { type: "text/csv;charset=utf-8;" }) );
  }
};
