import React , { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { BasicSortOptions } from 'lib/sorter';

export interface sortPropertyAndMethod {
  sortProperty: string;
  sortMethod?: string;
}

export const makeDropdownOptionValue = ({
  sortProperty = "",
  sortMethod = BasicSortOptions.AlphabeticalAscending
}: sortPropertyAndMethod) => [sortProperty, sortMethod].join(",");

export const getSortProperty = (dropdownOptionValue: string) => dropdownOptionValue.split(",")[0];
export const getSortMethod = (dropdownOptionValue: string) => dropdownOptionValue.split(",")[1] || BasicSortOptions.AlphabeticalAscending;

export const dropdownSortPropertyAndMethodSeparator = " ";

export const makeDropdownOptionText = (
  sortPropertyText = "", 
  sortMethodText = ""
) => [sortPropertyText, sortMethodText].join(dropdownSortPropertyAndMethodSeparator);

export interface DropdownOption {
  id: number;
  value: string;
  name: string; // translated
}

interface DropdownProps {
  dropdownId: string;
  dropdownOptions: DropdownOption[];
  onSelect: (selectedOptionValue: string) => void;
  label?: ReactNode;
  className?: string;
  dropdownValue?: DropdownOption;
}

export const Dropdown =  ({
  dropdownId, 
  dropdownOptions, 
  onSelect,
  label,
  className,
  dropdownValue
}: DropdownProps) => {

  const [selectedDropdownOption, setSelectedDropdownOption] = useState<string>(dropdownValue ? dropdownValue.value : dropdownOptions[0].value);

  const onDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newSelectedDropdownOptionValue: string = event.target.value;
    setSelectedDropdownOption(newSelectedDropdownOptionValue);
    onSelect(newSelectedDropdownOptionValue);
  };

  useEffect(() => {
    if(dropdownValue) {
      setSelectedDropdownOption(dropdownValue?.value);
    }
  }, [dropdownValue]);

  return (
    <div className={classNames(styles.dropdown, className)}>
      <div className={styles.dropdownContainer}>
        <label htmlFor={dropdownId}>
          {label ? label : (<FormattedMessage id="sort" defaultMessage="Sort"/>)}:
        </label>
        <select
          name={dropdownId} 
          onChange={onDropdownChange}
          value={selectedDropdownOption}
          multiple={false}>
            {dropdownOptions.map((dropdownOption: DropdownOption) => {
              return ( 
                <option key={dropdownOption.id} value={dropdownOption.value}>
                  {dropdownOption.name || dropdownOption.value.split(",").join(dropdownSortPropertyAndMethodSeparator)}
                </option> 
              );
            })}
        </select>
      </div>
    </div>
  );
};
