import React, { FC } from 'react';
import LeftNav from "./LeftNav";
import ContentWrapper from "./ContentWrapper";
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';

const LayoutPresentation : FC = ({children}) => {
  return (<div className={styles.layoutPresentation}>
    <LeftNav/>
    <ContentWrapper>
      {children}
    </ContentWrapper>
    <div className={styles.mobileWarning}>
      <p>
        <FormattedMessage id="modal.mobileWarning" defaultMessage="For safety and security, please use a desktop device." />
      </p>
    </div>
  </div>);
};

export default LayoutPresentation;
