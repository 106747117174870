import React, { useCallback, useEffect, useState } from "react";
import styles from './styles.module.scss';
import Layout from "Layout";
import { FormattedMessage } from "react-intl";
import { FeedbackType, getFeedback } from "actions/feedbackActions";
import classNames from "classnames";
import { AttentionMessage } from "components/AttentionMessage";

export const Feedback = () => {
  const [feedback, setFeedback] = useState<Array<FeedbackType>>([]);
  
  const populateFeedback = useCallback(async () => {
    try {
      const feedbackData = await getFeedback();
      const feedbackResult = feedbackData?.data;
      if (feedbackResult) {
        setFeedback(feedbackResult);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    populateFeedback();
  }, []);

  return (
    // TECH DEBT: Add date_submitted for feedback
    <Layout>
      <div className={styles.feedback}>
        <h3 className={styles.title}>
          <FormattedMessage id="adminPanel.feedback" defaultMessage="Feedback"/>
        </h3>
        <div className={styles.feedbackContent}>
          <div className={classNames(styles.feedbackTable)}>
            <div className={classNames(styles.usersTableRow, styles.rowHeader)}>
              <p className={styles.experience}><FormattedMessage id="experience" defaultMessage="Experience"/></p>
              <p className={styles.helpfulness}><FormattedMessage id="helpfulness" defaultMessage="Helpfulness"/></p>
              <p className={styles.recommended}><FormattedMessage id="recommended" defaultMessage="Recommended"/></p>
              <p className={styles.howtoImprove}><FormattedMessage id="howToImprove" defaultMessage="How to improve"/></p>
            </div>

            {!feedback || feedback?.length === 0 && 
              <div className={styles.noUsersMessage}>
                <AttentionMessage message="message.noFeedback"></AttentionMessage>
              </div>
            }

            <div className={styles.userRows}>
              {feedback?.map((feedback) => {
                return (
                  <div className={classNames(styles.usersTableRow, styles.rowBorder)} key={feedback.id}>
                    <h5 className={styles.experience}>
                      <FormattedMessage id={`feedback.${feedback.experience}`} defaultMessage={feedback.experience}/>
                    </h5>
                    <span className={styles.helpfulness}>{feedback.helpfulness}</span>
                    <span className={styles.recommended}>{feedback.recommended}</span>
                    <span className={styles.howtoImprove}>{feedback.howToImprove}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
}
