import { UserInfo, useUserState } from "context/user";
import { Permissions, RoleType } from "actions/roleActions";
import { ReportData } from "actions/reportActions";
import { hasDepartmentLevelPermission, hasOrganizationLevelPermission, hasUserLevelPermission, isPermissionScopeAll } from "lib/permissions";


export const useHasPermission = () => {
  const userInfo = useUserState();

  return (
    permission: Permissions, 
    report: ReportData | undefined, 
    user: UserInfo & RoleType = userInfo
  ) => {
    const permissionScope = user?.[permission];
    
    return (
      isPermissionScopeAll(permissionScope) || 
      hasOrganizationLevelPermission(permissionScope, report, user.organizationId) ||
      hasDepartmentLevelPermission(permissionScope, report, user.assignedDepartmentIds) ||
      hasUserLevelPermission(permissionScope, report, user.id)
    );
  }
}
