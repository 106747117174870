import React from "react";
import { BaseModal } from "components/Modals/BaseModal";
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { ButtonStyle } from "components/Buttons/Button";
import { ModalProps } from "../BaseModal";
import { Button } from "../../Buttons";

interface YesNoModalProps extends ModalProps {
    onYesClick: () => void;
    onNoClick: () => void;
}

export const YesNoModal = ({ isOpen, onClose, message, onNoClick, onYesClick } : YesNoModalProps) => {
  return (
    <BaseModal className={styles.yesNoModal} isOpen={isOpen} onClose={onClose} >
      <div className={styles.yesNoModalContent}>
        {message && <p className={styles.yesNoMessage}><FormattedMessage id={message}/> </p>}
        <div className={styles.yesNoButtons}>
          <Button
            onClick={onNoClick}
            style={ButtonStyle.Secondary}>
            <FormattedMessage id="common.cancel" defaultMessage="Cancel"/> 
          </Button>
          <Button
            onClick={onYesClick}
            style={ButtonStyle.Primary}>
            <FormattedMessage id="common.yes" defaultMessage="Yes"/> 
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
