import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import { Button, ButtonStyle} from "../Buttons";
import { IButtonProps, LinkButtonProps, LinkButton } from "../Buttons/Button";
import styles from "./styles.module.scss";

export enum IconStyle {
  Default = "default",
  InLine = "inline"
}

export interface IconProps {
  className?: string;
  style?: IconStyle;
  icon?: string;
}

export const VestaLogo: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-vesta-logo")}/>
  );
}

export const QuestionButtonIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(className, "i--icon-red-question")}/>
  );
}

export const ExclamationButtonIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(className, "i--icon-red-exclamation")}/>
  );
}

export const GreenCheckIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames(className, "i--icon-green-check")}/>
  );
}

export const CrossIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames(className, "i--icon-red-cross")}/>
  );
}

export const CloseButtonIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(className, "i--icon-close")}/>
  );
}

export const CalendarIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(className, "i--icon-datetime")}/>
  );
}


export const BaseIcon = ({
  className = "",
  style,
  icon
}: IconProps) => {
  
  let iconStyle = undefined;

  switch(style) {
    case IconStyle.InLine:
      iconStyle = styles.inlineIcon;
      break;
    case IconStyle.Default:
    default:
      iconStyle = styles.defaultIcon;
      break;
  }
  return (
    <div
      className={classNames(icon, iconStyle, className)}/>
  );
}

export const LocationIcon = ({
  className = "",
  style,
  icon
}: IconProps) => {
  return (<BaseIcon 
    {...{className, style, icon: "i--icon-location-red"}}
  />);
}

export const LocationGrayIcon = ({
  className = "",
  style,
  icon
}: IconProps) => {
  return (<BaseIcon 
    {...{className, style, icon: "i--icon-location-blue"}}
  />);
}

export const ExclamationGrayIcon = ({
  className = "",
  style,
  icon
}: IconProps) => {
  return (<BaseIcon 
    {...{className, style, icon: "i--icon-exclamation"}}
  />);
}


export const LocationIconButton: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(className, "i--icon-location")}/>
  );
}

export enum CategoryIconVersion {
  White = "white",
  Dark = "dark",
  Default = "default"
}

interface CategoryIconProps extends IButtonProps {
  iconKey?: string,
  version: CategoryIconVersion;
}

export const CategoryIcon: FC<CategoryIconProps> = ({
  onClick = () => {},
  iconKey = "default-category",
  version = CategoryIconVersion.Default
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(`i--icon-${iconKey}-${version}`)}/>
  );
}

export const ArrowIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(className, "i--icon-input-arrow")}/>
  );
}

export const ArrowRightWhite: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} className={classNames(className, "i--icon-arrow-right-white")}/>
    );
}

export const DoubleArrowRightWhite: FC<IButtonProps> = ({
  onClick = () => {},
  className = ""
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} className={classNames(className, "i--icon-double-white-arrow-right")}/>
    );
}

export const ArrowRightDark: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} className={classNames("i--icon-arrow-right-dark")}/>
  );
}

export const ArrowRightDefault: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames("i--icon-arrow-right-default")}/>
  );
}

export const ArrowDownDefault: FC<IButtonProps> = ({
  className = "",
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(className, "i--icon-arrow-down-default")}/>
  );
}

export const FlagIcon: FC<IButtonProps> = ({
  onClick = () => {},
  className = "",
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} 
      className={classNames(className, "i--icon-flag")}/>
  );
}

export const LockIcon: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames(className, "i--icon-lock")} 
   />
  );
}

export const LocationIconGrey: FC<IButtonProps> = ({
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None} className={classNames("i--icon-location-grey")}/>
  );
}

export const MapLocationIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-map-location")}/>
  );
}

export const BadgeIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-badge")}/>
  );
}

export const LocationUnfilledIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-location-unfilled")}/>
  );
}

export const PaperclipWhiteIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-paperclip-white")}/>
  );
}

export const FileIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-file")}/>
  );
}

interface SensoryIconProps extends IButtonProps {
  sense: string;
};

export const SensoryIcon: FC<SensoryIconProps> = ({
  sense,
  className = ""
}) => {
  return (
    <div className={classNames(className, `i--icon-${sense}`)}/>
  );
}

export const ExternalLinkIcon: FC<LinkButtonProps> = ({
  to,
  className = ""
}) => {
  return (
    <LinkButton to={to}
      style={ButtonStyle.None}
      className={classNames(className, "i--icon-external-link-icon")}/>
  );
}

export const ExternalIconDark: FC<IButtonProps> = ({
  className = ""
}) => {
  return (
    <div className={classNames(className, "i--icon-external-link-icon-dark")}/>
  );
}

export const WhiteEditIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-edit-white")}/>
  );
}

export const WhiteCheckIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-check-white")}/>
  );
}

export const SMGroupIcon: FC<IButtonProps> = () => {
  return (
    <div className={classNames("i--icon-sm-group")}/>
  );
}

export const HelmIcon: FC<LinkButtonProps> = ({
  to,
  className = ""
}) => {
  return (
    <LinkButton to={to}
      style={ButtonStyle.None}
      className={classNames(className, "i--icon-helm")}/>
  );
}

export const PersonIcon: FC<LinkButtonProps> = ({
  to,
  className = ""
}) => {
  return (
    <LinkButton to={to}
      style={ButtonStyle.None}
      className={classNames(className, "i--icon-person-grey")}/>
  );
}

interface DownloadIconProps extends IButtonProps {
  className?: string;
  iconClassName?: string;
  href?: string;
  downloadUrl?: string;
  children?: ReactNode;
}

export const DownloadIcon: FC<DownloadIconProps> = ({
  className = "",
  iconClassName = "",
  href,
  downloadUrl,
  children
}) => {
  return (
    <a href={href} download={downloadUrl} className={className}>
      <div className={classNames("i--icon-download", iconClassName)}/>
      {children}
    </a>
  );
}

export const PencilIcon: FC<LinkButtonProps> = ({
  to,
  className = ""
}) => {
  return (
    <LinkButton to={to}
      style={ButtonStyle.None}
      className={classNames(className, "i--icon-pencil")}/>
  );
}

export const DeleteIcon: FC<IButtonProps> = ({
  className = "",
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None}
      className={classNames(className, "i--icon-delete")}/>
  );
}


export const EyeViewIcon: FC<IButtonProps> = ({
  className = "",
  onClick = () => {}
}) => {
  return (
    <Button onClick={onClick} 
      style={ButtonStyle.None}
      className={classNames(className, "i--icon-eye-view")}/>
  );
}

