import * as base from "./lib/baseActions";
import { OrganizationType, OrganizationTypeIds } from "./organizationActions";
import { DataWrapper } from "./lib/baseActions";

export enum PermissionScope {
  All = "All",
  Organization = "Organization",
  Assigned = "Assigned",
  None = "None"
}

export enum Permissions {
  ShareOrganization = "shareOrganization",
  ManageDepartments = "manageDepartments",
  ManageOrganizations = "manageOrganizations",
  ManageUsers = "manageUsers",
  ViewReportDetails = "viewReportDetails",
  ViewReportNotes = "viewReportNotes",
  AddReportNotes = "addReportNotes",
  ManageReportDepartmentAssignment = "manageReportDepartmentAssignment",
  ManageReportUserAssignment = "manageReportUserAssignment",
  ManageReportStatus = "manageReportStatus",
  ViewReportStatistics = "viewReportStatistics",
  ViewAuditLogs = "viewAuditLogs",
  ViewReportContactDetails = "viewReportContactDetails"
}

export interface RoleType {
  id?: number;
  name?: string;
  organizationTypeId?: number;
  organizationTypeName?: OrganizationType;
  shareOrganization?: PermissionScope;
  manageDepartments?: PermissionScope;
  manageOrganizations?: PermissionScope;
  manageUsers?: PermissionScope;
  viewReportDetails?: PermissionScope;
  viewReportNotes?: PermissionScope;
  addReportNotes?: PermissionScope;
  manageReportDepartmentAssignment?: PermissionScope;
  manageReportUserAssignment?: PermissionScope;
  manageReportStatus?: PermissionScope;
  viewReportStatistics?: PermissionScope;
  viewAuditLogs?: PermissionScope;
  viewReportContactDetails?: PermissionScope;
}

export const survivorRoleId = 10;

const getRoleById = async (roleId: string | number): Promise<DataWrapper<RoleType> | undefined> => {

  try {
    const result = await base.get(`roles/roleId/${roleId}`);
    return result;
  } catch(err) {
    // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
    console.log(err);
  }
};

const getRoles = async (organizationTypeId?: OrganizationTypeIds): Promise<DataWrapper<RoleType[]>> => {

  let getRolesUrl = 'roles';
  if (organizationTypeId) {
    getRolesUrl += `?organizationTypeId=${organizationTypeId}`;
  }

  const getRolesResult = await base.get(getRolesUrl);
  
  return getRolesResult;
};

const upsertRoles = async (data: { roles: RoleType[] }) => {

  const upsertNoteResult = await base.put(
    'roles',
    data
  );

  return upsertNoteResult;
};

export {
  getRoleById,
  getRoles,
  upsertRoles
};
