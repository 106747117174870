import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import { FormattedMessage } from "react-intl";
import { Organization } from "actions/organizationActions";
import { AssignOrganizationsTable } from "./AssignOrganizationsTable";
import { useHistory, useParams } from "react-router-dom";
import { Button, ButtonStyle } from 'components/Buttons';
import { ReportData, updateAssignedReportOrganizations } from "actions/reportActions";
import { ErrorType } from "actions/lib/baseActions";
import { routePaths } from "App/routing";
import { AssignPageLayout } from "./AssignPageLayout";
import { useReportUserNotesAnswersState } from "context/reportUserNotesAnswers";
import { usePopulateReportUserNotesAnswers } from "hooks/usePopulateReportUserNotesAnswers";
import { isOrganizationTypeDummyOrg, isOrganizationTypeVesta } from "lib/permissions";


export interface AssignOrganizationsFormFields {
  organizations?: Organization[];
  assignedOrganizationIds: number[];
};

export const AssignOrganizationsErrors = {
  GeneralAssignOrganizationError: { id: 'organization', intlId: 'assign.error.organization', defaultMessage: 'There was an error updating the list of assigned organizations. Please try again later. If you continue to have problems, please contact support at support@vestasit.ca.' }
};

export const AssignOrganizations = React.memo(() => {

  const history = useHistory();
  const { reportId } = useParams<{ reportId: string }>();

  const reportUserNotesAnswers = useReportUserNotesAnswersState();
  const populateReportUserNotesAnswers = usePopulateReportUserNotesAnswers();

  const [assignedOrganizationIds, setAssignedOrganizationIds] = useState<number[]>([]);

  const [assignOrganizationsError, setAssignOrganizationsError] = useState<ErrorType | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  useEffect(() => {
    if (reportId) {
      const report: ReportData | undefined = reportUserNotesAnswers?.report;
      const reportAssignedOrganizationIds = report?.assignedOrganizations?.reduce((filteredOrgIds: number[], org) => {
        if (!isOrganizationTypeVesta(org.organizationTypeId) && !isOrganizationTypeDummyOrg(org.organizationTypeId)) {
          filteredOrgIds.push(Number(org.id))
        }
        return filteredOrgIds;
      }, []);
      setAssignedOrganizationIds(Array.from(new Set(reportAssignedOrganizationIds)));
    }
  }, [reportId, reportUserNotesAnswers.report]);

  const onSubmit = async () => {
    if (reportId) {
      setIsSubmitting(true);
      try {
        await updateAssignedReportOrganizations(reportId, { organizationIdArray: assignedOrganizationIds });
        populateReportUserNotesAnswers(reportId, false, true);
        setConfirmationModalIsOpen(true);
        setIsSubmitting(false);
        history.push(routePaths.reports);
        return;
      } catch (err) {
        setAssignOrganizationsError(err.body);
        setIsSubmitting(false);
        throw err;
      }
    }
  };

  return (
    <AssignPageLayout confirmationModalIsOpen={confirmationModalIsOpen} setConfirmationModalIsOpen={setConfirmationModalIsOpen}>
      <form>
        <div className={styles.organizationsTableContainer}>
          <AssignOrganizationsTable
            assignedOrganizationIds={assignedOrganizationIds}
            setAssignedOrganizationIds={setAssignedOrganizationIds}
            setAssignOrganizationsError={setAssignOrganizationsError}
          /> 
          
          <Button style={ButtonStyle.Secondary}
            loading={isSubmitting}
            disabled={isSubmitting} 
            className={styles.assignButton}
            onClick={onSubmit}
            type="button">
              <FormattedMessage id="confirmAssignment" defaultMessage="Confirm Assignment"/>
          </Button>

          {assignOrganizationsError && (
            <div className={styles.errorMessage}>
              <FormattedMessage id={assignOrganizationsError.intlId} defaultMessage={assignOrganizationsError.defaultMessage || "Sorry, there was an error."}/>
            </div>
          )}
        </div>
      </form>
    </AssignPageLayout>
  );
});
