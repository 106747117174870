import DatePicker from "react-datepicker";
import React, { forwardRef } from "react";
import classNames from "classnames";
import styles from './styles.module.scss';
import "react-datepicker/dist/react-datepicker.css";

import { CalendarIcon } from "components/Icons";

interface DateProps {
  showTimeSelect?: boolean;
  inputName: string;
  onChange?: (event: any) => void;
  value?: Date,
  disableDate?: Date,
  directionToDisableDates?: 'before' | 'after';
};

interface CustomInputProps {
  value?: any;
  onClick?: any;
  name?: string;
  type?: string;
  ref?: any;
  className?: string;
}

const CustomInput = forwardRef(({ value, onClick, name, className }: CustomInputProps, _ref) => {
  return (
    <div className={classNames(styles.inputContainer)}>
      <input
        onClick={onClick}
        value={value}
        readOnly
        name={name}
        placeholder={'MM/DD/YYYY'}
        type="text"
        className={classNames(className)}
      />
        <CalendarIcon className={styles.calendarIcon} onClick={onClick}/>
    </div>
  );
});

const DateFormat = "MMMM d, yyyy";

export const SingleDatePicker = ({ directionToDisableDates, disableDate, showTimeSelect = true, inputName = "date", onChange = () => {}, value  }: DateProps) => {
  return ( 
    <div className={styles.singleDateInput}>
      <DatePicker
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className={styles.inputContainer}
        selected={value}
        onChange={onChange}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        filterDate = {(date) => {
          if(new Date() < date) {
            return false;
          }
          if(disableDate && directionToDisableDates) {
            return directionToDisableDates === 'after' ? 
              disableDate > date : disableDate < date;
          } else {
            return  true;
          }
        }}
        showPopperArrow={false}
        showTimeSelect={showTimeSelect}
        timeIntervals={30}
        dateFormat={DateFormat}
        customInput={<CustomInput/>}
      />
    </div>
  ); 
};
