import React from "react";
import Layout from "Layout";
import { ReportsTable } from "./ReportsTable";
import styles from './styles.module.scss';

export const Reports = () => {
  return (
    <Layout>
      <div className={styles.reportsPageContent}>
        <ReportsTable className={styles.reports}/>
      </div>
    </Layout>
  );
}
