export const intl = {
  'en-CA': {
    'report.id': 'Report Id',
    'report.reportKey': 'Report Key',
    'report.userId': 'Report User Id',
    'report.acknowledgementOfConsent': 'Acknowledged Consent',
    'report.allowPoliceContact': 'Consented to Police Contact',
    'report.allowCampusContact': 'Consented to Campus Contact',
    'report.allowSupportCenterContact': 'Consented to Support Center Contact',
    'report.allowVestaContact': 'Consented to Vesta Contact',
    'report.policeStatus': 'Police Status',
    'report.supportCenter': 'Support Center Status',
    'report.dateSubmitted': 'Date Report Submitted',
    'report.dateReviewed': 'Date Report Reviewed',
    'report.datePassedToLawEnforcement': 'Date passed to Law Enforcement',
    'report.dateInvestigationStarted': 'Date Investigation Started',
    'report.methodOfContact': 'Preferred Method of Contact',
    'report.reportPathType': 'Report Path Type',
    'report.createdAt': 'Date Report Created',
    'report.updatedAt': 'Date Report Updated',
    'note.id': 'Note Id',
    'note.userId': 'Note Author Id',
    'note.author': 'Note Author Name',
    'note.note': 'Note',
    'note.createdAt': 'Date Note Created',
    'stats.totalReports': 'Total Reports',
    'stats.totalSubmittedReports': 'Total Submitted Reports',
    'stats.savedButNotSubmittedPercentage': 'Reports saved but not submitted to Vesta, police or sexual assault center',
    'stats.totalSupportConsent': 'Has support center consent',
    'stats.totalPoliceConsent': 'Has police consent',
    'stats.totalCampusConsent': 'Has campus consent',
    'stats.totalBothPoliceAndSupportConsent': 'Has both police and support center consent',
    'stats.totalBothPoliceAndCampusConsent': 'Has both police and campus consent',
    'stats.totalBothSupportAndCampusConsent': 'Has both support center and campus consent',
    'stats.totalPoliceSupportAndCampusConsent': 'Has police, support center and campus consent',
    'stats.totalNeitherPoliceNorSupportNorCampusConsent': 'Has neither police nor campus nor support center consent',
    'stats.activeCasesPercentage': 'Active reports',
    'stats.totalKnownOffenders': 'Has known offenders',
    'stats.totalUnknownOffenders': 'Has unknown offenders',
    'stats.totalNamedOffenders': 'Has known and named offenders',
    'stats.totalNoResponseOffender': 'No response for offender question'
  },
  'fr-CA': {
    'report.id': 'Report Id',
    'report.reportKey': 'Report Key',
    'report.userId': 'Report User Id',
    'report.acknowledgementOfConsent': 'Acknowledged Consent',
    'report.allowPoliceContact': 'Consented to Police Contact',
    'report.allowCampusContact': 'Consented to Campus Contact',
    'report.allowSupportCenterContact': 'Consented to Support Center Contact',
    'report.allowVestaContact': 'Consented to Vesta Contact',
    'report.policeStatus': 'Police Status',
    'report.supportCenter': 'Support Center Status',
    'report.dateSubmitted': 'Date Report Submitted',
    'report.dateReviewed': 'Date Report Reviewed',
    'report.datePassedToLawEnforcement': 'Date passed to Law Enforcement',
    'report.dateInvestigationStarted': 'Date Investigation Started',
    'report.methodOfContact': 'Preferred Method of Contact',
    'report.reportPathType': 'Report Path Type',
    'report.createdAt': 'Date Report Created',
    'report.updatedAt': 'Date Report Updated',
    'note.id': 'Note Id',
    'note.userId': 'Note Author Id',
    'note.author': 'Note Author Name',
    'note.note': 'Note',
    'note.createdAt': 'Date Note Created',
    'stats.totalReports': 'Total Reports',
    'stats.totalSubmittedReports': 'Total Submitted Reports',
    'stats.savedButNotSubmittedPercentage': 'Reports saved but not submitted to Vesta, police or sexual assault center',
    'stats.totalSupportConsent': 'Has support center consent',
    'stats.totalPoliceConsent': 'Has police consent',
    'stats.totalCampusConsent': 'Has campus consent',
    'stats.totalBothPoliceAndSupportConsent': 'Has both police and support center consent',
    'stats.totalBothPoliceAndCampusConsent': 'Has both police and campus consent',
    'stats.totalBothSupportAndCampusConsent': 'Has both support center and campus consent',
    'stats.totalPoliceSupportAndCampusConsent': 'Has police, support center and campus consent',
    'stats.totalNeitherPoliceNorSupportNorCampusConsent': 'Has neither police nor campus nor support center consent',
    'stats.activeCasesPercentage': 'Active reports',
    'stats.totalKnownOffenders': 'Has known offenders',
    'stats.totalUnknownOffenders': 'Has unknown offenders',
    'stats.totalNamedOffenders': 'Has known and named offenders',
    'stats.totalNoResponseOffender': 'No response for offender question'
  }
};
