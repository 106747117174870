import { useState, useEffect, useCallback } from 'react'
import { createOrganization, updateOrganization, getOrganizationById, Organization, OrganizationTypeIds } from 'actions/organizationActions';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import Layout from 'Layout';
import { ConfirmationModal } from 'components/Modals/ConfirmationModal';
import { AddOrEditOrganizationUsersForm } from './AddOrEditOrganizationUsersForm';
import { routePaths } from 'App/routing';
import { ErrorType } from 'actions/lib/baseActions';
import { UserInfo } from 'context/user';


export interface AddOrEditOrganizationUsersFormFields {
  address?: string;
  phoneNumber?: string;
  email?: string;
  url?: string;
  type?: OrganizationTypeIds;
  name?: string;
  organizationName?: string;
  organizationLocation?: string;
  intakeFormLink?: string;
  crisisLink?: string;
  crisisPhone?: string;
  users?: UserInfo[];
  assignedUserIds?: number[];
  isPartnered?: boolean;
};

export const AddOrEditOrganizationUsersErrors = {
  OrganizationAlreadyExistsError: { id: 'organizationAlreadyExists', intlId: 'addOrEditOrganizationUsers.error.organizationAlreadyExists', defaultMessage: 'A organization by that name already exists' },
  GeneralAddError: { id: 'add', intlId: 'addOrEditOrganizationUsers.error.add', defaultMessage: 'There was an error creating the organization. Please try again later. If you continue to have problems, please contact support.' },
  GeneralEditError: { id: 'edit', intlId: 'addOrEditOrganizationUsers.error.edit', defaultMessage: 'There was an error editing the organization. Please try again later. If you continue to have problems, please contact support.' }
};

export const AddOrEditOrganizationUsers = () => {

  const location = useLocation();
  const currentPath = location.pathname;
  const currentState: any = location?.state;
  const isAddOrganizationPage = (currentPath === routePaths.addOrganizationsUsers);
  const isEditOrganizationPage = (currentPath === routePaths.editOrganizationsUsers);
  const organizationFromState = currentState?.organization;
  const { editOrganizationId } = useParams<{ editOrganizationId: string }>();

  const [organizationToEdit, setOrganizationToEdit] = useState<Organization>(organizationFromState);

  const [addOrEditOrganizationUsersError, setAddOrEditOrganizationUsersError] = useState<ErrorType | null>(null);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const getAndSetOrganizationToEdit = useCallback(async (editOrganizationId: string | number) => {
    try {
      const organizationResult = await getOrganizationById(editOrganizationId);
      const organization = organizationResult?.data?.[0];
      if (organization) {
        setOrganizationToEdit(organization);
      }
    } catch(err) {
      setAddOrEditOrganizationUsersError(AddOrEditOrganizationUsersErrors.GeneralEditError);
    }
  }, []);
  
  useEffect(() => {
    if (isEditOrganizationPage && editOrganizationId) {
      if (organizationFromState && (editOrganizationId === organizationFromState.id)) {
        setOrganizationToEdit(organizationFromState);
      } else {
        getAndSetOrganizationToEdit(editOrganizationId);
      }
    }
  }, [isEditOrganizationPage, editOrganizationId, organizationFromState]);
  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  };

  //Formik prop values - initialValues, validate, onSubmit
  let initialValues;
  if (isAddOrganizationPage || !organizationToEdit) {
    initialValues = { 
      name: "",
      type: OrganizationTypeIds.Vesta,
      address: "",
      phoneNumber: "",
      email: "",
      url: "",
      assignedUserIds: [],
      organizationName: "",
      organizationLocation: "",
      intakeFormLink: "",
      crisisLink: "",
      crisisPhone: "",
      isPartnered: false
    };
  } else {
    initialValues = { 
      name: organizationToEdit.name,
      type: organizationToEdit.organizationTypeId,
      address: organizationToEdit.address,
      phoneNumber: organizationToEdit.phoneNumber,
      email: organizationToEdit.email,
      url: organizationToEdit.url,
      assignedUserIds: organizationToEdit.assignedUserIds,
      organizationName: organizationToEdit.organizationName,
      organizationLocation: organizationToEdit.organizationLocation,
      intakeFormLink: organizationToEdit.intakeFormLink,
      crisisLink: organizationToEdit.crisisLink,
      crisisPhone: organizationToEdit.crisisPhone,
      isPartnered: organizationToEdit.isPartnered
    };
  }

  const validate = (values: AddOrEditOrganizationUsersFormFields) => { 
    const errors: FormikErrors<AddOrEditOrganizationUsersFormFields> = {}; 

    const name = values.name || organizationToEdit?.name;
    if (!name) {
      errors.name= 'Required';
    }

    const address = values.address || organizationToEdit?.address;
    if (!address) {
      errors.address= 'Required';
    }

    return errors;
  };

   const onSubmit = async (values: AddOrEditOrganizationUsersFormFields, { setSubmitting }: FormikHelpers<AddOrEditOrganizationUsersFormFields>) => {
      const name = values.name || "";
      const type = values.type || OrganizationTypeIds.Vesta;
      const address = values.address || "";
      const phoneNumber = values.phoneNumber || "";
      const email = values.email || "";
      const url = values.url || "";
      const assignedUserIds = values.assignedUserIds || [];
      const organizationName= values.organizationName || "";
      const organizationLocation = values.organizationLocation || "";
      const intakeFormLink = values.intakeFormLink || "";
      const crisisLink = values.crisisLink || "";
      const crisisPhone = values.crisisPhone || "";
      const isPartnered = values.isPartnered || false;

      const oO = {
        organizationName: organizationName || organizationToEdit?.organizationName ? organizationName : undefined,
        organizationLocation: organizationLocation || organizationToEdit?.organizationLocation ? organizationLocation : undefined,
        intakeFormLink: intakeFormLink || organizationToEdit?.intakeFormLink ? intakeFormLink : undefined,
        crisisLink: crisisLink || organizationToEdit?.crisisLink ? crisisLink : undefined,
        crisisPhone: crisisPhone || organizationToEdit?.crisisPhone ? crisisPhone : undefined,
        isPartnered: (typeof isPartnered === "undefined" ? undefined : !!(isPartnered)),
      };

      setSubmitting(true);
      try {
        if (isAddOrganizationPage) {
          await createOrganization({ name, organizationTypeId: type, address, phoneNumber, email, url, assignedUserIds, ...oO });
        } else {
          await updateOrganization(editOrganizationId, { name, organizationTypeId: type, address, phoneNumber, email, url, assignedUserIds, ...oO });
        }
        setConfirmationModalIsOpen(true);
        setSubmitting(false);
        return;
      } catch(err) {
        setAddOrEditOrganizationUsersError(err.body);
        setSubmitting(false);
        throw err;
      };
    }
  
  return (
    <Layout>
      <div className={styles.addOrEditOrganizationUsersPage}>

        <h3>
          {isAddOrganizationPage 
            ? (<FormattedMessage id="addNewOrganization" defaultMessage="Add New Organization"/>) 
            : (<FormattedMessage id="editOrganizationUsers" defaultMessage="Edit Organization"/>)}
        </h3>

        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
        {({ resetForm }) => (
          <div className={styles.addOrEditOrganizationUsersPageContent}>
            <AddOrEditOrganizationUsersForm
              addOrEditOrganizationUsersError={addOrEditOrganizationUsersError}
              setAddOrEditOrganizationUsersError={setAddOrEditOrganizationUsersError}
            />

            <ConfirmationModal 
              isOpen={confirmationModalIsOpen}
              onClose={() => { closeConfirmationModal(); resetForm(); }}
              onCloseRoute={routePaths.manageOrganizations}
            >
              <p className={styles.organizationSuccessfullyAddedOrEdited}>
                {isAddOrganizationPage 
                  ? (<FormattedMessage id="modal.confirm.organizationAdded" defaultMessage="Organization has been successfully created"/>) 
                  : (<FormattedMessage id="modal.confirm.organizationEdited" defaultMessage="Organization has been successfully edited"/>)}
              </p>
            </ConfirmationModal>
          </div>
        )}
        </Formik>

      </div> 
    </Layout>
  );
};
