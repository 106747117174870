import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import { FormattedMessage } from "react-intl";
import { AssignUsersTable } from "./AssignUsersTable";
import { UserInfo } from "context/user";
import { useHistory, useParams } from "react-router-dom";
import { Button, ButtonStyle } from 'components/Buttons';
import { ReportData, updateAssignedReportUsers } from "actions/reportActions";
import { ErrorType } from "actions/lib/baseActions";
import { routePaths } from "App/routing";
import { AssignPageLayout } from "pages/AssignOrganizations/AssignPageLayout";
import { Department } from "actions/departmentActions";
import { AssignDepartmentsTable } from "./AssignDepartmentsTable";
import { useReportUserNotesAnswersState } from "context";
import { useHasPermission } from "hooks/useHasPermission";
import { usePopulateReportUserNotesAnswers } from "hooks/usePopulateReportUserNotesAnswers";
import { Permissions, RoleType } from "actions/roleActions";
import { isPermissionScopeAssigned } from "lib/permissions";
import _ from "lodash";


export interface AssignUsersFormFields {
  departments?: Department[];
  users?: (UserInfo & RoleType)[];
  assignedDepartmentIds: number[];
  assignedDepartmentUserIds: number[];
  assignedUserIds: number[];
};

export const AssignDepartmentsErrors = {
  GeneralAssignDepartmentError: { id: 'department', intlId: 'assign.error.department', defaultMessage: 'There was an error updating the list of assigned departments. Please try again later. If you continue to have problems, please contact support at support@vestasit.ca.' }
};

export const AssignUsersErrors = {
  GeneralAssignUserError: { id: 'user', intlId: 'assign.error.user', defaultMessage: 'There was an error updating the list of assigned users. Please try again later. If you continue to have problems, please contact support at support@vestasit.ca.' }
};

export const getAssignableUserIds = (users: (UserInfo & RoleType)[] | undefined) => {
  return users?.reduce((assignableUserIds: number[], u) => {
    if (isPermissionScopeAssigned(u.viewReportDetails)) {
      assignableUserIds.push(Number(u.id));
    }
    return assignableUserIds;
  }, []) || [];
};

export const AssignUsers = React.memo(() => {

  const history = useHistory();
  const { reportId } = useParams<{ reportId: string }>();

  const reportUserNotesAnswers = useReportUserNotesAnswersState();
  const populateReportUserNotesAnswers = usePopulateReportUserNotesAnswers();
  const hasPermission = useHasPermission();

  const [departments, setDepartments] = useState<Department[]>();

  const [assignedDepartmentIds, setAssignedDepartmentIds] = useState<number[]>([]);
  const [assignedUserIds, setAssignedUserIds] = useState<number[]>([]);
  const [allAssignedUserIds, setAllAssignedUserIds] = useState<number[]>([]);

  const [assignDepartmentsError, setAssignDepartmentsError] = useState<ErrorType | null>(null);
  const [assignUsersError, setAssignUsersError] = useState<ErrorType | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  useEffect(() => {
    const report: ReportData | undefined = reportUserNotesAnswers?.report;
    const reportAssignedDepartmentIds = report?.assignedDepartments?.map((d: Department) => Number(d.id)) || [];
    const reportAssignedUserIds = getAssignableUserIds(report?.assignedUsers);

    const assignedDepartments = departments?.filter(d => reportAssignedDepartmentIds.includes(Number(d.id))) || [];
    const assignedDepartmentUserIds = assignedDepartments?.reduce((assignedUserIdsAccumulator: number[], department) => {
      getAssignableUserIds(department.assignedUsers).forEach(id => assignedUserIdsAccumulator.push(id));
      return assignedUserIdsAccumulator;
    }, []) || [];
    if (assignedDepartmentUserIds) {
      setAllAssignedUserIds(Array.from(new Set([...reportAssignedUserIds, ...assignedDepartmentUserIds])));
    }
    
    setAssignedDepartmentIds(reportAssignedDepartmentIds);
    setAssignedUserIds(reportAssignedUserIds);
  }, [reportId, reportUserNotesAnswers.report, departments]);

  useEffect(() => {
    // update lists of assigned department ids
    let updatedAssignedDepartmentIds = assignedDepartmentIds;
    let updatedAssignedUserIds = allAssignedUserIds;
    departments?.forEach((department) => {
      const departmentId = Number(department.id);
      const departmentUserIds = getAssignableUserIds(department.assignedUsers);

      if (departmentUserIds.length) {
        if (departmentUserIds.every(id => allAssignedUserIds.includes(id))) {
          // ensure department id is added to list of assigned ones if the corresponding group of users are assigned
          updatedAssignedDepartmentIds = [...updatedAssignedDepartmentIds, departmentId];
          updatedAssignedUserIds = [...updatedAssignedUserIds.filter(id => !departmentUserIds.includes(id))];
        } else {
          // ensure department id is removed from list of assigned ones if the group of users for it aren't all assigned
          updatedAssignedDepartmentIds = [...updatedAssignedDepartmentIds.filter(id => (id !== departmentId))];

        }
      }
    });

    setAssignedDepartmentIds(Array.from(new Set(updatedAssignedDepartmentIds)));
    setAssignedUserIds(Array.from(new Set(updatedAssignedUserIds)));
  }, [allAssignedUserIds]);

  const onSubmit = async () => {
    if (reportId) {
      setIsSubmitting(true);

      try {
        await updateAssignedReportUsers(
          reportId, 
          { 
            userIdArray: (hasPermission(Permissions.ManageReportUserAssignment, reportUserNotesAnswers.report) && assignedUserIds) || [],
            departmentIdArray: (hasPermission(Permissions.ManageReportDepartmentAssignment, reportUserNotesAnswers.report) && assignedDepartmentIds) || [] 
          }
        );

        if (Number(reportId) === Number(reportUserNotesAnswers.report?.id)) {
          // refresh report context since status and assigned deps/users has been updated
          populateReportUserNotesAnswers(reportId, false, true);
        }
        setConfirmationModalIsOpen(true);
        setIsSubmitting(false);
        history.push(routePaths.reports);
        return;
      } catch (err) {
        if (err === AssignDepartmentsErrors) {
          setAssignDepartmentsError(err.body);
        } else {
          setAssignUsersError(err.body);
        }
        setIsSubmitting(false);
        throw err;
      }
    }
  };


  // if a person has neither manageReportDepartmentAssignment nor manageReportUserAssignment permission, 
  // they won't be able to visit this page in the first place - 
  // the button to access this page on reports table would be disabled in that case
  return (
    <AssignPageLayout 
      confirmationModalIsOpen={confirmationModalIsOpen} 
      setConfirmationModalIsOpen={setConfirmationModalIsOpen}
    >
      <form>
        <div className={styles.usersTableContainer}>

          <AssignDepartmentsTable 
            departments={departments}
            assignedDepartmentIds={assignedDepartmentIds}
            allAssignedUserIds={allAssignedUserIds}
            setDepartments={setDepartments}
            setAssignedDepartmentIds={setAssignedDepartmentIds}
            setAllAssignedUserIds={setAllAssignedUserIds}
            setAssignDepartmentsError={setAssignDepartmentsError}
            className={!hasPermission(Permissions.ManageReportDepartmentAssignment, reportUserNotesAnswers.report) && styles.displayNone}/>

          <AssignUsersTable 
            assignedUserIds={assignedUserIds}
            allAssignedUserIds={allAssignedUserIds}
            setAllAssignedUserIds={setAllAssignedUserIds}
            setAssignUsersError={setAssignUsersError}
            className={!hasPermission(Permissions.ManageReportUserAssignment, reportUserNotesAnswers.report) && styles.displayNone}/>
          
          <Button style={ButtonStyle.Secondary}
            loading={isSubmitting}
            disabled={isSubmitting} 
            className={styles.assignButton}
            onClick={onSubmit}
            type="button">
              <FormattedMessage id="confirmAssignment" defaultMessage="Confirm Assignment"/>
          </Button>

          {assignDepartmentsError && (<div className={styles.errorMessage}><FormattedMessage id={assignDepartmentsError.intlId} defaultMessage={assignDepartmentsError.defaultMessage || "Sorry, there was an error assigning departments."}/></div>)}
          {assignUsersError && (<div className={styles.errorMessage}><FormattedMessage id={assignUsersError.intlId} defaultMessage={assignUsersError.defaultMessage || "Sorry, there was an error assigning users."}/></div>)}
        </div>
      </form>
    </AssignPageLayout>
  );
});
