import React, { FC, ReactNode, useEffect, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';

export enum TextFieldTypes {
  TextArea = "textarea",
  Text = "text",
  Email = "email",
  Password = "password",
  InlineText = "inlineText",
  Number = "number",
  Boolean = "boolean"
};

export type TextFieldEventType = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>;

export interface TextFieldProps {
  name?: string;
  type: TextFieldTypes;
  label?: ReactNode;
  required?: boolean;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  errorMessage?: ReactNode;
  value?: string;
  onChange?: (event: TextFieldEventType) => void;
  onBlur?: (event: TextFieldEventType) => void;
  icon?: any;
  imageKey?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  allowShowPassword?: boolean;
}

export const TextField : FC<TextFieldProps> = ({
  name,
  errorMessage,
  label,
  value,
  required,
  type,
  placeholder,
  className = "",
  labelClassName,
  fieldClassName,
  onChange,
  icon,
  imageKey = "",
  allowShowPassword = false,
  onKeyDown
}) => {
  const intl = useIntl();

  const [internalValue, setInternalValue] = useState(value || '');
  const [showHideLabel, setShowHideLabel] = useState( intl.formatMessage({ id: 'common.show', defaultMessage: 'Show' }) );
  const [showHidePasswordType, setShowHidePasswordType] = useState(TextFieldTypes.Password);

  useEffect(() => {
    setInternalValue(value || '');
  }, [value]
  );

  const fieldOnChange = useCallback((e: TextFieldEventType) => {
    if(!value) {
      setInternalValue(e.target.value || '');
    }

    if(onChange) {
      onChange(e);
    }
  }, [setInternalValue, onChange, value]);

  const togglePasswordVisiblity = useCallback(() => {
    if(showHidePasswordType === TextFieldTypes.Password) {
      setShowHidePasswordType(TextFieldTypes.Text);
      setShowHideLabel( intl.formatMessage({ id: 'common.hide', defaultMessage: 'Hide' }) );
    } else {
      setShowHidePasswordType(TextFieldTypes.Password);
      setShowHideLabel( intl.formatMessage({ id: 'common.show', defaultMessage: 'Show' }) );
    }
  }, [setShowHideLabel, setShowHidePasswordType, intl, showHidePasswordType ]);

  const getTextFieldType = useCallback((type: string) => {
    if(type === TextFieldTypes.Password) {
      return showHidePasswordType;
    } else {
      return type === TextFieldTypes.InlineText ? TextFieldTypes.Text : type;
    }
  }, [showHidePasswordType]);

  const actualValue = useMemo(() => { return value || internalValue; }, [value, internalValue]);

  return (
    <div className={classNames(styles.textFieldContainer, className, type === TextFieldTypes.InlineText && styles.inlineTextInput)}>
      { (label || required) 
        && (
          <label htmlFor={name} className={classNames(styles.label, labelClassName)}>
            {label}
            {required && (<span className={styles.required}>*</span>)}
          </label>
        )
      }
      { type === TextFieldTypes.TextArea 
        ? (<div className={classNames(styles.textAreaContainer, styles && styles[imageKey])}>
            <textarea name={name} 
              onChange={fieldOnChange} 
              onBlur={fieldOnChange} 
              placeholder={placeholder} 
              className={classNames(errorMessage && styles.errorField, styles.textarea, fieldClassName)} 
              value={actualValue} />
          </div>) 
        : (<input name={name} 
            onChange={fieldOnChange} 
            onBlur={fieldOnChange} 
            type={getTextFieldType(type)} 
            placeholder={placeholder} 
            onKeyDown={onKeyDown}
            className={classNames(errorMessage && styles.errorField, styles.textInput, type === TextFieldTypes.InlineText ? styles.inlineTextInput : "", fieldClassName)} 
            value={actualValue} />)
      }
      {(type === TextFieldTypes.Password && allowShowPassword) ? (
        <p onClick={togglePasswordVisiblity} className={styles.passwordShowHide}>{showHideLabel}</p>
      ): null}

      { icon && ( <div className={styles.icon}>{icon}</div> ) }
      { errorMessage && ( <p className={styles.alert}>{errorMessage}</p> ) }
    </div>
  );
};

export default TextField;
