import { UserInfo } from "context/user";
import * as base from "./lib/baseActions";
import { DataWrapper } from "./lib/baseActions";
import { AddOrEditDepartmentUsersErrors } from "pages/AddOrEditDepartmentUsers";
import { RoleType } from "./roleActions";

export interface Department {
  id?: string | number;
  organizationId?: string | number;
  name?: string;
  assignedUsers: (UserInfo & RoleType)[];
}

export interface addOrEditDepartmentData {
  id?: string | number;
  organizationId?: string | number;
  name: string;
  assignedUserIds: number[];
};

const getDepartments = async (searchQuery?: string | undefined): Promise<DataWrapper<{departments: Department[]}> | undefined> => {

  try {
    let getDepartmentsUrl = 'departments';
    if (searchQuery) {
      getDepartmentsUrl += `?searchQuery=${searchQuery}`;
    }
    const departmentsResult = await base.get(getDepartmentsUrl);
    return departmentsResult;
  } catch(err) {
    // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
    console.log(err);
  }
};

const getDepartmentUsers = async (departmentId: string | number, searchQuery?: string | undefined): Promise<DataWrapper<UserInfo[]> | undefined> => {
  let getDepartmentUsersUrl = `departments/${departmentId}/users`
  if (searchQuery) {
    getDepartmentUsersUrl += `?searchQuery=${searchQuery}`;
  }

  const departmentUsersResult = await base.get(getDepartmentUsersUrl);
  return departmentUsersResult;
};

const getDepartmentById = async (id: string | number): Promise<DataWrapper<Department[]> | undefined> => {
  try {
    const departmentResult = await base.get(`department/${id}`);
    return departmentResult;
  } catch (err) {
    throw err;
  }
};

const createDepartment = async (data: addOrEditDepartmentData) : Promise<base.DataWrapper<Department>> => {
  const { name, assignedUserIds } = data;

  try {
    const createDepartmentResult = await base.post("department", { name, assignedUserIds });
    return createDepartmentResult;
  } catch (err) {
    if (err?.original?.response?.status === 400) {
      throw base.customizeError(err, AddOrEditDepartmentUsersErrors.DepartmentAlreadyExistsError);
    } else {
      throw base.customizeError(err, AddOrEditDepartmentUsersErrors.GeneralAddError);
    }
  }
};

const updateDepartment = async (data: addOrEditDepartmentData) : Promise<base.DataWrapper<Department>> => {
  const { id, organizationId, name, assignedUserIds } = data;

  try {
    const updateDepartmentResult = await base.put("department", { id, organizationId, name, assignedUserIds });
    return updateDepartmentResult;
  } catch (err) {
    throw base.customizeError(err, AddOrEditDepartmentUsersErrors.GeneralEditError);
  }

};

const deleteDepartmentById = async (id: string | number | undefined) => {
  try {
    const departmentResult = await base.del(`department?id=${id}`);
    return departmentResult;
  } catch (err) {
    // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
    console.log(err);
  }
}

export {
  getDepartments,
  getDepartmentUsers,
  getDepartmentById,
  createDepartment,
  updateDepartment,
  deleteDepartmentById
};
