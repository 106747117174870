import { QuestionTypes, SectionType, QuestionType } from 'actions/questionActions';
import { useTranslator }  from "hooks/useTranslator";
import { dateFormatter } from 'lib/commonFunctions';
import questions from 'data/questions.json';
import { Answer, ApiAnswer, getAnswerKey, UploadedAttachmentType } from 'context/reportUserNotesAnswers';

export const useApiAnswersToAnswersMap = () => {
  const translator = useTranslator();
  const newAnswersMap = new Map<string, Answer>();

  return (apiAnswers: Array<ApiAnswer>) => {
    apiAnswers.forEach((apiAnswer: ApiAnswer) => {
      const answer = {
        ...apiAnswer,
        answerKey: getAnswerKey(apiAnswer.sectionKey, apiAnswer.questionKey),
        value: JSON.parse(apiAnswer.value)
      }
      newAnswersMap.set(answer.answerKey, answer);
    });

    // We need to handle special case types that need to be converted, i.e. Date.
    if(questions) {
      questions.sections.forEach((section: SectionType) => {
        section.questions.forEach((question: QuestionType) => {
          const apiAnswer = newAnswersMap.get(getAnswerKey(section.key, question.key));

          if (apiAnswer) {
            if ( (question.type === QuestionTypes.DateTimeRange) && Array.isArray(apiAnswer.value.answer) ) {
              apiAnswer.valueFormatted = ( apiAnswer.value.answer.length === 1 ) 
                                            ? dateFormatter(apiAnswer.value.answer[0]) 
                                            : `${dateFormatter(apiAnswer.value.answer[0])} - ${dateFormatter(apiAnswer.value.answer[1])}`;
            } else if (question.type === QuestionTypes.MultiChoice) {
              apiAnswer.valueFormatted = translator(apiAnswer.value.answer[0]);
            } else if (question.type === QuestionTypes.Attachments) {
              apiAnswer.value.answer =  apiAnswer.value.answer.filter((attachment: UploadedAttachmentType) => {
                return Object.keys(attachment).length !== 0;
              });
              const attachmentArray : [] = apiAnswer.value.answer.map((attachment: UploadedAttachmentType) => {
                return attachment.filename.toString();
              });
              apiAnswer.valueFormatted = attachmentArray.join(',');
            } else if (typeof(apiAnswer.value.answer) === "string") {
              apiAnswer.valueFormatted = translator(apiAnswer.value.answer);
            } else {
              if(apiAnswer.value.answer) {
                apiAnswer.valueFormatted = apiAnswer.value.answer.toString();
              }
            }

            apiAnswer.valueFormatted = apiAnswer.value.scale ? `${apiAnswer.valueFormatted} ${apiAnswer.value.scale}` : apiAnswer.valueFormatted;
            newAnswersMap.set(apiAnswer.answerKey, apiAnswer); 
          }
        });
      })
    } 

    return newAnswersMap;
  }
};
