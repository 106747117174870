import { useReducer, useState, Fragment } from "react";
import { ReportData } from "actions/reportActions";
import { ExternalLinkIcon, QuestionButtonIcon, PersonIcon, EyeViewIcon, ExclamationButtonIcon } from "components/Icons";
import { FormattedMessage } from "react-intl";
import { dateFormatter, DateFormStyle } from 'lib/commonFunctions';
import { ReportStatusesModal } from "components/Modals/ReportStatusesModal";
import { generatePath } from "react-router-dom";
import { routePaths } from "App/routing";
import styles from './styles.module.scss';
import classNames from "classnames";
import { ErrorGeneralModal } from "components/Modals/ErrorGeneralModal";
import { useHasPermission } from "hooks/useHasPermission";
import { Permissions, RoleType } from "actions/roleActions";
import { Dropdown } from "components/Forms/Dropdown";
import { SearchInput } from "components/SearchInput";
import { useSortedReports } from "./useSortedReports";
import { AttentionMessage } from "components/AttentionMessage";
import { Department } from "actions/departmentActions";
import { UserInfo, useUserState } from "context/user";
import _ from "lodash";
import { isOrganizationTypeCrisisCenter, isOrganizationTypeLawEnforcement, isOrganizationTypeCampus, isOrganizationTypeVesta, isPermissionScopeAllOrOrganization, isPermissionScopeAssigned, isPermissionScopeNone } from "lib/permissions";
import { convertBoolToYesNo } from "lib/commonFunctions";
import { filterStatusObjects, reportStatusTypes, reportStatuses,unknownReportStatus, statusObject, SupportCenterStatus, ReportStatusType, PoliceStatus } from "lib/reportStatuses";

export interface ReportsTableProps {
  isRecentReports?: boolean;
  className?: string;
}

export const ReportsTable = ( { isRecentReports=false, className } : ReportsTableProps ) => {

  const userInfo: UserInfo & RoleType = useUserState();
  const hasPermission = useHasPermission();

  const [reports, setReports] = useState<Array<ReportData>>();
  type reportStatusTypeModalIsOpen = { [x: string]: boolean; };
  const [reportStatusesModalIsOpen, dispatch] = useReducer((reportStatusTypesModalAreOpen: reportStatusTypeModalIsOpen, statusType: ReportStatusType) => (
    { [statusType]: !reportStatusTypesModalAreOpen[statusType] } // toggles modal between open and closed state
  ), 
   // sets police and support center status type modals to closed for initial state
   filterStatusObjects({organizationTypeId: userInfo.organizationTypeId, statusObjects: reportStatusTypes})?.reduce((isInitiallyOpen: reportStatusTypeModalIsOpen,  statusType: statusObject) => {
    if (statusType.type) isInitiallyOpen[statusType.type] = false;
    return isInitiallyOpen;
   }, {}) || {}
  );

  const [errorGeneralModalIsOpen, setErrorGeneralModalIsOpen] = useState(false);
  const [reportInfosOpen, setReportInfosOpen] = useState<Set<any>>(new Set());
  const [consentInfosOpen, setConsentInfosOpen] = useState<Set<any>>(new Set());

  const openReportStatusesModal = (reportStatusType: ReportStatusType) => {
    dispatch(reportStatusType);
  };

  const openErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(true);
  };

  const closeReportStatusesModal = (reportStatusType: ReportStatusType) => {
    dispatch(reportStatusType);
  };

  const closeErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(false);
  };

  const {
    reportSortDropdownOptions,
    populateReports,
    onSortOptionChange,
    organizations,
    filteredReports,
    sortedAndFilteredReports
  } = useSortedReports(reports, setReports, isRecentReports);

  const handleReportViewMoreInfoClick = (index: any) => {
    const reportInfosToOpen = new Set(reportInfosOpen.values());
    if (reportInfosToOpen.has(index)) {
      reportInfosToOpen.delete(index);
    } else {
      reportInfosToOpen.add(index);
    }
    setReportInfosOpen(reportInfosToOpen);
  };

  const handleConsentViewMoreInfoClick = (index: any) => {
    const consentInfosToOpen = new Set(consentInfosOpen.values());
    if (consentInfosToOpen.has(index)) {
      consentInfosToOpen.delete(index);
    } else {
      consentInfosToOpen.add(index);
    }
    setConsentInfosOpen(consentInfosToOpen);
  };

  // Basic Display types:

  return (
    <>
    <div className={classNames(styles.reportsTable, className, isRecentReports && styles.isRecentReports)}>
      <div className={styles.reportsFilterSortSearch}>
        <div className={classNames(styles.attMessageContainer, styles.noPermissions)}>
          <AttentionMessage message={"message.someFeaturesDisabled"} />
        </div>
        <Dropdown dropdownId="reportsTableSortOptions" key="reportsTableSortOptions"
          dropdownOptions={reportSortDropdownOptions}
          onSelect={onSortOptionChange}/>
        <SearchInput onChange={populateReports}/>
      </div>

      
      <div className={classNames(styles.reportsTableContent)}>
        <div className={styles.labels}>
          {isRecentReports 
              ? (<p className={styles.reportLabel}><FormattedMessage id="recentReports" defaultMessage="Recent Reports"/></p>)
              : (<p className={styles.reportLabel}><FormattedMessage id="reports" defaultMessage="Reports"/></p>)}
          {isPermissionScopeAllOrOrganization(userInfo.shareOrganization) && (
            <p className={styles.assignedEntitiesLabel}><FormattedMessage id="organizations" defaultMessage="Organizations"/></p>
          )}
          <p className={styles.assignedEntitiesLabel}><FormattedMessage id="departments" defaultMessage="Departments"/></p>
          <p className={styles.assignedEntitiesLabel}><FormattedMessage id="users" defaultMessage="Users"/></p>
          {filterStatusObjects({organizationTypeId: userInfo.organizationTypeId, statusObjects: reportStatusTypes})?.map((statusType: statusObject) => (
            <Fragment key={statusType.id}>
              <div className={classNames(styles.statusLabel, styles.microText)}>
                <span><FormattedMessage {...statusType.intl}/></span>
                <QuestionButtonIcon className={styles.questionIcon} onClick={() => openReportStatusesModal(statusType.type)}/>
              </div>
              <ReportStatusesModal 
                isOpen={reportStatusesModalIsOpen[statusType.type]} 
                onClose={() => closeReportStatusesModal(statusType.type)} 
                filteredStatusObjects={filterStatusObjects({ reportStatusType: statusType.type, statusObjects: reportStatuses })}
              />
            </Fragment>
          ))}
          {
            (isOrganizationTypeVesta(userInfo.organizationTypeId) || 
              (
                isOrganizationTypeCampus(userInfo.organizationTypeId)
              )) && (
            <p className={styles.viewOrAssignLabel}><FormattedMessage id="report.consent" defaultMessage="Consent"/></p>
          )}
          <p className={styles.viewOrAssignLabel}><FormattedMessage id="report.view" defaultMessage="View"/></p>
          {isPermissionScopeAllOrOrganization(userInfo.shareOrganization) && (
            <p className={styles.viewOrAssignLabel}><FormattedMessage id="report.assignToOrganization" defaultMessage="Assign to Org."/></p>
          )}
          <p className={styles.viewOrAssignLabel}><FormattedMessage id="report.assignToUser" defaultMessage="Assign to User"/></p>
        </div>

        <div className={styles.reportRows}>
          {sortedAndFilteredReports?.map((report) => {
            const reportId = report.id;
            const isInfoOpen = reportInfosOpen.has(reportId);
            const isConsentInfoOpen = consentInfosOpen.has(reportId);

            if (!report.assignedOrganizations?.some(o => (Number(o.id) === Number(userInfo.organizationId)))) {
              const updatedAssignedOrganizations = [
                ...report.assignedOrganizations || [], 
                { id: Number(userInfo.organizationId), name: userInfo.organizationName }
              ];
              report.assignedOrganizations = updatedAssignedOrganizations;
            }

            const assignedDepartmentUsers = report.assignedDepartments?.reduce((assignedDepartmentUsers: (UserInfo & RoleType)[], department: Department) => {
              const assignedUsers = department.assignedUsers?.filter(u => isPermissionScopeAssigned(u[Permissions.ViewReportDetails]));
              if (assignedUsers?.length) {
                assignedDepartmentUsers = _.uniqBy([...assignedDepartmentUsers, ...assignedUsers], "id");
              }
              return assignedDepartmentUsers;
            }, []) || [];

            const allAssignedUsers = _.uniqBy([...assignedDepartmentUsers, ...report.assignedUsers || []], "id");
            const filteredAllAssignedUsers = allAssignedUsers.filter(u => !isPermissionScopeNone(u.viewReportDetails));

            const hasLawEnforcementOrgAssigned = report.assignedOrganizations.some((organization) => isOrganizationTypeLawEnforcement(organization?.organizationTypeId));
            const hasSupportCenterOrgAssigned = report.assignedOrganizations.some((organization) => isOrganizationTypeCrisisCenter(organization?.organizationTypeId));
            const hasCampusOrgAssigned = report.assignedOrganizations.some((organization) => isOrganizationTypeCampus(organization.organizationTypeId));

            const assignedEntities: any[] = [report.assignedDepartments, filteredAllAssignedUsers];
            if (isPermissionScopeAllOrOrganization(userInfo.shareOrganization)) {
              assignedEntities.unshift(report.assignedOrganizations);
            }

            return (
              <div key={report.reportKey}>
                <div className={styles.report}>
                  <div className={styles.reportKeyAndDate}>
                    <h6>{report.reportKey}</h6>
                    {report.dateSubmitted && (
                      <p className={styles.smallText}>
                        {dateFormatter(report.dateSubmitted, DateFormStyle.TextDateTime)}
                      </p>
                    )}
                  </div>

                  {assignedEntities.map((assignedEntities: any, index) => {
                      const assignedEntityNames = assignedEntities?.map((e: any) => e.name || e.fullName);
                      const numAssigned = assignedEntityNames?.length;
                      return (
                        <div className={styles.assignedEntities} key={index}>
                          {(numAssigned > 1) && (
                            <EyeViewIcon 
                              onClick={() => handleReportViewMoreInfoClick(reportId)} 
                              className={styles.viewMoreInfoButton}/>
                          )}

                          <div>
                            <h6>{assignedEntityNames?.[0]}</h6>
                            {
                              (numAssigned && (numAssigned > 1)) 
                                ? (
                                    <p className={styles.smallText}>
                                      {numAssigned - 1} {(numAssigned > 2) 
                                                          ? (<FormattedMessage id="otherAssignments" defaultMessage="other assignments"/>)
                                                          : (<FormattedMessage id="otherAssignment" defaultMessage="other assignment"/>)}
                                    </p>
                                  )
                                : <></>
                            }
                          </div>
                        </div>
                      );
                  })}
                  {filterStatusObjects({organizationTypeId: userInfo.organizationTypeId, statusObjects: reportStatusTypes})?.map((statusType: statusObject) => {
                    // TODO: Get report statuses
                    const currentStatusTypeId = `${statusType.id}`;
                    const foundStatusType = report.statuses?.get(currentStatusTypeId);

                    let unassignedReportStatus;
                    switch (statusType.type) {
                      case ReportStatusType.Police:
                        unassignedReportStatus = reportStatuses.find(s => (s.key === PoliceStatus.Unassigned));
                        break;
                      case ReportStatusType.SupportCenter:
                        unassignedReportStatus = reportStatuses.find(s => (s.key === SupportCenterStatus.Unassigned));
                        break;
                      default:
                        unassignedReportStatus = unknownReportStatus; 
                        break;
                    }
                    const reportStatus = foundStatusType ?  {
                     key: foundStatusType.id,
                     intl: {
                      id: foundStatusType.reportStatusName ? `report.status.${foundStatusType.reportStatusName}` : 'report.status.unassigned',
                      defaultMessage: foundStatusType.reportStatusDisplayName
                     },
                     name: foundStatusType.name
                     } : unassignedReportStatus;

                    return (
                      <p key={currentStatusTypeId} className={classNames(styles.smallText, styles.reportStatus, styles[reportStatus?.key || unassignedReportStatus?.key || styles.unassigned])}>
                        <FormattedMessage {...(reportStatus?.intl || unassignedReportStatus?.intl)}/>
                      </p>
                    );
                  })}

                  {                    
                    (isOrganizationTypeCampus(userInfo.organizationTypeId) &&
                    !(hasPermission(Permissions.ManageReportUserAssignment, report, userInfo)) &&
                      !(hasPermission(Permissions.ViewReportDetails, report, userInfo))
                    
                    )
                    ?  <p className={classNames(styles.smallText, styles.iconColumn)}></p> :
                    (
                      isOrganizationTypeVesta(userInfo.organizationTypeId) || 
                      (isOrganizationTypeCampus(userInfo.organizationTypeId) && 
                        (hasPermission(Permissions.ManageReportUserAssignment, report, userInfo)
                          || hasPermission(Permissions.ViewReportDetails, report, userInfo)
                        )
                      )
                    ) && (
                      <p className={classNames(styles.smallText, styles.iconColumn)}>
                        {
                          (
                            (!hasLawEnforcementOrgAssigned && report?.allowPoliceContact) || 
                            (!hasCampusOrgAssigned && report?.allowCampusContact) || 
                            (!hasSupportCenterOrgAssigned && report?.allowSupportCenterContact)
                          ) ?
                          (
                            <ExclamationButtonIcon 
                              onClick={() => {handleConsentViewMoreInfoClick(reportId)}}
                              className={classNames(styles.icon, styles.externalLinkIcon)}/>
                          ) : 
                          (
                            <EyeViewIcon 
                              onClick={() => {handleConsentViewMoreInfoClick(reportId)}}
                              className={classNames(styles.icon, styles.externalLinkIcon)}/>
                          )
                        }
                      </p>
                    )
                  }
                  <div className={styles.iconColumn} key="view">
                    <ExternalLinkIcon className={classNames(!hasPermission(Permissions.ViewReportDetails, report) && styles.noPermission, styles.icon, styles.externalLinkIcon)}
                      to={{ 
                        pathname: generatePath(routePaths.report, { reportId }), 
                        state: { report, getNotes: true }
                      }}/>
                  </div>

                  {hasPermission(Permissions.ShareOrganization, report) && (
                  <div className={styles.iconColumn} key="assignOrganizations">
                      <PersonIcon className={classNames(styles.icon, styles.personIcon)} 
                        to={{ 
                          pathname: generatePath(routePaths.assignOrganization, { reportId }),
                          state: { report, getNotes: false }
                        }}
                      />
                  </div>
                  )}


                  <div className={styles.iconColumn} key="assignUsers">
                    <PersonIcon className={classNames(!hasPermission(Permissions.ManageReportDepartmentAssignment, report) && !hasPermission(Permissions.ManageReportUserAssignment, report) && styles.noPermission, styles.icon, styles.personIcon)} 
                      to={{ 
                        pathname: generatePath(routePaths.assignUser, { reportId }), 
                        state: { report, getNotes: false }
                      }}/>  
                  </div>

                </div>

                <div className={classNames(styles.consentInfoDropdown, isConsentInfoOpen && styles.active)}>
                  <h5 className={styles.consentInfoDropdownHeader}>
                    <FormattedMessage id="consentExtraInfo.title" defaultMessage="Consent Information"/>
                  </h5>
                  
                  <div className={styles.consetFields}>
                    <p>
                      <FormattedMessage id="report.allowSupportCenterContact" defaultMessage="Consented to Support Center Contact"/>
                        :
                      <span className={styles.consentAnswer}>{convertBoolToYesNo(report.allowSupportCenterContact)}</span>
                    </p>
                    <p>
                      <FormattedMessage id="report.allowPoliceContact" defaultMessage="Consented to Police Contact"/>
                        :
                      <span className={styles.consentAnswer}>{convertBoolToYesNo(report.allowPoliceContact)}</span>
                    </p>
                    <p>
                      <FormattedMessage id="report.allowCampusContact" defaultMessage="Consented to Campus Contact"/>
                        :
                      <span className={styles.consentAnswer}>{convertBoolToYesNo(report.allowCampusContact)}</span>
                    </p>
                  </div>

                  <div>
                    {
                      (
                        (!hasLawEnforcementOrgAssigned && report?.allowPoliceContact) || 
                        (!hasCampusOrgAssigned && report?.allowCampusContact) || 
                        (!hasSupportCenterOrgAssigned && report?.allowSupportCenterContact)
                      ) ?  
                      (
                        <h5 className={styles.consentInfoAttentionHeader}>
                          <ExclamationButtonIcon 
                            className={classNames(styles.icon)}/>
                            <FormattedMessage id="consentExtraInfo.reportNeedsAttention" defaultMessage="Report Needs Attention"/>
                        </h5>
                      ) : null
                    }

                    {(!hasLawEnforcementOrgAssigned && report?.allowPoliceContact) && (
                      <p><FormattedMessage id="consentExtraInfo.assignToLawEnforcement" defaultMessage="This report has not been assigned to a Law Enforcement organization."/></p>
                    ) || null}

                    {(!hasSupportCenterOrgAssigned && report?.allowSupportCenterContact) && (
                      <p><FormattedMessage id="consentExtraInfo.assignToSupportCentre" defaultMessage="This report has not been assigned to a Support Center organization."/></p>
                    ) || null}

                    {(!hasCampusOrgAssigned && report?.allowCampusContact) && (
                      <p><FormattedMessage id="consentExtraInfo.assignToCampus" defaultMessage="This report has not been assigned to a Campus organization."/></p>
                    ) || null}     
                  </div>
                </div>

                <div className={classNames(styles.reportInfoDropdown, isInfoOpen && styles.active)}>
                  <h5 className={styles.reportInfoDropdownHeader}>
                    <FormattedMessage id="reportExtraInfo.title" defaultMessage="Report Information"/>
                  </h5>
                  <div className={styles.reportInfoDropdownContent}>
                    {hasPermission(Permissions.ShareOrganization, report) && (
                      <div className={styles.reportInfoContainer}>
                        <h6 className={styles.reportInfoContainerHeader}>
                          <FormattedMessage id="reportExtraInfo.assignedOrgs" defaultMessage="Assigned Organizations"/>
                        </h6>
                        {report.assignedOrganizations?.map((organization) => (
                          <p key={`${report.reportKey}-organizationId-${organization.id}`}>{organization.name}</p>
                        ))}
                      </div>
                    )}

                    <div className={styles.reportInfoContainer}>
                      <h6 className={styles.reportInfoContainerHeader}>
                        <FormattedMessage id="reportExtraInfo.assignedDepts" defaultMessage="Assigned Departments"/>
                      </h6>
                      {report.assignedDepartments?.map((department) => (
                        <div key={`${report.reportKey}-departmentId-${department.id}`} className={styles.departmentInfoContainer}>
                          <h6>{department.name}</h6>
                          {department.assignedUsers.map((assignedUser) => (
                            <p key={assignedUser.id}>{assignedUser.fullName}</p>
                          ))}
                        </div>
                      ))}
                    </div>

                    <div className={styles.reportInfoContainer}>
                      <h6 className={styles.reportInfoContainerHeader}>
                        <FormattedMessage id="reportExtraInfo.assignedUsers" defaultMessage="Assigned Users"/>
                      </h6>
                      {filteredAllAssignedUsers?.map((user) => (
                        <p key={`${report.reportKey}-userId-${user.id}`}>{user.fullName}</p>
                      ))}
                    </div>
                    {hasPermission(Permissions.ShareOrganization, report) && (
                      <div className={styles.reportInfoContainer}>
                        <h6 className={styles.reportInfoContainerHeader}>
                          <FormattedMessage id="reportExtraInfo.status" defaultMessage="Status"/>
                        </h6>
                        {report.assignedOrganizations?.map((organization) => (organization.reportStatusName && (
                          <p key={`${report.reportKey}-organizationId-${organization.id}`}>
                            <FormattedMessage id={`report.status.${organization.reportStatusName}`} defaultMessage={organization.reportStatusDisplayName}/>
                          </p>
                        )))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {(sortedAndFilteredReports.length === 0) && (
          <div className={styles.attMessageContainer}>
            <AttentionMessage message={"message.noReportsAssigned"} />
          </div>
        )}
      </div>
    </div>
    
    <ErrorGeneralModal isOpen={errorGeneralModalIsOpen} onClose={closeErrorGeneralModal}/>
    </>
  );
}
