import * as base from "./lib/baseActions";
import { googleAPIKey } from "lib/config";
import { ReportNotesAnswersDocument } from "components/ReportNotesAnswersDocument";

const stringTrue = "true";

export enum AnonymousType {
  All = "All",
  Anonymous = "Anonymously",
 NonAnonomous = "Non-Anonymously",
}

export interface locationQuery {
    isAnonymous?:boolean,
    startDate?:Date,
    endDate?:Date,
    noDate?:boolean,
    isMorning?:boolean,
    isAfternoon?:boolean,
    isEvening?:boolean,
    isNight?:boolean,
    noTime?:boolean,
    allDates?:boolean,
}

export interface reportLocation {
    address?: string;
    lat?: number;
    long?: number;
    date?: Date[];
    type?: string;
    image?: string;
}
export enum displayTypes {
    anonymous = "anonymous",
    nonAnonymous = "nonAnonymous",
    notPinable = "notPinable"
}

export enum pinImage {
    anonymousNoDate = "mapPins/anonymousNoDate.png",
    anonymousNoTime = "mapPins/anonymousNoTime.png",
    anonymousMorning = "mapPins/anonymousMorning.png",
    anonymousAfternoon = "mapPins/anonymousAfternoon.png",
    anonymousEvening = "mapPins/anonymousEvening.png",
    anonymousNight = "mapPins/anonymousNight.png",
    nonAnonymousNoDate = "mapPins/nonAnonymousNoDate.png",
    nonAnonymousNoTime = "mapPins/nonAnonymousNoTime.png",
    nonAnonymousMorning = "mapPins/nonAnonymousMorning.png",
    nonAnonymousAfternoon = "mapPins/nonAnonymousAfternoon.png",
    nonAnonymousEvening = "mapPins/nonAnonymousEvening.png",
    nonAnonymousNight = "mapPins/nonAnonymousNight.png"
  }
  
export enum dateToggles {
    allDates = "allDates",
    noDate = "noDate",
    noTime = "noTime",
    morning = "morning",
    afternoon = "afternoon",
    evening = "evening",
    night = "night"
}

const acceptableLocationTypes = new Map([
  ['street_address','street_address'],
  ['point_of_interest','point_of_interest'],
  ['establishment', 'establishment'],
  ['premise', 'premise']
]);

// API call
const getLocations = async (searchQuery: locationQuery)  => {

    try {
      let locationsURL = 'locationStats?';

      if (searchQuery) {
        if(typeof searchQuery.isAnonymous !== "undefined") {
          locationsURL += `&isAnonymous=${searchQuery.isAnonymous}`;
        }
        if(typeof searchQuery.startDate !== "undefined" && typeof searchQuery.endDate !== "undefined") {
          // Make sure we're covering whole days and not stopping at some arbitrary time in the middle
          searchQuery.startDate.setHours(0,0,0,0);
          searchQuery.endDate.setHours(23,59,59,999);
          if(typeof searchQuery.allDates !== "undefined") {
            // do not use start and end to get all dates
          } else {
            locationsURL += `&startDate=${searchQuery.startDate.toISOString()}`;
            locationsURL += `&endDate=${searchQuery.endDate.toISOString()}`;
          }
        }
        if(typeof searchQuery.noDate !== "undefined") {
          locationsURL += `&noDate=${searchQuery.noDate}`;
        }
        if(typeof searchQuery.noTime !== "undefined") {
          locationsURL += `&noTime=${searchQuery.noTime}`;
        }
        if(typeof searchQuery.isMorning !== "undefined") {
          locationsURL += `&isMorning=${searchQuery.isMorning}`;
        }
        if(typeof searchQuery.isAfternoon !== "undefined") {
          locationsURL += `&isAfternoon=${searchQuery.isAfternoon}`;
        }
        if(typeof searchQuery.isEvening !== "undefined") {
          locationsURL += `&isEvening=${searchQuery.isEvening}`;
        }
        if(typeof searchQuery.isNight !== "undefined") {
          locationsURL += `&isNight=${searchQuery.isNight}`;
        }
      }
      const locationResults = await base.get(locationsURL);
      const locations = await buildLocations(locationResults.data);
      return locations;

    } catch(err) {
      console.log(err);
    }
};

//get api coordinates through google
const getCoordinates = async (address: string) => {
     return fetch("https://maps.googleapis.com/maps/api/geocode/json?address="+address+'&key='+googleAPIKey)
      .then(response => {
       return response.json()
      })
      .then(data => { 
        return data })
};

interface MapsLocation {
    lat: number;
    lng: number
}

interface MapsResultType {
  geometry: {
    location: MapsLocation
  };
  types: string[];
}

// builds report location types for lcoations page
const buildLocations = async (data: any[]) => {
    let builtLocations : Array<reportLocation> = [];
    for (const [reportId, value] of Object.entries(data)) {
        let coordinates: MapsLocation | undefined = undefined;
        if(value.location) {
            const response = await getCoordinates(value.location);
            if(response.status === "OK"){
              // Find the first 
              let foundCoordinate = response.results.find((r: MapsResultType) => {
                if(r.types?.find((t: string) => {
                  return acceptableLocationTypes.get(t);
                })) {
                  return true;
                } else {
                  return false;
                }
              });
              coordinates = foundCoordinate?.geometry?.location;           
            }
        }
        let reportCategory;
        if (coordinates) {
          reportCategory = value.isAnonymous && value.isAnonymous === stringTrue ? displayTypes.anonymous: displayTypes.nonAnonymous
        } else {
          reportCategory = displayTypes.notPinable
        }

        let report: reportLocation = {
            address: value.location? value.location: undefined,
            lat: coordinates? coordinates.lat: undefined,
            long: coordinates? coordinates.lng: undefined,
            date: value.time,
            type: reportCategory,
            image: "",
        }
        if (reportCategory !== displayTypes.notPinable) {
          report.image = mapPinImage(report);
        }
        builtLocations.push(report);
    }
    return builtLocations;
};

// finds correct image marker for a report location
const mapPinImage  = (report: reportLocation) =>{
    let timeGroup = timeOfDay(report);
    let image = "";
    
    if(timeGroup === dateToggles.noDate){
      if(report.type === displayTypes.nonAnonymous){
        image = pinImage.nonAnonymousNoDate
      }
      else if(report.type === displayTypes.anonymous){
        image = pinImage.anonymousNoDate
      }
    }
    else if(timeGroup === dateToggles.noTime){
      if(report.type === displayTypes.nonAnonymous){
        image = pinImage.nonAnonymousNoTime
      }
      else if(report.type === displayTypes.anonymous){
        image = pinImage.anonymousNoTime
      }
    }
    else if(timeGroup === dateToggles.morning){
      if(report.type === displayTypes.nonAnonymous){
        image = pinImage.nonAnonymousMorning
      }
      else if(report.type === displayTypes.anonymous){
        image = pinImage.anonymousMorning
      }
    }
    else if(timeGroup === dateToggles.afternoon){
      if(report.type === displayTypes.nonAnonymous){
        image = pinImage.nonAnonymousAfternoon
      }
      else if(report.type=== displayTypes.anonymous){
        image = pinImage.anonymousAfternoon
      }
    }
    else if(timeGroup === dateToggles.evening){
      if(report.type === displayTypes.nonAnonymous){
        image = pinImage.nonAnonymousEvening
      }
      else if(report.type === displayTypes.anonymous){
        image = pinImage.anonymousEvening
      }
    }
    else if(timeGroup === dateToggles.night){
      if(report.type === displayTypes.nonAnonymous){
        image = pinImage.nonAnonymousNight
      }
      else if(report.type === displayTypes.anonymous){
        image = pinImage.anonymousNight
      }
    }    
    return image;
  }

  // fined time of day for a report
  const timeOfDay = (report: reportLocation) => {
    if(!report.date || report.date.length === 0){
      return dateToggles.noDate; 
    }
    const time = new Date(report.date[0]);

    if(time.getHours() < 6){
        return dateToggles.night;
    }
    else if(time.getHours() < 12 && time.getHours() >= 6){
        return dateToggles.morning;
    }
    else if(time.getHours() < 18 && time.getHours() >= 12){
        return dateToggles.afternoon;

    }
    else if(time.getHours() >= 18) {
        return dateToggles.evening;
    } else {
      return dateToggles.noTime; 
    }
  }

export{
    getLocations,    
    timeOfDay
};
