import { Organization, OrganizationTypeIds } from "actions/organizationActions";
import { ReportData } from "actions/reportActions";
import { Organizations } from "aws-sdk";
import _ from "lodash";
import { MessageDescriptor } from "react-intl";

export enum ReportStatusType {
  Police = "police",
  SupportCenter = "supportCenter",
  Unknown = "unknown",
  Organization = "organization"
};

export interface statusObject {
  id?: number;
  type: ReportStatusType;
  key: string;
  intl: MessageDescriptor;
  shortHandIntl?: MessageDescriptor;
  descriptionIntl?: MessageDescriptor;
}

// TODO: REMOVE THIS LOGIC
export const reportStatusTypes: statusObject[] = [
  {
    id: 2,
    type: ReportStatusType.Police,
    key: "policeStatus",
    intl: { id: "report.policeStatus", defaultMessage: "Police Status" },
    shortHandIntl: { id: "report.policeStatus.shortHand", defaultMessage: "LE" }
  },
  {
    id: 3,
    type: ReportStatusType.SupportCenter,
    key: "supportCenterStatus",
    intl: { id: "report.supportCenterStatus", defaultMessage: "Support Center Status" },
    shortHandIntl: { id: "report.supportCenterStatus.shortHand", defaultMessage: "SAC" }
  }
];

// TODO: REMOVE THIS LOGIC
export enum PoliceStatus {
  Unassigned = "unassigned",
  AssignedUnactioned = "assignedUnactioned",
  ActiveOngoing = "activeOngoing",
  ClearedByCharge = "clearedByCharge",
  ClearedInsufficientEvidence = "clearedInsufficientEvidence",
  ClearedNoChargeableSuspectIdentified ="clearedNoChargeableSuspectIdentified",
  ClearedComplainantDeclinesToProceed ="clearedComplainantDeclinesToProceed",
  ClearedOtherwise = "clearedOtherwise",
  Unknown = "unknown"
};

// TODO: REMOVE THIS LOGIC
export enum SupportCenterStatus {
  Unassigned = "unassigned",
  AssignedUnactioned = "assignedUnactioned",
  ActiveAppointmentBooked = "activeAppointmentBooked",
  ActiveWaitlisted = "activeWaitlisted",
  ActiveCounselling = "activeCounselling",
  DischargedNoContact = "dischargedNoContact",
  DischargedChangedMind = "dischargedChangedMind",
  DischargedReferredElsewhere = "dischargedReferredElsewhere",
  Unknown = "unknown"
};

// TODO: REMOVE THIS LOGIC
export const reportStatuses: statusObject[] = [
  {
    type: ReportStatusType.Police,
    key: PoliceStatus.Unassigned,
    intl: { id: "policeStatus.unassigned", defaultMessage: "Unassigned" },
    descriptionIntl:  { id: "policeStatus.unassigned.description", defaultMessage: "Survivor Report received but not assigned by the Admin to an Investigating Officer" }
  },
  {
    type: ReportStatusType.Police,
    key: PoliceStatus.AssignedUnactioned,
    intl: { id: "policeStatus.assignedUnactioned", defaultMessage: "Assigned - Unactioned" },
    descriptionIntl:  { id: "policeStatus.assignedUnactioned.description", defaultMessage: "Survivor Report received and assigned to an Investigating Officer; however, it remains unactioned by the Investigator." }
  },
  {
    type: ReportStatusType.Police,
    key: PoliceStatus.ActiveOngoing,
    intl: { id: "policeStatus.activeOngoing", defaultMessage: "Active/Ongoing" },
    descriptionIntl:  { id: "policeStatus.activeOngoing.description", defaultMessage: "Survivor Report assigned to Investigating Officer. Entered into Record Management System" }
  },
  {
    type: ReportStatusType.Police,
    key: PoliceStatus.ClearedByCharge,
    intl: { id: "policeStatus.clearedByCharge", defaultMessage: "Cleared By Charge" },
    descriptionIntl:  { id: "policeStatus.clearedByCharge.description", defaultMessage: "Suspect charged" }
  },
  {
    type: ReportStatusType.Police,
    key: PoliceStatus.ClearedInsufficientEvidence,
    intl: { id: "policeStatus.clearedInsufficientEvidence", defaultMessage: "Cleared Insufficient Evidence" },
    descriptionIntl:  { id: "policeStatus.clearedInsufficientEvidence.description", defaultMessage: "Cleared by Insufficient Evidence. Suspect identified but insufficient evidence to proceed." }
  },
  {
    type: ReportStatusType.Police,
    key: PoliceStatus.ClearedNoChargeableSuspectIdentified,
    intl: { id: "policeStatus.clearedNoChargeableSuspectIdentified", defaultMessage: "Cleared No Chargeable Suspect Identified" },
    descriptionIntl:  { id: "policeStatus.clearedNoChargeableSuspectIdentified.description", defaultMessage: "Cleared. No chargeable suspect identified." }
  },
  {
    type: ReportStatusType.Police,
    key: PoliceStatus.ClearedComplainantDeclinesToProceed,
    intl: { id: "policeStatus.clearedComplainantDeclinesToProceed", defaultMessage: "Cleared Complainant Declines to Proceed (Chargeable Suspect Identified)" },
    descriptionIntl:  { id: "policeStatus.clearedComplainantDeclinesToProceed.description", defaultMessage: "Cleared. Complainant declines to proceed despite having a chargeable suspect." }
  },
  {
    type: ReportStatusType.Police,
    key: PoliceStatus.ClearedOtherwise,
    intl: { id: "policeStatus.clearedOtherwise", defaultMessage: "Cleared Otherwise" },
    descriptionIntl:  { id: "policeStatus.clearedOtherwise.description", defaultMessage: "Cleared. Status does not fit into any of the other options." }
  },
  {
    type: ReportStatusType.SupportCenter,
    key: SupportCenterStatus.Unassigned,
    intl: { id: "supportCenterStatus.unassigned", defaultMessage: "Unassigned" },
    descriptionIntl:  { id: "supportCenterStatus.unassigned.description", defaultMessage: "Survivor Report received but not assigned by the Admin to a Case Worker" }
  },
  {
    type: ReportStatusType.SupportCenter,
    key: SupportCenterStatus.AssignedUnactioned,
    intl: { id: "supportCenterStatus.assignedUnactioned", defaultMessage: "Assigned - Unactioned" },
    descriptionIntl:  { id: "supportCenterStatus.assignedUnactioned.description", defaultMessage: "Survivor Report received and assigned to a Case Worker; however, it remains unactioned by the Case Worker" }
  },
  {
    type: ReportStatusType.SupportCenter,
    key: SupportCenterStatus.ActiveAppointmentBooked,
    intl: { id: "supportCenterStatus.activeAppointmentBooked", defaultMessage: "Active - Appointment booked" },
    descriptionIntl:  { id: "supportCenterStatus.activeAppointmentBooked.description", defaultMessage: "Survivor Report assigned to Case Worker, client details have been entered in the Record Management System, and an appointment has been booked" }
  },
  {
    type: ReportStatusType.SupportCenter,
    key: SupportCenterStatus.ActiveWaitlisted,
    intl: { id: "supportCenterStatus.activeWaitlisted", defaultMessage: "Active - Waitlisted" },
    descriptionIntl:  { id: "supportCenterStatus.activeWaitlisted.description", defaultMessage: "Survivor Report assigned to Case Worker, client details have been entered in the Record Management System, and the client has been waitlisted for services" }
  },
  {
    type: ReportStatusType.SupportCenter,
    key: SupportCenterStatus.ActiveCounselling,
    intl: { id: "supportCenterStatus.activeCounselling", defaultMessage: "Active - Counselling" },
    descriptionIntl:  { id: "supportCenterStatus.activeCounselling.description", defaultMessage: "Survivor Report assigned to Case Worker and the client is receiving services" }
  },
  {
    type: ReportStatusType.SupportCenter,
    key: SupportCenterStatus.DischargedNoContact,
    intl: { id: "supportCenterStatus.dischargedNoContact", defaultMessage: "Discharged - No Contact" },
    descriptionIntl:  { id: "supportCenterStatus.dischargedNoContact.description", defaultMessage: "Case Worker did not receive a response to contact request" }
  },
  {
    type: ReportStatusType.SupportCenter,
    key: SupportCenterStatus.DischargedChangedMind,
    intl: { id: "supportCenterStatus.dischargedChangedMind", defaultMessage: "Discharged - Changed Mind" },
    descriptionIntl:  { id: "supportCenterStatus.dischargedChangedMind.description", defaultMessage: "Client indicated that they have changed their minds and do not want to receive services." }
  },
  {
    type: ReportStatusType.SupportCenter,
    key: SupportCenterStatus.DischargedReferredElsewhere,
    intl: { id: "supportCenterStatus.dischargedReferredElsewhere", defaultMessage: "Discharged - Referred Elsewhere" },
    descriptionIntl:  { id: "supportCenterStatus.dischargedReferredElsewhere.description", defaultMessage: "Client referred to other service provider." }
  }
];

export const unknownReportStatus =   {
  type: ReportStatusType.Unknown,
  key: SupportCenterStatus.Unknown,
  intl: { id: "supportCenterStatus.unknown", defaultMessage: "Unknown" },
  descriptionIntl:  { id: "unknown.description", defaultMessage: "Client referred to unknown." }
};

interface filterStatusObjectsProps {
  organizationTypeId?: OrganizationTypeIds; 
  reportStatusType?: ReportStatusType;
  statusObjects?: statusObject[];
};

// TODO: REMOVE THIS LOGIC 
export const filterStatusObjects = ({organizationTypeId, reportStatusType, statusObjects}: filterStatusObjectsProps): statusObject[] | undefined => {
  const reportStatusObjectsByType = _.groupBy(statusObjects, "type");

  if (organizationTypeId) {
    switch (organizationTypeId) {
      case OrganizationTypeIds.Vesta:
        return statusObjects;
      case OrganizationTypeIds.LawEnforcement:
        return reportStatusObjectsByType[ReportStatusType.Police];
      case OrganizationTypeIds.CrisisCenter:
        return reportStatusObjectsByType[ReportStatusType.SupportCenter];
    }
  }
  
  if (reportStatusType) {
    switch (reportStatusType) {
      case ReportStatusType.Police:
        return reportStatusObjectsByType[ReportStatusType.Police];
      case ReportStatusType.SupportCenter:
        return reportStatusObjectsByType[ReportStatusType.SupportCenter];
    }
  }
};

export const filterStatus = (organizations: Organization[]) => {
  const s: Map<string, statusObject> = organizations.reduce((result: Map<string, statusObject>, o: Organization) => {
    if (o.reportStatusName) {
      const newMap = result.set(o.reportStatusName , {
        type: ReportStatusType.Organization,
        key: o.reportStatusName,
        intl: { id: `report.status.${o.reportStatusName}`, defaultMessage:`${o.reportStatusDisplayName}`},
      });
    }
    return result;
  }, new Map<string, statusObject>());

  return s;
}
