
import styles from "./styles.module.scss";
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ArrowButton, ArrowDirection, ArrowPosition } from "components/Buttons/Button";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface PageNavigationProps {
  numPages: number | null;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  className?: string;
}

export const PageNavigation = ({ numPages, pageNumber, setPageNumber, className } : PageNavigationProps) => {

  const onPreviousPageClick = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber-1);
    }
  }

  const onNextPageClick = () => {
    if (pageNumber !== numPages) {
      setPageNumber(pageNumber+1);
    }
  }

  return (
    <div className={classNames(styles.pageNavigation, className)}>
      <ArrowButton clickAction={onPreviousPageClick}
        arrowDirection={ArrowDirection.Left} 
        arrowPosition={ArrowPosition.Left} 
        className={classNames(styles.prevButton, styles.pageNavigationButton)}
      />

      <span>
        <FormattedMessage id="page" defaultMessage="Page"/> {pageNumber} <FormattedMessage id="of" defaultMessage="of"/> {numPages}
      </span>
      
      <ArrowButton clickAction={onNextPageClick}
        arrowDirection={ArrowDirection.Right} 
        arrowPosition={ArrowPosition.Right}
        className={classNames(styles.nextButton, styles.pageNavigationButton)}
      />
    </div>
  );
}
