import React, { ReactNode } from "react";
import styles from './styles.module.scss';
import { ExternalLinkIcon } from "components/Icons";
import { routePaths } from "App/routing";
import classNames from "classnames";

export interface StatCardProps {
  className?: string;
  label: ReactNode;
  statNumber: number;
  statPercentage?: number | string;
  displayPercentage?: boolean;
  displayExternalLinkIcon?: boolean;
}

export const StatCard = ( { className, label, statNumber, statPercentage, displayPercentage=false, displayExternalLinkIcon=false } : StatCardProps) => {
  return (
    <div className={classNames(className, styles.statCard)}>
      <h1>{statNumber}</h1> 
      {displayPercentage && (<h5>({statPercentage}%)</h5>)}
      <span className={styles.label}>{label}</span>
      {displayExternalLinkIcon && ( <ExternalLinkIcon className={styles.externalLinkIcon} to={{ pathname: routePaths.statistics }}/> )}
    </div>
  );
}
