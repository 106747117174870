import { useMemo } from "react";
import { awsData } from '../../../../../lib/config';
import { Button, ButtonStyle } from "components/Buttons";
import { UploadedAttachmentType } from "context/reportUserNotesAnswers";
import JSZip from "jszip";
import axios from "axios";
import FileSaver from "file-saver";
export interface DownloadAllAttachmentsProps {
  reportId?: number;
  answers: any;
  className?: string;
  style?: ButtonStyle;
}

export interface AttachmentType {
  fileName: string;
  downloadLink?: string;
  uniqueKey?: string;
}

const downloadS3File = async (file: AttachmentType) => {
  return downloadFile({
    ...file, 
    //downloadLink: (`https://s3.${awsData.awsRegion}.amazonaws.com/${awsData.awsBucket}/${file.uniqueKey}`) 
    downloadLink: file.uniqueKey,
  });
}

const downloadFile = async (file: AttachmentType) => {
  let fullUrl = file.downloadLink;

  if (!fullUrl) {
    return { fileName: file.fileName, file: new Blob([]), uniqueKey: file.uniqueKey };
  } else {
    // const myInit = {
    //   method: 'GET',
    // //  mode: 'no-cors' as RequestMode,
    //   headers: {
    //     'Cache-Control': 'no-cache',
    //     'Pragma': 'no-cache',
    //     'Expires': '0',
    //   }, 
    // };

    // const myRequest = new Request(fullUrl, myInit);
//  //   console.log('downloadFile');
    // const downloadedFile = await fetch(myRequest);
    // if (!downloadedFile.ok) {
    //   console.log('download File Failed:', downloadedFile.status);
    // } else {
    //   console.log('DF:', downloadedFile.status);
    // }

    const downloadedFile = await axios.get(fullUrl, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }, 
    });

    const resultBlob = new Blob([downloadedFile.data]);
   // const resultBlob = downloadedFile.blob();
    return { fileName: file.fileName, file: resultBlob, uniqueKey: file.uniqueKey };
  }
}

export const DownloadAllAttachments = ({reportId, answers, className, style}  : DownloadAllAttachmentsProps) => {
  const imageFiles = useMemo(() => {

    const answerFiles = answers?.map(((x: UploadedAttachmentType) => {
      if(x.filename.includes(".jpg") || x.filename.includes(".jpeg") || x.filename.includes(".png") || x.filename.includes(".heic")) {
        return (
          {
            uniqueKey: x.uniqueKey,
            fileName: x.filename
          }
        )
      }
    }))
    .filter(((x : UploadedAttachmentType) => {
        return x != null;
    })) || [];
    return answerFiles;
  }, [answers]); 
  
  const videoFiles = useMemo(() => {

    const answerFiles = answers?.map(((x: UploadedAttachmentType) => {
        if(x.filename.includes(".mp4") || x.filename.includes(".wav")) {
          return (
            {
              uniqueKey: x.uniqueKey,
              fileName: x.filename
            }
          )
        }
      }))
      .filter(((x : UploadedAttachmentType) => {
        return x != null;
      })) || [];
    return answerFiles;
  }, [answers]); 


  const pdfFiles = useMemo(() => {

    const answerFiles = answers?.map(((x: UploadedAttachmentType) => {
      if(x.filename.includes(".pdf")) {
        return (
          {
            uniqueKey: x.uniqueKey,
            fileName: x.filename
          }
        )
      }
      }))
      .filter(((x : UploadedAttachmentType) => {
        return x != null;
      })) || [];
    return answerFiles;
  }, [answers]); 
  
  const soundFiles = useMemo(() => {
    const answerFiles = answers?.map(((x: UploadedAttachmentType) => {
      if(x.filename.includes(".mp3"))
        return (
          {
            uniqueKey: x.uniqueKey,
            fileName: x.filename
          }
        )
      }))
      .filter(((x : UploadedAttachmentType) => {
        return x != null;
      })) || [];
    return answerFiles;
  }, [answers]); 

  const downloadFiles = async () => {
    // Download Files.
    const downloadedImageFiles = imageFiles.map((file: AttachmentType) => {
      return downloadS3File(file);
    });

    const downloadedVideoFiles = videoFiles.map((file: AttachmentType) => {
      return downloadS3File(file);
    })
    const downloadedPdfFiles = pdfFiles.map((file: AttachmentType) => {
      return downloadS3File(file);
    })
    const downloadedSoundFiles = soundFiles.map((file: AttachmentType) => {
      return downloadS3File(file);
    });

    const allFiles = [ ...downloadedImageFiles, ...downloadedVideoFiles, ...downloadedPdfFiles, ...downloadedSoundFiles];
    const downloadedResults = await Promise.all(allFiles);

    // Zip Files.
    const zip = new JSZip();
    zip.folder("files");

    downloadedResults.reduce((result, file) => {
      result.file(file.fileName,file.file);
      return result;
    }, zip);

    zip.generateAsync({type:"blob"}).then(function(content) {
        // see FileSaver.js
        FileSaver.saveAs(content, "attachments.zip");
    });
  } 

  return <Button className={className} style={style}  onClick={() => { downloadFiles()}}>Download All Files</Button>
}
