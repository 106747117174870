import React from "react";
import { ReportTimeStep } from "./ReportTimeStep";
import { FormattedMessage } from "react-intl";
import { useReportUserNotesAnswersState } from "context/reportUserNotesAnswers";
import { dateFormatter } from 'lib/commonFunctions';
import styles from './styles.module.scss';
import { AuthorType, NoteType } from "actions/noteActions";
import classNames from "classnames";


export const ReportTimeline = () => {

  const reportUserNotesAnswers = useReportUserNotesAnswersState();

  const dateSubmitted = reportUserNotesAnswers.report?.dateSubmitted;
  const dateUpdated = reportUserNotesAnswers.report?.updatedAt;

  return (
    <div className={styles.reportTimeline}>
        <h3 className={styles.header}>Report Timeline</h3>
        <div className={styles.reportTimeStepsContainer}>
          {dateSubmitted && (
            <ReportTimeStep key="dateSubmitted">
              <h5 className={styles.noteContent}><FormattedMessage id="reportTimeline.submitted" defaultMessage="Report Submitted"/></h5>
              <p className={styles.microText}>{dateFormatter(dateSubmitted)}</p>
            </ReportTimeStep>
          )}
          
          {dateUpdated && ( 
            <ReportTimeStep key="dateUpdated">
              <h5 className={styles.noteContent}><FormattedMessage id="reportTimeline.updated" defaultMessage="Report Updated"/></h5>
              <p className={styles.microText}>{dateFormatter(dateUpdated)}</p>
            </ReportTimeStep>
          )}

          {reportUserNotesAnswers.notes?.map((note: NoteType) => (
            <ReportTimeStep key={note.id} isAdminNote={true}>
              <h5 className={classNames(styles.subheader, styles.noteContent)}>{note.note}</h5>
              {(note.author !== AuthorType.System) && (
                <p className={styles.microText}><FormattedMessage id="reportTimeline.noteAuthor" defaultMessage="Note Author"/>: {note.author}</p>
              )}
              <p className={styles.microText}>{note.createdAt && dateFormatter(note.createdAt)}</p>
            </ReportTimeStep>
          ))}

        </div>
    </div>
  );
}
