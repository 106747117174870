
import { useState } from "react";
import styles from "./styles.module.scss";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { PageNavigation } from "./PageNavigation";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import useMeasure from "react-use-measure";

export interface DisplayPDFProps {
  pdfUrl: string;
}

export const DisplayPDF = ({ pdfUrl } : DisplayPDFProps) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
  }

  const [ref, bounds] = useMeasure();

  return (
    
    <div className={styles.pdfContainer}>
      <div className={styles.innerPdfContainer} ref={ref}>
        <Document
          file={pdfUrl}
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            width={bounds.width} 
            key={`page_${pageNumber}`}
            pageNumber={pageNumber} 
          />
        </Document>

        <PageNavigation
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          className={styles.pdfPageNavigation}
        />
      </div>
    </div>
  );
}
