import React, { useState } from "react";
import { deleteDepartmentById, Department } from "actions/departmentActions";
import { PencilIcon, DeleteIcon } from "components/Icons";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import { routePaths } from "App/routing";
import styles from './styles.module.scss';
import classNames from "classnames";
import { ErrorGeneralModal } from "components/Modals/ErrorGeneralModal";
import { SearchInput } from "components/SearchInput";
import { Dropdown } from "components/Forms/Dropdown";
import { useSortedDepartments } from "./useSortedDepartments";
import { LinkButton, ButtonStyle } from "components/Buttons";
import { YesNoModal } from "components/Modals/YesNoModal";
import { AttentionMessage } from "components/AttentionMessage";


export interface DepartmentsTableProps {
  className?: string;
}

export const DepartmentsTable = ( { className } : DepartmentsTableProps ) => {

  const [departments, setDepartments] = useState<Department[]>();

  const [errorGeneralModalIsOpen, setErrorGeneralModalIsOpen] = useState(false);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string | number | undefined>();

  const openErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(true);
  }

  const closeErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(false);
  };

  const {
    departmentSortDropdownOptions,
    populateDepartments,
    onSortOptionChange,
    sortedDepartments
  } = useSortedDepartments(departments, setDepartments);

  const deleteDepartment = async (departmentId: string | number | undefined) => {
    if (departmentId) {
      try {
        const deleteDepartmentResult = await deleteDepartmentById(departmentId);
        if (deleteDepartmentResult) { // backend returns true if successfully deleted
          setDepartments(departments?.filter(d => (d.id !== departmentId)));
        }
      } catch (err) {
        openErrorGeneralModal();
      }
    }
    setConfirmDeleteModalIsOpen(false); 
  };

  return (
    <>
      <div className={classNames(styles.departmentsTable, className)}>
        <div className={styles.departmentsFilterSortSearch}>
          <div className={styles.addButtonContainer}>
            <LinkButton to={{ pathname: routePaths.addDepartmentUsers, state: {} }}
              style={ButtonStyle.Secondary}
              className={styles.addButton}>
              <span className={styles.addButtonText}><FormattedMessage id="buttons.add" defaultMessage="Add"/></span>
            </LinkButton>
          </div>

          <Dropdown dropdownId="departmentsTableSortOptions" key="departmentsTableSortOptions"
            dropdownOptions={departmentSortDropdownOptions}
            className={styles.departmentsTableDropdown}
            onSelect={onSortOptionChange}/>
          <SearchInput onChange={populateDepartments}/>
        </div>

        <div className={styles.departmentsTableContainer}>
          <div className={classNames(styles.departmentsTableRow, styles.rowHeader)}>
            <p className={styles.name}><FormattedMessage id="department" defaultMessage="Department"/></p>
            <p className={styles.numberOfUsers}><FormattedMessage id="numberOfUsers" defaultMessage="Number of users"/></p>
            <p className={styles.editDepartmentUsers}><FormattedMessage id="edit" defaultMessage="Edit"/></p>
            <p className={styles.deleteDepartment}><FormattedMessage id="delete" defaultMessage="Delete"/></p>
          </div>

          <div className={styles.departmentRows}>

            {sortedDepartments?.map((department) => {
              const editDepartmentUsersPath = generatePath(routePaths.editDepartmentUsers, { editDepartmentId: department.id });
              const assignedUserIds = department.assignedUsers?.map(u => u.id);
              return (
                <div className={classNames(styles.departmentsTableRow, styles.rowBorder)} key={department.id}>
                  <h5 className={styles.name}>{department.name}</h5>
                  <span className={styles.numberOfUsers}>{assignedUserIds?.length}</span>
                  <PencilIcon to={{ pathname: editDepartmentUsersPath, state: { department, assignedUserIds } }}
                    className={styles.editDepartmentUsers}/>
                  <DeleteIcon className={styles.deleteDepartment} 
                    onClick={() => { 
                      setUserIdToDelete(department.id);
                      setConfirmDeleteModalIsOpen(true); 
                    }}/>
                </div>
              );
            })}
          </div>

          {!sortedDepartments || sortedDepartments?.length === 0 && 
            <div className={styles.noDeptMessage}>
              <AttentionMessage message="message.noDepartments"></AttentionMessage>
            </div>
          }
        </div>
      </div>
      <YesNoModal 
        isOpen={confirmDeleteModalIsOpen} 
        message="modal.areYouSureDeleteDept"
        onClose={() => { setConfirmDeleteModalIsOpen(false); }}
        onYesClick={() => { return deleteDepartment(userIdToDelete); }}
        onNoClick={() => { setConfirmDeleteModalIsOpen(false); }} 
      />
      <ErrorGeneralModal isOpen={errorGeneralModalIsOpen} onClose={closeErrorGeneralModal}/>
    </>
  );
}
