import { routePaths } from "App/routing";
import { useReportUserNotesAnswersState, ReportUserNotesAnswers, useSetReportUserNotesAnswersState, initialReportUserNotesAnswersState } from 'context/reportUserNotesAnswers';
import styles from './styles.module.scss';
import { dateFormatter, DateFormStyle } from 'lib/commonFunctions';
import { FormattedMessage } from "react-intl";
import { ArrowLink } from "components/Buttons/Button";
import { useHasPermission } from "hooks/useHasPermission";
import { Permissions } from "actions/roleActions";
import { ReportNotesAnswersDocument } from "components/ReportNotesAnswersDocument";
import { PDFDownloadLink } from '@react-pdf/renderer';


export const ReportHeader = () => {

  const reportUserNotesAnswers: ReportUserNotesAnswers = useReportUserNotesAnswersState();
  const setReportUserNotesAnswers = useSetReportUserNotesAnswersState();
  const hasPermission = useHasPermission();

  const clearReportUserNotesAnswersContext = () => setReportUserNotesAnswers(initialReportUserNotesAnswersState);

  const dateSubmitted = reportUserNotesAnswers.report?.dateSubmitted;
  
  return (
      <div className={styles.reportHeader}>

        <ArrowLink to={routePaths.reports}
          onClick={clearReportUserNotesAnswersContext}
        > 
          <FormattedMessage id="reports" defaultMessage="Reports"/> 
        </ArrowLink>

        <div className={styles.reportTitleContainer}>
          <div className={styles.reportKeyAndDateSubmitted}>
            <h2 className={styles.reportKey}>{reportUserNotesAnswers.report?.reportKey}</h2>
            <span className={styles.dateSubmitted}>
              {dateSubmitted && dateFormatter(dateSubmitted, DateFormStyle.TextDateTime)}
            </span>
          </div>

           {hasPermission(Permissions.ViewReportDetails, reportUserNotesAnswers.report) && (
            <PDFDownloadLink 
              document={ReportNotesAnswersDocument()}
              style={{
                display: 'flex',
                textDecoration: 'underline',
                fontWeight: 800,
                flex: 1,
                padding: 10
              }}
              fileName={reportUserNotesAnswers.report?.reportKey + '.pdf'}
            >
              {({ blob, url, loading, error }: any) => { 
                if (loading) {
                  return (<FormattedMessage id="loading" defaultMessage="Loading"/>)
                } else {
                  return (<FormattedMessage id="buttons.download.report" defaultMessage="Download Report"/>);
                }
              }}
            </PDFDownloadLink>
          )} 
          
        </div>
      </div>
  );
}
