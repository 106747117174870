import React from "react";
import { BaseModal, ModalProps } from "../BaseModal";
import { FormattedMessage } from 'react-intl';
import { LinkButton, ButtonStyle } from "components/Buttons/Button";
import { routePaths } from "App/routing";
import styles from './styles.module.scss';


export const ErrorGeneralModal = ({ isOpen, onClose } : ModalProps) => {
  return (
    <BaseModal title={<FormattedMessage id="errorModal.general.main" defaultMessage="Something went awry - don't worry, we're working on it!"/>}
      isOpen={isOpen} onClose={onClose} 
      showCloseButton={false} 
      centerTitle={true}
      className={styles.errorGeneralModal}>

      <p className={styles.body}>
        <FormattedMessage id="errorModal.tryAgainLaterOrContactSupport" defaultMessage="Please try again later, or contact Vesta support at {vestaSupport}." 
          values={{ vestaSupport: <b><FormattedMessage id="errorModal.vestaSupport" defaultMessage="vesta.com/support"/></b> }}/>
      </p>

      <LinkButton to={{pathname: routePaths.login}} style={ButtonStyle.Underline}>
        <FormattedMessage id="loginPage" defaultMessage="Login Page"/> 
      </LinkButton>
    </BaseModal>
  );
}
