import React, { useEffect } from "react";
import { SectionQuestionAnswers } from "./SectionQuestionAnswers";
import { AdminNotes } from "./AdminNotes";
import Layout from "Layout";
import { ReportHeader } from "Layout/ReportHeader";
import styles from './styles.module.scss';
import { ReportTimeline } from './ReportTimeline';
import { SurvivorInfo } from "./SurvivorInfo";
import { Section } from "./Section";
import { SetReportStatus } from "./SetReportStatus";
import { SurvivorNotes } from "./SurvivorNotes";
import { useReportUserNotesAnswersState } from "context/reportUserNotesAnswers";
import { useHasPermission } from "hooks/useHasPermission";
import { Permissions } from "actions/roleActions";
import { useLocation, useParams } from "react-router-dom";
import { usePopulateReportUserNotesAnswers } from "hooks/usePopulateReportUserNotesAnswers";
import { FormattedMessage } from "react-intl";
import { useSetReportUserNotesAnswersState, initialReportUserNotesAnswersState } from '../../context/reportUserNotesAnswers';

export const Report = () => {

  const reportUserNotesAnswers = useReportUserNotesAnswersState();
  const populateReportUserNotesAnswers = usePopulateReportUserNotesAnswers();
  const hasPermission = useHasPermission();
  const location: any = useLocation();

  const { reportId } = useParams<{ reportId: string }>();

  const setReportUserNotesAnswers = useSetReportUserNotesAnswersState();

  useEffect(() => {
    if (reportId) {
      if ( !reportUserNotesAnswers.report || (reportUserNotesAnswers?.report?.id && Number(reportId) !== reportUserNotesAnswers.report.id) ) {
        const populateContext = async () => {
          try {
            // Clear old context so we don't have old data in new reports
            setReportUserNotesAnswers(initialReportUserNotesAnswersState);

            await populateReportUserNotesAnswers(reportId, location.state.getNotes || true);
          } catch (err) {
            // TODO - https://trello.com/c/nKVkLvrk/622-error-handling-low
          }
        }
        populateContext();
      }
    }
  }, [reportId, reportUserNotesAnswers.report]);
  
  const updateReport = () => {
    if (reportId) {
      const populateContext = async () => {
        try {
          await populateReportUserNotesAnswers(reportId, location.state.getNotes || true);
        } catch (err) {
          // TODO - https://trello.com/c/nKVkLvrk/622-error-handling-low
        }
      }
      populateContext();
    }
  };

  return (
    <Layout>
      {hasPermission(Permissions.ViewReportDetails, reportUserNotesAnswers.report)
        &&
        <div className={styles.reportData}>

          <div className={styles.middle}>
            <ReportHeader/>

            {hasPermission(Permissions.ManageReportStatus, reportUserNotesAnswers.report) && (
              <SetReportStatus />
            )}
            <Section sectionKey="survivorInformation" className={styles.survivorInformation}>
              <SurvivorInfo/>
            </Section>

            <SectionQuestionAnswers/>

            {hasPermission(Permissions.ViewReportNotes, reportUserNotesAnswers.report) && (
              <Section sectionKey="reportUpdates" className={styles.reportUpdates}>
                <SurvivorNotes/>
              </Section>
            )}
          </div>

          <div className={styles.right}>
            <ReportTimeline/>

            {hasPermission(Permissions.AddReportNotes, reportUserNotesAnswers.report) && (
              <AdminNotes updateReport={updateReport}/>
            )}

            {(!hasPermission(Permissions.ViewReportNotes, reportUserNotesAnswers.report) && !hasPermission(Permissions.AddReportNotes, reportUserNotesAnswers.report)) && (
              <p className={styles.noTimelineMessage}>
                <FormattedMessage id="reports.noTimelineMessage" defaultMessage="You do not have permission to view the report timeline" />
              </p>
            )}
          </div>
        </div>
      }
    </Layout>
  );
}
