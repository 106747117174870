import React from "react";
import styles from './styles.module.scss';
import { useFormikContext } from "formik";
import { Checkbox } from "components/Checkbox";
import { UserInfo } from "context/user";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { ErrorType } from "actions/lib/baseActions";
import { Dropdown } from "components/Forms/Dropdown";
import { SearchInput } from "components/SearchInput";
import { useSortedUsers } from "pages/UserRoles/UsersTable/useSortedUsers";
import { AttentionMessage } from "components/AttentionMessage";
import { RoleType } from "actions/roleActions";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { isOrganizationTypeDummyOrg } from "lib/permissions";

interface AssignOrganizationUsersTableProps {
  setAssignUsersError: (error: ErrorType | null) => void;
}

export const AssignOrganizationUsersTable = React.memo(({ 
  setAssignUsersError 
}: AssignOrganizationUsersTableProps) => {

  const { editOrganizationId } = useParams<{ editOrganizationId: string }>();

  const { values, setFieldValue, setValues, setSubmitting } = useFormikContext<any>();

  const setUsers = (users: (UserInfo & RoleType)[] | undefined) => {
    setFieldValue("users", users);

    const assignedUserIds = _.groupBy(users, "organizationId")?.[editOrganizationId]?.map((u: any) => u.id) || [];
    setFieldValue(
      "assignedUserIds", 
      Array.from(new Set(assignedUserIds))
    );
  }

  const {
    userSortDropdownOptions,
    onSortOptionChange,
    populateUsers,
    sortedUsers
  } = useSortedUsers(values.users, setUsers);

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const userId = parseInt(e.target.id);
    let assignedOrganizationUsersIds: number[] = values.assignedUserIds || [];
    if (e.target.checked) {
      assignedOrganizationUsersIds.push(userId);
    } else {
      assignedOrganizationUsersIds = assignedOrganizationUsersIds.filter(id => (id !== userId));
    }

    setValues({ ...values, assignedUserIds: Array.from(new Set(assignedOrganizationUsersIds)) });
    setAssignUsersError(null);
    setSubmitting(false);
  }

  const assignedOrganizationUsersCount = values?.assignedUserIds?.length;
  const pluralOrSingularUserId = (assignedOrganizationUsersCount > 1) ? "users" : "user";

  return (
    <div className={styles.assignOrganizationUsersTableContainer}>
      <div className={styles.assignOrganizationUsersTableHeaders}>
        <p className={classNames((assignedOrganizationUsersCount <= 0) && styles.assignedNone, styles.smallText)}>
          <FormattedMessage id="assignedTo" defaultMessage="Assigned to"/> {assignedOrganizationUsersCount} <FormattedMessage id={pluralOrSingularUserId} defaultMessage={pluralOrSingularUserId}/>
        </p>
        <div className={styles.organizationsSortSearch}>
          <Dropdown dropdownId="assignOrganizationUserSortOptions" key="assignOrganizationUserSortOptions"
            dropdownOptions={userSortDropdownOptions}
            onSelect={onSortOptionChange}/>
          <SearchInput className={styles.searchInput} onChange={populateUsers}/>
        </div> 
      </div>

      {!sortedUsers || (sortedUsers.length === 0) && (
        <div className={styles.noUsersMessage}>
          <AttentionMessage message="message.noUsers"></AttentionMessage>
        </div>
      )}

      <div className={styles.assignOrganizationUsersTable}>
        {sortedUsers?.map((user: UserInfo & RoleType) => {
          const userFullName= user.fullName;
          const userId = user.id;
          const isAssigned = values.assignedUserIds?.includes(userId);
          const roleName = user.roleName;

          return (
            <div className={styles.userRow} key={userId}>
              <div className={styles.userNameAndRole}>
                <h4>{userFullName}</h4>
                {roleName && (
                  <p className={classNames(styles.roleName, styles.smallText)}>
                    <FormattedMessage id={roleName} defaultMessage={roleName}/>
                  </p>
                )}
              </div>
              <Checkbox 
                name={userFullName}
                id={userId}
                value={isAssigned}
                onChange={onCheckboxChange}
                disabled={isOrganizationTypeDummyOrg(values.type) && isOrganizationTypeDummyOrg(user.organizationTypeId)}
              />
            </div>
          )
        })}
      </div>
    </div>
  );
});
