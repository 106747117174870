import React from "react";
import styles from './styles.module.scss';
import Layout from "Layout";
import { LocationStatsGroup } from "./LocationStatsGroup";


export const LocationStatistics = () => {
  return (
    <Layout>
      <div className={styles.statistics}>
        <LocationStatsGroup/>
      </div>
    </Layout>
  );
}