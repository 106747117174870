import React , { useState, useEffect, useCallback } from 'react'
import { createDepartment, updateDepartment, getDepartmentById, Department } from 'actions/departmentActions';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import Layout from 'Layout';
import { ConfirmationModal } from 'components/Modals/ConfirmationModal';
import { AddOrEditDepartmentUsersForm } from './AddOrEditDepartmentUsersForm';
import { routePaths } from 'App/routing';
import { ErrorType } from 'actions/lib/baseActions';
import { UserInfo } from 'context/user';


export interface AddOrEditDepartmentUsersFormFields {
  name?: string;
  users?: UserInfo[];
  assignedUserIds: number[];
};

export const AddOrEditDepartmentUsersErrors = {
  DepartmentAlreadyExistsError: { id: 'departmentAlreadyExists', intlId: 'addOrEditDepartmentUsers.error.departmentAlreadyExists', defaultMessage: 'A department by that name already exists' },
  GeneralAddError: { id: 'add', intlId: 'addOrEditDepartmentUsers.error.add', defaultMessage: 'There was an error creating the department. Please try again later. If you continue to have problems, please contact support.' },
  GeneralEditError: { id: 'edit', intlId: 'addOrEditDepartmentUsers.error.edit', defaultMessage: 'There was an error editing the department. Please try again later. If you continue to have problems, please contact support.' }
};

export const AddOrEditDepartmentUsers = () => {

  const location = useLocation();
  const currentPath = location.pathname;
  const currentState: any = location?.state;
  const isAddDepartmentPage = (currentPath === routePaths.addDepartmentUsers);
  const isEditDepartmentPage = (currentPath === routePaths.editDepartmentUsers);
  const departmentFromState = currentState?.department;
  const { editDepartmentId } = useParams<{ editDepartmentId: string }>();

  const [departmentToEdit, setDepartmentToEdit] = useState<Department>(departmentFromState);

  const [addOrEditDepartmentUsersError, setAddOrEditDepartmentUsersError] = useState<ErrorType | null>(null);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const getAndSetDepartmentToEdit = useCallback(async (editDepartmentId: string | number) => {
    try {
      const departmentResult = await getDepartmentById(editDepartmentId);
      const department = departmentResult?.data?.[0];
      if (department) {
        setDepartmentToEdit(department);
      }
    } catch(err) {
      setAddOrEditDepartmentUsersError(AddOrEditDepartmentUsersErrors.GeneralEditError);
    }
  }, []);
  
  useEffect(() => {
    if (isEditDepartmentPage && editDepartmentId) {
      if (departmentFromState && (editDepartmentId === departmentFromState.id)) {
        setDepartmentToEdit(departmentFromState);
      } else {
        getAndSetDepartmentToEdit(editDepartmentId);
      }
    }
  }, [isEditDepartmentPage, editDepartmentId, departmentFromState]);

  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  };

  //Formik prop values - initialValues, validate, onSubmit
  const initialValues = { 
    name: (isAddDepartmentPage || !departmentToEdit)  ? "" : departmentToEdit.name,
    assignedUserIds: departmentToEdit?.assignedUsers?.map((u: UserInfo) => Number(u.id)) || []
  };

  const validate = (values: AddOrEditDepartmentUsersFormFields) => { 
    const errors: FormikErrors<AddOrEditDepartmentUsersFormFields> = {}; 

    const name = values.name || departmentToEdit?.name;
    if (!name) {
      errors.name= 'Required';
    }

    return errors;
  };

   const onSubmit = async (values: AddOrEditDepartmentUsersFormFields, { setSubmitting }: FormikHelpers<AddOrEditDepartmentUsersFormFields>) => {
      const name = values.name || "";
      const { assignedUserIds } = values;

      setSubmitting(true);
      try {
        if (isAddDepartmentPage) {
          await createDepartment({ name, assignedUserIds });
        } else {
          await updateDepartment({ id: editDepartmentId, name, assignedUserIds });
        }
        setConfirmationModalIsOpen(true);
        setSubmitting(false);
        return;
      } catch(err) {
        setAddOrEditDepartmentUsersError(err.body);
        setSubmitting(false);
        throw err;
      };
    }
  
  return (
    <Layout>
      <div className={styles.addOrEditDepartmentUsersPage}>

        <h3>
          {isAddDepartmentPage 
            ? <FormattedMessage id="addNewDepartment" defaultMessage="Add New Department"/> 
            : <FormattedMessage id="editDepartmentUsers" defaultMessage="Edit Department"/>}
        </h3>

        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
        {({ resetForm }) => (
          <div className={styles.addOrEditDepartmentUsersPageContent}>
            <AddOrEditDepartmentUsersForm
              addOrEditDepartmentUsersError={addOrEditDepartmentUsersError}
              setAddOrEditDepartmentUsersError={setAddOrEditDepartmentUsersError}
            />

            <ConfirmationModal 
              isOpen={confirmationModalIsOpen}
              onClose={() => { closeConfirmationModal(); resetForm(); }}
              onCloseRoute={routePaths.manageDepartments}
            >
              <p className={styles.departmentSuccessfullyAddedOrEdited}>
                {isAddDepartmentPage 
                  ? (<FormattedMessage id="modal.confirm.departmentAdded" defaultMessage="Department has been successfully created"/>) 
                  : (<FormattedMessage id="modal.confirm.departmentEdited" defaultMessage="Department has been successfully edited"/>)}
              </p>
            </ConfirmationModal>
          </div>
        )}
        </Formik>

      </div> 
    </Layout>
  );
};
