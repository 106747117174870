import { ReportUserNotesAnswers, useReportUserNotesAnswersState } from "context/reportUserNotesAnswers";
import { NoteType } from "actions/noteActions";
import styles from './styles.module.scss';
import { dateFormatter } from 'lib/commonFunctions';


export const SurvivorNotes = () => {
  const reportUserNotesAnswers: ReportUserNotesAnswers = useReportUserNotesAnswersState();

  return (
    <div className={styles.survivorNotes}>
      {reportUserNotesAnswers.notes?.map((note: NoteType) => {
        const reportUserId = reportUserNotesAnswers.report?.userId;
        return (note.userId === reportUserId) && (
          <div className={styles.reportUpdate} key={note.id}>
            <h2 className={styles.dateCreated}>{note.createdAt && dateFormatter(note.createdAt)}</h2>
            <p>{note.note}</p>
          </div>
        );
      })}
    </div>
  );
}
