import React from "react";
import styles from './styles.module.scss';
import Layout from "Layout";
import { FormattedMessage } from "react-intl";
import { useUserState } from "context";
import { RoleDescription } from "pages/AddOrEditUser/RoleDescription";
import { UserInfo } from "context/user";
import { RoleType } from "actions/roleActions";


export const UserInformation = () => {
  const userInfo: UserInfo & RoleType = useUserState();
  
  return (
    <Layout>
      <div className={styles.userInformation}>
        <h3 className={styles.title}>
          <FormattedMessage id="userInfo.title" defaultMessage="User Information"/>
        </h3>
        <div className={styles.userInformationRoleContent}>
          <div className={styles.roleDesc}>
            <RoleDescription role={userInfo}/>
          </div>
          <div className={styles.helpInfo}>
            <h3>
              <FormattedMessage id="userInfo.contactVesta" defaultMessage="Contact VESTA Support"></FormattedMessage>
            </h3>
            <div className={styles.contactDetails}>
              <h5 className={styles.contactHeader}>
                <FormattedMessage id="userInfo.email" defaultMessage="Email"></FormattedMessage>
              </h5>
              <p className={styles.contactValue}>
                <FormattedMessage id="userInfo.emailValue" defaultMessage="info@vestasit.com"></FormattedMessage>
              </p>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
}
