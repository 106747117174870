import React from "react";
import styles from './styles.module.scss';
import { StatsGroup } from "./StatsGroup";
import Layout from "Layout";


export const Statistics = () => {
  return (
    <Layout>
      <div className={styles.statistics}>
        <StatsGroup/>
      </div>
    </Layout>
  );
}
