import React, { FC, ReactNode, useState, ChangeEvent, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

interface OptionsBubbleProps {
  label?: ReactNode;
  className?: string;
  value?: any;
  options: Array<any>;
  groupName: string;
  onChange?: (value: any, groupName?: string) => void;
};

export const OptionsBubble: FC<OptionsBubbleProps> = ({ label, groupName, options, value, className, onChange }) => {
const [selectedOptionValue, setSelectedOptionValue] = useState<string | undefined>(value);

useEffect(() => {
  setSelectedOptionValue(value);
}, [value]);

const onOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
  setSelectedOptionValue(e.target.value);
    
  if (onChange) {
    onChange(e.target.value);
  }
}

return (
  <div className={classNames(styles.optionsBubble, className)}>
    <span className={styles.optionsBubbleLabel}>{label}</span>
    <div className={classNames(styles.options)}>
      {options.map((option) => {
        const optionKey = `${groupName}.${option}`;
        return (
          <div className={styles.option} key={optionKey}>
            <input className={styles.radio}  
              id={optionKey} 
              name={optionKey}
              type="radio"
              checked={selectedOptionValue === option} 
              value={option} 
              onChange={onOptionChange}/>
            <label htmlFor={optionKey}>
              <FormattedMessage id={optionKey} defaultMessage={option}/>
            </label>
          </div>
        ); 
      })}
    </div>
  </div>
);
};
