import React, { ReactNode } from "react";
import styles from "./styles.module.scss";
import Modal from 'react-modal';
import {CloseButtonIcon} from "../../Icons";
import classNames from "classnames";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  message?: string;
}

export interface BaseModalProps extends ModalProps {
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
  showCloseButton?: boolean;
  centerTitle?: boolean;
}

export const BaseModal = ({ isOpen, onClose, title, children, className, showCloseButton = true, centerTitle = false } : BaseModalProps) => {
  return (
    <Modal isOpen={isOpen}
      className={classNames(className, styles.modal)}
      onRequestClose={onClose} 
      ariaHideApp={false}>

      {showCloseButton && <div className={styles.closeIcon}><CloseButtonIcon onClick={onClose}/></div>}
      { title && <h1 className={centerTitle ? styles.centerTitle : styles.title}>{title}</h1>}
      {children}

    </Modal>
  );
}
