import * as base from "./lib/baseActions";

export const adminPanelNoteType = "adminPanelNote";

export interface NoteType {
  id?: string | number;
  userId: string | number;
  author?: string;
  note: string;
  type?: typeof adminPanelNoteType;
  createdAt?: Date;
}

export enum AuthorType {
  Survivor = "Survivor",
  System = "System",
  AdminPanelUser = "AdminPanelUser"
}

const getReportNotes = async (reportId: string | number) => {

  const getReportNotesResult = await base.get(`reports/${reportId}/notes`);
  
  return getReportNotesResult;
};

const createReportNote = async (reportId: string | number, data: { notes: NoteType[] }) => {

  const createNoteResult = await base.post(
    `reports/${reportId}/notes`,
    data
  );

  return createNoteResult;
};

export {
  getReportNotes,
  createReportNote
};
