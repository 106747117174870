import { FC, useState, useEffect } from 'react'
import { verify2FACode, userIsAuthorized } from '../../actions/userActions';
import { useAppState, useMergeAppState, useMergeUserState } from '../../context';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { routePaths } from '../../App/routing';
import { FormattedMessage } from 'react-intl';
import { VestaLogo } from '../../components/Icons';
import { TwoFAForm } from './TwoFA';
import { ErrorType } from '../../actions/lib/baseActions';
import classNames from 'classnames';
import { LinkButton, ButtonStyle } from '../../components/Buttons';
import { useUserState } from "context/user";
import ReactGA from 'react-ga';
import { isOrganizationTypeCrisisCenter, isOrganizationTypeLawEnforcement } from 'lib/permissions';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "");

export interface TwoFAFormFields {
  twoFaCode?: string,
};

export const TwoFAFormErrors = {
  Invalid2FACode: { id: 'invalid2FACode', intlId: 'login.error.invalid2FACode', defaultMessage: 'Sorry, but the code you entered in was wrong. Please try again, or contact us at support.' },
  General: { id: 'general', intlId: 'twoFAForm.error.general', defaultMessage: 'There was an error verrifying you 2FA code. Please try again or login again to get a new code. If you continue to have problems, please contact support.' },
};

const validationErrors = (values: TwoFAFormFields) => {
  const errors: TwoFAFormFields = {}; 

  if (!values.twoFaCode) {
    errors.twoFaCode = 'Required';
  } else if (values.twoFaCode.length !== 6) {
    errors.twoFaCode = 'Not 6 characters';
  }

  return errors;
};

export const TwoFA : FC = () => {
  const appState = useAppState();
  const userInfo = useUserState();
  const mergeAppState = useMergeAppState();
  const mergeUserData = useMergeUserState();

  const [twoFAError, setTwoFAError] = useState<ErrorType | null>(null);

  const history: any = useHistory();

  useEffect(() => {
    if (appState.Authenticated) {
      history.push(routePaths.home);
    }
  }, []);

  const initialValues = { 
    twoFACode: ''
  };

  const onSubmit = async (values: TwoFAFormFields, { setSubmitting }: FormikHelpers<any>) => {

    const { twoFaCode }: TwoFAFormFields = values;

    mergeAppState({ Authenticating: true });
    setSubmitting(true);

    try {
      const results = await verify2FACode({ twoFaCode, email: userInfo.email });
      if (results?.data) {
        const userData = results.data;
        mergeUserData(userData);

        if (isOrganizationTypeCrisisCenter(userData.organizationTypeId)) {
          ReactGA.event({
            category: 'Org Type Login',
            action: 'sac-login',
            label: 'sac-login'
          });
        } else if (isOrganizationTypeLawEnforcement(userData.organizationTypeId)) {
          ReactGA.event({
            category: 'Org Type Login',
            action: 'kp-login',
            label: 'kp-login'
          });
        }
      }
      mergeAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
      setSubmitting(false);
      history.push(routePaths.home);
      return;
    } catch (err) {
      setTwoFAError(err.body);
      mergeAppState({ Authenticated: false, Authenticating: false });
      setSubmitting(false);
      throw err;
    } 
  }

  return (
    <div className={styles.twoFAPage}>
      <div className={styles.loginPageContent}>
          <div className={styles.left}>
            <div className={styles.vestaLogo}><VestaLogo/></div>

            <h3 className={classNames(styles.header, styles.subheader)}>
              <FormattedMessage id="twoFA.enterYourTwoFACode" defaultMessage="Please enter the 2FA code that was emailed to you"/>
            </h3>

            <Formik
              initialValues={initialValues}
              validate={validationErrors}
              onSubmit={onSubmit}
            >
              {() => (
                <TwoFAForm twoFAError={twoFAError}
                  setTwoFAError={setTwoFAError}/>
              )}
            </Formik>

            <LinkButton className={styles.loginButton} to={{pathname: routePaths.login}} style={ButtonStyle.Underline}>
              <FormattedMessage id="loginPage" defaultMessage="Return to Login"/> 
            </LinkButton>
          </div>
        </div>
    </div>
  );
};
