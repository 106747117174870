import React, { useState } from "react";
import styles from './styles.module.scss';
import { SensoryIcon } from "components/Icons";
import classNames from "classnames";
import { useTranslator }  from "hooks/useTranslator";
import { QuestionType, QuestionTypes } from "actions/questionActions";
import { AttachmentDisplay } from "../AttachmentDisplay"
import { DownloadAllAttachments } from "./DownloadAllAttachments"
import { Answer } from "context/reportUserNotesAnswers";
import { Button, ButtonStyle } from "components/Buttons";
import { FormattedMessage } from "react-intl";

interface QuestionAnswerProps {
  question: QuestionType;
  answer: Answer | undefined;
}

export const QuestionAnswer = ( { question, answer }: QuestionAnswerProps ) => {
  const translator = useTranslator();
  const [showAttachment, setShowAttachment] = useState<boolean>(false);

  const formattedAnswer = [];
  formattedAnswer.push((
    (question.type !== QuestionTypes.Attachments) ? (
      <div key={question.key} className={styles.answer}>
        {answer?.value.answer 
          ? answer?.valueFormatted
          : (<FormattedMessage id="report.undefined" defaultMessage="n/a"/>)}
      </div>
    ) : (
      <div key={question.key} className={styles.attachmentWrapper}>
        <div className={styles.attachmentWarning}>
          <DownloadAllAttachments answers={answer?.value.answer}   
            className={styles.viewButton}  />
          <p><FormattedMessage id="message.attachmentsWarning" defaultMessage="Attachments may have sensitive content. Please make sure you are in an area where it is safe to view these attachments."/></p>
          <Button 
            style={ButtonStyle.Primary} 
            className={styles.viewButton}
            onClick={()=> setShowAttachment(!showAttachment)}
          >
            {showAttachment 
              ? (<FormattedMessage id="buttons.hideAttachments" defaultMessage="Hide attachments"/>)
              : (<FormattedMessage id="buttons.viewAttachments" defaultMessage="View attachments"/>)}
          </Button>
        </div>
        <AttachmentDisplay 
          key={`${answer?.reportId}-attachments-display`}
          showAttachment={showAttachment}
          reportId={answer?.reportId} 
          answers={answer?.value.answer} 
        />
      </div>
    )
  ));

  return (
    <div className={classNames(styles.questionAnswer, question.imageKey && styles.sensoryIconCard)}>
      <h5>{translator(question.key)}</h5>
      <div className={styles.questionAnswerContent}>
        {question.imageKey && (
          <SensoryIcon sense={question.imageKey} className={styles.sensoryIconSize}/>
        )}
        {formattedAnswer}
      </div>
    </div>
  );
}
