import { Button, ButtonStyle, LinkButton } from 'components/Buttons';
import { useLogoutUser } from 'hooks/useLogoutUser';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { routePaths } from "App/routing";
import styles from './styles.module.scss';
import classNames from 'classnames';
import { DoubleArrowRightWhite } from 'components/Icons';
import { isOrganizationTypeVesta, isPermissionScopeAllOrOrganization } from 'lib/permissions';
import { useUserState } from 'context/user';
import { releaseVersion } from 'actions/releaseActions';
import React , { useEffect, useState } from 'react';


export const LeftNav = () => {

  const currentPath = useLocation().pathname;
  const logoutUser = useLogoutUser();
  const userInfo = useUserState();
  const [version, setVersion] = useState('');

  const onLogout = () => {
    logoutUser();
  };

  useEffect(()=>{
    const getVersion = async () =>{
      setVersion(await releaseVersion())
    }
    getVersion()
  },[])
  
  const { dashboard, reports, userRoles, manageDepartments, manageOrganizations, statistics, feedback, locationStatistics } = routePaths;
  const navPaths: Partial<typeof routePaths> = { 
    dashboard, 
    reports,
  };

  if (isPermissionScopeAllOrOrganization(userInfo?.manageUsers)) {
    navPaths.userRoles = userRoles;
  }
  if (isPermissionScopeAllOrOrganization(userInfo?.manageDepartments)) {
    navPaths.manageDepartments = manageDepartments;
  }
  if (isPermissionScopeAllOrOrganization(userInfo?.manageOrganizations)) {
    navPaths.manageOrganizations = manageOrganizations;
  }
  if (isPermissionScopeAllOrOrganization(userInfo?.viewReportStatistics)) {
    navPaths.statistics = statistics;
  }
  if (isPermissionScopeAllOrOrganization(userInfo?.manageUsers) || isPermissionScopeAllOrOrganization(userInfo?.viewReportStatistics)) {
    navPaths.locationStatistics = locationStatistics
  }

  if (isOrganizationTypeVesta(userInfo.organizationTypeId)) {
    navPaths.feedback = feedback;
  }

  return (
    <div className={styles.leftNav}>
      <div className={styles.userInfoSection}>
        <div className={styles.whiteVestaLogo}></div>
        <div className={styles.userLink}>
          <LinkButton 
            to={{ pathname: routePaths.userInformation}}
            style={ButtonStyle.None}
            className={classNames(styles.userButton, (currentPath === routePaths.userInformation) ? styles.active : undefined)}
          >
            <h3>{userInfo.fullName}</h3>
          </LinkButton>
          <div className={styles.doubleRightArrow}>
            <DoubleArrowRightWhite className={styles.arrowIcon}/>
          </div>
        </div>
        
        {userInfo.organizationName && (
          <h5>{userInfo.organizationName}</h5>
        )}
        
        <p className={styles.smallText}>{userInfo.name}</p>

        <Button
          onClick={onLogout}
          style={ButtonStyle.None}
          className={styles.logoutButton}
        >
          <FormattedMessage id='buttons.logout' defaultMessage='Logout' />
        </Button>
      </div>

      <div className={styles.navPaths}>
        {Object.entries(navPaths).map((navPath) => {
          const navPathKey = navPath[0];
          const navPathValue = navPath[1];

          return (
            <LinkButton className={classNames(styles.navItem, (currentPath === navPathValue) ? styles.active : undefined)}
              to={{ pathname: navPathValue }} 
              style={ButtonStyle.None}
              key={navPathKey}>

              <div className={classNames(styles[navPathKey], styles.imageStyling)}/>
              <h5 className={styles.subheader}>
                <FormattedMessage id={`adminPanel.${navPathKey}`} defaultMessage={navPathKey}/>
              </h5>
              <div className={styles.doubleRightArrow}>
                <DoubleArrowRightWhite className={styles.arrowIcon} />
              </div>
            </LinkButton>
          );
        })}
      </div>
      <div
          className={styles.version}>
        <FormattedMessage
          id={`version.number`}
          defaultMessage={'Version Number ' + version}
        />
      </div>
    </div>
  );
};

export default LeftNav;
