import { PermissionScope } from "actions/roleActions";
import { ReportData } from "actions/reportActions";
import { OrganizationTypeIds } from "actions/organizationActions";

export const isPermissionScopeAll = (permissionScope: string | undefined) => {
  return (permissionScope === PermissionScope.All);
};

export const isPermissionScopeOrganization = (permissionScope: string | undefined) => {
  return (permissionScope === PermissionScope.Organization);
};

export const isPermissionScopeAssigned = (permissionScope: string | undefined) => {
  return (permissionScope === PermissionScope.Assigned);
};

export const isPermissionScopeNone = (permissionScope: string | undefined) => {
  return (permissionScope === PermissionScope.None);
};

export const isPermissionScopeAllOrOrganization = (permissionScope: string | undefined) => {
  return (isPermissionScopeAll(permissionScope) || isPermissionScopeOrganization(permissionScope));
};

export const isAssignedUserForReport = (report: ReportData | undefined, userId: string | number | undefined) => {
  return userId && report?.assignedUsers?.find(u => (u.id === userId));
};

export const isAssignedOrganizationForReport = (report: ReportData | undefined, organizationId: string | number | undefined) => {
  return organizationId && report?.assignedOrganizations?.some(o => (o.id === organizationId));
};

export const isAssignedDepartmentForReport = (report: ReportData | undefined, departmentIds: Array<string | number> | undefined) => {
  return departmentIds && report?.assignedDepartments?.some(d => (d.id && departmentIds.includes(d.id)));
};

export const hasOrganizationLevelPermission = (permissionScope: PermissionScope | undefined, report: ReportData | undefined, userOrganizationId: string | number | undefined) => {
  return (isPermissionScopeOrganization(permissionScope) && isAssignedOrganizationForReport(report, userOrganizationId));
}

export const hasDepartmentLevelPermission = (permissionScope: PermissionScope | undefined, report: ReportData | undefined, userDepartmentIds: Array<string | number> | undefined) => {
  return (isPermissionScopeAssigned(permissionScope) && isAssignedDepartmentForReport(report, userDepartmentIds));
}

export const hasUserLevelPermission = (permissionScope: PermissionScope | undefined, report: ReportData | undefined, userId: string | number | undefined) => {
  return (isPermissionScopeAssigned(permissionScope) && isAssignedUserForReport(report, userId));
}

export const isOrganizationTypeVesta = (typeId?: OrganizationTypeIds) => (typeId === OrganizationTypeIds.Vesta);

export const isOrganizationTypeDummyOrg = (typeId?: OrganizationTypeIds) => (typeId === OrganizationTypeIds.DummyOrganization);

export const isOrganizationTypeLawEnforcement = (typeId?: OrganizationTypeIds) => (typeId === OrganizationTypeIds.LawEnforcement);

export const isOrganizationTypeCrisisCenter = (typeId?: OrganizationTypeIds) => (typeId === OrganizationTypeIds.CrisisCenter || typeId === OrganizationTypeIds.LocationSupport);
export const isOrganizationTypeCampus = (typeId?: OrganizationTypeIds) => (typeId === OrganizationTypeIds.CampusSupport);
