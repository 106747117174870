import { AddOrEditOrganizationUsersErrors } from "pages/AddOrEditOrganizationUsers";
import * as base from "./lib/baseActions";
import { DataWrapper } from "./lib/baseActions";

// TODO This list should pull from the DB
export enum OrganizationType {
  Vesta = "vesta",
  LawEnforcement = "lawEnforcement",
  CrisisCenter = "crisisCenter",
  LocationSupport = "locationSupport",
  CampusSupport = "campusSupport",
  None = "none",
  DummyOrganization = "dummyOrganization"
}

export enum OrganizationTypeIds {
  Vesta = 1,
  LawEnforcement = 2,
  CrisisCenter = 3,
  LocationSupport = 4,
  CampusSupport = 5,
  None = 6,
  DummyOrganization = 7
}

export interface Organization {
  id?: string | number;
  organizationTypeName?: OrganizationType;
  organizationTypeId?: OrganizationTypeIds;
  address?: string;
  phoneNumber?: string;
  email?: string;
  url?: string;
  name?: string;
  latitude?: number;
  longitude?: number;
  assignedUserIds?: number[];
  organizationName?: string;
  organizationLocation?: string;
  intakeFormLink?: string;
  crisisLink?: string;
  crisisPhone?: string;
  reportStatusId?: number | string;
  reportStatusName?: string;
  reportStatusDisplayName?: string;
  isPartnered?: boolean;
}

export interface addOrEditOrganizationData {
  name?: string;
  organizationTypeId?: OrganizationTypeIds;
  address?: string;
  phoneNumber?: string;
  email?: string;
  url?: string;
  assignedUserIds?: number[];
  organizationName?: string;
  organizationLocation?: string;
  intakeFormLink?: string;
  crisisLink?: string;
  crisisPhone?: string;
  isPartnered?: boolean;
};

const getOrganizationById = async (organizationId: string | number): Promise<DataWrapper<Organization[]> | undefined> => {

  try {
    const result = await base.get(`organizations/${organizationId}`);
    return result;
  } catch(err) {
    // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
    console.log(err);
  }
};

const getOrganizations = async (searchQuery?: string): Promise<DataWrapper<Organization[]>> => {

  let getOrganizationsUrl = 'organizations';
  if (searchQuery) {
    getOrganizationsUrl += `?searchQuery=${searchQuery}`;
  }

  const getOrganizationsResult = await base.get(getOrganizationsUrl);
  
  return getOrganizationsResult;
};

const createOrganization = async (data: addOrEditOrganizationData) => {
  const { name, organizationTypeId, address, phoneNumber, email, url, assignedUserIds,
    organizationName,
    organizationLocation,
    intakeFormLink,
    crisisLink,
    crisisPhone,
    isPartnered
  } = data;

  try {
    const createOrganizationResult = await base.post(
      "organizations", 
      { name, organizationTypeId, address, phoneNumber, email, url, assignedUserIds,
        organizationName,
        organizationLocation,
        intakeFormLink,
        crisisLink,
        crisisPhone, isPartnered
      }
    );
    return createOrganizationResult;
  } catch (err) {
    if (err?.original?.response?.status === 400) {
      throw base.customizeError(err, AddOrEditOrganizationUsersErrors.OrganizationAlreadyExistsError);
    } else {
      throw base.customizeError(err, AddOrEditOrganizationUsersErrors.GeneralAddError);
    }
  }
};

const updateOrganization = async (organizationId: string | number, data: addOrEditOrganizationData) => {
  const { name, organizationTypeId, address, phoneNumber, email, url, 
    assignedUserIds, organizationName, organizationLocation,
    intakeFormLink,
    crisisLink,
    crisisPhone,
    isPartnered
  } = data;

  try {
    const updateOrganizationResult = await base.put(
      `organizations/${organizationId}`, 
      { 
        name, organizationTypeId, address, phoneNumber,
        email, url, assignedUserIds, organizationName,
        organizationLocation,
        intakeFormLink,
        crisisLink,
        crisisPhone,
        isPartnered
      }
    );

    return updateOrganizationResult;
  } catch (err) {
    throw base.customizeError(err, AddOrEditOrganizationUsersErrors.GeneralEditError);
  }
};

export {
  getOrganizations,
  getOrganizationById,
  createOrganization,
  updateOrganization
};
