
import React from "react";
import styles from "./styles.module.scss";

export interface DisplayImageProps {
  imageUrl: string;
}

export const DisplayImage = ({ imageUrl } : DisplayImageProps) => {

  return (
    <img className={styles.imageContainer} src={imageUrl} />
  );
}
