import React from "react";
import { QuestionType, QuestionTypes, SectionType } from "actions/questionActions";
import { useReportUserNotesAnswersState, Answer, getAnswerKey, ReportSections } from "context/reportUserNotesAnswers";
import styles from './styles.module.scss';
import { Section } from "../Section";
import questions from 'data/questions.json';
import { QuestionAnswer } from "./QuestionAnswer";

export const SectionQuestionAnswers = () => {
  const reportUserNotesAnswers = useReportUserNotesAnswersState();
  return (
    <div className={styles.sectionQuestionAnswers}>

    {questions.sections.map((section: SectionType) => {
      if (section.key !== ReportSections.Break) {
        return (
          <Section sectionKey={section.key} key={section.key}>
            {section.questions.map((question: QuestionType) => {
              const answerKey: string = getAnswerKey(section.key, question.key);
              const answerObject: Answer | undefined = reportUserNotesAnswers.answers?.get(answerKey);
              
              if (question.type !== QuestionTypes.OffenderFace) {
                return (
                  <QuestionAnswer question={question} 
                    answer={answerObject}
                    key={question.key}/>
                );
              }
            })}
          </Section>
        );
      }  
    })}
    
    </div>
  );
}
