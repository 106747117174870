import React from "react";
import Layout from "Layout";
import { BasicReportInfo } from '../../Report/BasicReportInfo';
import styles from './styles.module.scss';
import { FormattedMessage } from "react-intl";
import { ReportHeader } from "Layout/ReportHeader";
import { ConfirmationModal } from "components/Modals/ConfirmationModal";
import { routePaths } from "App/routing";


interface AssignPageLayoutProps {
  children: React.ReactNode;
  confirmationModalIsOpen: boolean;
  setConfirmationModalIsOpen: (value: boolean) => void;
}

export const AssignPageLayout = React.memo(({children, confirmationModalIsOpen, setConfirmationModalIsOpen}: AssignPageLayoutProps) => {

  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  };


  return (
    <Layout>
      <div className={styles.assignPageLayout}>

        <ReportHeader/>
        
        <div className={styles.reportInfo}>
          <h3 className={styles.reportInfoHeader}>
            <FormattedMessage id="report.reportInformation" defaultMessage="Report Information"/>
          </h3>
          <BasicReportInfo/>
        </div>

        {children}

      </div>
      <ConfirmationModal 
        isOpen={confirmationModalIsOpen} 
        onClose={closeConfirmationModal}
        onCloseRoute={routePaths.reports}/>
    </Layout>
  );
});
