import { logoutUser } from "actions/userActions";
import { useMergeAppState, useClearState } from "context";

export const useLogoutUser = () => {
  const mergeAppState = useMergeAppState();
  const clearState = useClearState();

  return () => {
    logoutUser();
    mergeAppState({ Authenticated: false, Authenticating: false });
    clearState();
  };
};
