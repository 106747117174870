import classNames from "classnames";
import React from "react";
import { useEffect, useState } from "react";
import { DisplayImage } from "../../../../components/MediaDisplay/ImageDisplay";
import { DisplayVideo } from "../../../../components/MediaDisplay/VideoDisplay";
import { DisplayPDF } from "../../../../components/MediaDisplay/PDFDisplay";
import { DisplaySound } from "../../../../components/MediaDisplay/SoundDisplay";
import styles from './styles.module.scss';
import { awsData } from '../../../../lib/config';
import { UploadedAttachmentType } from "context/reportUserNotesAnswers";

export interface AttachmentDisplayProps {
  reportId?: number;
  answers: any;
  showAttachment?: boolean;
}

export const AttachmentDisplay = ({reportId, answers, showAttachment = false} : AttachmentDisplayProps) => {
  const [imageFiles, setImageFiles] = useState<[]>([]);
  const [videoFiles, setVideoFiles] = useState<[]>([]);
  const [pdfFiles, setPdfFiles] = useState<[]>([]);
  const [soundFiles, setSoundFiles] = useState<[]>([]);

  useEffect(() => {
    updateAttachments();
  }, [answers]);

  const updateAttachments = () => {
    if (!answers || answers.length === 0 || !reportId){
      return null;
    }
  
    const imageFiles = answers.map(((x: UploadedAttachmentType) => {
    if(x.filename.includes(".jpg") || x.filename.includes(".jpeg") || x.filename.includes(".png") || x.filename.includes(".heic"))
      return (
        {
          uniqueKey: x.uniqueKey,
          filename: x.filename
        }
      )
    }))
    .filter(((x : UploadedAttachmentType) => {
      return x != null;
    }));
  
    const videoFiles = answers.map(((x: UploadedAttachmentType) => {
      if(x.filename.includes(".mp4") || x.filename.includes(".wav"))
        return (
          {
            uniqueKey: x.uniqueKey,
            filename: x.filename
          }
        )
      }))
      .filter(((x : UploadedAttachmentType) => {
        return x != null;
      }));
  
      const pdfFiles = answers.map(((x: UploadedAttachmentType) => {
        if(x.filename.includes(".pdf"))
          return (
            {
              uniqueKey: x.uniqueKey,
              filename: x.filename
            }
          )
        }))
        .filter(((x: UploadedAttachmentType) => {
          return x != null;
        }));
  
      const soundFiles = answers.map(((x: UploadedAttachmentType) => {
        if(x.filename.includes(".mp3"))
          return (
            {
              uniqueKey: x.uniqueKey,
              filename: x.filename
            }
          )
        }))
        .filter(((x : UploadedAttachmentType) => {
          return x != null;
        }));
        setImageFiles(imageFiles);
        setPdfFiles(pdfFiles);
        setSoundFiles(soundFiles);
        setVideoFiles(videoFiles);
  };

  return(
    <div className={classNames(styles.attachmentDisplayContainer, showAttachment && styles.active)}>
        {imageFiles.map((file: UploadedAttachmentType) => {
          return(<DisplayImage key={file.uniqueKey} imageUrl={file.uniqueKey} />);
        })}
        {soundFiles.map((file: UploadedAttachmentType) => {
          return(<DisplaySound key={file.uniqueKey} soundUrl={file.uniqueKey} />);
        })}
        {videoFiles.map((file: UploadedAttachmentType) => {
          return(<DisplayVideo key={file.uniqueKey} videoUrl={file.uniqueKey} />);
        })}
        {pdfFiles.map((file: UploadedAttachmentType) => {
          return(<DisplayPDF key={file.uniqueKey} pdfUrl={file.uniqueKey} />);
        })}
    </div>
  );
}
