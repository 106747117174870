import { creatContextStore } from './base';
import { ReportData } from "actions/reportActions";
import { NoteType } from "actions/noteActions";
import { UserInfo } from './user';

export enum ReportSections {
  Incident = "s-incident",
  Offender = "s-offender",
  Break = "s-break",
  Experience = "s-experience",
  OtherDetails = "s-details",
  SensoryExperience = "s-sensory",
  Impact = "s-impact"
}

export interface AnswerValue {
  answer: any;
  scale?: any;
};

export interface Answer {
  answerKey: string;
  sectionKey: string;
  questionKey: string;
  value: AnswerValue;
  valueFormatted?: string | Date;
  isFile?: boolean;
  reportId: number;
};

export interface ApiAnswer {
  questionKey: string;
  sectionKey: string;
  id: number;
  reportId: number;
  value: string;
  uniqueKey: string;
};

export interface UploadedAttachmentType {
  id: number;
  reportId: number;
  filename: string;
  url: string;
  uploaded: boolean;
  uniqueKey: any;
  file?: File
};

export const getAnswerKey = (sectionKey : string, questionKey: string) => {
  return `${sectionKey}:${questionKey}`;
}

export const getFilesToUploadFromAnswers = (answers: Map<string,Answer>) => {
  return Array.from(answers.values()).filter((a) => { return a.isFile; });
}

/* ------------------------------ context store setup ----------------------------- */

export type ReportUserNotesAnswers = {
  reportUser?: UserInfo,
  report?: ReportData,
  notes?: Array<NoteType>,
  answers?: Map<string,Answer>,
}

export const initialReportUserNotesAnswersState = {
  reportUser: undefined,
  report: undefined,
  answers: new Map<string,Answer>(),
  notes: undefined
}

const [ Provider, useState, useSetState, useMergeState ] = creatContextStore<ReportUserNotesAnswers>(initialReportUserNotesAnswersState);

export {
  Provider as ReportUserNotesAnswersContextProvider,
  useState as useReportUserNotesAnswersState,
  useSetState as useSetReportUserNotesAnswersState,
  useMergeState as useMergeReportUserNotesAnswersState
};
