import React, { useState } from "react";
import styles from './styles.module.scss';
import { Organization } from "actions/organizationActions";
import { Checkbox } from "components/Checkbox";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { Dropdown } from "components/Forms/Dropdown";
import { SearchInput } from "components/SearchInput";
import { useSortedOrganizations } from "./useSortedOrganizations";
import { ErrorType } from "actions/lib/baseActions";
import { useUserState } from "context";
import { UserInfo } from "context/user";

interface AssignOrganizationsTableProps {
  assignedOrganizationIds: number[];
  setAssignedOrganizationIds: (organizationIds: number[]) => void;
  setAssignOrganizationsError: (error: ErrorType | null) => void;
}

export const AssignOrganizationsTable = React.memo(({ 
  assignedOrganizationIds,
  setAssignedOrganizationIds,
  setAssignOrganizationsError
}: AssignOrganizationsTableProps) => {

  const userInfo: UserInfo = useUserState();

  const [organizations, setOrganizations] = useState<Organization[]>();

  const {
    organizationSortDropdownOptions,
    onSortOptionChange,
    populateOrganizations,
    sortedOrganizations
  } = useSortedOrganizations(organizations, setOrganizations);

  const onCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>, 
    organization: Organization
  ) => { 
    const organizationId = Number(organization.id);
    
    let updatedAssignedOrganizationIds;
    if (e.target.checked) {
      updatedAssignedOrganizationIds = [...assignedOrganizationIds, organizationId];
    } else {
      updatedAssignedOrganizationIds = [...assignedOrganizationIds.filter(id => (id !== organizationId))];
    }
    setAssignedOrganizationIds(Array.from(new Set(updatedAssignedOrganizationIds)));

    setAssignOrganizationsError(null);
  }

  const assignedOrganizationsCount = assignedOrganizationIds?.length;
  const pluralOrSingularOrganizationId = (assignedOrganizationsCount > 1) ? "organizations" : "organization";

  return (
    <div className={styles.assignOrganizationsTableContainer}>
      <div className={styles.assignOrganizationsTableHeaders}>
        <p className={classNames((assignedOrganizationsCount <= 0) && styles.assignedNone, styles.smallText)}>
          <FormattedMessage id="assignedTo" defaultMessage="Assigned to"/> {assignedOrganizationsCount} <FormattedMessage id={pluralOrSingularOrganizationId} defaultMessage={pluralOrSingularOrganizationId}/>
        </p>
        <div className={styles.organizationsSortSearch}>
          <Dropdown dropdownId="assignOrganizationSortOptions" key="assignOrganizationSortOptions"
            dropdownOptions={organizationSortDropdownOptions}
            onSelect={onSortOptionChange}/>
          <SearchInput className={styles.searchInput} onChange={populateOrganizations}/>
        </div> 
      </div>
      
      <div className={styles.assignOrganizationsTable}>
      {sortedOrganizations?.map((organization: Organization) => {
        const organizationName = organization.name;
        const organizationType = organization.organizationTypeName;
        const organizationId = Number(organization.id);
        const isAssigned = assignedOrganizationIds.includes(organizationId);
        return (
          <div className={styles.organizationRow} key={organizationId}>
            <div className={styles.organizationNameAndType}>
              <h5>{organizationName}</h5>
              <p className={classNames(styles.roleName, styles.smallText)}>
                <FormattedMessage id={`organizationType.${organization.organizationTypeId}`} defaultMessage={organizationType}/>
              </p>
            </div>
            <Checkbox 
              name={organizationName}
              id={organizationId}
              value={isAssigned}
              disabled={(organizationId === userInfo.organizationId)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onCheckboxChange(e, organization); }}
            />
          </div>
        )
      })}
      </div>

    </div>
  );
});
