import { MessageDescriptor } from "react-intl";

export enum BasicSortOptions {
  AlphabeticalAscending = "A-Z",
  AlphabeticalDescending = "Z-A",
}

export const BasicSortOptionsIntl: Map<BasicSortOptions, MessageDescriptor> = new Map([
  [BasicSortOptions.AlphabeticalAscending, { id: "alphabeticalAscending", defaultMessage: "A-Z" }],
  [BasicSortOptions.AlphabeticalDescending, { id: "alphabeticalDescending", defaultMessage: "Z-A" }]
]);

export enum DateSortOptions {
  Newest = "newest",
  Oldest = "oldest"
}

export const DateSortOptionsIntl: Map<DateSortOptions, MessageDescriptor> = new Map([
  [DateSortOptions.Newest, { id: "newest", defaultMessage: "Newest" }],
  [DateSortOptions.Oldest, { id: "oldest", defaultMessage: "Oldest" }]
]);

export const sortAlphaNumeric = (a: any, b: any) => { 
  return a.localeCompare(b , 'en', { numeric: true }); 
};

export const sortByNames = (a: any, b: any) => { 
  return sortAlphaNumeric(a.name, b.name);
};

export const createAlphaNumericSorterByProperty = (property: string) => {
  return (a: any, b: any) => {
    return sortAlphaNumeric(a?.[property] || '', b?.[property] || '');
  }
}

export const basicSorter = (
  unsortedValues: any[] = [],
  sortOption: any,
  sortByProperty: (a: any, b: any) => any
) => {
  const sortByPropertyReverse = (a: any, b: any) => sortByProperty(b, a); // arguments reversed

  switch (sortOption) {
    case BasicSortOptions.AlphabeticalAscending:
      return [...unsortedValues].sort(sortByProperty);
    case BasicSortOptions.AlphabeticalDescending:
      return [...unsortedValues].sort(sortByPropertyReverse);
    default:
      return [...unsortedValues].sort(sortByProperty);
  }
}
