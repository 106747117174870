import * as base from "./lib/baseActions";

export interface FeedbackType {
    id: number;
    experience?: string;
    helpfulness?: number;
    howToImprove?: string;
    recommended?: number;
}

const getFeedback = async (): Promise<base.DataWrapper<FeedbackType[]>> => {
  const getFeedbackResult = await base.get(`feedback`);
  return getFeedbackResult;
};

export {
    getFeedback,
};
