
import React from "react";
import styles from "./styles.module.scss";

export interface DisplayVideoProps {
  videoUrl: string;
}

export const DisplayVideo = ({ videoUrl } : DisplayVideoProps) => {

  return (
    <video muted={false} className={styles.videoContainer} controls>
      <source src={videoUrl} type="video/mp4"/>
    </video>
  );
}
