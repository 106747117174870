
import React from "react";
import styles from "./styles.module.scss";
import { ExclamationButtonIcon } from "components/Icons";
import { FormattedMessage } from "react-intl";

export interface AttentionMessageProps {
  message?: string;
  defaultMessage?: string;
}

export const AttentionMessage = ({ message, defaultMessage } : AttentionMessageProps) => {
  return (
    <div className={styles.noPermissionGeneralMessage}>
      <ExclamationButtonIcon className={styles.attIcon} />
      <p className={styles.noPermissionMessage}>
        <FormattedMessage id={message} defaultMessage={defaultMessage}/>
      </p>
    </div>
  );
}
