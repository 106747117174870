import React,  { FC, useEffect } from "react";
import { AppProvider, useMergeAppState, useMergeUserState } from 'context/index';
import { Routing } from './routing';
import { fetchAdminPanelUser } from 'actions/userActions';
import "./App.scss";

const useGetAuth =  () => {
  const mergeAppState = useMergeAppState();
  const mergeUserData = useMergeUserState();

  const fetchData = async () => {
    mergeAppState({ Authenticating: true });

    try {
      const result = await fetchAdminPanelUser();
      if (result?.data) {
        mergeUserData(result.data);
        mergeAppState({ Authenticated: true });
      }
    } catch (err) {
      mergeAppState({ Authenticated: false });
      console.log(err);
    }

    mergeAppState({ Authenticating: false });
  };

  useEffect(() => {
    fetchData();
  }, []);
};

// We need to attach providers here
const AppProviderWrapper: FC = () => {
  return (
    <AppProvider>
        <AppWithContext>
          <Routing />
        </AppWithContext>
    </AppProvider>
  );
};


// We can now call context here, as it's wrapped by the AppProviderWrapper
const AppWithContext: FC = ({children}) => {
  useGetAuth();

  return <>
    {children}
  </>
}

export default AppProviderWrapper;
