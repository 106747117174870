import React, { useCallback, useEffect, useState } from "react";
import styles from './styles.module.scss';
import { getReportStats, ReportLocationType } from "actions/reportActions";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import { DownloadIcon } from "components/Icons";
import { DownloadReportPDFLink } from "components/ReportLocationDocument";
import { useDownloadStatsURL } from "hooks/useDownloadStatsURL";
import { useDownloadLocationsURL } from "hooks/useDownloadLocationsURL";
import { useLocation } from "react-router-dom";
import { AttentionMessage } from "components/AttentionMessage";
import { SingleDatePicker } from "components/Forms/SingleDatePicker";
import { isOrganizationTypeCrisisCenter, isOrganizationTypeLawEnforcement, isOrganizationTypeVesta } from "lib/permissions";
import { useUserState } from "context/user";
import { Dropdown } from "components/Forms/Dropdown";
import { Checkbox } from "components/Checkbox";
import { ColourCodedCheckbox } from "./ColourCodedCheckbox";
import { GoogleMap , useLoadScript, Marker} from "@react-google-maps/api";
import { googleAPIKey } from "lib/config";
import { float } from "aws-sdk/clients/lightsail";
import { dateToggles, getLocations, reportLocation, timeOfDay, displayTypes, AnonymousType, locationQuery } from "actions/locationActions";
import { reportStatusTypes } from "lib/reportStatuses";
import { filter, last } from "lodash";


export interface StatsGroupProps {
  className?: string;
  isDashboard?: boolean;
}

interface latLong {
  lat: number;
  lng: number;
}


// set to kingston 
const screenLat = 44.2312;
const screenLong = -76.4860;

export const LocationStatsGroup = ( {className, isDashboard = false} : StatsGroupProps) => {  
  const today = new Date();
  
  const lastTwoWeeks = new Date();
  lastTwoWeeks.setDate( today.getDate() - 14 );


  const [startDate, setStartDate] = useState<Date>(lastTwoWeeks);
  const [endDate, setEndDate] = useState<Date>(today);
  const [sortReport, setReportSort] = useState('All');
  const [checkBoxes, setCheckBoxes] = useState<Array<string>>([]);
  const [notPinnable, setNotPinnable] = useState<Array<reportLocation>>([]);
  const [anonymousLocations, setAnonymousLocations] = useState<Array<reportLocation>>([]);
  const [nonAnonymousLocations, setNonAnonymousLocations] = useState<Array<reportLocation>>([]);
  const [anonNumber, setAnonNumber] = useState(0);
  const [nonAnonNumber, setNonAnonNumber] = useState(0);
  const [lastPinAdded, setLastPinAdded] = useState('');
  const [allLocations, setAllLocations] = useState<Array<ReportLocationType>>([]);
  const [mapPins, setMapPins] = useState<Array<reportLocation>>([]);
  const [map, setMap] = useState<google.maps.Map>();
  const getDownloadLocationsUrl = useDownloadLocationsURL(); 

  //latitude range will be rounded if outside this range [-90,90] 
  //longitude range will be rounded if outside this range [-180,180]  
  //set southern ontraio as default
  const [screenLatLong, setScreenLatLong] = useState<latLong>({lat: screenLat, lng: screenLong});
  const [screenZoom, setScreenZoom] = useState<float>(7);

  const {isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleAPIKey || ''
  })

  const filterCatagories = [
    {id: 1, value: AnonymousType.All, name: 'All'},
    {id: 2, value: AnonymousType.NonAnonomous, name: 'Non-Anonymously'},
    {id: 3, value: AnonymousType.Anonymous, name: 'Anonymously'}
  ]

  const intl = useIntl();

  const requestLocations = async () => {

    try {
      let locationsQuery: locationQuery = {
        startDate: startDate,
        endDate: endDate,
      };

      if (sortReport === AnonymousType.Anonymous) {
        locationsQuery.isAnonymous = true;
      }

      if (sortReport === AnonymousType.NonAnonomous) {
        locationsQuery.isAnonymous = false;
      }

      if (checkBoxes.includes(dateToggles.noDate)) {
        locationsQuery.noDate = true;
      }

      if (checkBoxes.includes(dateToggles.noTime)) {
        locationsQuery.noTime = true;
      }

      if (checkBoxes.includes(dateToggles.morning)) {
        locationsQuery.isMorning = true;
      }

      if (checkBoxes.includes(dateToggles.afternoon)) {
        locationsQuery.isAfternoon = true;
      }

      if (checkBoxes.includes(dateToggles.evening)) {
        locationsQuery.isEvening = true;
      }

      if (checkBoxes.includes(dateToggles.night)) {
        locationsQuery.isNight = true;
      }

      if (checkBoxes.includes(dateToggles.allDates)) {
        locationsQuery.allDates = true;
      }

      const locations = await getLocations(locationsQuery);

      buildPinsList(locations || []);

    } catch(err) {
      console.log('ERROR getting locations: ' + err);
    }
  };

  useEffect(() => {
    requestLocations();
  }, [startDate, endDate, sortReport, checkBoxes]);

  const buildPinsList = (locations: Array<reportLocation>) => {
    const anon = locations?.filter((l) => l.type === displayTypes.anonymous);
    setAnonymousLocations(anon);
    const nonAnon = locations?.filter((l) => l.type === displayTypes.nonAnonymous);
    setNonAnonymousLocations(nonAnon);
    const notPinnable = locations?.filter((l) => l.type === displayTypes.notPinable);
    setNotPinnable(notPinnable);
    let anonNumber = 0;
    let nonAnonNumber = 0;
    let lastPin = new Date(0);
    anon.forEach((report: reportLocation) => {
      if(report.date && report.date?.length > 0  && new Date(report.date[0]) > lastPin){
        lastPin = report.date[0];
      }
      if(report.type === displayTypes.anonymous){
        anonNumber++;   
      }
    })
    nonAnon.forEach((report: reportLocation) => {
      if(report.date && report.date?.length > 0  && new Date(report.date[0]) > lastPin){
        lastPin = report.date[0];
      }
      if(report.type === displayTypes.nonAnonymous){
        nonAnonNumber++;
      }     
    })

    setLastPinAdded(lastPin.toString());
    setAnonNumber(anonNumber);
    setNonAnonNumber(nonAnonNumber);
    setMapPins(locations);
    setAllLocations(buildReportLocations(locations));
  }

  const buildReportLocations = (filteredLocations: Array<reportLocation>) =>{
    let reportlocations : Array<ReportLocationType> = [];
    filteredLocations.forEach((report: reportLocation) => {
        if(report.address && report.lat && report.long && report.date){
          const newReport: ReportLocationType = {
            address: report.address,
            lat: report.lat,
            long: report.long,
            date: report.date[0],
            type: report.type,
            image: report.image,
          } 
          reportlocations.push(newReport);
        }
      }
    );

    return reportlocations
  }

  const filterReport = (report: reportLocation) => {
    return (
      (
        !report.date && 
        checkBoxes.includes(dateToggles.noDate)
      ) || (
        reportInDateRange(report) 
        && checkBoxes.includes(timeOfDay(report))
      ) || checkBoxes.length === 0
    );
  }

  const updateBounds = () =>{
    const bounds = new window.google.maps.LatLngBounds();
    mapPins.forEach((report: reportLocation) => {
      if(report.lat && report.long){
        bounds.extend({lat: report.lat, lng: report.long});
      }
    });
    return bounds;
  }

  const reportInDateRange = (report: reportLocation ) => {

    const reportDate = (report.date && new Date(report?.date[0]));
    return (
      (reportDate 
      || checkBoxes.includes(dateToggles.noDate)) &&  reportDate  && reportDate > startDate && reportDate < endDate
    )
  }

  const selectStartDate = (value: Date) => { 
    setStartDate(value); 
  };

  const selectEndDate = (value: Date) => { 
    setEndDate(value); 
  };

  const getFormatedString = (id: string, defaultMessage: string) => {
    return intl.formatMessage({ id, defaultMessage })

  }

  const statsURL = getDownloadLocationsUrl(allLocations);

  return (
    <>
      {false && ( //Will be used when information fails to show up - change false to desiered boolean
        <div className={styles.attMessageContainer}>
          <AttentionMessage message={"error.general"}/>
        </div>
      )}
      
      <div className={classNames(isDashboard && styles.isDashboard, styles.statsGroup, className)}>
        <div className={styles.statsHeader}>
          <h3 className={styles.title}><FormattedMessage id="stats.locationStatistics" defaultMessage="Location Statistics"/></h3>
            <DownloadIcon href={statsURL} 
              downloadUrl={`stats.csv`} 
              className={styles.downloadComponent}
              iconClassName={styles.downloadComponentIcon}>
              <FormattedMessage id="buttons.download.csv" defaultMessage="Download CSV"/>
            </DownloadIcon>
             <DownloadReportPDFLink
              reportLocationData={allLocations} 
              downloadUrl={`stats.pdf`} 
              className={styles.downloadComponent}
              iconClassName={styles.downloadComponentIcon}>
              <FormattedMessage id="buttons.download.pdf" defaultMessage="Download PDF"/>
            </DownloadReportPDFLink>
        </div>

        <div className={styles.filtersHeader}>
          <div className={styles.filter}>
            <Dropdown
              dropdownId="reportsTableSortOptions" 
              key="reportsTableSortOptions"
              dropdownOptions={filterCatagories}
              onSelect={(value) => setReportSort(value)}/>
          </div>
          <div className={styles.datePickersContainer}>
          <div className={styles.noDateRange}>
              <ColourCodedCheckbox
                title={getFormatedString('locationStats.allDates', 'Include All Dates')}
                value={dateToggles.allDates}
                colour={''}
                callBack={setCheckBoxes}
                checkBoxes= {checkBoxes}
                />
            </div>
            <div className={styles.noDateRange}>
              <ColourCodedCheckbox
                title={getFormatedString('locationStats.includeNoDatePins', 'Include pins with no date')}
                value={dateToggles.noDate}
                colour={'black'}
                callBack={setCheckBoxes}
                checkBoxes= {checkBoxes}
                />
            </div>
            <p className={styles.rangeLabel}>Date Range: </p>
            <div className={styles.datePickers}>
              <div className={styles.dateSelector}>
                <p className={styles.dateSelectorLabel}>
                  <FormattedMessage id="stats.startDate" defaultMessage="Start date"/>:
                </p>
                <SingleDatePicker inputName="startDate" 
                  key="startDate"
                  showTimeSelect={false}
                  value={startDate} 
                  onChange={selectStartDate}
                  disableDate={endDate}
                  directionToDisableDates={'after'}/>
              </div>
              <div className={styles.dateSelector}>
                <p className={styles.dateSelectorLabel}>
                  <FormattedMessage id="stats.endDate" defaultMessage="End date"/>:
                </p>
                <SingleDatePicker inputName="endDate" 
                  key="endDate"
                  showTimeSelect={false}
                  value={endDate} 
                  onChange={selectEndDate}
                  disableDate={startDate}
                  directionToDisableDates={'before'}/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.checkboxesFilter}>
          <div className={styles.group}>
            <FormattedMessage id="locationStats.timeOfDay" defaultMessage="Time of day:"/>
          </div>
          <div className={styles.group}>
            <ColourCodedCheckbox
              title={getFormatedString('locationStats.morningTimeFrame', 'Morning (6:00am - 11:59am)')}
              value={dateToggles.morning}
              colour={'orange'}
              callBack={setCheckBoxes}
              checkBoxes= {checkBoxes}
              />
            <ColourCodedCheckbox
              title={getFormatedString('locationStats.afternoonTimeFrame', 'Afternoon (12:00pm - 5:59pm)')}
              value={dateToggles.afternoon}
              colour={'green'}
              callBack={setCheckBoxes}
              checkBoxes= {checkBoxes}
              />
              
          </div>
          <div className={styles.group}>
            <ColourCodedCheckbox
              title={getFormatedString('locationStats.eveningTimeFrame', 'Evening (6:00pm - 11:59apm)')}
              value={dateToggles.evening}
              colour={'blue'}
              callBack={setCheckBoxes}
              checkBoxes= {checkBoxes}
              />
            <ColourCodedCheckbox
              title={getFormatedString('locationStats.nightTimeFrame', 'Night (12:00am - 5:59am)')}
              value={dateToggles.night}
              colour={'purple'}
              callBack={setCheckBoxes}
              checkBoxes= {checkBoxes}
              />
            <ColourCodedCheckbox
              title={getFormatedString('locationStats.noTimeFrame', 'No time specified')}
              value={dateToggles.noTime}
              colour={'none'}
              callBack={setCheckBoxes}
              checkBoxes= {checkBoxes}
              />
          </div>
        </div>
        <div className={styles.map}>
          <div className={styles.lastPin}>
            <p className={styles.label}>
              <FormattedMessage id="stats.lastPin" defaultMessage="Last pin added on"/>:
            </p>
            <p>{lastPinAdded}</p> 
          </div>
          {!isLoaded ? (
              <div>Loading...</div>
            ) : (
              <GoogleMap
                zoom={screenZoom}
                center={screenLatLong}
                mapContainerClassName={styles.googleMap}
                onLoad={map => {
                  if(mapPins.length > 0){
                    map.fitBounds(updateBounds());
                  }
                  setMap(map);
                }}
                >
                {mapPins && mapPins.map((location: reportLocation) => (
                  location.lat && location.long && <Marker
                    position={{lat: location.lat, lng: location.long}}
                    icon={location.image}
                  />
                ))}
              </GoogleMap>
            )
          }
        </div>
        <div className={styles.summaries}>
          <div className={styles.reportedLocations}>
            <div className={styles.reportSummary}>
              <div className={styles.title}>
                <div className={classNames(styles.anonymousIcon, styles.imageStyling)}/>
                <div className={classNames(styles.description, styles.anonymous)}>
                {<FormattedMessage id="locationStats.anonymousReportedLocationsTitle" 
                    defaultMessage="Anonymously reported locations ({anonNumb}):"
                    values = {{ 
                      anonNumb:  <span className={classNames(styles.description, styles.anonymous)}>{anonNumber}</span>
                  }}
                  />}
                </div>
              </div>
              <div className={styles.note}>
                <FormattedMessage id="locationStats.reportedLocationsDescription" defaultMessage="This data gathered from the report question, Are you able to provide an address (for the incident)?. These are from "/>
                <span className={styles.noteHighlight}>
                  <FormattedMessage id="locationStats.anonymousReports" defaultMessage="Anonymous reports"/>
                </span>
              </div>
              <ol>
                {anonymousLocations.map(item => <li key ={`${item.address}:${item.date}`}>{item.address}</li>)}
              </ol>
            </div>

            <div className={styles.reportSummary}>
              <div className={styles.title}>
                <div className={classNames(styles.nonAnonymousIcon, styles.imageStyling)}/>
                <div className={classNames(styles.description, styles.nonAnonymous)}>
                  {<FormattedMessage id="locationStats.nonAnonymousReportedLocationsTitle" 
                    defaultMessage="Non-Anonymously reported locations ({nonAnonNumb}):"
                    values = {{ 
                      nonAnonNumb:  <span className={classNames(styles.description, styles.nonAnonymous)}>{nonAnonNumber}</span>
                  }}
                  />}
                </div>
              </div>
              <div className={styles.note}>
                <FormattedMessage id="locationStats.reportedLocationsDescription" defaultMessage= "This data gathered from the report question, Are you able to provide an address (for the incident)?. These are from "/>
                <span className={styles.noteHighlight}>
                  <FormattedMessage id="locationStats.nonAnonymousReports" defaultMessage="Non-Anonymous reports"/>
                </span>
              </div>
              <ol>
                {nonAnonymousLocations.map(item => <li key = {`${item.address}:${item.date}`}>{item.address}</li>)}
              </ol>
            </div>
          </div>
          <div className={styles.notPinnable}>
            <div className={styles.summary}>
              <div className={styles.title}>
                <FormattedMessage id="locationStats.nonPinnableLocationsTitle" defaultMessage="Locations that cannot be pinned"/>
              </div>
              <ol>
                {notPinnable.map(item => item.address && <li key ={`${item.address}:${item.date}`}>{item.address}</li>)}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
