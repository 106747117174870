import React, { useState, ReactNode } from "react";
import { useIntl } from 'react-intl';
import { ReportSections } from "context/reportUserNotesAnswers";
import { ArrowDownDefault } from "components/Icons";
import styles from './styles.module.scss';
import { ArrowDirection } from "components/Buttons/Button";
import classNames from "classnames";


interface SectionProps {
  sectionKey: string;
  className?: string;
  children?: ReactNode;
}

export const Section = ( { sectionKey, className, children }: SectionProps ) => {

  const intl = useIntl();

  const [arrowDirection, setArrowDirection] = useState(ArrowDirection.Down);

  const sectionTranslated = sectionKey && ( sectionKey === ReportSections.SensoryExperience ) 
                              ? intl.formatMessage({ id: "sensoryInformation.title", defaultMessage: "Sensory Information" })
                              : intl.formatMessage({ id: `report.${sectionKey}.title` });

  const onSectionClick = () => {
    setArrowDirection( (arrowDirection === ArrowDirection.Down) ? ArrowDirection.Up : ArrowDirection.Down );
  };

  return (
    <div className={classNames(styles.sectionContainer, className)}>
      <div className={styles.sectionHeader} onClick={onSectionClick}>
        <h3>{sectionTranslated}</h3>
        <ArrowDownDefault className={classNames(styles.arrowIcon, (arrowDirection === ArrowDirection.Down) ? styles.down : styles.up )} />
      </div>
      <div className={classNames(styles.sectionContent, styles[arrowDirection], styles[sectionKey])}>
        {children}
      </div>
    </div>
  ); 
}
