import { UserFields, useUserState } from 'context/user';
import styles from './styles.module.scss';
import { FormattedMessage } from "react-intl";
import { ReportFields } from "actions/reportActions";
import { useReportUserNotesAnswersState } from "context/reportUserNotesAnswers";
import { convertBoolToYesNo, convertTypesOfSupportToValue } from "lib/commonFunctions";
import { isOrganizationTypeCrisisCenter, isOrganizationTypeLawEnforcement } from "lib/permissions";

export const SurvivorInfo = () => {
  const userInfo = useUserState();
  
  const reportUserNotesAnswers = useReportUserNotesAnswersState();

  const userFields = Object.values(UserFields).map((userField) => {
    if (
      ((isOrganizationTypeCrisisCenter(userInfo.organizationTypeId) && !reportUserNotesAnswers.report?.allowSupportCenterContact)) || 
      ((isOrganizationTypeLawEnforcement(userInfo.organizationTypeId) && !reportUserNotesAnswers.report?.allowPoliceContact))
    ) {
        if (
          (userField === UserFields.Email) || 
          (userField === UserFields.PhoneNumber) || 
          (userField === UserFields.MethodOfContact)
        ) {
          return null;
        }
    }

    return {
      key: userField,
      value: reportUserNotesAnswers.reportUser?.[userField]
    }
  });

  const reportFields = [
    ReportFields.AllowPoliceContact,
    ReportFields.AllowSupportCenterContact,
    ReportFields.SupportOrganization,
    ReportFields.TypesOfSupport,
  ].map((reportField) => {

    if ((reportField === ReportFields.AllowSupportCenterContact) && 
      isOrganizationTypeCrisisCenter(userInfo.organizationTypeId)) {
      return {
        key: ReportFields.AllowSupportCenterContact,
        value: convertBoolToYesNo( reportUserNotesAnswers.report?.[ReportFields.AllowSupportCenterContact] )
      }
    }
    else if ((reportField === ReportFields.AllowPoliceContact) && 
      isOrganizationTypeLawEnforcement(userInfo.organizationTypeId)) {
      return {
        key: ReportFields.AllowPoliceContact,
        value: convertBoolToYesNo( reportUserNotesAnswers.report?.[ReportFields.AllowPoliceContact] )
      }
    }
    else if ((reportField === ReportFields.SupportOrganization) && 
      isOrganizationTypeCrisisCenter(userInfo.organizationTypeId)) {
      return {
        key: ReportFields.SupportOrganization,
        value: reportUserNotesAnswers.report?.supportOrganization?.organizationName
      }
    }
    else if ((reportField === ReportFields.TypesOfSupport) && 
      isOrganizationTypeCrisisCenter(userInfo.organizationTypeId)) {
      return {
        key: ReportFields.TypesOfSupport,
        value: convertTypesOfSupportToValue(reportUserNotesAnswers.report?.typesOfSupport)
      }
    }
    else {
      return null;
    }

  });

  const survivorInfoFields = [...userFields, ...reportFields];

  return (
    <div className={styles.survivorInfo}>

      {survivorInfoFields.map((field, index) => {
        if (!field) return null;

        return (
          <div className={styles.field} key={`${field?.key}-${index}`}>
            <h5 className={styles.label}>
              <FormattedMessage id={`report.${field?.key}`} defaultMessage={field?.key}/>
            </h5>
            <p className={styles.value}>
              {
              field?.value ? 
                field.key === UserFields.AgeRange ? 
                  <FormattedMessage id={`report.${field.value}`} defaultMessage={field.value}/>
                  : 
                field.value
                :
                <FormattedMessage id={`report.undefined`} defaultMessage={'n/a'}/>
              }
            </p> 
          </div> 
        )
      })}

      <div>
        <h5><FormattedMessage id={`report.otherReports`} defaultMessage="Other submitted reports"></FormattedMessage></h5>
        {reportUserNotesAnswers?.report?.otherReportKeys?.map((reportKey) => {
          return (
            <p>{reportKey}</p>
          )
        })}
      </div>
      
    </div>
  );
}
