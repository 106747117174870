import { IntlShape, MessageDescriptor } from "react-intl";
import timezoneDate from "date-and-time";
import { SupportType } from "actions/reportActions";

export const convertYesNoToBool = (selected: string) => {
  return (selected === "Yes") ? true :  false; 
}

export const convertBoolToYesNo = (value: any) => {
  return (value && parseInt(value)) ? "Yes" : "No";
}

export const convertBoolToYesNoIntl = (value: any) => {
  return (value && parseInt(value)) 
          ? { id: "common.yes", defaultMessage: "Yes" }
          : { id: "common.no", defaultMessage: "No" };
}

export const isUndefined = (input: any) => {
  return ( input === undefined );
}

export const getTranslatedText = (intl: IntlShape, messageDescriptor?: MessageDescriptor) => {
  if (messageDescriptor) {
    messageDescriptor.defaultMessage = messageDescriptor.defaultMessage || `${messageDescriptor.id}`;
    return intl.formatMessage(messageDescriptor);
  }
  return "";
}

export enum DateFormStyle {
  None = "None",
  Text = "Text",
  TextDateTime = "TextDateTime",
  DateTime = "DateTime"
};

export const dateFormatter = (date: string | Date, style: DateFormStyle = DateFormStyle.None, useDateTime = false ) => {
  let parsedDate;
  if (typeof date === "string") {
    parsedDate = timezoneDate.parse(
      date.replace('T', ' ').replace(/\..*$|T/gm, ''), 
      "YYYY-MM-DD HH:mm:ss", 
      true // if this is false it assumes that the INPUT dateString is a local date-time, while true says that it is UTC date-time
    );
  } else {
    parsedDate = date;
  }


  if (parsedDate && (parsedDate instanceof Date)) {

    if(style === DateFormStyle.TextDateTime) {
      return timezoneDate.format(parsedDate, 'MMMM DD, YYYY hh:mmA', false); // third parameter of false outputs a local date-time string while true outputs a UTC date-time string
    } else if (style === DateFormStyle.Text) {
      return timezoneDate.format(parsedDate, 'MMMM DD, YYYY', false); // third parameter of false outputs a local date-time string while true outputs a UTC date-time string
    } else if (style === DateFormStyle.DateTime) {
      return timezoneDate.format(parsedDate, 'YYYY-MM-DD HH:mm', false); // third parameter of false outputs a local date-time string while true outputs a UTC date-time string
    }

    if(useDateTime) {
        return timezoneDate.format(parsedDate, 'YYYY-MM-DD HH:mm', false); // third parameter of false outputs a local date-time string while true outputs a UTC date-time string
    } else {
      return timezoneDate.format(parsedDate, 'MMMM DD, YYYY', false); // third parameter of false outputs a local date-time string while true outputs a UTC date-time string
    }
  } else {
    return '';
  }
};

export const convertTypesOfSupportToValue = (typesOfSupport: SupportType[] | undefined) => {
  let val = '';
  if (typesOfSupport) {
    for (const type of typesOfSupport) {
      val += type.text + "\n"
    }
  }
  return val;

}
