import React, { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import { Button, ButtonStyle, LinkButton } from 'components/Buttons';
import { TextField, TextFieldTypes, TextFieldEventType } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import { AddOrEditDepartmentUsersFormFields } from '..';
import { routePaths } from 'App/routing';
import { ErrorType } from 'actions/lib/baseActions';
import { AssignDepartmentUsersTable } from '../AssignDepartmentUsersTable';


interface AddOrEditDepartmentUsersFormProps {
  addOrEditDepartmentUsersError: ErrorType | null;
  setAddOrEditDepartmentUsersError: (value: ErrorType | null) => void;
}

export const AddOrEditDepartmentUsersForm = ({ addOrEditDepartmentUsersError, setAddOrEditDepartmentUsersError }: AddOrEditDepartmentUsersFormProps) => {

  const { values, handleChange, isSubmitting, setSubmitting, errors, validateForm, resetForm, handleSubmit } = useFormikContext<AddOrEditDepartmentUsersFormFields>();

  // force validation because it doesn't do it the first time you change something
  useEffect(() => {
    if (values.name !== "") {
      validateForm();
    }
  }, [values]);

  const onTextFieldChange = (e: TextFieldEventType) => {
    e.target.name = e.target.name || "";
    handleChange(e);
    setAddOrEditDepartmentUsersError(null);
    setSubmitting(false); 
  };

  return (    
    <Form className={styles.addOrEditDepartmentUsersForm}>
      <TextField type={TextFieldTypes.Text} 
        name="name" 
        placeholder="Department Name"
        label="Department name"
        value={values.name}
        onChange={onTextFieldChange}
        className={classNames(styles.inputField, errors.name && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.name}/>

      <AssignDepartmentUsersTable setAssignUsersError={setAddOrEditDepartmentUsersError}/>

      <div className={styles.cancelOrSaveButtons}>
        <LinkButton to={{pathname: routePaths.manageDepartments}}
          style={ButtonStyle.Primary}
          onClick={() => { resetForm({}); }}
          type="button"
        >
          <FormattedMessage id="buttons.cancel" defaultMessage="Cancel"/>
        </LinkButton>

        <Button style={ButtonStyle.Secondary}
          loading={isSubmitting}
          disabled={isSubmitting} 
          onClick={() => { handleSubmit(); }}
          type="button"
        >
          <FormattedMessage id="buttons.saveDepartmentDetails" defaultMessage="Save Department"/>
        </Button>
      </div>

      {_.isEmpty(errors) && addOrEditDepartmentUsersError && (<div className={styles.errorMessage}><FormattedMessage id={addOrEditDepartmentUsersError.intlId} defaultMessage={addOrEditDepartmentUsersError.defaultMessage || "Sorry, there was an error."}/></div>)}
    </Form>
  );
};
