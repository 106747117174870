import React, { FC } from 'react';
import { AppContextProvider } from './app';
import { UserContextProvider } from './user';
import { ReportUserNotesAnswersContextProvider } from './reportUserNotesAnswers';
import { IntlProvider } from 'react-intl';
import { intl } from 'intl/intl';

import { useSetUserState } from './user';
import { useSetReportUserNotesAnswersState, initialReportUserNotesAnswersState } from './reportUserNotesAnswers';

export { useAppState, useSetAppState, useMergeAppState } from './app';
export { useUserState, useSetUserState, useMergeUserState } from './user';
export { useReportUserNotesAnswersState, useSetReportUserNotesAnswersState, useMergeReportUserNotesAnswersState } from './reportUserNotesAnswers';

export interface AppContextInterface {
  token?: string;
  email?: string;
  userName?: string;
}

export const useClearState = () => {
  const setUserInfo = useSetUserState();
  const setReportUserNotesAnswers = useSetReportUserNotesAnswersState();

  return () => {
    setUserInfo({});
    setReportUserNotesAnswers(initialReportUserNotesAnswersState);
  };
};

export const AppProvider : FC = ({children}) => {
  return (
    <AppContextProvider>
      <UserContextProvider>
        <ReportUserNotesAnswersContextProvider> {/* stores 1 set of reportUserNotesAnswers corresponding to 1 report */}
            <IntlProvider locale="en-CA" defaultLocale="en-CA" messages={intl["en-CA"]}>
              {children}
            </IntlProvider>
          </ReportUserNotesAnswersContextProvider>
      </UserContextProvider>
    </AppContextProvider>
  );
}
