
import { intl as reportIntl } from './report.intl';
import { intl as csvIntl } from './csv.intl';

export const intl = {
  'en-CA': { ...{
    'common.yes': 'Yes',
    'common.no': 'No', 
    'common.show': 'Show', 
    'common.hide': 'Hide',
    'common.search': 'Search...',
    'common.save': 'Save',
    'value': 'Value',
    'loading': 'Loading',
    'downloadDate': 'Download Date',
    'question': 'Question',
    'answer': 'Answer',
    'user': 'User',
    'users': 'Users',
    'organization': 'Organization',
    'organizations': 'Organizations',
    'departments': 'Departments',
    'fullName': 'FullName',
    'experience': 'Experience',
    'helpfulness': 'Helpfulness',
    'recommended': 'Recommended',
    'howToImprove': 'How to improve',
    'forgotPassword.title': 'Enter your email to have a password reset link emailed to you',
    'forgotPassword.complete': 'Your password reset link has been emailed to you',
    'edit': 'Edit',
    'editUser':'Edit User',
    'addNewUser':'Add New User',
    'delete': 'Delete',
    'sort': 'Sort',
    'button.saveUser':'Save User',
    'assignedTo': 'Assigned to',
    'adminPanel.dashboard': 'Dashboard',
    'adminPanel.reports': 'Reports',
    'adminPanel.userRoles': 'Users And Roles',
    'adminPanel.manageDepartments': 'Manage Departments',
    'adminPanel.manageOrganizations': 'Manage Organizations',
    'adminPanel.statistics': 'Statistics',
    'adminPanel.feedback': 'Feedback',
    'adminPanel.locationStatistics': 'Location Statistics',
    'alphabeticalAscending': 'A-Z',
    'alphabeticalDescending': 'Z-A',
    'reportKey': 'Report Key',
    'reportDataKey': 'Report Data Key',
    'survivorDataKey': 'Survivor Data Key',
    'assignedOrganizations': 'Assigned Organizations',
    'assignedDepartments': 'Assigned Departments',
    'role': 'Role',
    'role.admin': 'Admin',
    'role.lawEnforcement': 'Law Enforcement',
    'role.crisisCentre': 'Crisis Centre',
    'role.survivor': 'Survivor',
    'roleDescription': 'Role Description',
    'roleDescription.shareOrganization.title': 'Share Reports',
    'roleDescription.shareOrganization.description': 'Allow sharing reports across organizations',
    'roleDescription.manageDepartments.title': 'Manage Departments',
    'roleDescription.manageDepartments.description': 'Create, edit or delete departments',
    'roleDescription.manageOrganizations.title': 'Manage Organizations',
    'roleDescription.manageOrganizations.description': 'Create, edit, or delete organizations',
    'roleDescription.manageUsers.title': 'Manage Users',
    'roleDescription.manageUsers.description': 'Create, edit or delete users',
    'roleDescription.viewReportDetails.title': 'See Full Report Details',
    'roleDescription.viewReportDetails.description': 'See all details of a survivor report that the survivor has consented to share',
    'roleDescription.viewReportNotes.title': 'See Report Notes',
    'roleDescription.viewReportNotes.description': 'See notes on a survivor report made by other users within an organization',
    'roleDescription.addReportNotes.title': 'Add Report Notes',
    'roleDescription.addReportNotes.description': 'Create notes on a survivor report',
    'roleDescription.manageReportDepartmentAssignment.title': 'Manage Report Department Assignments',
    'roleDescription.manageReportDepartmentAssignment.description': 'Assign Departments to Reports',
    'roleDescription.manageReportUserAssignment.title': 'Manage Report User Assignments',
    'roleDescription.manageReportUserAssignment.description': 'Assign Users to Reports',
    'roleDescription.manageReportStatus.title': 'Manage Report Status',
    'roleDescription.manageReportStatus.description': 'Assign Status to Reports',
    'roleDescription.viewReportStatistics.title': 'See Report Statistics',
    'roleDescription.viewReportStatistics.description': 'See aggregate information of survivor reports without personal information',
    'roleDescription.viewAuditLogs.title': 'See Audit Logs',
    'roleDescription.viewAuditLogs.description': 'See audit logs on a survivor report',
    'roleDescription.viewReportContactDetails.title': 'See survivor report contact details',
    'roleDescription.viewReportContactDetails.description': 'See contact details of a survivor report',
    'roleDescription.hasFullAccess.description': 'This user role has full access permissions to reports and is assigned by default.',
    'manageUsersAndRoles': 'Manage Users and Roles',
    'organizationType.7': 'Dummy Organization',
    'organizationType.2': 'Law Enforcement',
    'organizationType.5': 'Campus Support',
    'organizationType.4': 'Location Support',
    'organizationType.3': 'Crisis Center',
    'organizationType.1': 'Vesta',
    'type.7': 'Dummy Organization',
    'type.2': 'Law Enforcement',
    'type.5': 'Campus Support',
    'type.4': 'Location Support',
    'type.3': 'Crisis Center',
    'type.1': 'Vesta',
    'permissionScope.All': 'All',
    'permissionScope.Organization': 'Organization',
    'permissionScope.Assigned': 'Assigned',
    'permissionScope.None': 'None',
    'login.welcome': 'Welcome to the Vesta Admin Portal.',
    'login.loginWithUsernameAndPassword': 'Login with your provided username and password.',
    'recentReports': 'Recent Reports',
    'reports': 'Reports',
    'reports.noTimelineMessage': 'You do not have permission to view the report timeline',
    'reportStatuses': 'Report Statuses',
    'report.status.unassigned': 'Unassigned',
    'report.status.assignedUnactioned': 'Assigned - Unactioned',
    'report.status.activeOngoing': 'Active/Ongoing',
    'report.status.clearedByCharge': 'Cleared By Charge',
    'report.status.clearedInsufficientEvidence': 'Insufficient Evidence',
    'report.status.clearedNoChargeableSuspectIdentified': 'No Chargeable Suspect Identified',
    'report.status.clearedComplainantDeclinesToProceed': 'Complainant Declines to Proceed (Chargeable Suspect Identified)',
    'report.status.clearedOtherwise': 'Cleared Otherwise',
    'policeStatus.unassigned': 'Unassigned',
    'policeStatus.unassigned.description': 'Survivor Report received but not assigned by the Admin to an Investigating Officer',
    'policeStatus.assignedUnactioned': 'Assigned - Unactioned',
    'policeStatus.assignedUnactioned.description': 'Survivor Report received and assigned to an Investigating Officer; however, it remains unactioned by the Investigator.',
    'policeStatus.activeOngoing': 'Active/Ongoing',
    'policeStatus.activeOngoing.description': 'Survivor Report assigned to Investigating Officer. Entered into Record Management System',
    'policeStatus.clearedByCharge': 'Cleared By Charge',
    'policeStatus.clearedByCharge.description': 'Suspect charged',
    'policeStatus.clearedInsufficientEvidence': 'Insufficient Evidence',
    'policeStatus.clearedInsufficientEvidence.description': 'Cleared by Insufficient Evidence. Suspect identified but insufficient evidence to proceed.',
    'policeStatus.clearedNoChargeableSuspectIdentified': 'No Chargeable Suspect Identified',
    'policeStatus.clearedNoChargeableSuspectIdentified.description': 'Cleared. No chargeable suspect identified.',
    'policeStatus.clearedComplainantDeclinesToProceed': 'Complainant Declines to Proceed (Chargeable Suspect Identified)',
    'policeStatus.clearedComplainantDeclinesToProceed.description': 'Cleared. Complainant declines to proceed despite having a chargeable suspect.',
    'policeStatus.clearedOtherwise': 'Cleared Otherwise',
    'policeStatus.clearedOtherwise.description': 'Cleared. Status does not fit into any of the other options.',
    'supportCenterStatus.unassigned': 'Unassigned',
    'supportCenterStatus.unassigned.description': 'Survivor Report received but not assigned by the Admin to a Case Worker',
    'supportCenterStatus.assignedUnactioned': 'Assigned - Unactioned',
    'supportCenterStatus.assignedUnactioned.description': 'Survivor Report received and assigned to a Case Worker; however, it remains unactioned by the Case Worker',
    'supportCenterStatus.activeAppointmentBooked': 'Active - Appointment booked',
    'supportCenterStatus.activeAppointmentBooked.description': 'Active - Survivor Report assigned to Case Worker, client details have been entered in the Record Management System, and an appointment has been booked',
    'supportCenterStatus.activeWaitlisted': 'Active - Waitlisted',
    'supportCenterStatus.activeWaitlisted.description': 'Survivor Report assigned to Case Worker, client details have been entered in the Record Management System, and the client has been waitlisted for services',
    'supportCenterStatus.activeCounselling': 'Active - Counselling',
    'supportCenterStatus.activeCounselling.description': 'Survivor Report assigned to Case Worker and the client is receiving services',
    'supportCenterStatus.dischargedNoContact': 'Discharged - No Contact',
    'supportCenterStatus.dischargedNoContact.description': 'Case Worker did not receive a response to contact request',
    'supportCenterStatus.dischargedChangedMind': 'Discharged - Changed Mind',
    'supportCenterStatus.dischargedChangedMind.description': 'Client indicated that they have changed their minds and do not want to receive services.',
    'supportCenterStatus.dischargedReferredElsewhere': 'Discharged - Referred Elsewhere',
    'supportCenterStatus.dischargedReferredElsewhere.description': 'Client referred to other service provider.',
    'report.setState': 'Set State',
    'report.reportInformation': 'Report Information',
    'report.survivorInformation.title': 'Survivor Information',
    'report.reportUpdates.title': 'Report Updates',
    'report.fullName': 'Name',
    'report.ageRange': 'Age Range',
    'report.phoneNumber':'Phone Number',
    'report.address': 'Address',
    'report.email': 'Email',
    'report.locationOfIncident':'Location of Incident',
    'report.timeOfIncident':'Date/Time of Incident',
    'report.status': 'Status',
    'report.view': 'View',
    'report.consent':'Consent',
    'report.assignToOrganization': 'Assign to Org.',
    'report.followedIntakeFormLink': 'Followed Intake Form link',
    'report.assignToUser': 'Assign to User',
    'report.olderThanNineteen': 'Aged 19+',
    'report.sixteenToEighteen': 'Aged 16-18',
    'report.youngerThanSixteen': 'Younger than 16',
    'report.releaseVersion': 'Release Version',
    'report.undefined': 'n/a',
    'report.otherReports': 'Other submitted reports',
    'reportTimeline.submitted': 'Report Submitted',
    'reportTimeline.updated': 'Report Updated',
    'reportTimeline.addComments': 'Add comments...',
    'stats.startDate': 'Start date',
    'stats.endDate': 'End date',
    'stats.numSubmittedReports': 'Number of submitted reports',
    'stats.overallStatistics': 'Overall Statistics',
    'stats.statusSubtitle': '*Encompasses all reports submitted across all organizations',
    'stats.viewAcrossJurisdictions': 'View statistics across all jurisdictions',
    'stats.Date': 'Date',
    'stats.Address': 'Address',
    'stats.Type': 'Type',
    'stats.counsellorConsent':  'Has support center consent',
    'stats.supportCenterConsent':  'Has support center consent',
    'stats.policeConsent': 'Has police consent',
    'stats.totalBothPoliceAndSupportConsent': 'Has both police and counsellor consent',
    'stats.totalNoPoliceOrSupportConsent': 'Has neither police nor counsellor consent',
    'locationStats.locationStatistics': 'Location Statistics',
    'locationStats.lastPin': 'Last pin added on',
    'locationStats.morningTimeFrame': 'Morning (6:00am - 11:59am)',
    'locationStats.afternoonTimeFrame': 'Afternoon (12:00pm - 5:59pm)',
    'locationStats.eveningTimeFrame': 'Evening (6:00pm - 11:59apm)',
    'locationStats.nightTimeFrame': 'Night (12:00am - 5:59am)',
    'locationStats.noTimeFrame': 'No time specified',
    'locationStats.anonymousReportedLocationsTitle': 'Anonymously reported locations ({anonNumb}):',
    'locationStats.nonAnonymousReportedLocationsTitle': 'Non-Anonymously reported locations ({nonAnonNumb}):',
    'locationStats.nonPinnableLocationsTitle': 'Locations that cannot be pinned',
    'locationStats.reportedLocationsDescription': 'This data gathered from the report question, "Are you able to provide an address (for the incident)?". These are from ',
    'locationStats.anonymousReports': 'Anonymous reports',
    'locationStats.nonAnonymousReports': 'Non-Anonymous reports',
    'locationStats.includeNoDatePins': 'Include pins with no date',
    'locationStats.allDates': 'Include all dates',
    'locationStats.timeOfDay': 'Time of day:',
    'buttons.accessPortalButton':'Access Portal',
    'buttons.forgotUsernameOrPassword': 'Forgot username/password?',
    'buttons.contactSupport': 'Contact support',
    'buttons.back': 'Back',
    'buttons.saveNote':'Save Note',
    'buttons.download.report':'Download Report',
    'buttons.download.csv':'Download CSV',
    'buttons.download.pdf':'Download PDF',
    'buttons.add':'Add',
    'buttons.addUser':'Add User',
    'buttons.logout':'Logout',
    'buttons.viewAttachments': "View attachments",
    'buttons.hideAttachments': "Hide attachments",
    'buttons.saveDepartmentDetails': 'Save department',
    'buttons.saveOrganizationDetails': 'Save organization',
    'buttons.backToUserRoles':'Back to users',
    'buttons.emailForgotPassword': 'Email Password Reset',
    'common.na': 'n/a',
    'common.okay':'Okay',
    'common.cancel':'Cancel',
    'modal.confirm.reportStatusUpdated': 'Report status has been successfully updated',
    'modal.confirm.userAdded': 'User has been successfully created',
    'modal.confirm.userEdited': 'User has been successfully edited',
    'modal.confirm.departmentCreated': 'Department has been successfully created',
    'modal.confirm.departmentEdited': 'Department has been successfully updated',
    'login.error.general': 'There was an error with logging in. Please try again later. If you continue to have problems, please contact support.',
    'login.error.invalidCredentials': "Sorry, but the Email or Password you entered in was wrong. Please try again, or contact us at support.",
    'modal.mobileWarning': "For safety and security, please use a desktop device.",
    'message.someFeaturesDisabled': "Some features may be disabled based on your permissions.",
    'message.noReportsAssigned': "There are currently no reports assigned to you.",
    'message.noDepartments': "There are currently no departments.",
    'message.noOrganizations': "There are currently no organizations.",
    'message.noUsers': "There are currently no users.",
    'message.noFeedback': "There is currently no feedback.",
    'message.assignedUsers': 'Only users who have been specifically assigned count towards assigned users. Users who have organization-level permissions do not count.',
    'message.assigningDepts': "Assigning a department will assign users within the department.",
    'message.selectUserRole': 'Select a user role to see which actions that role has permissions for.',
    'modal.areYouSureDelete': "Are you sure you want to delete this user?",
    'modal.areYouSureDeleteDept': "Are you sure you want to delete this department? Doing so will not delete the users associated with this department.",
    'errorModal.general.main': "Something went awry - don't worry, we're working on it!",
    'errorModal.tryAgainLaterOrContactSupport': "Please try again later, or contact Vesta support at {vestaSupport}.",
    'errorModal.vestaSupport':"vesta.com/support",
    'error.general': "Something went awry - don't worry, we're working on it! Please check back later.",
    'message.attachmentsWarning': "Attachments may have sensitive content. Please make sure you are in an area where it is safe to view these attachments.",
    'userInfo.title': "User Information",
    'userInfo.contactVesta': "Contact VESTA Support",
    'userInfo.email': 'Email',
    'userInfo.emailValue': 'info@vestasit.com',
    'reportExtraInfo.title': 'Report Information',
    'reportExtraInfo.assignedOrgs': 'Assigned Organizations',
    'reportExtraInfo.assignedDepts': 'Assigned Departments',
    'reportExtraInfo.assignedUsers': 'Assigned Users',
    'reportExtraInfo.status': 'Status',
    'consentExtraInfo.title': 'Consent Information',
    'consentExtraInfo.reportNeedsAttention': 'Report Needs Attention',
    'consentExtraInfo.assignToLawEnforcement': 'This report has not been assigned to a Law Enforcement organization.',
    'consentExtraInfo.assignToSupportCentre': 'This report has not been assigned to a Support Center organization.',
    'feedback.MostSad': 'Most Sad',
    'feedback.Sad': 'Sad',
    'feedback.Neutral': 'Neutral',
    'feedback.Happy': 'Happy',
    'feedback.MostHappy': 'Most Happy',
    'page': 'Page',
    'of': 'of',
    'editOrganization.isPartnered': 'Is Partnered',
  }, ...(reportIntl['en-CA']), ...(csvIntl['en-CA'])},
  'fr-CA': { ...{
    'common.yes': 'Yes',
    'common.no': 'No', 
    'common.show': 'Show', 
    'common.hide': 'Hide',
    'common.search': 'Search...',
    'common.save': 'Save',
    'value': 'Value',
    'loading': 'Loading',
    'downloadDate': 'Download Date',
    'question': 'Question',
    'answer': 'Answer',
    'user': 'User',
    'users': 'Users',
    'organization': 'Organization',
    'organizations': 'Organizations',
    'departments': 'Departments',
    'fullName': 'FullName',
    'experience': 'Experience',
    'helpfulness': 'Helpfulness',
    'recommended': 'Recommended',
    'howToImprove': 'How to improve',
    'forgotPassword.title': 'Enter your email to have a password reset link emailed to you',
    'forgotPassword.complete': 'Your password reset link has been emailed to you',
    'edit': 'Edit',
    'editUser':'Edit User',
    'addNewUser':'Add New User',
    'delete': 'Delete',
    'sort': 'Sort',
    'button.saveUser':'Save User',
    'assignedTo': 'Assigned to',
    'adminPanel.dashboard': 'Dashboard',
    'adminPanel.reports': 'Reports',
    'adminPanel.userRoles': 'Users And Roles',
    'adminPanel.manageDepartments': 'Manage Departments',
    'adminPanel.manageOrganizations': 'Manage Organizations',
    'adminPanel.statistics': 'Statistics',
    'adminPanel.locationStatistics': 'Location Statistics',
    'adminPanel.feedback': 'Feedback',
    'alphabeticalAscending': 'A-Z',
    'alphabeticalDescending': 'Z-A',
    'reportKey': 'Report Key',
    'reportDataKey': 'Report Data Key',
    'survivorDataKey': 'Survivor Data Key',
    'assignedOrganizations': 'Assigned Organizations',
    'assignedDepartments': 'Assigned Departments',
    'role': 'Role',
    'role.admin': 'Admin',
    'role.lawEnforcement': 'Law Enforcement',
    'role.crisisCentre': 'Crisis Centre',
    'role.survivor': 'Survivor',
    'roleDescription': 'Role Description',
    'roleDescription.shareOrganization.title': 'Share Reports',
    'roleDescription.shareOrganization.description': 'Allow sharing reports across organizations',
    'roleDescription.manageDepartments.title': 'Manage Departments',
    'roleDescription.manageDepartments.description': 'Create, edit or delete departments',
    'roleDescription.manageOrganizations.title': 'Manage Organizations',
    'roleDescription.manageOrganizations.description': 'Create, edit, or delete organizations',
    'roleDescription.manageUsers.title': 'Manage Users',
    'roleDescription.manageUsers.description': 'Create, edit or delete users',
    'roleDescription.viewReportDetails.title': 'See Full Report Details',
    'roleDescription.viewReportDetails.description': 'See all details of a survivor report that the survivor has consented to share',
    'roleDescription.viewReportNotes.title': 'See Report Notes',
    'roleDescription.viewReportNotes.description': 'See notes on a survivor report made by other users within an organization',
    'roleDescription.addReportNotes.title': 'Add Report Notes',
    'roleDescription.addReportNotes.description': 'Create notes on a survivor report',
    'roleDescription.manageReportDepartmentAssignment.title': 'Manage Report Department Assignments',
    'roleDescription.manageReportDepartmentAssignment.description': 'Assign Departments to Reports',
    'roleDescription.manageReportUserAssignment.title': 'Manage Report User Assignments',
    'roleDescription.manageReportUserAssignment.description': 'Assign Users to Reports',
    'roleDescription.manageReportStatus.title': 'Manage Report Status',
    'roleDescription.manageReportStatus.description': 'Assign Status to Reports',
    'roleDescription.viewReportStatistics.title': 'See Report Statistics',
    'roleDescription.viewReportStatistics.description': 'See aggregate information of survivor reports without personal information',
    'roleDescription.viewAuditLogs.title': 'See Audit Logs',
    'roleDescription.viewAuditLogs.description': 'See audit logs on a survivor report',
    'roleDescription.viewReportContactDetails.title': 'See survivor report contact details',
    'roleDescription.viewReportContactDetails.description': 'See contact details of a survivor report',
    'roleDescription.hasFullAccess.description': 'This user role has full access permissions to reports and is assigned by default.',
    'manageUsersAndRoles': 'Manage Users and Roles',
    'organizationType.7': 'Dummy Organization',
    'organizationType.2': 'Law Enforcement',
    'organizationType.5': 'Campus Support',
    'organizationType.4': 'Location Support',
    'organizationType.3': 'Crisis Center',
    'organizationType.1': 'Vesta',
    'type.7': 'Dummy Organization',
    'type.2': 'Law Enforcement',
    'type.5': 'Campus Support',
    'type.4': 'Location Support',
    'type.3': 'Crisis Center',
    'type.1': 'Vesta',
    'permissionScope.All': 'All',
    'permissionScope.Organization': 'Organization',
    'permissionScope.Assigned': 'Assigned',
    'permissionScope.None': 'None',
    'login.welcome': 'Welcome to the Vesta Admin Portal.',
    'login.loginWithUsernameAndPassword': 'Login with your provided username and password.',
    'recentReports': 'Recent Reports',
    'reports': 'Reports',
    'reports.noTimelineMessage': 'You do not have permission to view the report timeline',
    'reportStatuses': 'Report Statuses',
    'report.status.unassigned': 'Unassigned',
    'report.status.assignedUnactioned': 'Assigned - Unactioned',
    'report.status.activeOngoing': 'Active/Ongoing',
    'report.status.clearedByCharge': 'Cleared By Charge',
    'report.status.clearedInsufficientEvidence': 'Insufficient Evidence',
    'report.status.clearedNoChargeableSuspectIdentified': 'No Chargeable Suspect Identified',
    'report.status.clearedComplainantDeclinesToProceed': 'Complainant Declines to Proceed (Chargeable Suspect Identified)',
    'report.status.clearedOtherwise': 'Cleared Otherwise',
    'policeStatus.unassigned': 'Unassigned',
    'policeStatus.unassigned.description': 'Survivor Report received but not assigned by the Admin to an Investigating Officer',
    'policeStatus.assignedUnactioned': 'Assigned - Unactioned',
    'policeStatus.assignedUnactioned.description': 'Survivor Report received and assigned to an Investigating Officer; however, it remains unactioned by the Investigator.',
    'policeStatus.activeOngoing': 'Active/Ongoing',
    'policeStatus.activeOngoing.description': 'Survivor Report assigned to Investigating Officer. Entered into Record Management System',
    'policeStatus.clearedByCharge': 'Cleared By Charge',
    'policeStatus.clearedByCharge.description': 'Suspect charged',
    'policeStatus.clearedInsufficientEvidence': 'Insufficient Evidence',
    'policeStatus.clearedInsufficientEvidence.description': 'Cleared by Insufficient Evidence. Suspect identified but insufficient evidence to proceed.',
    'policeStatus.clearedNoChargeableSuspectIdentified': 'No Chargeable Suspect Identified',
    'policeStatus.clearedNoChargeableSuspectIdentified.description': 'Cleared. No chargeable suspect identified.',
    'policeStatus.clearedComplainantDeclinesToProceed': 'Complainant Declines to Proceed (Chargeable Suspect Identified)',
    'policeStatus.clearedComplainantDeclinesToProceed.description': 'Cleared. Complainant declines to proceed despite having a chargeable suspect.',
    'policeStatus.clearedOtherwise': 'Cleared Otherwise',
    'policeStatus.clearedOtherwise.description': 'Cleared. Status does not fit into any of the other options.',
    'supportCenterStatus.unassigned': 'Unassigned',
    'supportCenterStatus.unassigned.description': 'Survivor Report received but not assigned by the Admin to a Case Worker',
    'supportCenterStatus.assignedUnactioned': 'Assigned - Unactioned',
    'supportCenterStatus.assignedUnactioned.description': 'Survivor Report received and assigned to a Case Worker; however, it remains unactioned by the Case Worker',
    'supportCenterStatus.activeAppointmentBooked': 'Active - Appointment booked',
    'supportCenterStatus.activeAppointmentBooked.description': 'Active - Survivor Report assigned to Case Worker, client details have been entered in the Record Management System, and an appointment has been booked',
    'supportCenterStatus.activeWaitlisted': 'Active - Waitlisted',
    'supportCenterStatus.activeWaitlisted.description': 'Survivor Report assigned to Case Worker, client details have been entered in the Record Management System, and the client has been waitlisted for services',
    'supportCenterStatus.activeCounselling': 'Active - Counselling',
    'supportCenterStatus.activeCounselling.description': 'Survivor Report assigned to Case Worker and the client is receiving services',
    'supportCenterStatus.dischargedNoContact': 'Discharged - No Contact',
    'supportCenterStatus.dischargedNoContact.description': 'Case Worker did not receive a response to contact request',
    'supportCenterStatus.dischargedChangedMind': 'Discharged - Changed Mind',
    'supportCenterStatus.dischargedChangedMind.description': 'Client indicated that they have changed their minds and do not want to receive services.',
    'supportCenterStatus.dischargedReferredElsewhere': 'Discharged - Referred Elsewhere',
    'supportCenterStatus.dischargedReferredElsewhere.description': 'Client referred to other service provider.',
    'report.setState': 'Set State',
    'report.reportInformation': 'Report Information',
    'report.survivorInformation.title': 'Survivor Information',
    'report.reportUpdates.title': 'Report Updates',
    'report.fullName': 'Name',
    'report.ageRange': 'Age Range',
    'report.phoneNumber':'Phone Number',
    'report.address': 'Address',
    'report.email': 'Email',
    'report.locationOfIncident':'Location of Incident',
    'report.timeOfIncident':'Date/Time of Incident',
    'report.status': 'Status',
    'report.view': 'View',
    'report.consent':'Consent',
    'report.assignToOrganization': 'Assign to Org.',
    'report.followedIntakeFormLink': 'Followed Intake Form link',
    'report.assignToUser': 'Assign to User',
    'report.olderThanNineteen': 'Aged 19+',
    'report.sixteenToEighteen': 'Aged 16-18',
    'report.youngerThanSixteen': 'Younger than 16',
    'report.releaseVersion': 'Release Version',
    'report.undefined': 'n/a',
    'report.Date': 'Date',
    'report.Type': 'Type',
    'report.otherReports': 'Other submitted reports',
    'reportTimeline.submitted': 'Report Submitted',
    'reportTimeline.updated': 'Report Updated',
    'reportTimeline.addComments': 'Add comments...',
    'stats.startDate': 'Start date',
    'stats.endDate': 'End date',
    'stats.numSubmittedReports': 'Number of submitted reports',
    'stats.overallStatistics': 'Overall Statistics',
    'stats.statusSubtitle': '*Encompasses all reports submitted across all organizations',
    'stats.viewAcrossJurisdictions': 'View statistics across all jurisdictions',
    'stats.Date': 'Date',
    'stats.Address': 'Address',
    'stats.Type': 'Type',
    'stats.counsellorConsent':  'Has support center consent',
    'stats.supportCenterConsent':  'Has support center consent',
    'stats.policeConsent': 'Has police consent',
    'stats.totalBothPoliceAndSupportConsent': 'Has both police and counsellor consent',
    'stats.totalNoPoliceOrSupportConsent': 'Has neither police nor counsellor consent',
    'locationStats.locationStatistics': 'Location Statistics',
    'locationStats.lastPin': 'Last pin added on',
    'locationStats.morningTimeFrame': 'Morning (6:00am - 11:59am)',
    'locationStats.afternoonTimeFrame': 'Afternoon (12:00pm - 5:59pm)',
    'locationStats.eveningTimeFrame': 'Evening (6:00pm - 11:59apm)',
    'locationStats.nightTimeFrame': 'Night (12:00am - 5:59am)',
    'locationStats.noTimeFrame': 'No time specified',
    'locationStats.anonymousReportedLocationsTitle': 'Anonymously reported locations ({anonNumb}):',
    'locationStats.nonAnonymousReportedLocationsTitle': 'Non-Anonymously reported locations ({nonAnonNumb}):',
    'locationStats.nonPinnableLocationsTitle': 'Locations that cannot be pinned',
    'locationStats.reportedLocationsDescription': 'This data gathered from the report question, "Are you able to provide an address (for the incident)?". These are from ',
    'locationStats.anonymousReports': 'Anonymous reports',
    'locationStats.nonAnonymousReports': 'Non-Anonymous reports',
    'locationStats.includeNoDatePins': 'Include pins with no date',
    'locationStats.allDates': 'Include all dates',
    'locationStats.timeOfDay': 'Time of day:',
    'buttons.accessPortalButton':'Access Portal',
    'buttons.forgotUsernameOrPassword': 'Forgot username/password?',
    'buttons.contactSupport': 'Contact support',
    'buttons.back': 'Back',
    'buttons.saveNote':'Save Note',
    'buttons.download.report':'Download Report',
    'buttons.download.csv':'Download CSV',
    'buttons.download.pdf':'Download PDF',
    'buttons.add':'Add',
    'buttons.addUser':'Add User',
    'buttons.logout':'Logout',
    'buttons.viewAttachments': "View attachments",
    'buttons.hideAttachments': "Hide attachments",
    'buttons.saveDepartmentDetails': 'Save department',
    'buttons.saveOrganizationDetails': 'Save organization',
    'buttons.backToUserRoles':'Back to users',
    'buttons.emailForgotPassword': 'Email Password Reset',
    'common.na': 'n/a',
    'common.okay':'Okay',
    'common.cancel':'Cancel',
    'modal.confirm.reportStatusUpdated': 'Report status has been successfully updated',
    'modal.confirm.userAdded': 'User has been successfully created',
    'modal.confirm.userEdited': 'User has been successfully edited',
    'modal.confirm.departmentCreated': 'Department has been successfully created',
    'modal.confirm.departmentEdited': 'Department has been successfully updated',
    'login.error.general': 'There was an error with logging in. Please try again later. If you continue to have problems, please contact support.',
    'login.error.invalidCredentials': "Sorry, but the Email or Password you entered in was wrong. Please try again, or contact us at support.",
    'modal.mobileWarning': "For safety and security, please use a desktop device.",
    'message.someFeaturesDisabled': "Some features may be disabled based on your permissions.",
    'message.noReportsAssigned': "There are currently no reports assigned to you.",
    'message.noDepartments': "There are currently no departments.",
    'message.noOrganizations': "There are currently no organizations.",
    'message.noUsers': "There are currently no users.",
    'message.noFeedback': "There is currently no feedback.",
    'message.assignedUsers': 'Only users who have been specifically assigned count towards assigned users. Users who have organization-level permissions do not count.',
    'message.assigningDepts': "Assigning a department will assign users within the department.",
    'message.selectUserRole': 'Select a user role to see which actions that role has permissions for.',
    'modal.areYouSureDelete': "Are you sure you want to delete this user?",
    'modal.areYouSureDeleteDept': "Are you sure you want to delete this department? Doing so will not delete the users associated with this department.",
    'errorModal.general.main': "Something went awry - don't worry, we're working on it!",
    'errorModal.tryAgainLaterOrContactSupport': "Please try again later, or contact Vesta support at {vestaSupport}.",
    'errorModal.vestaSupport':"vesta.com/support",
    'error.general': "Something went awry - don't worry, we're working on it! Please check back later.",
    'message.attachmentsWarning': "Attachments may have sensitive content. Please make sure you are in an area where it is safe to view these attachments.",
    'userInfo.title': "User Information",
    'userInfo.contactVesta': "Contact VESTA Support",
    'userInfo.email': 'Email',
    'userInfo.emailValue': 'info@vestasit.com',
    'reportExtraInfo.title': 'Report Information',
    'reportExtraInfo.assignedOrgs': 'Assigned Organizations',
    'reportExtraInfo.assignedDepts': 'Assigned Departments',
    'reportExtraInfo.assignedUsers': 'Assigned Users',
    'consentExtraInfo.title': 'Consent Information',
    'consentExtraInfo.reportNeedsAttention': 'Report Needs Attention',
    'consentExtraInfo.assignToLawEnforcement': 'This report has not been assigned to a Law Enforcement organization.',
    'consentExtraInfo.assignToSupportCentre': 'This report has not been assigned to a Support Center organization.',
    'feedback.MostSad': 'Most Sad',
    'feedback.Sad': 'Sad',
    'feedback.Neutral': 'Neutral',
    'feedback.Happy': 'Happy',
    'feedback.MostHappy': 'Most Happy',
    'page': 'Page',
    'of': 'of',
    'editOrganization.isPartnered': 'Is Partnered',
  }, ...(reportIntl['fr-CA']), ...(csvIntl['fr-CA'])},
};
