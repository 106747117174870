import * as base from "./lib/baseActions";

const releaseVersion = async () => {

  const releaseVersionResult = await base.get(
    `releaseVersion`
  );
  return releaseVersionResult.data.release_version;
};

export {
    releaseVersion
};
