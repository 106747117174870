import React, { useState } from "react";
import styles from './styles.module.scss';
import { Checkbox } from "components/Checkbox";
import { UserInfo, useUserState } from "context/user";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { ErrorType } from "actions/lib/baseActions";
import { Dropdown } from "components/Forms/Dropdown";
import { SearchInput } from "components/SearchInput";
import { useSortedUsers } from "pages/UserRoles/UsersTable/useSortedUsers";
import { AttentionMessage } from "components/AttentionMessage";
import { isPermissionScopeAllOrOrganization, isPermissionScopeAssigned, isPermissionScopeNone } from "lib/permissions";
import { Permissions, RoleType } from "actions/roleActions";
import { useHasPermission } from "hooks/useHasPermission";
import { useReportUserNotesAnswersState } from "context/reportUserNotesAnswers";

interface AssignUsersTableProps {
  assignedUserIds: number[];
  allAssignedUserIds: number[];
  setAllAssignedUserIds: (userIds: number[]) => void;
  setAssignUsersError: (error: ErrorType | null) => void;
  className?: any;
}

export const AssignUsersTable = React.memo(({ 
  assignedUserIds,
  allAssignedUserIds,
  setAllAssignedUserIds,
  setAssignUsersError,
  className 
}: AssignUsersTableProps) => {

  const userInfo = useUserState();
  const reportUserNotesAnswers = useReportUserNotesAnswersState();
  const hasPermission = useHasPermission();

  const [users, setUsers] = useState<(UserInfo & RoleType)[]>();

  const {
    userSortDropdownOptions,
    onSortOptionChange,
    populateUsers,
    sortedUsers
  } = useSortedUsers(users, setUsers);

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const userId = parseInt(e.target.id);
    let updatedAllAssignedUserIds: number[];
    if (e.target.checked) {
      updatedAllAssignedUserIds = [...allAssignedUserIds, userId];
    } else {
      updatedAllAssignedUserIds = [...allAssignedUserIds.filter(id => (id !== userId))];
    }
    setAllAssignedUserIds(updatedAllAssignedUserIds);

    setAssignUsersError(null);
  }

  const assignedUsersCount = assignedUserIds?.length;
  const pluralOrSingularUserId = (assignedUsersCount > 1) ? "users" : "user";

  return (
    <div className={classNames(styles.assignUsersTableContainer, className)}>
      <AttentionMessage message="message.assignedUsers" />
      <div className={styles.assignUsersTableHeaders}>
        <p className={classNames((assignedUsersCount <= 0) && styles.assignedNone, styles.smallText)}>
          <FormattedMessage id="assignedTo" defaultMessage="Assigned to"/> {assignedUsersCount} <FormattedMessage id={pluralOrSingularUserId} defaultMessage={pluralOrSingularUserId}/>
        </p>
        <div className={styles.usersSortSearch}>
          <Dropdown dropdownId="assignUserSortOptions" key="assignUserSortOptions"
            dropdownOptions={userSortDropdownOptions}
            onSelect={onSortOptionChange}/>
          <SearchInput className={styles.searchInput} onChange={populateUsers}/>
        </div> 
      </div>

      {(sortedUsers && sortedUsers?.length === 0) && (
        <div className={styles.noDeptMessage}>
          <AttentionMessage message="message.noUsers" />
        </div>
      )}

      <div className={styles.assignUsersTable}>
        {sortedUsers?.map((user: UserInfo & RoleType) => {
          const userFullName= user.fullName;
          const userId = Number(user.id);
          const isAssigned = allAssignedUserIds?.includes(userId);
          const roleName = user.roleName;
          const isDisabled = isPermissionScopeNone(user.viewReportDetails) || (userInfo.id === userId);
          const hasFullAccess = isPermissionScopeAllOrOrganization(user.viewReportDetails);

          return (
            <div className={classNames(styles.userRow, isDisabled && styles.noViewReportDetailsPermission)} key={userId}>
              <div className={styles.userNameAndRole}>
                <div>
                  <h5>{userFullName}</h5>
                  {roleName && (
                    <p className={classNames(styles.roleName, styles.smallText)}>
                      <FormattedMessage id={roleName} defaultMessage={roleName}/>
                    </p>  
                  )}
                </div>
                {hasFullAccess && (
                  <p className={classNames(styles.userNote, styles.smallText)}>
                    <FormattedMessage id="roleDescription.hasFullAccess.description" defaultMessage="This user role has full access permissions to reports and is assigned by default."></FormattedMessage>
                  </p>
                )}

              </div>
              <Checkbox 
                name={userFullName}
                id={userId}
                value={(isPermissionScopeAssigned(user.viewReportDetails) 
                      && isAssigned) || hasFullAccess}
                disabled={isDisabled || hasFullAccess || !hasPermission(Permissions.ManageReportUserAssignment, reportUserNotesAnswers.report)}
                onChange={onCheckboxChange}
              />
            </div>
          )
        })}
      </div>
    </div>
  );
});
