import React from "react";
import styles from "./styles.module.scss";
import { FormattedMessage } from "react-intl";
import { BaseModal, ModalProps } from "../BaseModal";
import classNames from "classnames";
import _ from "lodash";
import { statusObject } from "lib/reportStatuses";

export interface ReportStatusesModalProps extends ModalProps {
  filteredStatusObjects?: statusObject[];
}

export const ReportStatusesModal = ({ 
  isOpen, 
  onClose, 
  filteredStatusObjects 
} : ReportStatusesModalProps) => {
  return (
    <BaseModal title={<FormattedMessage id="reportStatuses" defaultMessage="Report Statuses"/>}
      isOpen={isOpen} 
      showCloseButton={true}
      onClose={onClose}>

      {filteredStatusObjects?.map((reportStatus) => (
        <div className={styles.reportStatusContainer} key={reportStatus.key}>
          <h5 className={classNames(styles.reportStatusLabel, styles[reportStatus.key])}>
            <FormattedMessage {...reportStatus.intl}/>
          </h5>

          <p className={styles.reportStatusDescription}>
            <FormattedMessage {...reportStatus.descriptionIntl}/>
          </p>
        </div>
      ))}

    </BaseModal>
  );
}
