import { useCallback, useEffect, useState } from "react";
import { IntlShape, MessageDescriptor, useIntl } from "react-intl";
import { makeDropdownOptionValue, getSortProperty, getSortMethod, makeDropdownOptionText, sortPropertyAndMethod } from "components/Forms/Dropdown";
import { createAlphaNumericSorterByProperty } from "lib/sorter";
import { getOrganizations, Organization } from "actions/organizationActions";
import { getTranslatedText } from "lib/commonFunctions";
import { UserInfo, useUserState } from "context/user";
import { RoleType } from "actions/roleActions";
import { routePaths } from "App/routing";
import { matchPath, useHistory } from "react-router-dom";
import { isOrganizationTypeDummyOrg, isOrganizationTypeVesta } from "lib/permissions";

enum OrganizationSortProperties {
  Name = "name",
}

const OrganizationSortPropertiesIntl: Map<OrganizationSortProperties, MessageDescriptor> = new Map([
  [OrganizationSortProperties.Name, { id: "name", defaultMessage: "Name" }]
]);

enum OrganizationSortMethods {
  AlphabeticalAscending = "A-Z",
  AlphabeticalDescending = "Z-A"
};

const OrganizationSortMethodsIntl: Map<OrganizationSortMethods, MessageDescriptor> = new Map([
  [OrganizationSortMethods.AlphabeticalAscending, { id: "alphabeticalAscending", defaultMessage: "A-Z" }],
  [OrganizationSortMethods.AlphabeticalDescending, { id: "alphabeticalDescending", defaultMessage: "Z-A" }]
]);

const sortOrganizations = (
  unsortedOrganizations: Organization[] = [],
  sortProperty: string = "",
  sortMethod: string = ""
) => {
  const sortAlphaNumericAscending = createAlphaNumericSorterByProperty(sortProperty);
  switch (sortMethod) {
    case OrganizationSortMethods.AlphabeticalAscending:
      return [...unsortedOrganizations].sort(sortAlphaNumericAscending);
    case OrganizationSortMethods.AlphabeticalDescending:
      const sortAlphaNumericDescending = (a: any, b: any) => sortAlphaNumericAscending(b, a); // arguments reversed
      return [...unsortedOrganizations].sort(sortAlphaNumericDescending);
    default:
      return [...unsortedOrganizations].sort(sortAlphaNumericAscending);
  }
};

const organizationSortDropdownOptions = (intl: IntlShape) => [
  { sortProperty: OrganizationSortProperties.Name, sortMethod: OrganizationSortMethods.AlphabeticalAscending },
  { sortProperty: OrganizationSortProperties.Name, sortMethod: OrganizationSortMethods.AlphabeticalDescending },
].map((sortPropertyAndMethod: sortPropertyAndMethod, index) => {
  const { sortProperty = "", sortMethod = "" } = sortPropertyAndMethod;
  const sortPropertyText = getTranslatedText(intl, OrganizationSortPropertiesIntl.get(sortProperty  as OrganizationSortProperties));
  const sortMethodText = getTranslatedText(intl, OrganizationSortMethodsIntl.get(sortMethod  as OrganizationSortMethods));
  return ({
    id: index,
    value: makeDropdownOptionValue(sortPropertyAndMethod),
    name: makeDropdownOptionText(sortPropertyText, sortMethodText)
  });
});

export const useSortedOrganizations = (
  organizations: Organization[] | undefined,
  setOrganizations: (organizations: Organization[] | undefined) => void
) => {

  const intl = useIntl();
  const history = useHistory();
  const userData: UserInfo & RoleType = useUserState();

  const initialSortPropertyAndMethod: string = organizationSortDropdownOptions(intl)[0].value;
  const initialSortProperty = getSortProperty(initialSortPropertyAndMethod);
  const initialSortMethod = getSortMethod(initialSortPropertyAndMethod);

  const [selectedSortProperty, setSelectedSortProperty] = useState<string>(initialSortProperty);
  const [selectedSortMethod, setSelectedSortMethod] = useState<string>(initialSortMethod);

  // only organizations that adminPanelUser has permissions to either manage are returned by backend
  const populateOrganizations = useCallback(async (query?: string) => {
    try {
      const organizationsData = await getOrganizations(query);
      const organizationsResult = organizationsData?.data;

      const sortedOrganizations = sortOrganizations(organizationsResult, selectedSortProperty, selectedSortMethod);

      const isAssignOrganizationPage = matchPath(history.location?.pathname, {
        path: routePaths.assignOrganization,
        exact: true
      });

      setOrganizations(isAssignOrganizationPage ? sortedOrganizations?.filter(o => (!isOrganizationTypeVesta(o.organizationTypeId) && !isOrganizationTypeDummyOrg(o.organizationTypeId))) : sortedOrganizations);
    } catch (err) {
      // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
      console.log(err);
    }
  }, [selectedSortProperty, selectedSortMethod]);

  useEffect(() => {
    populateOrganizations();
  }, [userData.shareOrganization, userData.organizationId, !organizations, history.location?.pathname]);

  const onSortOptionChange = (sortPropertyAndMethod: string) => {
    const newSortProperty = getSortProperty(sortPropertyAndMethod);
    const newSortMethod = getSortMethod(sortPropertyAndMethod);
    setSelectedSortProperty(newSortProperty);
    setSelectedSortMethod(newSortMethod);

    const sortedOrganizations = sortOrganizations(organizations, newSortProperty, newSortMethod);
    setOrganizations(sortedOrganizations);
  };

  return {
    organizationSortDropdownOptions: organizationSortDropdownOptions(intl),
    populateOrganizations,
    onSortOptionChange,
    sortedOrganizations: organizations // organizations in state are sorted
  }
}
