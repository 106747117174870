import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import { getReportStats, ReportStatsType } from "actions/reportActions";
import { FormattedMessage } from "react-intl";
import { HorizontalBarGraph } from "components/HorizontalBarGraph";
import { LineGraph, LineGraphData } from "components/LineGraph";
import { StatCard } from "./StatCard";
import classNames from "classnames";
import { DownloadIcon } from "components/Icons";
import { useDownloadStatsURL } from "hooks/useDownloadStatsURL";
import { useLocation } from "react-router-dom";
import { routePaths } from "App/routing";
import { useBarChartData } from "./useBarChartData";
import { AttentionMessage } from "components/AttentionMessage";
import { SingleDatePicker } from "components/Forms/SingleDatePicker";
import { isOrganizationTypeCrisisCenter, isOrganizationTypeLawEnforcement, isOrganizationTypeVesta } from "lib/permissions";
import { useUserState } from "context/user";

export interface StatsGroupProps {
  className?: string;
  isDashboard?: boolean;
}

export const StatsGroup = ( {className, isDashboard = false} : StatsGroupProps) => {  
  const today = new Date();
  const userInfo = useUserState();
  
  const lastTwoWeeks = new Date();
  lastTwoWeeks.setDate( today.getDate() - 14 );

  const [startDate, setStartDate] = useState<Date>(lastTwoWeeks);
  const [endDate, setEndDate] = useState<Date>(today);

  const [stats, setStats] = useState<ReportStatsType>();
  const [totalSubmitted, setTotalSubmitted] = useState<Array<LineGraphData>>();

  const {
    consentBarChartData,
    offendersBarChartData,
    policeStatusesBarChartData,
    supportCenterStatusesBarChartData,
    consentData,
    setConsentData,
    offendersData,
    setOffendersData,
    policeStatusesData,
    setPoliceStatusesData,
    supportCenterStatusesData,
    setSupportCenterStatusesData
  } = useBarChartData();

  const populateReportStats = async () => {
    try {
      // the backend function corresponding to this checks req.user.viewReportStatistics and filters on req.user.organizationId if required

      // make sure startDate and endDate are set to 00:00:00 (day start), 23:59:59 (day end) respectively
      const startDateFullStart = new Date(startDate.setHours(0,0,0,0));
      const endDateFullEnd = new Date(endDate.setHours(23,59,59,59));

      const results = await getReportStats(startDateFullStart, endDateFullEnd);
      const reportStats = results?.data;
      
      if (reportStats) {
        setStats(reportStats);
        setTotalSubmitted(reportStats.totalSubmitted);
        setConsentData(
          consentBarChartData(
            reportStats.totalSupportConsent, 
            reportStats.totalPoliceConsent, 
            reportStats.totalCampusConsent,
            reportStats.totalBothPoliceAndSupportConsent, 
            reportStats.totalBothPoliceAndCampusConsent,
            reportStats.totalBothSupportAndCampusConsent,
            reportStats.totalPoliceSupportAndCampusConsent, 
            reportStats.totalNeitherPoliceNorSupportNorCampusConsent
          )
        );
        setOffendersData(offendersBarChartData(
            reportStats.totalKnownOffenders, 
            reportStats.totalNamedOffenders, 
            reportStats.totalUnknownOffenders, 
            reportStats.totalNoResponseOffender
          )
        );
        setPoliceStatusesData(policeStatusesBarChartData(
            reportStats.totalPoliceUnassigned, 
            reportStats.totalPoliceAssignedUnactioned,
            reportStats.totalActiveOngoing,  
            reportStats.totalClearedByCharge, 
            reportStats.totalClearedInsufficientEvidence, 
            reportStats.totalClearedNoChargeableSuspectIdentified, 
            reportStats.totalClearedComplainantDeclinesToProceed, 
            reportStats.totalClearedOtherwise
          )
        );
        setSupportCenterStatusesData(supportCenterStatusesBarChartData(
            reportStats.totalSupportCenterUnassigned, 
            reportStats.totalSupportCenterAssignedUnactioned, 
            reportStats.totalActiveAppointmentBooked, 
            reportStats.totalActiveWaitlisted, 
            reportStats.totalActiveCounselling, 
            reportStats.totalDischargedNoContact, 
            reportStats.totalDischargedChangedMind, 
            reportStats.totalDischargedReferredElsewhere
          )
        );
      }

    } catch (err) {
      // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
      console.log(err);
    }
  };

  useEffect(() => {
    populateReportStats();
  }, [startDate, endDate]);

  const isDashboardPage = (useLocation().pathname === routePaths.dashboard);

  const statsURL = (useDownloadStatsURL())(stats);

  const selectStartDate = (value: Date) => { 
    setStartDate(value); 
  };

  const selectEndDate = (value: Date) => { 
    setEndDate(value); 
  };

  return (
    <>
      {!stats && (
        <div className={styles.attMessageContainer}>
          <AttentionMessage message={"error.general"}/>
        </div>
      )}
      
      {stats && (
        <div className={classNames(isDashboard && styles.isDashboard, styles.statsGroup, className)}>

          <div className={styles.statsHeader}>
            <h3 className={styles.title}><FormattedMessage id="stats.overallStatistics" defaultMessage="Overall Statistics"/></h3>
            <p className={classNames(styles.subtitle, styles.smallText)}><FormattedMessage id="stats.statusSubtitle" defaultMessage="*Encompasses all reports submitted across all organizations"/></p>
            <DownloadIcon href={statsURL} 
              downloadUrl={`stats.csv`} 
              className={styles.downloadComponent}
              iconClassName={styles.downloadComponentIcon}>
                
              <FormattedMessage id="buttons.download.csv" defaultMessage="Download CSV"/>
            </DownloadIcon>
          </div>

          <div className={styles.datePickerHeader}>
            <p className={styles.rangeLabel}>Range: </p>
            <div className={styles.datePickers}>
              <div className={styles.dateSelector}>
                <p className={styles.dateSelectorLabel}>
                  <FormattedMessage id="stats.startDate" defaultMessage="Start date"/>:
                </p>

                <SingleDatePicker inputName="startDate" 
                  key="startDate"
                  showTimeSelect={false}
                  value={startDate} 
                  onChange={selectStartDate}
                  disableDate={endDate}
                  directionToDisableDates={'after'}
                />
              </div>
              
              <div className={styles.dateSelector}>
                <p className={styles.dateSelectorLabel}>
                  <FormattedMessage id="stats.endDate" defaultMessage="End date"/>:
                </p>

                <SingleDatePicker inputName="endDate" 
                  key="endDate"
                  showTimeSelect={false}
                  value={endDate} 
                  onChange={selectEndDate}
                  disableDate={startDate}
                  directionToDisableDates={'before'}
                />
              </div>
            </div>
          </div>


          <div className={styles.nonGraphs}>
            <StatCard 
              className={styles.totalReports}
              label={<FormattedMessage id="stats.totalSubmittedReports" defaultMessage="Total Submitted Reports"/>}
              statNumber={stats.totalReports || 0}
              displayExternalLinkIcon={isDashboardPage ? true : false}/>

            <StatCard className={styles.activeCasesPercentage}
              label={<FormattedMessage id="stats.activeCasesPercentage" defaultMessage="Active reports"/>}
              statNumber={stats.totalActiveOngoing || 0}
              statPercentage={Math.round((stats.totalActiveOngoing || 0) / stats.totalReports * 100) || 0}
              displayPercentage={true}/>

            <StatCard 
              className={styles.savedButNotSubmittedPercentage}
              label={<FormattedMessage id="stats.savedButNotSubmittedPercentage" defaultMessage="Saved but not submitted"/>}
              statNumber={stats.totalSavedNotSubmittedReports}
              displayPercentage={false}/>
          </div>

          <div className={styles.statGraphs}>
            <div className={styles.trendGraph}>
              <LineGraph 
                data={totalSubmitted}
                />
            </div>
          </div>

          <div className={classNames(styles.secondaryStats)}>
              <div className={styles.horizontalBarGraphs}>
                <div className={styles.counsellorPoliceConsent}>
                  <HorizontalBarGraph data={consentData} />
                </div>
              </div>
              <div className={styles.horizontalBarGraphs}>
                <div className={styles.knownNamedOffenders}>
                  <HorizontalBarGraph data={offendersData} />
                </div>
              </div>
          </div>

          <div className={classNames(styles.secondaryStats)}>
            {(isOrganizationTypeVesta(userInfo.organizationTypeId) || isOrganizationTypeLawEnforcement(userInfo.organizationTypeId)) && (
              <div className={styles.horizontalBarGraphs}>
                <div className={styles.policeStatusesData}>
                  <HorizontalBarGraph data={policeStatusesData} />
                </div>
              </div>
            )}
            {(isOrganizationTypeVesta(userInfo.organizationTypeId) || isOrganizationTypeCrisisCenter(userInfo.organizationTypeId)) && (
              <div className={styles.horizontalBarGraphs}>
                <div className={styles.supportCenterStatusesData}>
                  <HorizontalBarGraph data={supportCenterStatusesData} />
                </div>
              </div>
            )}
          </div>
          
        </div>
      )}
    </>
  );
}
