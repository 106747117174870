import React, { useState } from "react";
import { deleteAdminPanelUser } from "actions/userActions";
import { PencilIcon, DeleteIcon } from "components/Icons";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import { routePaths } from "App/routing";
import styles from './styles.module.scss';
import classNames from "classnames";
import { ErrorGeneralModal } from "components/Modals/ErrorGeneralModal";
import { YesNoModal } from "components/Modals/YesNoModal";
import { UserInfo, useUserState } from "context/user";
import { SearchInput } from "components/SearchInput";
import { Dropdown } from "components/Forms/Dropdown";
import { useSortedUsers } from "./useSortedUsers";
import { LinkButton, ButtonStyle } from "components/Buttons";
import { AttentionMessage } from "components/AttentionMessage";
import { RoleType } from "actions/roleActions";


export interface UsersTableProps {
  className?: string;
}

export const UsersTable = ( { className } : UsersTableProps ) => {

  const userInfo = useUserState();

  const [users, setUsers] = useState<(UserInfo & RoleType)[]>();

  const [errorGeneralModalIsOpen, setErrorGeneralModalIsOpen] = useState(false);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string | number | undefined>();

  const openErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(true);
  }

  const closeErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(false);
  };

  const {
    userSortDropdownOptions,
    populateUsers,
    onSortOptionChange
  } = useSortedUsers(users, setUsers);

  const deleteUser = async (userId: string | number | undefined) => {
    if (userId) {
      try {
        const deleteAdminPanelUserResult = await deleteAdminPanelUser(userId);
        if (deleteAdminPanelUserResult) { // backend returns true if successfully deleted
          setUsers(users?.filter(u => (u.id !== userId)));
        }
      } catch (err) {
        openErrorGeneralModal();
      }
    }
    setConfirmDeleteModalIsOpen(false);
  };

  return (
    <>
      <div className={classNames(styles.usersTable, className)}>
        <div className={styles.usersFilterSortSearch}>
          <div className={styles.addButtonContainer}>
            <LinkButton to={{ pathname: routePaths.addUser, state: {} }}
              style={ButtonStyle.Secondary}
              className={styles.addButton}>
              <span className={styles.addButtonText}><FormattedMessage id="buttons.add" defaultMessage="Add"/></span>
            </LinkButton>
          </div>
          <Dropdown dropdownId="usersTableSortOptions" key="usersTableSortOptions"
            dropdownOptions={userSortDropdownOptions}
            className={styles.userTableDropdown}
            onSelect={onSortOptionChange}/>
          <SearchInput onChange={populateUsers}/>
        </div>

        <div className={styles.usersTableContainer}>
          <div className={classNames(styles.usersTableRow, styles.rowHeader)}>
            <p className={styles.fullName}><FormattedMessage id="user" defaultMessage="User"/></p>
            <p className={styles.organization}><FormattedMessage id="organization" defaultMessage="Organization"/></p>
            <p className={styles.roleName}><FormattedMessage id="role" defaultMessage="Role"/></p>
            <p className={styles.editUser}><FormattedMessage id="edit" defaultMessage="Edit"/></p>
            <p className={styles.deleteUser}><FormattedMessage id="delete" defaultMessage="Delete"/></p>
          </div>

          {(!users || (users?.length === 0)) && (
            <div className={styles.noUsersMessage}>
              <AttentionMessage message="message.noUsers"/>
            </div>
          )}

          <div className={styles.userRows}>
            {users?.filter(u => (u.id !== userInfo.id))?.map((user: UserInfo & RoleType) => {
              const editUserPath = generatePath(routePaths.editUser, { editUserId: user.id });
              return (
                <div className={classNames(styles.usersTableRow, styles.rowBorder)} key={user.id}>
                  <h5 className={styles.fullName}>{user.fullName}</h5>
                  <span className={styles.organization}>{user.organizationName}</span>
                  <span className={styles.roleName}>{user.roleName}</span>
                  <PencilIcon to={{ pathname: editUserPath, state: {user} }}
                    className={styles.editUser}/>
                  <DeleteIcon className={styles.deleteUser} 
                    onClick={() => { 
                      setUserIdToDelete(user.id);
                      setConfirmDeleteModalIsOpen(true);
                    }}/>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <YesNoModal 
          isOpen={confirmDeleteModalIsOpen} 
          message={'modal.areYouSureDelete'}
          onClose={()=>{
            setConfirmDeleteModalIsOpen(false);
          }}
          onYesClick={()=>{
            return deleteUser(userIdToDelete); 
          }}
          onNoClick={()=>{
            setConfirmDeleteModalIsOpen(false);
          }} />
      <ErrorGeneralModal isOpen={errorGeneralModalIsOpen} onClose={closeErrorGeneralModal}/>
    </>
  );
}
