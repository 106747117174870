import { useState, useEffect, useCallback } from 'react'
import { createAdminPanelUser, updateAdminPanelUser, getAdminById } from 'actions/userActions';
import { Organization, OrganizationTypeIds } from 'actions/organizationActions';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { matchPath, useHistory, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import { RoleType } from 'actions/roleActions';
import { RoleDescription } from './RoleDescription';
import Layout from 'Layout';
import { ConfirmationModal } from 'components/Modals/ConfirmationModal';
import { AddOrEditUserForm } from './AddOrEditUserForm';
import { routePaths } from 'App/routing';
import { UserInfo } from 'context/user';
import { ErrorType } from 'actions/lib/baseActions';


export interface AddOrEditUserFormFields {
  fullName?: string;
  email?: string;
  role?: RoleType;
  organizationId?: number;
  organizationTypeId?: OrganizationTypeIds;
  organizations?: Organization[];
};

export const AddOrEditUserErrors = {
  UserAlreadyExistsError: { id: 'userAlreadyExists', intlId: 'addOrEditUser.error.userAlreadyExists', defaultMessage: 'A user for the provided email address already exists' },
  GeneralAddError: { id: 'add', intlId: 'addOrEditUser.error.add', defaultMessage: 'There was an error creating the user. Please try again later. If you continue to have problems, please contact support.' },
  GeneralEditError: { id: 'edit', intlId: 'addOrEditUser.error.edit', defaultMessage: 'There was an error editing the user. Please try again later. If you continue to have problems, please contact support.' }
};

export const AddOrEditUser = () => {

  const history = useHistory();
  const currentLocation = history.location;
  const currentPath = currentLocation.pathname;
  const currentState = currentLocation.state as { user: UserInfo & RoleType };
  const userToEdit = currentState?.user; 

  const { editUserId } = useParams<{ editUserId: string }>();
  const isEditUserPage = !!editUserId;

  const isAddUserPage = matchPath(currentPath, {
    path: routePaths.addUser,
    exact: true
  });

  const [addOrEditUserError, setAddOrEditUserError] = useState<ErrorType | null>(null);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const getAndSetUserToEdit = useCallback(async (editUserId: string | number) => {
    try {
      const userResult = await getAdminById(editUserId);
      history.replace({
        state: {
          user: userResult.data
        }
      });
    } catch(err) {
      setAddOrEditUserError(AddOrEditUserErrors.GeneralEditError);
    }
  }, []);

  useEffect(() => {
    if (isEditUserPage && editUserId && !(Number(editUserId) === userToEdit?.id)) {
      getAndSetUserToEdit(editUserId);
    }
  }, [editUserId]);

  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  };

  //Formik prop values - initialValues, validate, onSubmit
  const initialValues = { 
    fullName: (isAddUserPage || !userToEdit?.fullName) ? "" : userToEdit.fullName, 
    email: (isAddUserPage || !userToEdit?.email) ? "" : userToEdit.email
  };

  const validate = (values: AddOrEditUserFormFields) => { 
    const errors: FormikErrors<AddOrEditUserFormFields> = {}; 

    const fullName = values.fullName || userToEdit?.fullName;
    if (!fullName) {
      errors.fullName= 'Required';
    }

    const email = values.email || userToEdit?.email;
    if (!email) {
      errors.email = 'Required';
    } else if (
      !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g.test(email.toLowerCase())
    ) {
      errors.email = 'Invalid email address';
    }

    if (!values.role?.id) {
      errors.role = 'Select a user role';
    }

    return errors;
   };

   const onSubmit = async (values: AddOrEditUserFormFields, { setSubmitting }: FormikHelpers<AddOrEditUserFormFields>) => {
    const { fullName, email, role, organizationId }: AddOrEditUserFormFields = values;
    const addOrEditUserObj = { fullName, email: email?.toLowerCase(), roleId: role?.id, organizationId };

    try {
      if (isAddUserPage) {
        await createAdminPanelUser(addOrEditUserObj);
      } else {
        await updateAdminPanelUser(addOrEditUserObj);
      }

      setConfirmationModalIsOpen(true);
      setSubmitting(false);
    } catch (err) {
      setAddOrEditUserError(err.body);
      setSubmitting(false);
      throw err;
    }
  };
  
  return (
    <Layout>
      <div className={styles.addOrEditUserPage}>
        <h3>
          {isAddUserPage 
            ? <FormattedMessage id="addNewUser" defaultMessage="Add New User"/> 
            : <FormattedMessage id="editUser" defaultMessage="Edit User"/>}
        </h3>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {({ values, resetForm }) => (
            <div className={styles.addOrEditUserPageContent}>
              <div className={styles.middle}>
                <AddOrEditUserForm 
                  addOrEditUserError={addOrEditUserError}
                  setAddOrEditUserError={setAddOrEditUserError} />
              </div>

              <div className={styles.right}>
                {values.role ? (<RoleDescription role={values.role}/>) : (
                  <p className={styles.selectRoleMessage}>
                    <FormattedMessage id="message.selectUserRole" defaultMessage="Select a user role to see which actions that role has permissions for."/>
                  </p>
                )}
              </div>

              <ConfirmationModal 
                isOpen={confirmationModalIsOpen}
                onClose={() => { closeConfirmationModal(); resetForm(); }}
                onCloseRoute={routePaths.userRoles}
              >
                <p className={styles.userSuccessfullyAddedOrEdited}>
                  {isAddUserPage 
                    ? (<FormattedMessage id="modal.confirm.userAdded" defaultMessage="User has been successfully created"/>) 
                    : (<FormattedMessage id="modal.confirm.userEdited" defaultMessage="User has been successfully edited"/>)}
                </p>
              </ConfirmationModal>
            </div>
          )}
        </Formik>
      </div> 
    </Layout>
  );
};
