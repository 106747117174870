import { getReportAnswers, getReportContactInfoAnswers, getSurvivorAndReportById } from "actions/reportActions";
import { getReportNotes } from "actions/noteActions";
import { useMergeReportUserNotesAnswersState, useUserState } from 'context';
import { useApiAnswersToAnswersMap } from "./useApiAnswersToAnswersMap";
import { ReportUserNotesAnswers } from "context/reportUserNotesAnswers";
import { useLocation } from "react-router-dom";
import { UserInfo } from "context/user";
import { useHasPermission } from "./useHasPermission";
import { Permissions, RoleType } from "actions/roleActions";

export const usePopulateReportUserNotesAnswers = () => {
  const userInfo: UserInfo & RoleType = useUserState();
  const mergeReportUserNotesAnswers = useMergeReportUserNotesAnswersState();
  const apiAnswersToAnswersMap = useApiAnswersToAnswersMap();
  const hasPermission = useHasPermission();
  const location: any = useLocation();
  const populateReportUserNotesAnswers = async (
    reportId: string | number, 
    getNotes: boolean,
    onlyReportInfo?: boolean
  ) => {
    const reportUserNotesAnswers: ReportUserNotesAnswers = {};

    try {
      const reportFromState = location.state?.report;
      if ( reportFromState && (reportFromState.id === reportId) ) {
        reportUserNotesAnswers.report = reportFromState;
      } else {
        const reportResult = await getSurvivorAndReportById(reportId);
        reportUserNotesAnswers.report = reportResult?.data;
        reportUserNotesAnswers.reportUser = reportResult?.data;
        
        if (hasPermission(Permissions.ShareOrganization, reportUserNotesAnswers.report) && userInfo.organizationId) {
          // if logged in user has permission to assign org, their org should be assigned for that report 
          // this is a redundancy - newly created reports should auto-assign vesta admin org ids on backend
          const updatedAssignedOrganizations = [
            ...reportUserNotesAnswers.report?.assignedOrganizations || [], 
            { id: Number(userInfo.organizationId), name: userInfo.organizationName, type: userInfo.organizationTypeId }
          ];
          reportUserNotesAnswers.report = {
            ...reportUserNotesAnswers.report, 
            assignedOrganizations: updatedAssignedOrganizations
          }
        }
      };

      try {
        const answersResult = onlyReportInfo ? await getReportContactInfoAnswers(reportId) : await getReportAnswers(reportId);
      reportUserNotesAnswers.answers = apiAnswersToAnswersMap(answersResult.data);
      } catch (err) {
        reportUserNotesAnswers.answers = apiAnswersToAnswersMap([])
      }

      if (getNotes) {
        try {
        const notesResult = await getReportNotes(reportId);
        reportUserNotesAnswers.notes = notesResult.data;
        } catch(err) {
          reportUserNotesAnswers.notes = [];
 
        }
      }
    } catch (err) {
      // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
      console.log(err);
    }

    mergeReportUserNotesAnswers(reportUserNotesAnswers);
  }

  return populateReportUserNotesAnswers;
};
