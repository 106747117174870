import { Form, useFormikContext } from 'formik';
import { Button, ButtonStyle } from '../../../components/Buttons';
import { TextField, TextFieldTypes, TextFieldEventType } from '../../../components/Forms/TextField';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import { ErrorType } from '../../../actions/lib/baseActions';
import { TwoFAFormFields } from '../';

interface TwoFAFormProps {
  twoFAError: ErrorType | null;
  setTwoFAError: (value: ErrorType | null) => void;
}

export const TwoFAForm = ({ twoFAError, setTwoFAError }: TwoFAFormProps) => {

  const { values, handleChange, isSubmitting, setSubmitting, errors } = useFormikContext<TwoFAFormFields>();

  const onTextFieldChange = (e: TextFieldEventType) => {
    handleChange(e);
    setTwoFAError(null); 
    setSubmitting(false); 
  };

  return (
    <Form className={styles.twoFAForm}>
      <TextField type={TextFieldTypes.Text} 
        name="twoFaCode" 
        placeholder=""
        value={values.twoFaCode}
        onChange={onTextFieldChange}
        className={classNames(styles.inputField, errors.twoFaCode && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.twoFaCode}/>

      <Button loading={isSubmitting} 
        disabled={isSubmitting} 
        type="submit"
        style={ButtonStyle.Primary}
        className={styles.accessPortalButton}>
        <FormattedMessage id="buttons.VerifyTwoFA" defaultMessage="Verify Code"/>
      </Button>

      {_.isEmpty(errors) && twoFAError && (
        <div className={styles.errorMessage}>
          <FormattedMessage id={twoFAError.intlId} defaultMessage={twoFAError.defaultMessage || "Sorry, there was an error."}/>
        </div>
      )}
    </Form>
  );
};
