import React from 'react'
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import { RoleType, Permissions, PermissionScope } from 'actions/roleActions';
import { GreenCheckIcon, CrossIcon } from 'components/Icons';
import classNames from 'classnames';


interface RoleDescriptionProps {
  role: RoleType | undefined;
}

export const RoleDescription = ({ role }: RoleDescriptionProps) => {

  const roleName = role?.name;

  return (
    <div className={styles.roleDescription}>
      <div className={styles.roleTitle}>
        <h5 className={styles.subheader}><FormattedMessage id="roleDescription" defaultMessage="Role Description"/>: </h5> 
        <h5>{roleName && <FormattedMessage id={roleName} defaultMessage={roleName}/>}</h5>
      </div> 
      <div className={styles.permissionsContainer}>
        {Object.values(Permissions).map((permission) => {
          const rolePermission = role?.[permission];
          const isPermissionScopeNone = (rolePermission === PermissionScope.None);
          const permissionScopeIntlId = ( rolePermission && (rolePermission in PermissionScope) ) ? `permissionScope.${rolePermission}` : ``;
          return (
            <div className={classNames(styles.permission, isPermissionScopeNone && styles.greyOpacity)} key={permission}>
              {isPermissionScopeNone ? <CrossIcon className={styles.greenCheckIcon}/> : <GreenCheckIcon className={styles.crossIcon}/>}
              <div className={styles.permissionContainer}>
                <h2>
                  <span>{<FormattedMessage id={`roleDescription.${permission}.title`} defaultMessage={permission}/>}: </span> 
                  <span className={styles.permissionScope}>
                    {rolePermission && <FormattedMessage id={permissionScopeIntlId} defaultMessage={rolePermission}/>}
                  </span>
                </h2>
                <p><FormattedMessage id={`roleDescription.${permission}.description`}/></p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
