import React from "react";
import { BaseModal, BaseModalProps } from "components/Modals/BaseModal";
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { GreenCheckIcon } from "../../Icons";
import { LinkButton, ButtonStyle, Button } from "components/Buttons/Button";

interface ConfirmationModalProps extends BaseModalProps {
  onCloseRoute?: string;
}

export const ConfirmationModal = ({ isOpen, onClose, message, onCloseRoute, children } : ConfirmationModalProps) => {
  return (
    <BaseModal className={styles.confirmationModal} isOpen={isOpen} onClose={onClose}>
      <div className={styles.confirmationModalContent}>
        <GreenCheckIcon className={styles.greenCheckIcon}/>
        {message && (<p className={styles.confirmationMessage}><FormattedMessage id={message}/></p>)}
        {children}
       
        <div className={styles.confirmationButtonContainer}>
          {onCloseRoute ? (
            <LinkButton to={{pathname: onCloseRoute}} style={ButtonStyle.Primary} className={styles.confirmationButton}>
              <FormattedMessage id="common.okay" defaultMessage="Okay"/> 
            </LinkButton> ) : (
            <Button onClick={() => onClose()} style={ButtonStyle.Primary} className={styles.confirmationButton}>
              <FormattedMessage id="common.okay" defaultMessage="Okay"/> 
            </Button>
          )}
        </div>
      </div>
    </BaseModal>
  );
}
