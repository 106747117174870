import React from "react";
import styles from './styles.module.scss';
import {ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Line, LineChart} from 'recharts';
import { FormattedMessage, useIntl } from "react-intl";

export interface LineGraphProps {
  data?: Array<LineGraphData>;
}

export interface LineGraphData {
  value: number;
  date: string;
  label: string;
}

export const LineGraph = ({
  data,
}: LineGraphProps) => {
  const intl = useIntl();

  return (
    <>
      <ResponsiveContainer>
        <LineChart 
          width={600} 
          height={300} 
          data={data}
          className={styles.lineChart}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Legend wrapperStyle={{ paddingTop: "15px", paddingLeft: "0px", fontSize: "2px" }}/>
          <XAxis 
            dataKey="label"
            tickCount={0}
          />
          <YAxis 
            allowDecimals={false}/>
          <CartesianGrid 
            strokeDasharray="3 3"/>
          <Tooltip/>
          <Line name={intl.formatMessage({ id: "stats.numSubmittedReports", defaultMessage: "Number of submitted reports" })} 
            type="monotone" 
            dataKey="value" 
            stroke="#8884d8" 
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

