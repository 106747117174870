import { useTranslator } from "hooks/useTranslator";
import { ReportLocationType } from "actions/reportActions";
import { dateFormatter, DateFormStyle } from "lib/commonFunctions";

enum csvStatsKeys {
  date = "Date",
  street = "Address",
  type = "Type"
}

export const useDownloadLocationsURL = () => {
  const translator = useTranslator();
  
  return (statsData: Array<ReportLocationType>) => {

    const csvStatsLabels: string[] = Object.values(csvStatsKeys).map((csvStatsKey) => {
      return translator(csvStatsKey, 'stats');
    });
  
    const csvStatsData: string[][] = [...statsData.map((sd: ReportLocationType) => { return [sd.date ? (dateFormatter(sd.date, DateFormStyle.TextDateTime) || ''): '', `"${sd.address}"`, sd.type || '']; })];
 
    const csvStats: string = [ 
      csvStatsLabels, ...csvStatsData
    ].map((stringArray: string[]): string => {
      return stringArray.join(',');
    }).join('\r\n');

    return URL.createObjectURL( new Blob([csvStats], { type: "text/csv;charset=utf-8;" }) );
  }
};
