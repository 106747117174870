import React , { FC, useState, useEffect } from 'react'
import { loginAdminPanelUser, userIsAuthorized } from 'actions/userActions';
import { useAppState, useMergeAppState, useMergeUserState } from 'context';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { routePaths } from 'App/routing';
import { FormattedMessage } from 'react-intl';
import { VestaLogo } from 'components/Icons';
import { LoginForm } from './LoginForm';
import { RoleDescription } from 'pages/AddOrEditUser/RoleDescription';
import { ErrorType } from 'actions/lib/baseActions';
import classNames from 'classnames';


export interface LoginFormFields {
  email?: string;
  password?: string;
};

export const LoginErrors = {
  InvalidCredentials: { id: 'invalidCredentials', intlId: 'login.error.invalidCredentials', defaultMessage: 'Sorry, but the Email or Password you entered in was wrong. Please try again, or contact us at support.' },
  General: { id: 'general', intlId: 'login.error.general', defaultMessage: 'There was an error with logging in. Please try again later. If you continue to have problems, please contact support.' },
};

const validationErrors = (values: LoginFormFields) => {
  const errors: LoginFormFields = {}; 

  //TODO: determine with Nichie if these are the error messages we want, then insert their translations, https://trello.com/c/8Yb03bqT/626-login-errors-french-translation
  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Less than 8 characters';
  }

  return errors;
};

export const Login : FC = () => {
  const appState = useAppState();
  const mergeAppState = useMergeAppState();
  const mergeUserData = useMergeUserState();

  const [loginError, setLoginError] = useState<ErrorType | null>(null);

  const history: any = useHistory();

  const role = history.location.state?.role;

  useEffect(() => {
    if (appState.Authenticated) {
      history.push(routePaths.home);
    }
  }, []);

  const initialValues = { 
    email: '', 
    password: '' 
  };

  const onSubmit = async (values: LoginFormFields, { setSubmitting }: FormikHelpers<any>) => {
    const { email, password }: LoginFormFields = values;
    const loginAdminPanelUserObj = { email, password };
    mergeAppState({ Authenticating: true });
    setSubmitting(true);
    try {
      const loginResult = await loginAdminPanelUser(loginAdminPanelUserObj);
      if (!loginResult.data){
        loginResult.data = {};
      }
      loginResult.data.email = values.email;
      if (loginResult?.data) {
        mergeUserData(loginResult.data);
      }
      mergeAppState({ Authenticated: false, Authenticating: false });
      setSubmitting(false);
      
      history.push(routePaths.twoFA);
    } catch (err) {
      setLoginError(err.body);
      mergeAppState({ Authenticated: false, Authenticating: false });
      setSubmitting(false);
      throw err;
    }
  }

  return (
    <div className={classNames(styles.loginPage, role && styles.showRolePermissions)}>
      <div className={styles.loginPageContent}>
          <div className={styles.left}>
            <div className={styles.vestaLogo}><VestaLogo/></div>

            <h3 className={classNames(styles.header, styles.subheader)}>
              <FormattedMessage id="login.welcome" defaultMessage="Welcome to the Vesta Admin Portal."/> 
              <br/>
              <FormattedMessage id="login.loginWithUsernameAndPassword" defaultMessage="Login with your provided username and password."/>
            </h3>

            <Formik
              initialValues={initialValues}
              validate={validationErrors}
              onSubmit={onSubmit}
            >
            {() => (
              <LoginForm loginError={loginError}
                setLoginError={setLoginError}/>
            )}
            </Formik>
          </div>
        </div>
        {role && (
          <div className={styles.right}>
            <RoleDescription role={role}/>
          </div>
         )}
    </div>
  );
};
