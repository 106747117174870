import { useCallback, useEffect, useState } from "react";
import { IntlShape, MessageDescriptor, useIntl } from "react-intl";
import { makeDropdownOptionValue, getSortProperty, getSortMethod, makeDropdownOptionText, sortPropertyAndMethod } from "components/Forms/Dropdown";
import { createAlphaNumericSorterByProperty } from "lib/sorter";
import { getReports, ReportData, ReportInfo } from "actions/reportActions";
import { Organization } from "actions/organizationActions";
import { getTranslatedText } from "lib/commonFunctions";
import { filterStatusObjects, filterStatus, reportStatuses, ReportStatusType, reportStatusTypes, statusObject } from "lib/reportStatuses";
import { OrganizationTypeIds } from "actions/organizationActions";
import { useUserState } from "context";
import _ from "lodash";
import { isPermissionScopeAllOrOrganization } from "lib/permissions";
import { PermissionScope } from "actions/roleActions";

enum ReportSortProperties {
  ReportKey = "reportKey",
  AssignedOrganizations = "assignedOrganizations",
  AssignedDepartments = "assignedDepartments",
  AssignedUsers = "assignedUsers",
  // a subset of statuses below are displayed based on org type of logged in user
  Unassigned = "unassigned",
  AssignedUnactioned = "assignedUnactioned",
  ActiveOngoing = "activeOngoing",
  ClearedByCharge = "clearedByCharge",
  ClearedInsufficientEvidence= "clearedInsufficientEvidence",
  ClearedNoChargeableSuspectIdentified="clearedNoChargeableSuspectIdentified",
  ClearedComplainantDeclinesToProceed="clearedComplainantDeclinesToProceed",
  ClearedOtherwise = "clearedOtherwise",
  ActiveAppointmentBooked = "activeAppointmentBooked",
  ActiveWaitlisted = "activeWaitlisted",
  ActiveCounselling = "activeCounselling",
  DischargedNoContact = "dischargedNoContact",
  DischargedChangedMind = "dischargedChangedMind",
  DischargedReferredElsewhere = "dischargedReferredElsewhere"
}

const ReportSortPropertiesIntl = (organizationTypeId?: OrganizationTypeIds) => {
  const reportSortPropertiesIntlMap: Map<ReportSortProperties, MessageDescriptor> = new Map([
    [ReportSortProperties.ReportKey, { id: "reportKey", defaultMessage: "Report Key" }],
    [ReportSortProperties.AssignedOrganizations, { id: "assignedOrganizations", defaultMessage: "Assigned Organizations" }],
    [ReportSortProperties.AssignedDepartments, { id: "assignedDepartments", defaultMessage: "Assigned Departments" }],
    [ReportSortProperties.AssignedUsers, { id: "assignedUsers", defaultMessage: "Assigned Users" }]
  ]);
  filterStatusObjects({organizationTypeId, statusObjects: reportStatuses})?.forEach((status: statusObject) => {
    reportSortPropertiesIntlMap.set(status.key as ReportSortProperties, status.intl);
  });
  return reportSortPropertiesIntlMap;
};

const defaultOrganization: Organization = {
  id: 1,
  name: "Vesta",
  reportStatusName: ReportSortProperties.Unassigned,
  reportStatusDisplayName: "Unassigned"
}

enum ReportSortMethods {
  Newest = "newest",
  Oldest = "oldest",
  AlphabeticalAscending = "A-Z",
  AlphabeticalDescending = "Z-A",
  FilterByStatus = "filterByStatus"
};

const ReportSortMethodsIntl: Map<ReportSortMethods, MessageDescriptor> = new Map([
  [ReportSortMethods.Newest, { id: "newest", defaultMessage: "Newest" }],
  [ReportSortMethods.Oldest, { id: "oldest", defaultMessage: "Oldest" }],
  [ReportSortMethods.AlphabeticalAscending, { id: "alphabeticalAscending", defaultMessage: "A-Z" }],
  [ReportSortMethods.AlphabeticalDescending, { id: "alphabeticalDescending", defaultMessage: "Z-A" }]
]);

const sortDateAscending = (a: ReportData, b: ReportData) => {
  if (a.dateSubmitted && b.dateSubmitted) {
    return new Date(b.dateSubmitted).valueOf() - new Date(a.dateSubmitted).valueOf();
  }
  return 0;
}

const sortDateDescending = (a: ReportData, b: ReportData) => sortDateAscending(b, a); // arguments reversed

const sortObjectsByReportSortMethod = (
  unsortedObjects: any[] = [],
  sortMethod: string = "",
  sortAlphaNumericAscending: (a: any, b: any) => any
) => {
  switch (sortMethod) {
    case ReportSortMethods.Newest:
      return [...unsortedObjects].sort(sortDateAscending);
    case ReportSortMethods.Oldest:
      return [...unsortedObjects].sort(sortDateDescending);
    case ReportSortMethods.AlphabeticalAscending:
      return [...unsortedObjects].sort(sortAlphaNumericAscending);
    case ReportSortMethods.AlphabeticalDescending:
      const sortAlphaNumericDescending = (a: any, b: any) => sortAlphaNumericAscending(b, a); // arguments reversed
      return [...unsortedObjects].sort(sortAlphaNumericDescending);
    default:
      return [...unsortedObjects].sort(sortDateAscending);
  }
};

const sortReports = (
  unsortedReports: ReportData[] = [],
  sortProperty: string = "",
  sortMethod: string = ""
):  ReportData[]  => {
  // if sorting reports by assigned entities, sort both the nested assigned entity arrays and reports by the value of their first assigned entity
  if (
      (sortProperty === ReportSortProperties.AssignedOrganizations) || 
      (sortProperty === ReportSortProperties.AssignedDepartments) || 
      (sortProperty === ReportSortProperties.AssignedUsers)
  ) {
    const assignedEntitySortProperty = (sortProperty === ReportSortProperties.AssignedUsers) ? "fullName": "name";

    const reportsWithSortedAssignedEntities: ReportData[] = [];
    const reportsWithoutSortedAssignedEntities: ReportData[] = [];

    // sort the nested array containing assigned organizations, departments or users alphanumerically
    unsortedReports.forEach(r => {
      // only bother sorting if there are any assigned entities
      if (r[sortProperty]?.length) {
        reportsWithSortedAssignedEntities.push({
          ...r,
          [sortProperty]: sortObjectsByReportSortMethod(r[sortProperty], sortMethod, createAlphaNumericSorterByProperty(assignedEntitySortProperty))
        });
      } else {
        reportsWithoutSortedAssignedEntities.push(r);
      }
    });

    // now sort reports alphanumerically by the value of their first assigned entity
    // and return reports with the ones that have assigned entities stacked on top
    return [
      ...sortObjectsByReportSortMethod(
        reportsWithSortedAssignedEntities, 
        sortMethod, 
        (a: ReportData, b: ReportData) => createAlphaNumericSorterByProperty(assignedEntitySortProperty)(a[sortProperty]?.[0], b[sortProperty]?.[0])
      ),
      ...reportsWithoutSortedAssignedEntities
    ];
  } else if (sortMethod === ReportSortMethods.FilterByStatus) {
    const reports2 = unsortedReports.reduce((res: ReportData[], val) => {
      let organizations = [...(val.assignedOrganizations || [])];
      // Handle if there are no organizations, it should count for sorting / filtering as 'unnassigned'.
      // NOTE: organizations shouldn't be lacking in reportStatusName, but if they do, and it's vesta, we also set to unassigned. This should actually be handled in back end though
      if(organizations.length === 0 || (organizations.length === 1 && organizations[0].id === 1 && !organizations[0].reportStatusName)) {
        organizations.push(defaultOrganization);
      }

      const foundKey = organizations?.find((o) => { 
        return `${o.reportStatusName}` === `${sortProperty}`;
       });
       
      if (foundKey) {
        res.push(val);
      }
      return res;
    },[] );

    return reports2;
  } else {
    // if not sorting reports on assigned entities, just sort on the value of that sort property
    return sortObjectsByReportSortMethod(unsortedReports, sortMethod, createAlphaNumericSorterByProperty(sortProperty));
  }
};

// first option is both the initial and default value i.e. newest
// this is deliberate so that the subset of reports displayed on dashboard is also "Recent Reports" as it's titled there
const reportSortDropdownOptions = (intl: IntlShape, shareOrganizationPermission?: PermissionScope, organizationTypeId?: OrganizationTypeIds, organizations?: Organization[]) => {
  const filteredSortPropertiesIntl = ReportSortPropertiesIntl(organizationTypeId);

  let sortDropdownOptions: { sortProperty: ReportSortProperties; sortMethod?: ReportSortMethods }[] = [
    { sortProperty: ReportSortProperties.ReportKey, sortMethod: ReportSortMethods.Newest },
    { sortProperty: ReportSortProperties.ReportKey, sortMethod: ReportSortMethods.Oldest },
  ];

  if (isPermissionScopeAllOrOrganization(shareOrganizationPermission)) {
    sortDropdownOptions = [
      ...sortDropdownOptions,
      { sortProperty: ReportSortProperties.AssignedOrganizations, sortMethod: ReportSortMethods.AlphabeticalAscending },
      { sortProperty: ReportSortProperties.AssignedOrganizations, sortMethod: ReportSortMethods.AlphabeticalDescending },
    ];
  }

  // combine filterStatusObjects with organizations.
  const organizationSortDropdownOptions = _.uniqBy([
    ...(organizations ? Array.from(filterStatus(organizations).values())?.map((status: statusObject) => ({ sortProperty: status.key as ReportSortProperties, sortMethod: ReportSortMethods.FilterByStatus })) : []),
    ...filterStatusObjects({organizationTypeId, statusObjects: reportStatuses})?.map((status: statusObject) => ({ sortProperty: status.key as ReportSortProperties, sortMethod: ReportSortMethods.FilterByStatus })) || []
  ],(o) => { return o.sortProperty }) ;

  sortDropdownOptions = [
    ...sortDropdownOptions,
    { sortProperty: ReportSortProperties.AssignedDepartments, sortMethod: ReportSortMethods.AlphabeticalAscending },
    { sortProperty: ReportSortProperties.AssignedDepartments, sortMethod: ReportSortMethods.AlphabeticalDescending },
    { sortProperty: ReportSortProperties.AssignedUsers, sortMethod: ReportSortMethods.AlphabeticalAscending },
    { sortProperty: ReportSortProperties.AssignedUsers, sortMethod: ReportSortMethods.AlphabeticalDescending },
    ...organizationSortDropdownOptions
  ];

  return sortDropdownOptions.map((sortPropertyAndMethod: sortPropertyAndMethod, index) => {
    const { sortProperty = "", sortMethod = "" } = sortPropertyAndMethod;
    const sortPropertyText = getTranslatedText(intl, filteredSortPropertiesIntl.get(sortProperty  as ReportSortProperties));
    const sortMethodText = getTranslatedText(intl, ReportSortMethodsIntl.get(sortMethod  as ReportSortMethods));
    return ({
      id: index,
      value: makeDropdownOptionValue(sortPropertyAndMethod),
      name: makeDropdownOptionText(sortPropertyText, sortMethodText)
    });
  });
};

export const useSortedReports = (
  reports: ReportData[] | undefined,
  setReports: (reports: ReportData[] | undefined) => void,
  isRecentReports: boolean,
) => {

  const intl = useIntl();
  const userInfo = useUserState();
  const [organizations, setOrganizations] = useState<Map<string,Organization>>(new Map<string,Organization>());
  const filteredReportSortDropdownOptions = reportSortDropdownOptions(intl, userInfo.shareOrganization, userInfo.organizationTypeId, Array.from(organizations?.values()));
  const initialSortPropertyAndMethod: string = filteredReportSortDropdownOptions[0].value;
  const initialSortProperty = getSortProperty(initialSortPropertyAndMethod);
  const initialSortMethod = getSortMethod(initialSortPropertyAndMethod);

  const [selectedSortProperty, setSelectedSortProperty] = useState<string>(initialSortProperty);
  const [selectedSortMethod, setSelectedSortMethod] = useState<string>(initialSortMethod);
  const [filteredReports, setFilteredReports] = useState<ReportData[]>();
  const [baseReports, setBaseReports] = useState<ReportData[]>();

  const calcReports = (reports: ReportData[], sortProperty?: string, sortMethod?: string) => {
    let sortedReports = sortReports(reports, sortProperty, sortMethod);
      if (isRecentReports) {
        const numRecentReports: number = 6;
        sortedReports = sortedReports.slice(0, numRecentReports) ;
      }

      const initialData = { 
        organizations: new Map<string, Organization>(), 
        allStatuses: new Map<string, Organization>(),
        reports: []} 

      interface sortReduceProp {
        organizations: Map<string, Organization>;
        allStatuses: Map<string, Organization>;
        reports: ReportInfo[]
      }

      // process reports.
      const processData = sortedReports ? sortedReports.reduce<sortReduceProp>((result, rE) => {
          const orgs = result.organizations;
          const statuses = new Map<string,Organization>();
          const allStatuses = result.allStatuses;
          const o2 = rE.assignedOrganizations?.forEach((ao) => {
            orgs.set(`${ao.id || 0}`, ao);

            statuses.set(`${ao.id || 0}`, ao);
            if (ao.reportStatusName) {
              allStatuses.set(`${ao.reportStatusName || 0}`, ao);
            }

          });
          
          return {
            organizations: orgs,
            allStatuses: allStatuses,
            reports: [...result.reports, {...rE, statuses: statuses}]
          }
      }, initialData
      ) : initialData;
      return processData; 
  }

  // only reports that adminPanelUser has permissions to either view or assign to org, dep or users are returned by backend
  const populateReports = useCallback(async (query?: string) => {
    try {
      const reportsData = await getReports(query);
      const reportsResult = reportsData?.data;
      setBaseReports(reportsResult);
      if(reportsResult) {
         let processData = calcReports(reportsResult);
      let sortedReports = processData.reports;

      setReports(sortedReports);
      setFilteredReports(sortedReports);
      setOrganizations(processData.organizations);

      }
    } catch (err) {
      // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
      console.log(err);
    }
  }, [selectedSortProperty, selectedSortMethod, calcReports]);

  useEffect(() => {
    populateReports();
  }, [!reports]);

  const onSortOptionChange = useCallback((sortPropertyAndMethod: string) => {
    const newSortProperty = getSortProperty(sortPropertyAndMethod);
    const newSortMethod = getSortMethod(sortPropertyAndMethod);
    setSelectedSortProperty(newSortProperty);
    setSelectedSortMethod(newSortMethod);
    if (baseReports) {
      const processData = calcReports(baseReports, newSortProperty, newSortMethod);
      setReports(processData.reports);
    }
  },[setReports, setSelectedSortMethod, setSelectedSortProperty, calcReports, getSortProperty, getSortMethod]);

  // Filters now is done in sort method
  return {
    reportSortDropdownOptions: filteredReportSortDropdownOptions,
    populateReports,
    organizations,
    filteredReports,
    onSortOptionChange,
    sortedAndFilteredReports:reports || [] // reports in state are sorted
  }
}
