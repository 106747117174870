import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { TextField, TextFieldTypes, TextFieldEventType } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import { Button, ButtonStyle } from "components/Buttons";
import { useReportUserNotesAnswersState } from "context";
import { ErrorGeneralModal } from "components/Modals/ErrorGeneralModal";
import { adminPanelNoteType, createReportNote, NoteType } from "actions/noteActions";
import { UserInfo, useUserState } from "context/user";

export interface AdminNotesProps {
  updateReport: () => void;
}


export const AdminNotes = ({updateReport} : AdminNotesProps)  => {

  const userInfo: UserInfo = useUserState();
  const reportUserNotesAnswers = useReportUserNotesAnswersState();
  const intl = useIntl();

  const [note, setNote] = useState<string>("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [errorGeneralModalIsOpen, setErrorGeneralModalIsOpen] = useState(false);

  const onNoteChange = (e: TextFieldEventType) => {
    setNote(e.target.value);
  }

  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  };

  const closeErrorGeneralModal = () => {
    setErrorGeneralModalIsOpen(false);
  };

  const onSaveAdminNote = async () => {
    if (reportUserNotesAnswers.report && userInfo.id) {
      const notes: NoteType[] = [{ userId: userInfo.id, note, type: adminPanelNoteType }];
      if (reportUserNotesAnswers.report?.id ) {
        try {
          await createReportNote(reportUserNotesAnswers.report.id, { notes });
          await updateReport();
          setNote("");
          setConfirmationModalIsOpen(true);
        } catch (err) {
          setErrorGeneralModalIsOpen(true);
        }
      }
    }
  }

  return (
    <div className={styles.adminNotes}>

      <TextField type={TextFieldTypes.TextArea}
        placeholder={intl.formatMessage({id: "reportTimeline.addComments", defaultMessage: "Add comments..."})}
        onChange={onNoteChange}
        value={note}
        />

      <Button onClick={onSaveAdminNote} style={ButtonStyle.Secondary}>
        <FormattedMessage id="buttons.saveNote" defaultMessage="Save Note"/>
      </Button>

      <ErrorGeneralModal isOpen={errorGeneralModalIsOpen} onClose={closeErrorGeneralModal}/>
    </div>
  );
}
