import React, { ReactNode } from "react";
import classNames from "classnames";
import styles from './styles.module.scss';

export interface TimeStepProps {
  children: ReactNode;
  className?: string;
  isAdminNote?: boolean;
}

export const ReportTimeStep = ( {children, className, isAdminNote} : TimeStepProps) => {

  return (
    <div className={classNames(styles.reportTimeStepOuter, className && styles[className], isAdminNote && styles.adminNote)}>
      <div className={classNames(styles.reportTimeStep)}>
        {children}
      </div>
    </div>
  );
}
