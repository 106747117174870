import React from "react";
import styles from './styles.module.scss';
import { Checkbox } from "components/Checkbox";
import { Department } from "actions/departmentActions";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { ErrorType } from "actions/lib/baseActions";
import { Dropdown } from "components/Forms/Dropdown";
import { SearchInput } from "components/SearchInput";
import { useSortedDepartments } from "pages/ManageDepartments/DepartmentsTable/useSortedDepartments";
import { AttentionMessage } from "components/AttentionMessage";
import { getAssignableUserIds } from "..";

interface AssignDepartmentsTableProps {
  departments: Department[] | undefined;
  assignedDepartmentIds: number[];
  allAssignedUserIds: number[];
  setDepartments: (departments: Department[] | undefined) => void;
  setAssignedDepartmentIds: (departmentIds: number[]) => void;
  setAllAssignedUserIds: (userIds: number[]) => void;
  setAssignDepartmentsError: (error: ErrorType | null) => void;
  className?: any;
}

export const AssignDepartmentsTable = React.memo(({ 
  departments,
  assignedDepartmentIds,
  allAssignedUserIds,
  setDepartments,
  setAssignedDepartmentIds,
  setAllAssignedUserIds,
  setAssignDepartmentsError,
  className 
}: AssignDepartmentsTableProps) => {

  const {
    departmentSortDropdownOptions,
    onSortOptionChange,
    populateDepartments,
    sortedDepartments
  } = useSortedDepartments(departments, setDepartments);

  const onCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>, 
    department: Department
  ) => { 
    const departmentId = Number(department.id);
    const departmentUserIds = getAssignableUserIds(department.assignedUsers);

    let updatedAssignedDepartmentIds;
    let updatedAllAssignedUserIds;
    if (e.target.checked) {
      updatedAssignedDepartmentIds = [...assignedDepartmentIds, departmentId];
      updatedAllAssignedUserIds = [...allAssignedUserIds, ...departmentUserIds];
    } else {
      updatedAssignedDepartmentIds = [...assignedDepartmentIds.filter(id => (id !== departmentId))];
      updatedAllAssignedUserIds = [...allAssignedUserIds.filter(id => !departmentUserIds.includes(id))];
    }
    setAssignedDepartmentIds(Array.from(new Set(updatedAssignedDepartmentIds)));
    setAllAssignedUserIds(Array.from(new Set( updatedAllAssignedUserIds)));

    setAssignDepartmentsError(null);
  }

  const assignedDepartmentsCount = assignedDepartmentIds?.length;
  const pluralOrSingularDepartmentId = (assignedDepartmentsCount > 1) ? "departments" : "department";

  return (
    <div className={classNames(styles.assignDepartmentsTableContainer, className)}>
      <AttentionMessage message="message.assigningDepts" />
      <div className={styles.assignDepartmentsTableHeaders}>
        <p className={classNames((assignedDepartmentsCount <= 0) && styles.assignedNone, styles.smallText)}>
          <FormattedMessage id="assignedTo" defaultMessage="Assigned to"/> {assignedDepartmentsCount} <FormattedMessage id={pluralOrSingularDepartmentId} defaultMessage={pluralOrSingularDepartmentId}/>
        </p>

        <div className={styles.departmentsSortSearch}>
          <Dropdown dropdownId="assignDepartmentSortOptions" key="assignDepartmentSortOptions"
            dropdownOptions={departmentSortDropdownOptions}
            onSelect={onSortOptionChange}/>
          <SearchInput className={styles.searchInput} onChange={populateDepartments}/>
        </div> 
      </div>

      {
        (sortedDepartments && sortedDepartments?.length === 0) && 
        <div className={styles.noDeptMessage}>
          <AttentionMessage message="message.noDepartments" />
        </div>
      }

      <div className={styles.assignDepartmentsTable}>
        {sortedDepartments?.map((department: Department) => {
          const departmentName= department.name;
          const departmentId = Number(department.id);
          const isAssigned = assignedDepartmentIds?.includes(departmentId);
          const assignedUsersCount = department.assignedUsers?.length;
          const pluralOrSingularUserId = (assignedUsersCount > 1) ? "users" : "user";

          return (
            <div className={styles.departmentRow} key={departmentId}>
              <div className={styles.departmentNameAndRole}>
                <h4>{departmentName}</h4>
                <p className={classNames((assignedUsersCount <= 0) && styles.assignedNone, styles.smallText)}>
                  {assignedUsersCount} <FormattedMessage id={pluralOrSingularUserId} defaultMessage={pluralOrSingularUserId}/>
                </p>
              </div>
              <Checkbox 
                name={departmentName}
                id={departmentId}
                value={isAssigned}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onCheckboxChange(e, department); }}
              />
            </div>
          )
        })}
      </div>
    </div>
  );
});
