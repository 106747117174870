import { Form, useFormikContext } from 'formik';
import { Button, ButtonStyle, LinkButton } from 'components/Buttons';
import { TextField, TextFieldTypes, TextFieldEventType } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import { OrganizationsDropdown } from '../OrganizationsDropdown';
import { RolesList } from '../RolesList';
import { AddOrEditUserFormFields } from '..';
import { routePaths } from 'App/routing';
import { ErrorType } from 'actions/lib/baseActions';
import { OptionsBubble } from 'components/Forms/OptionsBubble';
import { OrganizationType, OrganizationTypeIds } from 'actions/organizationActions';
import { isPermissionScopeAll } from 'lib/permissions';
import { useUserState } from 'context';
import { UserInfo } from 'context/user';
import { RoleType } from 'actions/roleActions';


interface AddOrEditUserFormProps {
  addOrEditUserError: ErrorType | null;
  setAddOrEditUserError: (value: ErrorType | null) => void;
}

export const AddOrEditUserForm = ({ addOrEditUserError, setAddOrEditUserError }: AddOrEditUserFormProps) => {

  const userInfo: UserInfo & RoleType = useUserState();

  const { values, setFieldValue, handleChange, isSubmitting, setSubmitting, errors, handleSubmit, resetForm } = useFormikContext<AddOrEditUserFormFields>();
  
  const onTextFieldChange = (e: TextFieldEventType) => {
    handleChange(e);
    setAddOrEditUserError(null);
    setSubmitting(false); 
  };

  const onOrganizationTypeChange = (organizationTypeId: OrganizationTypeIds) => { 
    const typeId = +organizationTypeId;

    setFieldValue('organizationTypeId', organizationTypeId);
    setFieldValue('organizationId', values.organizations?.find(o => (o.organizationTypeId === typeId))?.id);
    setAddOrEditUserError(null);
    setSubmitting(false); 
  };

  return (    
    <Form className={styles.addOrEditUserForm}>
      <TextField type={TextFieldTypes.Text} 
        name="fullName" 
        placeholder="Username"
        label="User name"
        value={values.fullName}
        onChange={(e) => { onTextFieldChange(e); }}
        className={classNames(styles.inputField, errors.fullName && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.fullName}
      />

      <TextField type={TextFieldTypes.Email} 
        name="email" 
        placeholder="Email"
        label="User email"
        value={values.email}
        onChange={(e) => { onTextFieldChange(e); }}
        className={classNames(styles.inputField, errors.email && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.email}
      />

      {isPermissionScopeAll(userInfo.shareOrganization) && (
        <OptionsBubble groupName="organizationType"   
          label={<FormattedMessage id="organizationType" defaultMessage="Organization Type"/>}
          options={Object.values(OrganizationTypeIds).filter(t => (t !== OrganizationTypeIds.DummyOrganization && t !== OrganizationTypeIds.None && typeof(t) === 'number')).map(String)}
          onChange={onOrganizationTypeChange}
          value={values.organizationTypeId?.toString()}
        />
      )}

      <OrganizationsDropdown/>

      <RolesList/>             

      <div className={styles.cancelAddEditButtons}>
        <LinkButton to={{ pathname: routePaths.userRoles }}
          style={ButtonStyle.Primary}
          onClick={() => { resetForm({}); }}
          type="button"
        >
          <FormattedMessage id="buttons.cancel" defaultMessage="Cancel"/>
        </LinkButton>

        <Button style={ButtonStyle.Secondary}
          loading={isSubmitting}
          disabled={isSubmitting} 
          onClick={() => { handleSubmit(); }}
          type="button"
        >
          <FormattedMessage id="button.saveUser" defaultMessage="Save User"/> 
        </Button>
      </div>

      {_.isEmpty(errors) && addOrEditUserError && (
        <div className={styles.errorMessage}>
          <FormattedMessage id={addOrEditUserError.intlId} defaultMessage={addOrEditUserError.defaultMessage || "Sorry, there was an error."}/>
        </div>
      )}
    </Form>
  );
};
