
import React from "react";
import styles from "./styles.module.scss";

export interface DisplaySoundProps {
  soundUrl: string;
}

export const DisplaySound = ({ soundUrl } : DisplaySoundProps) => {

  return (
    <video muted={false} className={styles.soundContainer} controls>
      <source src={soundUrl} type="audio/mp3"/>
    </video>
  );
}
