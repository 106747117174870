import React , { FC, useState } from 'react'
import { resetPassword } from 'actions/userActions';
import { Formik, FormikHelpers } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { routePaths } from 'App/routing';
import { FormattedMessage } from 'react-intl';
import { VestaLogo } from 'components/Icons';
import { CreatePasswordForm } from './CreatePasswordForm';
import { getRoleById } from 'actions/roleActions';
import { Button, ButtonStyle } from 'components/Buttons';
import { ErrorType } from 'actions/lib/baseActions';
import classNames from 'classnames';
import { useLogoutUser } from 'hooks/useLogoutUser';


export interface CreatePasswordFormFields {
  password?: string;
  confirmPassword?: string;
};

export const CreatePasswordErrors = {
  General: { id: 'general', intlId: 'createPassword.error.general', defaultMessage: 'There was an error creating the password. Please try again later. If you continue to have problems, please contact support.' },
};

const validationErrors = (values: CreatePasswordFormFields) => {
  const errors: CreatePasswordFormFields = {}; 

  //TODO: determine with Nichie if these are the error messages we want, then insert their translations, https://trello.com/c/IthwtgRl/624-create-password-errors-french-translation

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Less than 8 characters';
  } else if (!/\d/.test(values.password)) {
    errors.password = 'Needs at least one number';  
  } else if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(values.password)) {
    errors.password = 'Needs at least one special character';
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = 'Needs at least one uppercase letter';
  }

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Confirm Password does not match';
  }

  return errors;
};

export const CreatePassword : FC = () => {

  const [createPasswordError, setCreatePasswordError] = useState<ErrorType | null>(null);

  const logoutUser = useLogoutUser();
  const history = useHistory();

  const { resetKey } = useParams<{ resetKey: string }>();

  const onSubmit = async (values: CreatePasswordFormFields, { setSubmitting }: FormikHelpers<any>) => {
    try {
      const { password, confirmPassword }: CreatePasswordFormFields = values;
      const createPasswordObj = { password, confirmPassword };

      logoutUser();
      setSubmitting(false);

      const userResult = await resetPassword(createPasswordObj, resetKey);
      
      const roleId = userResult?.data?.roleId;
      if (roleId) {
        const roleData = await getRoleById(roleId);
        history.push({ 
          pathname: routePaths.login, 
          state: { 
            role: roleData?.data 
          } 
        });
      }
    } catch (err) {
      setCreatePasswordError(err.body);
      setSubmitting(false);
      throw err;
    }  
  }

  const onContactSupport = () => {
    window.location.href = "mailto:support@vestasit.com";
  }

  return (
    <div className={classNames(styles.createPasswordPage)}>
      <div className={styles.createPasswordContent}>
        <div className={styles.left}>
          <div className={styles.vestaLogo}><VestaLogo/></div>


          <h3 className={classNames(styles.header, styles.subheader)}>
            <FormattedMessage id="createPassword.welcome" defaultMessage="Welcome to the Vesta Admin Portal."/>
            <br/>
            <FormattedMessage id="createAPassword" defaultMessage="Create a Password"/>
          </h3>

          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validate={validationErrors}
            onSubmit={onSubmit}
          >
          {() => (
            <>
              <CreatePasswordForm createPasswordError={createPasswordError}
                setCreatePasswordError={setCreatePasswordError}/>
              <Button onClick={onContactSupport}
                style={ButtonStyle.Underline}>
                <FormattedMessage id="buttons.contactSupport" defaultMessage="Contact support"/>
              </Button>
            </>
          )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
