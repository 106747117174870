export const intl = {
  'en-CA': {
    'report.s-incident.title': 'The Incident',
    'report.s-incident.description': '',
    'report.s-incident.why': 'We are asking you to record anything you can remember or feel comfortable sharing about the incident. Sights, sounds, smells, even things that may not seem important. We are looking for repeat patterns or behaviours that can help create safe communities.',
    'report.q-occurrence': 'How often has this occurred?',
    'report.o-moreThanOnce': 'More than once',
    'report.o-once': 'Once',
    'report.q-whenDescribe': 'Are you able to describe when it happened?',
    'report.q-when': 'When did the incident happen?',
    'report.o-date': 'Date',
    'report.o-timeRange': 'Time Range',
    'report.q-where': 'Where did the incident happen?',
    'report.q-whereAddress': 'Are you able to provide an address?',
    'report.s-offender.title': 'The Offender',
    'report.s-offender.description': 'We need to know about the offender',
    'report.s-offender.why': 'We are asking you to record anything you can remember or feel comfortable sharing about the offender. What did they do, how did they smell, did they say anything? Record even things that may not seem important. Unfortunately, statistically it is possible that they may have done this to someone else and we are looking for repeat offenders or behaviours that can help create safe communities.',
    'report.q-offenderKnow': 'Are you able to name the offender?',
    'report.o-yes': 'Yes',
    'report.o-no': 'No',
    'report.o-no-notSure': 'No/NotSure',
    'report.o-maybe': 'Maybe',
    'report.o-idk': 'I don\'t know',
    'report.q-offenderGender': 'Gender',
    'report.o-male': 'Male',
    'report.o-female': 'Female',
    'report.o-other': 'Other',
    'report.o-man': 'Man',
    'report.o-woman': 'Woman',
    'report.o-nonBinary': 'Non-Binary',
    'report.o-unknownGender': 'Unknown',
    'report.q-age': 'Age',
    'report.o-younger': 'Younger',
    'report.o-older': 'Older',
    'report.q-complexion': 'Complexion',
    'report.o-fair': 'Pale',
    'report.o-deep': 'Brown',
    'report.o-native': 'Indigenous',
    'report.o-light': 'Light',
    'report.o-medium': 'Olive',
    'report.o-notSure': 'Not Sure',
    'report.o-dark': 'Tan',
    'report.q-hairColour': 'Hair Colour',
    'report.o-yellow': 'Yellow',
    'report.o-red': 'Red',
    'report.o-brown': 'Brown',
    'report.o-black': 'Black',
    'report.o-grey': 'Grey',
    'report.q-complexion-hairColour-other': 'Other Complexion/Hair Colour Features',
    'report.q-weight': 'Weight',
    'report.o-lbs': 'Lbs',
    'report.o-kgs': 'Kgs',
    'report.o-bigger': 'Bigger',
    'report.o-thinner': 'Thinner',
    'report.o-same': 'About the Same',
    'report.q-height': 'Height',
    'report.o-cm': 'cm',
    'report.o-in': 'in',
    'report.o-taller': 'Taller',
    'report.o-shorter': 'Shorter',
    'report.o-thin': 'Thin',
    'report.o-petite': 'Petite',
    'report.o-muscular': 'Muscular',
    'report.o-heavy': 'Heavy',
    'report.o-average': 'Average',
    'report.q-priorRelationship': 'Prior Relationship',
    'report.o-justMetThem': 'Just met them',
    'report.o-romanticPartners': 'Romantic Partners',
    'report.o-acquaintances': 'Acquaintances',
    'report.o-Colleagues': 'Colleagues',
    'report.o-familyMember': 'Family Member',
    'report.o-noRelationship': 'No Relationship',
    'report.q-distinguishingFeatures': 'How would you describe the offender?',
    'report.q-offenderName': 'Offender\'s name',
    'report.q-offenderAddress': 'Offender\'s address',
    'report.q-offenderSocialMedia': 'Are there any pieces of social media that you would like to attach?',
    'report.q-fileAttachments': 'If you have any images, videos, sound files, texts, screenshots of social media you would like to share, please attach your files here.',
    'report.s-experience.title': 'The Experience',
    'report.s-experience.description': 'Use your voice to tell your story',
    'report.s-experience.why': 'Describe what you can remember about what you were thinking, what emotions were you experiencing, how was your body reacting. There are no wrong answers. This will help police, if you decide to report, to better understand your point of view and experience of what happened.',
    'report.q-tellYourStory': 'You may upload an audio or video recording, or you may write down your words. Use whichever format your feel comfortable to tell your story.',
    'report.s-details.title': 'Other Details',
    'report.s-details.description': '',
    'report.s-details.why': 'Here you can upload any social media, texts, or screen shots that you have. If you decide to report, it will help preserve details otherwise lost over time.',
    'report.q-witnesses': 'Was there anyone else there?',
    'report.q-vehicle': 'Do you remember the offender\'s vehicle?',
    'report.q-offenderRelationship': 'Have you seen the offender before?',
    'report.q-circumcised': 'Was the offender circumcised',
    'report.o-unknown': `Don't know`,
    'report.q-otherAttachments': 'If you have any images, videos, sound files, texts, screenshots of social media you would like to share, please attach your files here.',
    'report.s-sensory.title': 'Sensory',
    'report.s-sensory.description': 'Let us know more about your experience',
    'report.s-sensory.why': 'Research shows that the body remembers, even if the mind does not. That means that our brain encodes things like smells, sounds, feelings and tastes. Describe any of these that you can remember. They why is not important. Thinking about these things can help you remember.',
    'report.q-environment': 'What were your surroundings like during the experience?',
    'report.q-scents': 'What could you smell?',
    'report.q-sounds': 'What could you hear?',
    'report.q-tastes': 'What are you able to remember about any tastes?',
    'report.q-touch': 'What did you feel?',
    'report.s-impact.title': 'Impact',
    'report.s-impact.description': 'Tell us about how this has impacted your life',
    'report.s-impact.why': 'Documenting how this incident has affected you, physically, emotionally, socially, even spiritually can help contextualize the incident and its aftermath.',
    'report.q-mostDifficult': 'What has been the most difficult part of this experience for you?',
    'report.q-cantForget': 'Is there anything from the experience that you cannot forget?',
    'report.q-impacted': 'How do you think the experience has impacted you?',
    'report.q-classifyWhatHappened': 'How would you classify what happened?',
    'report.o-rape': 'Rape',
    'report.o-workplaceHarassment': 'Workplace Harassment',
    'report.o-sexualAssault': 'Sexual Assault',
    'report.o-sexualHarassment': 'Sexual Harassment',
    'report.o-sexualExploitation': 'Sexual Exploitation',
    'report.o-stalking': 'Stalking',
    'report.o-exhibitionism': 'Exhibitionism',
    'report.o-voyeurism': 'Voyeurism',
    'report.o-cyberOrOnlineHarassment': 'Cyber/Online Harassment',
    'report.o-humanTrafficking': 'Human Trafficking',
  },
  'fr-CA': {
    'report.s-incident.title': 'The Incident',
    'report.s-incident.description': '',
    'report.s-incident.why': 'We are asking you to record anything you can remember or feel comfortable sharing about the incident. Sights, sounds, smells, even things that may not seem important. We are looking for repeat patterns or behaviours that can help create safe communities.',
    'report.q-occurrence': 'How often has this occurred?',
    'report.o-moreThanOnce': 'More than once',
    'report.o-once': 'Once',
    'report.q-whenDescribe': 'Are you able to describe when it happened?',
    'report.q-when': 'When did the incident happen?',
    'report.o-date': 'Date',
    'report.o-timeRange': 'Time Range',
    'report.q-where': 'Where did the incident happen?',
    'report.q-whereAddress': 'Are you able to provide an address?',
    'report.s-offender.title': 'The Offender',
    'report.s-offender.description': 'We need to know about the offender',
    'report.s-offender.why': 'We are asking you to record anything you can remember or feel comfortable sharing about the offender. What did they do, how did they smell, did they say anything? Record even things that may not seem important. Unfortunately, statistically it is possible that they may have done this to someone else and we are looking for repeat offenders or behaviours that can help create safe communities.',
    'report.q-offenderKnow': 'Are you able to name the offender?',
    'report.o-yes': 'Yes',
    'report.o-no': 'No',
    'report.o-no-notSure': 'No/NotSure',
    'report.o-maybe': 'Maybe',
    'report.o-idk': 'I don\'t know',
    'report.q-offenderGender': 'Gender',
    'report.o-male': 'Male',
    'report.o-female': 'Female',
    'report.o-other': 'Other',
    'report.o-man': 'Man',
    'report.o-woman': 'Woman',
    'report.o-nonBinary': 'Non-Binary',
    'report.o-unknownGender': 'Unknown',
    'report.q-age': 'Age',
    'report.o-younger': 'Younger',
    'report.o-older': 'Older',
    'report.q-complexion': 'Complexion',
    'report.o-fair': 'Pale',
    'report.o-deep': 'Brown',
    'report.o-native': 'Indigenous',
    'report.o-light': 'Light',
    'report.o-medium': 'Olive',
    'report.o-notSure': 'Not Sure',
    'report.o-dark': 'Tan',
    'report.q-hairColour': 'Hair Colour',
    'report.o-yellow': 'Yellow',
    'report.o-red': 'Red',
    'report.o-brown': 'Brown',
    'report.o-black': 'Black',
    'report.o-grey': 'Grey',
    'report.q-complexion-hairColour-other': 'Other Complexion/Hair Colour Features',
    'report.q-weight': 'Weight',
    'report.o-lbs': 'Lbs',
    'report.o-kgs': 'Kgs',
    'report.o-bigger': 'Bigger',
    'report.o-thinner': 'Thinner',
    'report.o-same': 'About the Same',
    'report.q-height': 'Height',
    'report.o-cm': 'cm',
    'report.o-in': 'in',
    'report.o-taller': 'Taller',
    'report.o-shorter': 'Shorter',
    'report.o-thin': 'Thin',
    'report.o-petite': 'Petite',
    'report.o-muscular': 'Muscular',
    'report.o-heavy': 'Heavy',
    'report.o-average': 'Average',
    'report.q-priorRelationship': 'Prior Relationship',
    'report.o-justMetThem': 'Just met them',
    'report.o-romanticPartners': 'Romantic Partners',
    'report.o-acquaintances': 'Acquaintances',
    'report.o-Colleagues': 'Colleagues',
    'report.o-familyMember': 'Family Member',
    'report.o-noRelationship': 'No Relationship',
    'report.q-distinguishingFeatures': 'How would you describe the offender?',
    'report.q-offenderName': 'Offender\'s name',
    'report.q-offenderAddress': 'Offender\'s address',
    'report.q-offenderSocialMedia': 'Are there any pieces of social media that you would like to attach?',
    'report.q-fileAttachments': 'If you have any images, videos, sound files, texts, screenshots of social media you would like to share, please attach your files here.',
    'report.s-experience.title': 'The Experience',
    'report.s-experience.description': 'Use your voice to tell your story',
    'report.s-experience.why': 'Describe what you can remember about what you were thinking, what emotions were you experiencing, how was your body reacting. There are no wrong answers. This will help police, if you decide to report, to better understand your point of view and experience of what happened.',
    'report.q-tellYourStory': 'You may upload an audio or video recording, or you may write down your words. Use whichever format your feel comfortable to tell your story.',
    'report.s-details.title': 'Other Details',
    'report.s-details.description': '',
    'report.s-details.why': 'Here you can upload any social media, texts, or screen shots that you have. If you decide to report, it will help preserve details otherwise lost over time.',
    'report.q-witnesses': 'Was there anyone else there?',
    'report.q-vehicle': 'Do you remember the offender\'s vehicle?',
    'report.q-offenderRelationship': 'Have you seen the offender before?',
    'report.q-circumcised': 'Was the offender circumcised',
    'report.o-unknown': `Don't know`,
    'report.q-otherAttachments': 'If you have any images, videos, sound files, texts, screenshots of social media you would like to share, please attach your files here.',
    'report.s-sensory.title': 'Sensory',
    'report.s-sensory.description': 'Let us know more about your experience',
    'report.s-sensory.why': 'Research shows that the body remembers, even if the mind does not. That means that our brain encodes things like smells, sounds, feelings and tastes. Describe any of these that you can remember. They why is not important. Thinking about these things can help you remember.',
    'report.q-environment': 'What were your surroundings like during the experience?',
    'report.q-scents': 'What could you smell?',
    'report.q-sounds': 'What could you hear?',
    'report.q-tastes': 'What are you able to remember about any tastes?',
    'report.q-touch': 'What did you feel?',
    'report.s-impact.title': 'Impact',
    'report.s-impact.description': 'Tell us about how this has impacted your life',
    'report.s-impact.why': 'Documenting how this incident has affected you, physically, emotionally, socially, even spiritually can help contextualize the incident and its aftermath.',
    'report.q-mostDifficult': 'What has been the most difficult part of this experience for you?',
    'report.q-cantForget': 'Is there anything from the experience that you cannot forget?',
    'report.q-impacted': 'How do you think the experience has impacted you?',
    'report.q-classifyWhatHappened': 'How would you classify what happened?',
    'report.o-rape': 'Rape',
    'report.o-workplaceHarassment': 'Workplace Harassment',
    'report.o-sexualAssault': 'Sexual Assault',
    'report.o-sexualHarassment': 'Sexual Harassment',
    'report.o-sexualExploitation': 'Sexual Exploitation',
    'report.o-stalking': 'Stalking',
    'report.o-exhibitionism': 'Exhibitionism',
    'report.o-voyeurism': 'Voyeurism',
    'report.o-cyberOrOnlineHarassment': 'Cyber/Online Harassment',
    'report.o-humanTrafficking': 'Human Trafficking',
  }
};
