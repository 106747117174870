import React from "react";
import styles from './styles.module.scss';
import Layout from "Layout";
import { DepartmentsTable } from "./DepartmentsTable";
import { FormattedMessage } from "react-intl";


export const ManageDepartments = () => {
  return (
    <Layout>
      <div className={styles.manageDepartments}>
        <h3><FormattedMessage id="manageDepartments" defaultMessage="Manage Departments"/></h3>
        <DepartmentsTable/>
      </div>
    </Layout>
  );
}
