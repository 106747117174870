import { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import { Button, ButtonStyle, LinkButton } from 'components/Buttons';
import { TextField, TextFieldTypes, TextFieldEventType } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import { routePaths } from 'App/routing';
import { ErrorType } from 'actions/lib/baseActions';
import { AssignOrganizationUsersTable } from '../AssignOrganizationUsersTable';
import { AddOrEditOrganizationUsersFormFields } from '..';
import { OptionsBubble } from 'components/Forms/OptionsBubble';
import { OrganizationTypeIds } from 'actions/organizationActions';
import { isOrganizationTypeVesta } from "lib/permissions";
import { UserInfo, useUserState } from 'context/user';
import { Dropdown, DropdownOption } from 'components/Forms/Dropdown';


interface AddOrEditOrganizationUsersFormProps {
  addOrEditOrganizationUsersError: ErrorType | null;
  setAddOrEditOrganizationUsersError: (value: ErrorType | null) => void;
}

export const IsPartnerOptions: DropdownOption[] = [ 
  {
    value:`false`,
    name:`No`,
    id: 0
  },
  {
  id: 1,
  value: `true`,
  name:`Yes`
  }
];

export const AddOrEditOrganizationUsersForm = ({ addOrEditOrganizationUsersError, setAddOrEditOrganizationUsersError }: AddOrEditOrganizationUsersFormProps) => {
  const userInfo: UserInfo = useUserState();  
  
  const { 
    values, 
    handleChange, 
    isSubmitting, 
    setSubmitting, 
    errors,
    setFieldValue, 
    validateForm, 
    resetForm, 
    handleSubmit 
  } = useFormikContext<AddOrEditOrganizationUsersFormFields>();

  // force validation because it doesn't do it the first time you change something
  useEffect(() => {
    if (values.name !== "") {
      validateForm();
    }
  }, [values]);

  const onTextFieldChange = (e: TextFieldEventType) => {
    e.target.name = e.target.name || "";
    handleChange(e);
    setAddOrEditOrganizationUsersError(null);
    setSubmitting(false); 
  };

  const onOrganizationTypeChange = (type: OrganizationTypeIds) => { 
    setFieldValue('type', type);
    setAddOrEditOrganizationUsersError(null);
    setSubmitting(false); 
  };

  const onSelectIsPartnerOptions = (value: string) => { 
    const boolValue = value === "true" ? true : false;
    setFieldValue('isPartnered', boolValue);
    setAddOrEditOrganizationUsersError(null);
    setSubmitting(false); 
  };


  return (    
    <Form className={styles.addOrEditOrganizationUsersForm}>
      <TextField type={TextFieldTypes.Text} 
        name="name" 
        placeholder="Organization Name"
        label="Organization name"
        value={values.name}
        onChange={onTextFieldChange}
        className={classNames(styles.inputField, errors.name && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.name}
      />

      <div className={styles.orgUsersFormHalfFields}>
        <TextField type={TextFieldTypes.Text} 
          name="address" 
          placeholder="Organization Address"
          label="Organization address"
          value={values.address}
          onChange={onTextFieldChange}
          className={classNames(styles.inputField, errors.name && styles.errorField)}
          labelClassName={styles.labels} 
          errorMessage={errors.address}
        />
        <OptionsBubble groupName="type"   
          label={<FormattedMessage id="type" defaultMessage="Organization Type"/>}
          options={Object.values(OrganizationTypeIds).filter(t => (t !== OrganizationTypeIds.DummyOrganization && t !== OrganizationTypeIds.None && typeof(t) === 'number')).map(String)}
          onChange={onOrganizationTypeChange}
          value={values.type?.toString()}
        />
      </div>

      <div className={styles.orgUsersFormHalfFields}>
        <TextField type={TextFieldTypes.Text} 
          name="email" 
          placeholder="Email (eg: info@vestasit.com)"
          label="Email"
          value={values.email}
          onChange={onTextFieldChange}
          className={classNames(styles.inputField, errors.name && styles.errorField)}
          labelClassName={styles.labels} 
          errorMessage={errors.email}
        />

        <TextField type={TextFieldTypes.Text} 
          name="phoneNumber" 
          placeholder="Phone Number (eg: +1 555-555-5555)"
          label="Phone number"
          value={values.phoneNumber}
          onChange={onTextFieldChange}
          className={classNames(styles.inputField, errors.name && styles.errorField)}
          labelClassName={styles.labels} 
          errorMessage={errors.phoneNumber}
        />

        <TextField type={TextFieldTypes.Text} 
          name="url" 
          placeholder="Website URL (eg: http://www.vestasit.com)"
          label="Website URL"
          value={values.url}
          onChange={onTextFieldChange}
          className={classNames(styles.inputField, errors.name && styles.errorField)}
          labelClassName={styles.labels} 
          errorMessage={errors.url}
        />
      </div>
      { isOrganizationTypeVesta(userInfo.organizationTypeId) &&
      (<>
        <div className={styles.orgUsersFormHalfFields}>
          <TextField type={TextFieldTypes.Text} 
            name="organizationName" 
            placeholder="Name of Organization for lists"
            label="Organization Name"
            value={values.organizationName}
            onChange={onTextFieldChange}
            className={classNames(styles.inputField, errors.name && styles.errorField)}
            labelClassName={styles.labels} 
            errorMessage={errors.organizationName}
          />

          <TextField type={TextFieldTypes.Text} 
            name="organizationLocation" 
            placeholder="Location of Organization for Lists"
            label="Organization Location"
            value={values.organizationLocation}
            onChange={onTextFieldChange}
            className={classNames(styles.inputField, errors.name && styles.errorField)}
            labelClassName={styles.labels} 
            errorMessage={errors.organizationLocation}
          />
        </div>      
        <div className={styles.orgUsersFormHalfFields}>
          <TextField type={TextFieldTypes.Text} 
            name="intakeFormLink" 
            placeholder="Intake form for location"
            label="Intake Form Link"
            value={values.intakeFormLink}
            onChange={onTextFieldChange}
            className={classNames(styles.inputField, errors.name && styles.errorField)}
            labelClassName={styles.labels} 
            errorMessage={errors.intakeFormLink}
          />

          <TextField type={TextFieldTypes.Text} 
            name="crisisLink" 
            placeholder="crisis online link"
            label="Crisis Link"
            value={values.crisisLink}
            onChange={onTextFieldChange}
            className={classNames(styles.inputField, errors.name && styles.errorField)}
            labelClassName={styles.labels} 
            errorMessage={errors.crisisLink}
          />

          <TextField type={TextFieldTypes.Text} 
            name="crisisPhone" 
            placeholder="crisis Phone"
            label="Crisis Phone"
            value={values.crisisPhone}
            onChange={onTextFieldChange}
            className={classNames(styles.inputField, errors.name && styles.errorField)}
            labelClassName={styles.labels} 
            errorMessage={errors.crisisPhone}
          />
        </div>
        <div className={styles.orgUsersFormHalfFields}>
        <Dropdown  
          dropdownId={"isPartnered"}
          label={<FormattedMessage id="editOrganization.isPartnered" defaultMessage="is partnered"/>}
          dropdownOptions={IsPartnerOptions}
          onSelect={onSelectIsPartnerOptions}
          dropdownValue={IsPartnerOptions.find((po) => { return po.value === values.isPartnered?.toString(); })}
        />
        </div>
      </>)
      }


      <AssignOrganizationUsersTable setAssignUsersError={setAddOrEditOrganizationUsersError}/>

      <div className={styles.cancelOrSaveButtons}>
        <LinkButton to={{pathname: routePaths.manageOrganizations}}
          style={ButtonStyle.Primary}
          onClick={() => { resetForm({}); }}
          type="button"
        >
          <FormattedMessage id="buttons.cancel" defaultMessage="Cancel"/>
        </LinkButton>

        <Button style={ButtonStyle.Secondary}
          loading={isSubmitting}
          disabled={isSubmitting} 
          onClick={() => { handleSubmit(); }}
          type="button"
        >
          <FormattedMessage id="buttons.saveOrganizationDetails" defaultMessage="Save Organization"/>
        </Button>
      </div>

      {_.isEmpty(errors) && addOrEditOrganizationUsersError && (
        <div className={styles.errorMessage}>
          <FormattedMessage id={addOrEditOrganizationUsersError.intlId} defaultMessage={addOrEditOrganizationUsersError.defaultMessage || "Sorry, there was an error."}/>
        </div>
      )}
    </Form>
  );
};
