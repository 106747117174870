import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, ButtonStyle } from '../../../components/Buttons';
import { TextField, TextFieldTypes, TextFieldEventType } from '../../../components/Forms/TextField';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';
import { BaseModal } from "../../../components/Modals/BaseModal";
import { sendForgotPasswordEmail, forgotPasswordData } from '../../../actions/userActions';
import { ErrorType } from 'actions/lib/baseActions';

interface ForgotPasswordFormProps {
  onClose: () => void;
}

export interface ForgotPasswordFormFields {
  email?: string;
};

export const ForgotPasswordErrors = {
  General: { id: 'general', intlId: 'forgotPassword.error.general', defaultMessage: 'There was an error with sending your password reset email. Please try again later. If you continue to have problems, please contact support.' },
  UserDoesNotExist: { id: 'userDoesNotExist', intlId: 'forgotPassword.error.userDoesNotExist', defaultMessage: 'No user for the provided email address exists' },
};

export const ForgotPasswordForm = ({ onClose }: ForgotPasswordFormProps) => {

  const { values, handleChange, isSubmitting, setSubmitting, errors, submitForm } = useFormikContext<ForgotPasswordFormFields>();

  const [showEmailSentMessage, setShowEmailSentMessage] = useState<boolean>(false);
  const [forgotPasswordError, setForgotPasswordError] = useState<ErrorType | null>(null);

  const onTextFieldChange = (e: TextFieldEventType) => {
    handleChange(e);
  };

  const onClickEmailPassword = async () => {
    try {
      const forgotPassword : forgotPasswordData = { email: values.email || ''};
      await sendForgotPasswordEmail(forgotPassword);
      setShowEmailSentMessage(true);
    } catch(err) {
      setForgotPasswordError(err.body);
    }
  };

  return (
    <BaseModal title={<FormattedMessage id="forgotPassword.title" defaultMessage="Enter your email to have a password reset link emailed to you"/>}
      centerTitle={true}
      isOpen={true} 
      onClose={onClose} 
      className={styles.forgotPasswordForm}
    >

      {!showEmailSentMessage ? (
        <>
          <TextField type={TextFieldTypes.Email} 
            name="email" 
            placeholder="Username"
            value={values.email}
            onChange={onTextFieldChange}
            className={classNames(styles.inputField, errors.email && styles.errorField)}
            labelClassName={styles.labels} 
            errorMessage={errors.email}
          />

          <Button loading={isSubmitting} 
            disabled={isSubmitting} 
            type="button"
            onClick={onClickEmailPassword}
            style={ButtonStyle.Primary}
            className={styles.accessPortalButton}>
              <FormattedMessage id="buttons.emailForgotPassword" defaultMessage="Email Password Reset"/>
          </Button>
        </>
      ) : ( 
        <>
          <p className={styles.forgotPasswordComplete}>
            <FormattedMessage id="forgotPassword.Complete" defaultMessage='Your password reset link has been emailed to you'/>
          </p>

          <Button loading={isSubmitting} 
            disabled={isSubmitting} 
            type="button"
            onClick={onClose}
            style={ButtonStyle.Primary}
            className={styles.accessPortalButton}>
              <FormattedMessage id="buttons.closeForgotEmail" defaultMessage="Back To Login"/>
          </Button>
        </>
      )}

      {forgotPasswordError && (
        <div className={styles.errorMessage}>
          <FormattedMessage id={forgotPasswordError.intlId} defaultMessage={forgotPasswordError.defaultMessage || "Sorry, there was an error."}/>
        </div>
      )}
    </BaseModal>
  );
};
