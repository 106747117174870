export interface OptionType {
  key: string;
  order: number;
  text: string;
};

export interface RequiredPreviousAnswers {
  key: string;
  sectionKey: string;
  questionKey: string;
  visibleOnValue: string;
};

export enum QuestionTypes {
  Boolean = "boolean",
  Options = "options",
  Number = "number",
  Text = "text",
  TextLong = "text-long",
  MultiChoice = "multichoice",
  Complexion = "complexion",
  HairColour = "hairColour",
  OffenderFace = "offender-face",
  Address = "address",
  DateTimeRange = "dateTimeRange",
  Attachments = "attachments"
};

export interface QuestionType {
  key: string;
  type: string;
  text: string;
  order: number;
  placeholder?: string;
  encryptedAndSecure?: boolean;
  options?: Array<OptionType>;
  scale?: Array<OptionType>;
  requiredPreviousAnswers?: Array<RequiredPreviousAnswers>;
  imageKey?: string;
};

export interface QuestionsType {
  version: string;
  name: string;
  key: string;
  createdon: string;
  updatedon: string;
  language: string;
  sections: Array<SectionType>;
};

export interface SectionType {
  key: string;
  title: string;
  description: string;
  type: string;
  reportPathType?: string;
  step: number;
  order: number;
  questions: Array<QuestionType>;
};
