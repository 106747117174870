import { BarChartData } from "pages/Statistics/StatsGroup/useBarChartData";
import React from "react";
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Cell } from 'recharts';


export interface HorizontalBarGraphProps {
  data: Array<BarChartData>;
}

const CustomizedLabel = ({x, y, width, height, fill, value}: any) => {
  return (
    <text 
      x={x + width + 10} 
      y={y + height / 1.5} 
      fontSize='12' 
      fontWeight='bold'
      fill={fill}
      textAnchor="middle"
    >
      {value}
    </text>
  );
}

export const HorizontalBarGraph = ({data}: HorizontalBarGraphProps) => {
  return (
    <ResponsiveContainer>
      <BarChart 
        data={data}
        layout="vertical" barCategoryGap={5}
        margin={{ top: 0, right: 50, left: 0, bottom: 0 }}>
        <XAxis type="number" hide />
          <YAxis 
            type="category" 
            width={200} 
            dataKey="name"
            tick={{fontSize: 15}}
          />

          <Bar 
            dataKey="value" 
            fill="#323232"
            label={<CustomizedLabel />}
          >
            {data.map((stat: any) => {
              return <Cell key={stat.name} fill={stat.color} />;
            })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

