import React , { ChangeEvent, useCallback, useState } from 'react'
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import _ from 'lodash';
import classNames from 'classnames';

interface SearchInputProps {
  placeholder?: string;
  className?: string;
  onChange: (query: string) => void;
  preventDefault?: boolean;
}

export const SearchInput =  ({
  placeholder, 
  className,
  onChange, 
  preventDefault = true
}: SearchInputProps) => {
  const intl = useIntl();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const debouncedSearch = useCallback(
    _.debounce(onChange, 200, { leading: true, trailing: true })
  , [onChange]);

  const onSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    debouncedSearch(newSearchQuery);
  };

  const searchOnEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.keyCode || event.which;
    if (key === 13){ //Enter Key
      onChange(searchQuery); // without debounce - to manually or immediately trigger search

      // prevent default
      if (preventDefault) {
        event.preventDefault();
      }
    }
  }

  return (
    <div className={classNames(styles.search, className)}>
      <div className={styles.searchContainer}>
        <input
          name="search" 
          placeholder={placeholder || intl.formatMessage({ id: "common.search", defaultMessage: "Search..." })}
          onChange={onSearchQueryChange}
          onKeyDown={searchOnEnterKeyDown}
          value={searchQuery}>
        </input>
      </div>
    </div>
  );
};
