import React, { useState } from 'react';
import { Form, useFormikContext } from 'formik';
import { Button, ButtonStyle } from 'components/Buttons';
import { TextField, TextFieldTypes, TextFieldEventType } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ArrowIcon } from 'components/Icons';
import _ from 'lodash';
import { ErrorType } from 'actions/lib/baseActions';
import { LoginFormFields } from '..';
import { ForgotPasswordForm } from '../ForgotPassword';

interface LoginFormProps {
  loginError: ErrorType | null;
  setLoginError: (value: ErrorType | null) => void;
}

export const LoginForm = ({ loginError, setLoginError }: LoginFormProps) => {

  const { values, handleChange, isSubmitting, setSubmitting, errors, submitForm } = useFormikContext<LoginFormFields>();

  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);

  const onTextFieldChange = (e: TextFieldEventType) => {
    handleChange(e);
    setLoginError(null); 
    setSubmitting(false); 
  };

  const onForgotUsernameOrPassword = () => {
    setShowForgotPassword(true);
  }

  const onContactSupport = () => {
    window.location.href = "mailto:support@vestasit.com";
  }

  const onCloseForgotUsernameOrPassword = () => {
    setShowForgotPassword(false);
  }
  
  // TODO: add on key down func for entering password too, https://trello.com/c/V3GukgrC/627-login-form-needs-keydown

  return (
    <Form className={styles.loginForm}>
      <TextField type={TextFieldTypes.Email} 
        name="email" 
        placeholder="Username"
        value={values.email}
        onChange={onTextFieldChange}
        className={classNames(styles.inputField, errors.email && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.email}/>

      <TextField type={TextFieldTypes.Password} 
        name="password" 
        placeholder="Password"  
        value={values.password}
        onChange={onTextFieldChange}
        className={classNames(styles.inputField, errors.password && styles.errorField)}
        labelClassName={styles.labels} 
        errorMessage={errors.password}
        icon={<ArrowIcon className={styles.arrowIcon} onClick={() => { return _.isEmpty(errors) && submitForm(); }} />}/>

      <Button loading={isSubmitting} 
        disabled={isSubmitting} 
        type="submit"
        style={ButtonStyle.Primary}
        className={styles.accessPortalButton}>
        <FormattedMessage id="buttons.accessPortal" defaultMessage="Access Portal"/>
      </Button>

      {_.isEmpty(errors) && loginError && (
        <div className={styles.errorMessage}>
          <FormattedMessage id={loginError.intlId} defaultMessage={loginError.defaultMessage || "Sorry, there was an error."}/>
        </div>
      )}

      <div className={styles.otherButtons}>
        <Button onClick={onForgotUsernameOrPassword}
          style={ButtonStyle.Underline}
          type="button"
          className={styles.forgotPassButton}>
          <FormattedMessage id="buttons.forgotUsernameOrPassword" defaultMessage="Forgot username/password?"/>
        </Button>

        <Button onClick={onContactSupport}
          style={ButtonStyle.Underline}
          type="button"
          className={styles.contactButton}>
          <FormattedMessage id="buttons.contactSupport" defaultMessage="Contact support"/>
        </Button>
      </div>

      {showForgotPassword && (<ForgotPasswordForm onClose={onCloseForgotUsernameOrPassword}/>)}

    </Form>
  );
};
