import * as base from "./lib/baseActions";
import { NoteType } from "actions/noteActions";
import { DataWrapper } from "./lib/baseActions";
import { Organization } from "./organizationActions";
import { UserInfo } from "context/user";
import { AssignOrganizationsErrors } from "pages/AssignOrganizations";
import { AssignDepartmentsErrors, AssignUsersErrors } from "pages/AssignUsers";
import { LineGraphData } from "components/LineGraph";
import { Department } from "./departmentActions";
import { RoleType } from "./roleActions";
import { PoliceStatus, SupportCenterStatus } from "lib/reportStatuses";

enum ThreePathsType {
  ReportAnonymously = "reportAnonymously",
  CaptureTheExperience  = "captureTheExperience",
  GiveAnImpactStatement = "giveAnImpactStatement"
}

export enum ReportFields {
  AllowPoliceContact = "allowPoliceContact",
  AllowSupportCenterContact = "allowSupportCenterContact",
  AllowCampusContact = "allowCampusContact",
  AllowVestaContact = "allowVestaContact",
  AcknowledgementOfConsent = "acknowledgementOfConsent",
  SupportOrganization = "supportOrganization",
  TypesOfSupport = "typesOfSupport",
};

export interface ReportLocationType {
  address: string;
  lat: number;
  long: number;
  date?: Date;
  type?: string;
  image?: string;
}


export interface ReportStatsType {
  totalReports: number;
  totalSavedNotSubmittedReports: number;
  totalSubmitted: LineGraphData[];
  totalSupportConsent: number;
  totalPoliceConsent: number;
  totalCampusConsent: number;
  totalBothPoliceAndSupportConsent: number;
  totalBothPoliceAndCampusConsent: number;
  totalBothSupportAndCampusConsent: number;
  totalPoliceSupportAndCampusConsent: number;
  totalNeitherPoliceNorSupportNorCampusConsent: number;
  totalPoliceUnassigned: number;
  totalPoliceAssignedUnactioned: number;
  totalActiveOngoing: number;
  totalClearedByCharge: number;
  totalClearedInsufficientEvidence: number;
  totalClearedNoChargeableSuspectIdentified: number;
  totalClearedComplainantDeclinesToProceed: number;
  totalClearedOtherwise: number;
  totalKnownOffenders: number;
  totalUnknownOffenders: number;
  totalNamedOffenders: number;
  totalNoResponseOffender: number;
  totalSupportCenterUnassigned: number;
  totalSupportCenterAssignedUnactioned: number;
  totalActiveAppointmentBooked: number; 
  totalActiveWaitlisted: number; 
  totalActiveCounselling: number; 
  totalDischargedNoContact: number; 
  totalDischargedChangedMind: number;
  totalDischargedReferredElsewhere: number;
}

export interface reportStatus {
  id?: string | number;
  organizationId?: string | number;
  isSystem?: boolean;
  isInitial?: boolean;
  isDefault?: boolean;
  name?: string;
  displayName?: string;
}

export interface ReportData {
  id?: string | number;
  reportKey?: string;
  userId?: string | number;
  type?: string;
  acknowledgementOfConsent?: boolean;
  allowPoliceContact?: boolean;
  allowSupportCenterContact?: boolean;
  allowCampusContact?: boolean;
  allowVestaContact?: boolean;
  policeStatus?: PoliceStatus; // TODO: REMOVE
  supportCenterStatus?: SupportCenterStatus; // TODO: REMOVE
  dateSubmitted?: Date;
  releaseVersion?: string;
  dateReviewed?: Date;
  datePassedToLawEnforcement?: Date;
  dateInvestigationStarted?: Date;
  methodOfContact?: string;
  notes?: NoteType[];
  reportPathType?: ThreePathsType;
  assignedOrganizations?: Organization[],
  assignedDepartments?: Department[],
  assignedUsers?: (UserInfo & RoleType)[],
  createdAt?: Date;
  updatedAt?: Date;
  otherReportKeys?: Array<string>;
  supportLocationId?: Number;
  supportLocation?: string;
  supportOrganization?: SupportLocationType;
  campusSupportLocationId?: Number;
  campusSupportOrganization?: SupportLocationType;
  followIntakeFormSupportLocationId?: Number;
  statuses?: Map<string,Organization>;
  typesOfSupport?:SupportType[],
};

export interface ReportOrganizationType {
  id?: string | number;
  reportId?: string | number;
  organizationId?: string | number;
  createdAt?: Date;
  updatedAt?: Date;
};

export interface ReportDepartmentType {
  id?: string | number;
  reportId?: string | number;
  departmentId?: string | number;
  createdAt?: Date;
  updatedAt?: Date;
};

export interface ReportUserType {
  id?: string | number;
  reportId?: string | number;
  userId?: string | number;
  createdAt?: Date;
  updatedAt?: Date;
};

export interface SupportLocationType {
  id: number;
  organizationLocation: string;
  organizationName: string;
  hasDashboardAccess: boolean;
  hasReportsAccess: boolean;
  websiteLink: string;
  intakeFormLink: string;
  crisisLink: string;
  crisisPhone: string;
  hidden?:boolean
}

export interface SupportType {
  value: number;
  text: string;
}
const getSurvivorAndReportById = async (reportId: string | number): Promise<DataWrapper<any> | undefined> => {

  try {
    const result = await base.get(`reports/${reportId}`);
    return result;
  } catch(err) {
    // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
    console.log(err);
  }
};

export type ReportInfo = UserInfo & ReportData;

const getReports = async (searchQuery?: string | undefined): Promise<DataWrapper<(UserInfo & ReportData)[]> | undefined> => {

  try {
    let getReportsUrl = 'reports';
    if (searchQuery) {
      getReportsUrl += `?searchQuery=${searchQuery}`;
    }
    const reportsResult = await base.get(getReportsUrl);
    return reportsResult;
  } catch(err) {
    // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
    console.log(err);
  }
};

const getReportContactInfoAnswers = async (reportId: string | number) => {
  const getReportAnswers = await base.get(
    `reports/${reportId}/answers/contactInfo`
  );

  return getReportAnswers;
};

const getReportAnswers = async (reportId: string | number) => {
  const getReportAnswers = await base.get(
    `reports/${reportId}/answers`
  );

  return getReportAnswers;
};

const getReportStats = async (startTime: Date, endTime: Date): Promise<DataWrapper<ReportStatsType> | undefined> => {

  try {
    const result = await base.get(`reportStats?startTime=${startTime}&endTime=${endTime}`);
    return result;
  } catch(err) {
    // TODO: https://trello.com/c/D2oyeSVv/391-error-handling-updates
    console.log(err);
  }
};

const updateReportById = async (reportId: string | number, data: ReportData) => {
  const updateReportResult = await base.put(
    `reports/${reportId}`,
    data
  );

  return updateReportResult;
};

const updateAssignedReportOrganizations = async (reportId: string | number, data: { organizationIdArray: number[] }) => {
  try {
    const updateAssignedReportOrganizationsResult = await base.put(
      `reports/${reportId}/organizations`,
      data
    );
    return updateAssignedReportOrganizationsResult;
  } catch (err) {
    throw base.customizeError(err, AssignOrganizationsErrors.GeneralAssignOrganizationError);
  }
};

const updateAssignedReportUsers = async (reportId: string | number, data: { userIdArray: number[], departmentIdArray: number[] }) => {
  try {
    const updateAssignedReportUsersResult = await base.put(
      `reports/${reportId}/users`,
      data
    );
    return updateAssignedReportUsersResult;
  } catch (err) {
    throw base.customizeError(err, AssignUsersErrors.GeneralAssignUserError);
  }
};

export {
  getSurvivorAndReportById,
  getReports,
  getReportAnswers,
  getReportContactInfoAnswers,
  getReportStats,
  updateReportById,
  updateAssignedReportOrganizations,
  updateAssignedReportUsers,
};
